import Vue from 'vue'
import {filterFactory} from './factories'

/**
 * Mixin to provide global functions to manage local/session storage.<br>
 * Provides methods to:
 * - Set value
 * - Get value
 * - Remove value
 * - Reset/clear all values
 * @mixin
 */
export const localStorageMixin = {
  methods: {
    /*
     * Method to set value in local/session storage
     * @param {string} item - Name of the variable in storage.
     * @param {string} value - Value of the variable in storage.
     */
    setLocalStorage (item, value) {
      localStorage.setItem(item, value)
    },
    /*
     * Method to retrieve a value from local/session storage
     * @param {string} item - Name of the variable in storage.
     */
    getLocalStorage (item) {
      return localStorage.getItem(item)
    },
    /*
     * Method to clear a single value in local/session storage
     * @param {string} item - Name of the variable in storage.
     */
    removeLocalStorage (item) {
      localStorage.removeItem(item)
    },
    /*
     * Method to clear/remove all values that are set in local/session storage
     */
    resetLocalStorage () {
      localStorage.clear()
    }
  }
}

/**
 * Mixin to provide global methods to manage session timing.<br>
 * Provides methods to:
 * - Update Session
 * @mixin
 */
export const sessionManager = {
  methods: {
    /**
     * Method to update time in session to current time, measured in seconds.
     */
    updateSession () {
      localStorage.setItem('session time', Math.round(Date.now() / 1000))
    }
  }
}

/**
 * Mixin to provide global methods to open and close modal windows.<br>
 * Provides methods to:
 * - Close Modal
 * - Open Modal
 * @mixin
 */
export const modalWindowManager = {
  methods: {
    /**
     * Method to update time in session to current time, measured in seconds.
     */
    closeModal (key) {
      Vue.set(this.modalIsActive, key, false)
    },
    openModal (key) {
      Vue.set(this.modalIsActive, key, true)
    }
  }
}

/**
 * Mixin to provide global methods to manage session timing.<br>
 * Provides methods to:
 * - Update Session
 * @mixin
 */
export const tableFilterManager = {
  computed: {
    /**
     * Fetch the active filters from vuex state
     * Create filter type in state if not exist
     */
    getFilters () {
      if (typeof this.$store.state.filterList[this.filterKey] === 'undefined') {
        this.$store.dispatch('updateFilterState', [this.filterKey, [], true])
      }
      return Object.values(this.$store.state.filterList[this.filterKey])
    },
    /**
     * Get the filters data
     */
    filters () {
      // To get the filters for the activation and repo shipments
      const filterList = Object.values(this.$store.state.filterList[this.filterKey])
      const preFilter = (filterList.length === 0) ? false : filterList
      return filterFactory(this.tableFilters, preFilter)
    },
    // skipcq: JS-0044.  Allow Cyclomatic complexity for this item
    tableFilters () {
      if (!this.filterConfig || this.filterConfig.length === 0) {
        return []
      }
      let customersAst = []
      let customersShp = []
      let astStatusOpts = []
      let statusOpts = []
      let alertOpts = []
      let deviceStatusOpts = []
      let locationsOpts = []
      let vehicleStatusOpts = []
      const filterOptions = this.filterConfig.map(flt => flt.id)
      const filterList = []

      // Get the customer options only if the config have the value 'custSelAst'
      if (filterOptions.includes('custSelAst')) {
        customersAst = [...new Set(Object.values(Object.values(this.$store.state.shipments.all).filter(yp => yp.class.includes('Asset'))).map(x => x.customer))]
      }

      // Get the customer options only if the config have the value 'custSelShp'
      if (filterOptions.includes('custSelShp')) {
        customersShp = [...new Set(Object.values(Object.values(this.$store.state.shipments.all)).map(x => x.customer))]
      }

      // Get the location options only if the config have any of the following in [originSel, originChk, destinationSel, destinationChk]
      if (filterOptions.includes('originSel') || filterOptions.includes('originChk') || filterOptions.includes('destinationSel') || filterOptions.includes('destinationChk')) {
        locationsOpts = Object.values(this.$store.state.locations.all).map(loc => { return loc.name }).sort()
      }

      // Get the device status options only if the config have any of the following in [devStatusSel, devStatusChk]
      if (filterOptions.includes('devStatusSel') || filterOptions.includes('devStatusChk')) {
        const deviceDefs = this.$store.state.stateDefs.device
        deviceStatusOpts = Object.entries(deviceDefs).map(x => {
          return {'name': x[1], 'val': x[0]}
        })
      }

      // Get the shipment status options only if the config have any of the following in [astStatusHdn, astStatusSel, astStatusChk]
      if (filterOptions.includes('astStatusHdn') || filterOptions.includes('astStatusSel') || filterOptions.includes('astStatusChk')) {
        const assetStateDefs = this.$store.state.stateDefs.asset
        astStatusOpts = Object.entries(assetStateDefs).map(x => {
          return {'name': x[1], 'val': x[0]}
        })
      }

      // Get the vehicle status options only if the config have any of the following in [astStatusHdn, astStatusSel, astStatusChk]
      if (filterOptions.includes('vehicleStatusHdn') || filterOptions.includes('vehicleStatusSel') || filterOptions.includes('vehicleStatusChk')) {
        const vehicleStateDefs = this.$store.state.stateDefs.vehicle
        vehicleStatusOpts = Object.entries(vehicleStateDefs).map(x => {
          return {'name': x[1], 'val': x[0]}
        })
      }

      // Get the shipment status options only if the config have any of the following in [statusHdn, statusSel, statusChk]
      if (filterOptions.includes('statusHdn') || filterOptions.includes('statusSel') || filterOptions.includes('statusChk')) {
        const shippingStateDefs = this.$store.state.stateDefs.shipping
        statusOpts = Object.entries(shippingStateDefs).map(x => {
          return {'name': x[1], 'val': x[0]}
        })
      }

      // Get the alert code options only if the config have any of the following in [alertHdn, alertSel, alertChk]
      if (filterOptions.includes('alertHdn') || filterOptions.includes('alertSel') || filterOptions.includes('alertChk')) {
        const alertFilters = new Set(Object.values(this.$store.state.configuration.alertFilters))
        alertOpts = [...alertFilters].map(alert => {
          const currentAlert = this.$store.state.configuration.alertEvent[alert]
          return {name: currentAlert.name, val: alert}
        })
      }

      const allFilters = {
        alertHdn: {col: 'securityStateStr', opts: alertOpts, hidden: true},
        alertSel: {col: 'securityStateStr', opts: alertOpts},
        alertChk: {col: 'securityStateStr', opts: alertOpts, type: 'checkbox'},
        astStatusHdn: {col: 'shippingState', opts: astStatusOpts, hidden: true},
        astStatusSel: {col: 'shippingState', opts: astStatusOpts},
        astStatusChk: {col: 'shippingState', opts: astStatusOpts, type: 'checkbox'},
        customerEnt: {col: 'customer'},
        custSelAst: {col: 'customer', opts: customersAst},
        custSelShp: {col: 'customer', opts: customersShp},
        destinationEnt: {col: 'destination'},
        destinationHdn: {col: 'destination', hidden: true},
        destinationSel: {col: 'destination', opts: locationsOpts},
        destinationChk: {col: 'destination', opts: locationsOpts, type: 'checkbox'},
        devStatusSel: {col: 'statusCode', opts: deviceStatusOpts},
        devStatusChk: {col: 'statusCode', opts: deviceStatusOpts, type: 'checkbox'},
        devStatusEnt: {col: 'statusCode'},
        locRegion: {col: 'locRegion'},
        macId: {col: 'macId'},
        originEnt: {col: 'origin'},
        originHdn: {col: 'origin', hidden: true},
        originSel: {col: 'origin', opts: locationsOpts},
        originChk: {col: 'origin', opts: locationsOpts, type: 'checkbox'},
        shipId: {col: 'clientShipmentId'},
        shipIdHdn: {col: 'clientShipmentId', hidden: true},
        statusHdn: {col: 'shippingState', opts: statusOpts, hidden: true},
        statusSel: {col: 'shippingState', opts: statusOpts},
        statusChk: {col: 'shippingState', opts: statusOpts, type: 'checkbox'},
        vehicleStatusHdn: {col: 'shippingState', opts: vehicleStatusOpts, hidden: true},
        vehicleStatusSel: {col: 'shippingState', opts: vehicleStatusOpts},
        vehicleStatusChk: {col: 'shippingState', opts: vehicleStatusOpts, type: 'checkbox'}
      }

      this.filterConfig.forEach((fltr) => {
        if (typeof allFilters[fltr.id] === 'undefined') {
          return
        }
        const filter = allFilters[fltr.id]
        filter.name = fltr.label
        filterList.push(filter)
      })

      return filterList
    }
  },
  methods: {
    /**
     * Remove the filter
     * Will update the filter state once remove the filter
    */
    clearFilter (fltr) {
      // console.log('Clear Now...xxxx....')
      this.filters.clear(fltr)
      const filters = this.filters.getActive()
      // On clering the filter, check the filter has a multi option type. If it has, set the value as array else empty value
      const value = (fltr.type === 'checkbox' || fltr.type === 'multiSelect') ? [] : ''
      filters.push(Object.assign({}, {col: fltr.col, name: fltr.name, val: value, active: false}))
      this.$store.dispatch('updateFilterState', [this.filterKey, filters])
    },
    /**
     * Update the filters and update in state
    */
    filtersReset () {
      this.filters.reset()
      this.$store.dispatch('updateFilterState', [this.filterKey, this.filters.getAll()])
    },
    /**
     * Get the exact filter value
     * If filter type is dropdown then return the option name, else return the value
    */
    getFilterValue (filter) {
      let filterVal = filter.val
      if (typeof filter.opts !== 'undefined') {
        // convert the filter value as a number if type is number
        let numFilterVal = filterVal
        if (Number(numFilterVal) !== 'NaN' && Number.isSafeInteger(Number(numFilterVal))) numFilterVal = Number(numFilterVal)
        // skipcq: JS-D1001.  Allow empty comment
        const fltrFn = function (option) {
          if (typeof option.val === 'undefined') return false
          // Get the option value
          let value = option.val
          // convert the option value as a number if the type is number
          if (Number(value) !== 'NaN' && Number.isSafeInteger(Number(value))) value = Number(value)
          // If the filter type is a multi option, check the value is exist in the filter options, else check the filter value is === value in the row
          // Convert the both the filter value and option value and compare the values vice versa
          return (filter.type === 'checkbox' || filter.type === 'multiSelect') ? filterVal.includes(option.val) : option.val === filterVal || value === filterVal || numFilterVal === value
        }
        const filterObj = filter.opts.filter(fltrFn).map(column => column.name)
        // Join the multiple options with | separator
        filterVal = (filterObj.length === 0) ? filterVal : filterObj.join(' | ')
      }
      // Join the multiple options with | separator
      return Array.isArray(filterVal) ? filterVal.join(' | ') : filterVal
    },
    setFilterbyQuery () {
      /**
       * Get the multiple filter values using split method from query string
      */
      let filterPayload = []
      let reset = false
      if (this.$route.query.fltcol !== '' && typeof this.$route.query.fltcol !== 'undefined') {
        const splitColumn = this.$route.query.fltcol.split(',')
        const splitColVal = this.$route.query.fltval.split(',')
        filterPayload = this.tableFilters.filter(column => splitColumn.includes(column.col)).map(mf => {
          const idx = splitColumn.indexOf(mf.col)
          let value = splitColVal[idx]
          // If the filter type is a multi value option, then split the value with | separator,
          if (mf.type === 'checkbox' || mf.type === 'multiSelect') {
            value = value.split('|')
          }
          mf.active = true
          mf.val = value
          return mf
        })
        reset = true
      }
      this.$store.dispatch('updateFilterState', [this.filterKey, filterPayload, reset])
    }
  }
}
