// Douglas Peucker polyline simplification algorithm
// Adapted from http://onthegomap.com

// utilities
const sqr = function (x) { return x * x }
const distSquared = function (p1, p2) {
  return sqr(p1['lat'] - p2['lat']) + sqr(p1['lng'] - p2['lng'])
}

// Representation of a line segment, used to determine distance to a point
const Line = function(p1, p2) {
  this.p1 = p1
  this.p2 = p2
  this.lengthSquared = distSquared(p1, p2)
}
Line.prototype = {
    getRatio: function (point) {
      const p1 = this.p1, p2 = this.p2
      const segmentLength = this.lengthSquared
      if (segmentLength === 0) {
          return distSquared(point, p1)
      }
      return ((point['lat'] - p1['lat']) * (p2['lat'] - p1['lat']) +
          (point['lng'] - p1['lng']) * (p2['lng'] - p1['lng'])) / segmentLength
    },
    distanceToSquared: function (point) {
      const p1 = this.p1, p2 = this.p2
      const t = this.getRatio(point)
      if (t < 0) { return distSquared(point, p1) }
      if (t > 1) { return distSquared(point, p2) }
      return distSquared(point, {
          lat: p1['lat'] + t * (p2['lat'] - p1['lat']),
          lng: p1['lng'] + t * (p2['lng'] - p1['lng'])
      })
    },
    distanceTo: function (point) {
      return Math.sqrt(this.distanceToSquared(point))
    }
}

export const simplifyDouglasPeucker = function (points, pointsToKeep) {
  let weights = []
  const len = points.length
  function douglasPeucker (start, end) {
    if (end > start + 1) {
      const line = new Line(points[start], points[end])
      let maxDist = -1
      let maxDistIndex = 0
      let dist
      // find point furthest off the line from start to end
      for (let i = start + 1; i < end; i += 1) {
        dist = line.distanceToSquared(points[i])
        if (dist > maxDist) {
          maxDist = dist
          maxDistIndex = i
        }
      }
      // record the weight of this point
      weights[maxDistIndex] = maxDist
      // split the segment at that furthest point, and recursively invoke
      // this method to assign weights to each point in the subsegments
      douglasPeucker(start, maxDistIndex)
      douglasPeucker(maxDistIndex, end)
    }
  }

  douglasPeucker(0, len - 1)
  weights[0] = Infinity
  weights[len - 1] = Infinity

  // create descending weight array so to get n most important weights,
  // just find all points with weights >= weightsDescending[n]
  const weightsDescending = weights.slice()
  weightsDescending.sort(function (a, b) {
    return b - a
  })

  const maxTolerance = weightsDescending[pointsToKeep -1]
  const result = points.filter(function (d, i) {
    return weights[i] >= maxTolerance
  })
  return result
}