import Vue from 'vue'

// initial state
const getDefaultState = () => {
  return {
    all: {},
    available: []
  }
}

const state = getDefaultState()

// getters
const getters = {
  getAvailable: (state) => () => {
    return state.available
  }
}

// actions
const actions = {
  /**
   * Calls RESET_STATE mutator to set state to initial values set upon creation.
   */
  resetState ({ commit }) {
    commit('RESET_STATE')
  }
}

// mutations
const mutations = {
  SET_TAGS (state, tags) {
    // console.log('TAG DATA: ', tags)
    for (let tag of tags) {
      Vue.set(state.all, tag.macId, tag)
    }
  },
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
