import {
  getTableEntriesProvider
} from '../providers'
import {isBetween, round} from '@/store/helpers.js'

import Vue from 'vue'
import moment from 'moment-timezone'

// initial state
const getDefaultState = () => {
  return {
    all: {}
  }
}

const state = getDefaultState()

// getters
const getters = {
  /**
   * Gets ambient weather records for a single station of a specific type (temp, humid, ect.)
   * Returns value IFF record exists in state, otherwise returns empty value.
   * @param {object} state - Vuex state
   * @param {String} key - Weather station id (key in state table)
   * @param {Array} range - Date range to filter data on (or false if no filter)
   * @param {String} field - Weather data type (temp, humid) to return
   * @returns {Array} - Array of tags that conform to given status
   * @todo restore filter function using 'inbetween' helper
   */
  ambientReadings: (state) => (key, field, range = false) => {
    if (state.all[key]) {
      console.log('Ambient: ', {key, field, range, f: state.all[key].length})
      const tsData = state.all[key].map(d => [d.timestamp, d[field]])
      return range ? tsData.filter(isBetween(range)) : tsData
    } else {
      return []
    }
  }
}

// actions
const actions = {
  /**
   * Populates ambient weather records for a single station from weatherData table
   * and adds to state, IFF records do not alreay exist.  If no data is found for
   * the station, a call to weather data table is made:<br>
   * - If successful, updates state using {@link setAmbientWeather}<br>
   * - If error, return w/o state update<br>
   * If no data for station is found, nothing happens.
   * @param {object} state - Vuex state
   * @param {Array} stations - List of stations to update
   * @todo accept dates as input (from slider or otherwise) to set limits on query
   */
  populateAmbientWeather ({ commit, state, rootState }, [stationId, max = 720]) {
    // add the module in the initialized modules array in state to reset on logging out
    if (!rootState.initialized.includes('weather')) {
      commit('setInitializedModule', 'weather', { root: true })
    }
    // If station is not already stored in vuex state - get weather data and add it
    console.log('--- populateAmbientWeather ---')
    const temperatureUnit = rootState.user.temperatureUnit || 'Fahrenheit'
    if (!state.all[stationId]) {
      const payload = {
        max: max,
        tableName: 'weatherData',
        where: `locationId = ${stationId}`,
        sort: 'recordingTime DESC'
      }
      getTableEntriesProvider(rootState, payload).then(response => {
        commit('SET_WEATHER_READINGS', [stationId, temperatureUnit, response.data])
      }).catch(e => {
        console.log(e)
      })
    } else {
      console.log('Station Found: ', stationId)
    }
  }
}

// mutations
const mutations = {
  /**
   * Updates state for ambient weather records from weatherData table<br>
   * @param {object} state - Vuex state
   * @param {object[]} inputs - Input to function
   * @param {string} inputs.station - Weather station id to data is to be applied.
   * @param {object} inputs.payload - Data payload of weather data
   */
  SET_WEATHER_READINGS (state, [station, temperatureUnit, payload]) {
    let weatherData = []
    for (let obj in Object.values(payload)) {
      const temperature = temperatureUnit === 'Celsius' ? (payload[obj].temperature - 32) * 5 / 9 : payload[obj].temperature
      const weatherPayload = {
        timestamp: Number(moment(payload[obj].recordingTime, 'ddd MMM D HH:mm:ss YYYY').format('x')),
        temperature: round(temperature, 1),
        // temperature: round(payload[obj].temperature, 1),
        humidity: round(payload[obj].humidity * 100, 1),
        datetime: payload[obj].recordingTime
      }
      weatherData.unshift(weatherPayload)
    }
    Vue.set(state.all, station, weatherData)
  },
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
