import {
  getMessagesProvider,
  getQueryProvider
} from '../providers'
import {isBetween, round} from '@/store/helpers.js'

import Vue from 'vue'
import moment from 'moment-timezone'

const getDefaultState = () => {
  return {
    all: {},
    lastUpdate: ''
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  doorData: (state) => (id) => {
    return state.all[id].doorData
  },
  powerData: (state) => (id, field, range) => {
    let timeSeriesData = state.all[id].powerData.map(d => [d.timestamp, d[field]])
    return timeSeriesData.filter(isBetween(range))
  },
  timeSeries: (state) => (id, field, range) => {
    let timeSeriesData = state.all[id].tsData.map(d => [d.timestamp, d[field]])
    return timeSeriesData.filter(isBetween(range))
  }
}

// actions
const actions = {
  /**
   * Retrieves and stores door position data from custom message query.<br>
   * - If successful, updates state using {@link setDoorData}<br>
   * - If error, log to console & return w/o state update
   * @param {object} state - Vuex state
   * @param {object} deviceId - MAC address of device to query
   * @todo Account for multiple doors open at the same time.
   * @todo Remove duplicate entries for same hour
   * @todo Allow for date range in query vs. using number of records
   */
  fetchDoorData ({ commit, rootState, state }, deviceId) {
  //   let msgType = 1
  //   let pktType = 47 // state.gateways[deviceId].pktType
  //   let payload = {deviceId: deviceId, msgType: msgType, pktType: pktType, max: 400}
    let queryPayload = {
      query: 'doorStatus',
      params: ['MAC_' + deviceId, '200']
    }
    const doorNames = state.all[deviceId].doorNames || false
    // call get messages to get 48 message, and send to be added
    let doorData = []
    let doorStatusData = []
    const regex7 = RegExp('07', 'g')
    const promises = []
    const msgPayload = {deviceId: deviceId, msgType: 1, pktType: 47, max: 1}
    promises.push(getQueryProvider(rootState, queryPayload))
    // Get the door status data
    promises.push(getMessagesProvider(rootState, msgPayload))
    Promise.all(promises).then(responses => {
      const qryResp = responses[0]
      const msgResp = responses[1].data
      if (msgResp[0] && Object.keys(msgResp[0]).length > 0) {
        const doorFilter = RegExp('Door.$')
        const doorKeys = Object.keys(msgResp[0]).filter(key => (key !== '# Doors') && doorFilter.test(key))
        doorStatusData = doorKeys.map((key, idx) => {
          // If doornames list found in device and name found in the list then use it
          // Else return door key value from the door status list
          const name = doorNames && doorNames[idx] && doorNames[idx].length > 0 ? doorNames[idx] : key
          return {name, status: msgResp[0][key]} 
        })
      }
      let doorStr = regex7.test(qryResp.data[0].doors) ? '07' : '06'
      for (let val of Object.values(qryResp.data)) {
        const start = parseInt(moment.utc(val.dateHour, 'MM-DD-YYYY HH:mm').format('x'))
        // Number of door is the  = response[0].doors.length / 2
        doorData.unshift([
          val.doors.indexOf(doorStr) / 2,
          start,
          start + 3600000
        ])
      }
      // console.log('doorData', doorData)
      commit('UPDATE_WAREHOUSE', {
        id: deviceId,
        data: {doorData: doorData}
      })
      commit('UPDATE_WAREHOUSE', {
        id: deviceId,
        data: {doorStatus: doorStatusData}
      })
    }).catch(e => {
      console.log(e)
    })
  },
  /**
   * Retrieves and stores power consumption data.<br>
   * - If successful, updates state using {@link setPowerData}<br>
   * - If error, log to console & return w/o state update
   * @param {object} state - Vuex state
   * @param {object} deviceId - MAC address of device to query
   * @todo Merge in with getTempHumid
   */
  fetchPowerData ({ commit, rootState }, whId) {
    let powerPayload = {deviceId: whId, msgType: 200, pktType: 48, max: 700}

    // call get messages to get 48 message, and send to be added
    let tsData = []
    getMessagesProvider(rootState, powerPayload).then(response => {
      for (let i = 1; i < Object.keys(response.data).length; i++) {
        let pwr = response.data[i - 1]['Energy Deliv'] - response.data[i]['Energy Deliv']
        let dt = moment.utc(response.data[i - 1].sourceTime, 'ddd MMM D HH:mm:ss YYYY').format('x')
        // let dt = response[i - 1]['Device Time (UTC)']
        // let dateVal = new Date(dt).getTime()
        tsData.unshift({
          timestamp: parseInt(dt),
          power: round(pwr, 2)
        })
      }
      commit('UPDATE_WAREHOUSE', {
        id: whId,
        data: {powerData: tsData}
      })
    }).catch(e => {
      console.log(e)
    })
  },
  /**
   * Retrieves and stores temperature and humidity data from device message query.<br>
   * - If successful, updates state using {@link setTimeSeries}<br>
   * - If error, log to console & return w/o state update
   *
   * @param {object} state - Vuex state
   * @param {object} deviceId - MAC address of device to query
   * @todo Get pktType dynamically from device (may need to load during device initialization)
   * @todo Use dates to determine number of messages retrieved instead of fixed value of 400
   */
  fetchTempHumid ({ commit, state, rootState }, whId) {
    // If the unit has a RHT record (based on its configuration notes) it will use a standard
    // 47/0 message for temp and humidity data.  If not use the first dhUnit record as a fallback
    let pktType = state.all[whId].rht ? 47 : 48
    let msgType = state.all[whId].rht ? 0 : state.all[whId].dhUnits[0].msgType
    let payload = {deviceId: whId, msgType: msgType, pktType: pktType, max: 700}
    // Get messages of appropriate type and process....
    let tsData = []
    getMessagesProvider(rootState, payload).then(response => {
      console.log('WH TS: ', response)
      for (let val of Object.values(response.data)) {
        // console.log('WH TS: ', val)
        // Get timestamp from Device time
        // Get temperature/humidity from averages calculated by iGate an sent in messages
        // then add to the tsData array.
        const dt = moment.utc(val.sourceTime, 'ddd MMM D HH:mm:ss YYYY').format('x')
        tsData.unshift({
          timestamp: parseInt(dt),
          temperature: round(parseFloat(val['Avg_T (F)']), 1),
          humidity: round(parseFloat(val['Avg_H (%)']), 1)
        })
      }
      console.log('TS DATA FOUND: ', tsData)
      commit('UPDATE_WAREHOUSE', {
        id: whId,
        data: {tsData: tsData}
      })
    }).catch(e => {
      console.log(e)
    })
  },
  /*  Fetch all messages for DH units based on index etc.
   *
   */
  fetchDHUnitMessages ({ commit, state, rootState }, whId) {
    // packet type is always 48, message type comes from each unit's config
    // if unit has value for index, const pktType = 48
    console.log('response:', state.all[whId].dhUnits)
    if (state.all[whId].dhUnits) {
      let DHUnits = JSON.parse(JSON.stringify(state.all[whId].dhUnits))
      const promises = []
      DHUnits.forEach((unit) => {
        const pktType = unit.pktType
        const msgType = unit.msgType
        const payload = {deviceId: whId, msgType, pktType, max: 1}
        if (unit.index) {
          payload.where = `CAST(substring(data,21,2) AS SIGNED) = ${unit.index}`
        }
        promises.push(getMessagesProvider(rootState, payload))
      })
      Promise.all(promises).then(responses => {
        /* console.log('wh message types', responses[1].data)
        let messageTypes = getMessageTypes(responses[1].data.messages)
        let status = responses[0].data
        status[0].messageTypes = messageTypes */
        responses.forEach((response, index) => {
          DHUnits[index].data = response.data[0]
          DHUnits[index].unitIndex = index
          // notes.dhUnits[index].data = response.data
        })
        commit('UPDATE_WAREHOUSE', {id: whId, data: {dhUnits: DHUnits}})
        /* commit('SET_DETAILS', {id: whId, status: status})
        commit('SET_HIGH_HUMID', {id: payload.deviceId, temps: responses[2].data}) */
      }).catch(e => {
        console.log(e)
      })
    }
  },
  /**
   * Gets data from list of warehouses from warehouseDetails query and builds the list of
   * warehouse records in state.
   * State is updated in the warehouse specific mutators.
   */
  initialize ({ commit, dispatch, state, rootState }, {force = false} = {}) {
    // Execute the function, when the state have no value or forced to initialize again
    if (!force && Object.values(state.all).length !== 0) {
      return
    }
    // add the module in the initialized modules array in state to reset on logging out
    if (!rootState.initialized.includes('warehouses')) {
      commit('setInitializedModule', 'warehouses', { root: true })
    }
    console.log(`--- Initialize Warehouse  ---`)
    // Add to initialized set to reset state @ logout
    // rootState.initialized.add('warehouses')
    const getDeviceQuery = {
      query: 'warehouseDetails',
      params: [String(rootState.company.id)]
    }
    getQueryProvider(rootState, getDeviceQuery).then(response => {
      // console.log('response', response)
      let compareDate = state.lastUpdate
      if (!state.lastUpdate) {
        const offset = rootState.configuration.lastRecordOffsets.warehouses
        compareDate = moment().subtract(parseInt(offset.quantity), offset.unit)
        // Set initial value for use in processWarehouseData
        commit('RESET_LAST_UPDATE_TIME', compareDate)
      }
      for (let wrhs of Object.values(response.data)) {
        // if (!state.all.hasOwnProperty(wrhs.macId)) {
        if (!Object.prototype.hasOwnProperty.call(state.all, wrhs.macId)) {
          // New shipment... add to state.
          dispatch('processWarehouseData', wrhs) // Updates shipment
        }
        compareDate = moment.max(compareDate, moment.utc(wrhs.lastUpdate, 'ddd MMM D HH:mm:ss YYYY'))
      }
      commit('RESET_LAST_UPDATE_TIME', compareDate)
    }).catch(e => {
      console.log(e)
    })
  },
  /**
   * Processes all data that is set and fixed for a warehouse
   * When complete will call ADD_WAREHOUSE to update to add to state.<br>
   * Thus this need only be run once when warehouse is first fetched.
   * @param {object} payloadObj  - Object of all warehouse data retrieved from query
   */
  processWarehouseData ({commit, state, rootState}, payloadObj) {
    // Devine any required regexs
    // const genRegexObj = /(\w+:[\w .\-|]+)[\f\n\r]?/g
    // set up object for fields that are fixed/static i.e. those fixed when shipment created
    const timestamp = state.lastUpdate
    let baseObj = {
      activeAlerts: 0,
      battery: 0,
      doorStatus: [],
      humidity: payloadObj.humidity || 0,
      // lastProcessedMessage: timestamp,
      // lastMessageUpdate: timestamp,
      status: '',
      temperature: payloadObj.temperature || 0,
      timestamp: timestamp,
      tsData: [],
      doorData: [],
      powerData: [],
      type: 'DEVICE_IGATE'
    }
    // set miscellaneous parameters from 'Notes' field into baseObj
    // Expect parms to be in colon delimited list
    /* if (genRegexObj.test(payloadObj.notes)) {
      genRegexObj.lastIndex = 0
      for (const entry of payloadObj.notes.match(genRegexObj)) {
        const vals = entry.split(':')
        baseObj[vals[0]] = vals[1].trim()
      }
    } */
    // Set default for highHumidHours# at 0
    for (let iter = 0; iter <= 6; iter++) {
      baseObj['highHumidHours' + iter] = 0
    }
    // Merge the DH Unit details in the notes value with the DH unit configuration
    // based on the code value available for the unit
    let notes = JSON.parse(payloadObj.notes)
    const stateDHUnits = rootState.configuration.dhUnits
    notes.dhUnits.forEach((unit, index) => {
      const code = unit.code ? unit.code : 'none'
      notes.dhUnits[index] = {...stateDHUnits[code], ...notes.dhUnits[index]}
    })
    // create new warehouse object and add to state.  Note the json parse adds the data found
    // in the notes field directly to the configuration.
    commit('ADD_WAREHOUSE', Object.assign(payloadObj, baseObj, notes))
  },
  /**
   * Calls RESET_STATE mutator to set state to initial values set upon creation.
   */
  resetState ({ commit }) {
    commit('RESET_STATE')
  },
  /**
   * Dispatches {@link updateWarehouseStatus} for all gateways defined as warehouses
   * @param {object} state - Vuex state
   * @todo Filter gateways to only process warehouses
   */
  /* updateAll ({ commit, dispatch, state }) {
    // Get devices -> populate state
    // then loop through devices and update status
    console.log('--- updateWarehouses ---')
    for (let id of Object.keys(state.all)) {
      console.log('--- updateWarehouses ---> ', id)
      dispatch('updateById', id)
    }
  }, */
  updateAll ({ commit, state, rootState }) {
    const alertQueryPayload = {
      query: 'warehouseActiveAlertCount',
      params: []
    }
    const humidityQueryPayload = {
      query: 'warehouseHighHumidityCount',
      params: ['C0C00NWH-DATA-0000-1111-10E5A4776D2A']
    }
    const humidityTemperatureCountPayload = {
      query: 'warehouseHumidityAndTemperatureCount',
      params: ['C0C00NWH-DATA-0000-1111-10E5A4776D2A']
    }
    // call get messages to get 48 message, and send to be added
    Promise.all([
      getQueryProvider(rootState, alertQueryPayload),
      getQueryProvider(rootState, humidityQueryPayload),
      getQueryProvider(rootState, humidityTemperatureCountPayload)
    ]).then(responses => {
      console.log('responses', responses)
      Object.values(responses[0].data).forEach((val) => {
        if (state.all[val.macId]) {
          commit('UPDATE_WAREHOUSE', {
            id: val.macId,
            data: {activeAlerts: val.activeAlerts}
          })
        }
      })
      Object.values(responses[1].data).forEach((val) => {
        if (state.all[val.macId]) {
          // const humidAlert = `highHumidHours${val.diff}`
          const data = {}
          data[`highHumidHours${val.diff}`] = parseInt(val.highHumidCount)
          commit('UPDATE_WAREHOUSE', {
            id: val.macId,
            data: data
          })
        }
      })
      Object.values(responses[2].data).forEach((val) => {
        if (state.all[val.macId]) {
          commit('UPDATE_WAREHOUSE', {
            id: val.macId,
            data: {humidity: parseFloat(val.humidity), temperature: parseFloat(val.temperature)}
          })
        }
      })
      /* console.log('wh message types', responses[1].data)
      let messageTypes = getMessageTypes(responses[1].data.messages)
      let status = responses[0].data
      status[0].messageTypes = messageTypes
      commit('SET_DETAILS', {id: whId, status: status})
      commit('SET_HIGH_HUMID', {id: payload.deviceId, temps: responses[2].data}) */
    }).catch(e => {
      console.log(e)
    })
  }

  /**
   * Updates state values for single gateway/warehouse object<br>
   * Uses {@link getMessagesProvider} to get latest status message to set majority
   * of fields.  All updates are processed with {@link setWarehouseDetails}<br>
   * Uses {@link getQueryProvider} to call "warehouseWeeklyStatus" query to set
   * count of daily overages which are persisted using {@link setWarehouseHighTemp}
   * @param {Numer} whId - MAC Id of warehouse to be updated
   * @todo Use gateway state to determine packet type instead of hardoding to 48
   * @todo Update query and parameters to use timezone offset
   * @todo Use warehouse humid threshold from gateway state as variable query
   * parameter (instead of hardcoded 35)
   */
  /* updateById ({ commit, dispatch, state, rootState }, whId) {
    // getMessages parameters:
    const pktType = 48
    const msgType = state.all[whId].msgType
    const payload = {deviceId: whId, msgType: msgType, pktType: pktType, max: 1}
    // getQuery parameters:
    const queryParams = generateParams(whId, msgType, state.all[whId].avgHumid)
    const queryPayload = {
      query: 'warehouseWeeklyStatus',
      params: queryParams
    }
    // call get messages to get 48 message, and send to be added
    Promise.all([
      getMessagesProvider(rootState, payload),
      getDeviceStatusProvider(rootState, whId),
      getQueryProvider(rootState, queryPayload)
    ]).then(responses => {
      console.log('wh message types', responses[1].data)
      let messageTypes = getMessageTypes(responses[1].data.messages)
      let status = responses[0].data
      status[0].messageTypes = messageTypes
      commit('SET_DETAILS', {id: whId, status: status})
      commit('SET_HIGH_HUMID', {id: payload.deviceId, temps: responses[2].data})
    }).catch(e => {
      console.log(e)
    })
  } */
}

// mutations
const mutations = {
  /**
    * Adds a single warehouse to state
    * @param {Object} warehouse - Warehouse object to be added.
    */
  ADD_WAREHOUSE (state, warehouse) {
    Vue.set(state.all, warehouse.macId, warehouse)
  },
  /**
   * Updates timestamp of lastUpdate in sstate
   * @param {Object} updateTime - MomentJS object representing timestampe of last update.
   */
  RESET_LAST_UPDATE_TIME (state, updateTime) {
    state.lastUpdate = updateTime
  },
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  /**
   * Sets state values for single gateway/warehouse object
   * @param {object} payload - Update payload
   * @param {string} payload.id - MAC Id of gateway to be updated
   * @param {object} payload.status - Return payload of getMessagesProvider
   * @todo Build object according to type of DH unit
   * @todo Use door count (or lack of door count) to show correct # of doors
   * @todo Include door data (open/close)
   */
  /* SET_DETAILS (state, payload) {
    const dhDef = {
      'AuxHeat': {label: 'Aux Heat', category: 'discretes'},
      'Faults': {label: 'Faults', category: 'discretes'},
      'AFSwitch': {label: 'AF Switch', category: 'discretes'},
      'Desiccant Wheel': {label: 'DH Wheel', category: 'discretes'},
      'System Mode': {label: 'System Mode', category: 'discretes'},
      'DH Status': {label: 'DH Status', category: 'discretes'},
      'Alarms(1)': {label: 'Alarms', category: 'discretes'},
      'DH Runtime': {label: 'Dehumidifier', category: 'runtimes'},
      'DHRunTime(w0)': {label: 'Dehumidifier', category: 'runtimes'},
      'Sys Runtime': {label: 'System', category: 'runtimes'},
      'SysRunTime(w0)': {label: 'System', category: 'runtimes'},
      'CmprA Discharge P (psig)': {label: 'Comp A Discharge', category: 'Compressor Status'},
      'CmprB Discharge P (psig)': {label: 'Comp B Discharge', category: 'Compressor Status'},
      'CmprC Discharge P (psig)': {label: 'Comp C Discharge', category: 'Compressor Status'},
      'CmprD Discharge P (psig)': {label: 'Comp D Discharge', category: 'Compressor Status'},
      'messageTypes': {label: 'messageTypes'}
    }
    // Initialize Arrays to Create/Reset
    state.all[payload.id]['Compressor Status'] = []
    state.all[payload.id].discretes = []
    state.all[payload.id].doors = []
    state.all[payload.id].runtimes = []

    // Set temperature and humidity
    const temperature = round(payload.status[0]['Avg_T (F)'], 1)
    const humid = round(payload.status[0]['Avg_H (%)'], 1)
    state.all[payload.id].temperature = temperature
    state.all[payload.id].humidity = humid
    // Manage Doors if they are included
    const doorFilter = RegExp('Door.$')
    for (let key in payload.status[0]) {
      if (dhDef[key]) {
        const label = dhDef[key].label
        const value = payload.status[0][key]
        // console.log('KEY: ', key, label, value)
        if (dhDef[key].category) {
          const category = dhDef[key].category
          if (state.all[payload.id][category]) {
            // category already defined
            state.all[payload.id][category].push({[label]: value})
          } else {
            state.all[payload.id][category] = [{[label]: value}]
          }
        } else {
          state.all[payload.id][label] = value
        }
      }
      if (doorFilter.test(key)) {
        state.all[payload.id].doors.push({
          name: key,
          status: payload.status[0][key]
        })
      }
    }
  }, */
  /**
   * Sets state values for highHumidHours0 - highHumidHours6<br>
   * Data is in form of offset & count.  Offset is number of days back (from today) which
   * the count is applicable to.<br>
   * Count is the number of hours where humidity exceeded threshold.
   * @param {object} payload - Update payload
   * @param {Array} payload.temps - Array of objects that contain date offset and count of overage hours.
   */
  SET_HIGH_HUMID (state, payload) {
    console.log('Humidity Overage Payload ->', payload)
    for (let value of Object.values(payload.temps)) {
      state.all[payload.id]['highHumidHours' + value.dif] = value.count
    }
  },
  /**
     * Updates state for existing warehouse record on a prop by prop basis
   * @param {Object} payload - Object with id (macId) and data object to be applied
   * to the warehouse identified by the macid.
   * @param {String} payload.id - String providing warehouse id (mac)
   * @param {Object} payload.data - Data object with multiple entries, each a
   * a name value pair with the name being the warehouse property name and value
   * the data to be applied to that prop
   */
  UPDATE_WAREHOUSE (state, payload) {
    for (let key in payload.data) {
      Vue.set(state.all[payload.id], key, payload.data[key])
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
