<template>
  <ValidationObserver ref="observer">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      v-on:close="close()"
    >
      <div class="content">
        <div>
          <div v-html="description"></div>
          <form id="shareLinkModal">
            <div class="field-group">
              <div class="field">
                <p>To copy the link, copy text below or simply click on the “Copy Link” icon.</p>
                <div class="columns">
                  <div class="control column is-11">
                    <input type="text" class="input" readonly v-model="shareLinkVal" id="shareLinkVal">
                    <span v-show="shareLinkMsg !== ''" class="help is-success">{{shareLinkMsg}}</span>
                  </div>
                  <div class="column is-1"><base-icon :style="'cursor:pointer'" :name="'link_add'" :size="30" :color="'blue'" v-tooltip="'Copy link'" @click="shareLink()"></base-icon></div>
                </div>
              </div>
              <div class="field" v-if="sendShareLinkMsg === ''">
                <p>To send an email which includes the link, enter one or more valid email addresses below and click on the “Send Email” icon.</p>
                <div class="columns">
                  <div class="control column is-11">
                    <div class="columns" v-for="(emailField, indx) in fieldEmails" :key="indx">
                      <div class="column is-1"><base-icon :style="'cursor:pointer'" :name="'cancel'" :size="20" :color="'red'" @click="removeEmail(indx)" v-show="emailField.value !== ''"></base-icon></div>
                      <div class="control column is-11">
                        <ValidationProvider name="Email address" :rules="{ required: true, email: true }"  v-slot="{ errors }">
                          <input type="text" class="input" :name="emailField.name" :id="emailField.name" placeholder="Enter email address" v-model="emailField.value" :key="emailField.name">
                          <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-1"></div>
                      <div class="column is-4" v-show="fieldEmails[fieldEmails.length - 1].value !== ''">
                        <a @click="addEmail()"><base-icon :style="'cursor:pointer'" :name="'add'" :size="20" :color="'green'"></base-icon> Add email</a>
                      </div>
                    </div>
                  </div>
                  <div class="column is-1"><base-icon :style="'cursor:pointer'" :name="'mail'" :size="24" :color="'blue'" v-tooltip="'Send Email'" @click="sendEmail()"></base-icon></div>
                </div>
              </div>
              <p v-else class="help is-success">{{sendShareLinkMsg}}</p>
            </div>
          </form>
        </div>
      </div>
      <template slot="footer">
        <a class="button" @click="close()">Close</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex'
import { getUrl } from '@/store/helpers'
import IntelytModal from '@/components/IntelytModal'
import { modalWindowManager } from '@/store/mixins'

export default {
  components: {
    IntelytModal
  },
  computed: {
    shareLinkVal: function () {
      // public or pubship
      return `${getUrl(`/public?id=`)}${this.guid}-${this.company.publicUsername}`
    },
    ...mapState({
      company: 'company',
      user: 'user'
    })
  },
  data () {
    return {
      // title: 'Shipment Link',
      shareLinkMsg: '',
      sendShareLinkMsg: '',
      fieldEmailsInc: 0,
      fieldEmails: [{name: 'email0', value: ''}]
    }
  },
  methods: {
    addEmail () {
      this.fieldEmails.push({name: `email${++this.fieldEmailsInc}`, value: ''})
    },
    close () {
      this.$emit('close')
    },
    removeEmail (index) {
      (this.fieldEmails.length === 1) ? this.fieldEmails[index].value = '' : this.fieldEmails.splice(index, 1)
    },
    shareLink () {
      // Get the text field
      const vm = this
      //  Clear the email validation error message
      this.$refs.observer.reset()
      const copyText = document.getElementById('shareLinkVal')
      // Select the text field
      copyText.select()
      copyText.setSelectionRange(0, 99999) // For mobile devices
      //  Copy the text inside the text field
      document.execCommand('copy')
      vm.shareLinkMsg = `${this.type} link is copied to clipboard`
    },
    sendEmail () {
      this.$refs.observer.validate().then(result => {
        if (result) {
          const recipients = this.fieldEmails.map(field => field.value)
          // console.log('fieldEmails', this.fieldEmails, recipients)
          const email = {
            sender: 'globaltracking',
            subject: `INTELYT Global Tracking ${this.type} Access`,
            message: `${this.user.fullName} has invited you to review the status of a recent ${this.type.toLowerCase()} tracked using the INTELYT Global Tracking platform. Use the link below to access a live view of this ${this.type.toLowerCase()}.\n\nTracking Link:\n${this.shareLinkVal}\n\nIf you would like to learn more about INTELYT tracking and monitoring solutions:\nhttp://www.intelyt.com/\n\n\nThank you,\nINTELYT Global Tracking Team`,
            toList: recipients.join(',')
          }
          this.$store.dispatch('sendEmail', email).then(() => {
            this.sendShareLinkMsg = `${this.type} link has been sent to email address`
            this.fieldEmails = [{name: 'email0', value: ''}]
          }).catch(e => {
            console.log('send email error: ', e)
          })
        }
      }).catch((e) => {
        console.log('send email error: ', e)
      })
    }
  },
  mounted () {
    this.$refs.observer.reset()
  },
  mixins: [modalWindowManager],
  props: {
    isActive: {
      type: Boolean
    },
    guid: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Shipment Link'
    },
    type: {
      type: String,
      default: 'Shipment'
    },
    description: {
      type: String
    }
  },
  watch: {
    // whenever guid changes, this function will run
    isActive: {
      handler: function () {
        this.fieldEmailsInc = 0
        this.fieldEmails = [{name: 'email0', value: ''}]
        this.shareLinkMsg = ''
        this.sendShareLinkMsg = ''
        this.$refs.observer.reset()
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
