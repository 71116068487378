// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import Vuex from 'vuex'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
// for tooltips
import Tooltip from 'v-tooltip'
import {tooltipConfig} from '../static/js/static-config'
import '../static/css/v-tool-tip.css'
// Slider style sheet
import 'vue-slider-component/theme/default.css'
// import 'vue-directive-tooltip/css/index.css'
// vee-validate
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'

import {localStorageMixin} from '@/store/mixins'

import BaseIcon from '@/components/BaseIcon'
import BaseTable from '@/components/BaseTable'
import BaseChart from '@/components/BaseChart'
import IntelytAlert from '@/components/IntelytAlert'
import IntelytCard from '@/components/IntelytCard'
import IntelytList from '@/components/IntelytList'
import IntelytMap from '@/components/IntelytMap'
import LevelBar from '@/layout/LevelBar'
import ReportConfigModal from '@/modals/ReportConfigModal'
import ReportResultsModal from '@/modals/ReportResultsModal'
import SendCommandModal from '@/modals/SendCommandModal'
import ShowMessageModal from '@/modals/ShowMessageModal'

Vue.use(Vuex)
// Vue.use(VueNVD3)
// Vue.use(ClientTable)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDcR5tbfJ5OXC8q0zOO8cokr3aoUbYnwxg',
    libraries: 'places'
    // v: 'OPTIONAL VERSION NUMBER',
    // libraries: 'places', //// If you need to use place input
  }
})
Vue.component('GmapCluster', GmapCluster)
Vue.component('base-icon', BaseIcon)
Vue.component('base-table', BaseTable)
Vue.component('base-chart', BaseChart)
Vue.component('intelyt-alert', IntelytAlert)
Vue.component('intelyt-card', IntelytCard)
Vue.component('intelyt-list', IntelytList)
Vue.component('intelyt-map', IntelytMap)
Vue.component('level-bar', LevelBar)
Vue.component('report-config-modal', ReportConfigModal)
Vue.component('report-results-modal', ReportResultsModal)
Vue.component('send-command-modal', SendCommandModal)
Vue.component('show-message-modal', ShowMessageModal)

Vue.use(Tooltip, tooltipConfig)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.config.productionTip = false

Vue.mixin(localStorageMixin)

// Ground Overlay map component
Vue.component('ground-overlay', VueGoogleMaps.MapElementFactory({
  mappedProps: {
    'opacity': {}
  },
  props: {
    'source': {type: String},
    'bounds': {type: Object}
  },
  events: ['click', 'dblclick'],
  name: 'groundOverlay',
  ctr: function ctr () {
    return window.google.maps.GroundOverlay
  },
  ctrArgs: (options, {source, bounds}) => [source, bounds, options]
}))

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App }
})
