<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      v-on:close="close()"
    >
      <form id="manageAssetConfig">
        <div class="control is-horizontal">
          <div class="control-label">
              <label class="label">Name</label>
          </div>
          <div class="control">
            <ValidationProvider name="Name" :rules="{required: true}" v-slot="{ errors }">
              <input class="input" type="text" v-model="assetDetail.name"/>
              <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Type</label>
          </div>
          <div class="control">
            <ValidationProvider name="Type" :rules="{required: true}" v-slot="{ errors }">
              <input class="input" type="text" v-model="assetDetail.value"/>
              <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Class</label>
          </div>
          <div class="control">
            <input class="input" type="text" v-model="assetDetail.config"/>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Active</label>
          </div>
          <div class="control">
            <input type="checkbox" v-model="assetDetail.active"/>
          </div>
        </div>
      </form>
      <template slot="footer">
        <a class="button is-primary" @click="saveConfig()" :disabled="invalid">{{actionBtnTxt}}</a>
        <a class="button" @click="close()">Cancel</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex'
import IntelytModal from '@/components/IntelytModal'
import {isJSON} from '@/store/helpers'
import {updateCompanyProvider} from '@/store/providers'

export default {
  components: {
    IntelytModal
  },
  computed: {
    ...mapState({
      configuration: 'configuration',
      user: 'user'
    }),
    actionBtnTxt () {
      return this.isNew ? 'Create' : 'Update'
    },
    title () {
      return this.isNew ? 'Create Config' : 'Manage Config'
    }
  },
  data () {
    return {
      assetDetail: {}
    }
  },
  methods: {
    close () {
      this.$refs.observer.reset()
      this.$emit('close')
    },
    saveConfig () {
      const vm = this
      // If form is valid, update the values
      this.$refs.observer.validate().then(result => {
        if (result) {
          const assetDetail = {...vm.assetDetail}
          let prefs = isJSON(vm.$store.state.company.customPrefs) ? JSON.parse(vm.$store.state.company.customPrefs) : {}
          // let assetTypes = prefs.hasOwnProperty('assetTypes') ? Object.values(prefs.assetTypes) : []
          let assetTypes = Object.prototype.hasOwnProperty.call(prefs, 'assetTypes') ? Object.values(prefs.assetTypes) : []
          if (vm.isNew) { // If new config
            assetTypes.push(assetDetail)
          } else { // update the existing config
            // The configIndex is used to identify the config item order in the overall config list
            const idx = assetDetail.configIndex
            // We don't need to store it's value in the db. So remove it from the object before update it to db
            delete assetDetail.configIndex
            // Replace the config item from the config list using the index(configIndex)
            assetTypes.splice(idx, 1, assetDetail)
          }
          prefs.assetTypes = assetTypes
          const customPrefs = JSON.stringify(prefs)
          const updateObj = {
            property: {
              customPrefs
            }
          }
          updateCompanyProvider(vm.$store.state, updateObj, vm.$store.state.company.id).then(() => {
            vm.$store.commit('company/UPDATE_CONFIG', {customPrefs})
            vm.close()
          })
        }
      })
    }
  },
  props: ['isActive', 'isNew', 'assetConfig'],
  watch: {
    isActive: function () {
      this.assetDetail = this.assetConfig
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
