<template>
  <intelyt-modal :isActive="isActive" :title="title" v-on:close="close()">
    <div class="content">
      <div class="field-group">
        <div class="field" v-if="!isHtml">
          {{ message }}
        </div>
        <div class="field" v-if="data.length > 0">
          <template v-for="detail in data">
            <div :key="detail.label">
              <p style="font-weight: bold">{{ detail.label }}</p>
              <div style="margin-bottom: 1em" v-if="detail.data.length > 0">
                <span v-for="(mac, idx) in detail.data" :key="idx">
                  <a href="javascript:void(0)" @click="callbackFn(detail, mac)">{{ mac }}</a> <span v-if="(detail.data.length -1) > idx">- </span>
                </span>
              </div>
              <div v-else> - </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <template slot="footer">
      <a class="button" @click="close()">Close</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'

export default {
  components: {
    IntelytModal
  },
  computed: {},
  data () {
    return {}
  },
  methods: {
    close () {
      this.$emit('close')
    },
    callbackFn (item, key) {
      if (typeof item.callback !== 'undefined') {
        item.callback(key)
      }
    }
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isActive: Boolean,
    isHtml: {
      type: Boolean,
      default: false
    },
    message: String,
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
