<template>
  <div>
    <div class="container is-fluid is-marginless app-content ">
        <level-bar></level-bar>
        <router-view></router-view>
    </div>
    <div id="downloadCsvSection" style="display:none;"></div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      msg: 'Hello World! This is a Event listener test.',
      windowWidth: 0,
      windowHeight: 0
    }
  },
  methods: {
    getWindowWidth () {
      this.windowWidth = document.documentElement.clientWidth
    },
    getWindowHeight () {
      this.windowHeight = document.documentElement.clientHeight
    }
  },
  created () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      window.addEventListener('resize', this.getWindowHeight)
      // Init
      this.getWindowWidth()
      this.getWindowHeight()
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
    window.removeEventListener('resize', this.getWindowHeight)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.app-main {
  padding-top: 50px;
  margin-left: 10px;
  transform: translate3d(0, 0, 0);
  min-height: 92vh;
}

.app-content {
  padding: 10px;
}

</style>
