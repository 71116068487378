import Vue from 'vue'
// import moment from 'moment-timezone'

const getDefaultState = () => {
  return {
    alertGroup: {},
    assetTypes: [],
    id: '',
    logo: '',
    name: '',
    metrics: {},
    origins: [],
    publicUsername: '',
    reports: [],
    users: [],
    pageOverrides: {},
    msgOverrides: {},
    vehicleTypes: []
  }
}

// initial state
const companyState = getDefaultState()

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
//   SET_BASE_CONFIG (state, [companyObj, prefs]) {
//     // add any custom props if they are found
//     try {
//       const props = JSON.parse(prefs)
//       console.log('COMPANY PROPS: ', props)
//       companyObj.logo = props.logo || ''
//       companyObj.publicUsername = props.publicUsername || ''
//     } catch (err) {
//       console.log('Custom Props not set properly for Company')
//     }
//     for (const [key, val] of Object.entries(companyObj)) {
//       Vue.set(state, key, val)
//     }
//   },
  UPDATE_CONFIG (state, update) {
    // Do something
    // console.log('company/UPDATE_CONFIG', update)
    for (const [key, val] of Object.entries(update)) {
      Vue.set(state, key, val)
    }
  },
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_GROUP_PROP (state, {groupName, prop, value} = {}) {
    // Updates a single property for a single group in state
    // console.log('company/UPDATE_GROUP_PROP', groupName, prop, value)
    Vue.set(state.groups[groupName], prop, value)
  }
}

export default {
  namespaced: true,
  state: companyState,
  getters,
  actions,
  mutations
}
