<template>
  <div>
    <div class="field">
      <label class="label">{{label}}</label>
      <p class="control" >
        <gmap-autocomplete
          class="input"
          :value="locString"
          @place_changed="setPlace"
          placeholder="Enter Address">
        </gmap-autocomplete>
      </p>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  name: 'LocationEnter',
  data () {
    return {
      locString: ''
    }
  },
  methods: {
    setPlace (place) {
      const comp = place.address_components
      const location = {
        name: `${comp[2] ? comp[2].short_name : ''}, ${comp[4] ? comp[4].short_name : ''} ${comp[5] ? comp[5].short_name : ''}`,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: place.formatted_address
      }
      this.locString = place.formatted_address
      for (const locField of this.field) {
        this.$store.commit('SET_FORM_FIELD', {key: locField, value: location, mode: this.mode})
      }
    }
  },
  props: {
    label: String,
    mode: String,
    reset: Boolean,
    field: Array
  },
  watch: {
    reset (val) {
      // If reset is true, then set the value as blank
      if (val) this.locString = ''
    }
  }
}
</script>
