<template>
  <div>
    <level-bar>{{name}}</level-bar>
    <div class="box" v-if="metrics.length > 0">
      <nav class="level">
        <metric-item :metricData="metric" v-for="(metric,idx) in metrics" :key="idx">
        </metric-item>
      </nav>
    </div>
    <div class="columns">
      <div class="column is-narrow">
        <intelyt-card>
          <template slot="header">All Locations</template>
          <intelyt-map
             :markers="markers"
             :mapWidth="mapWidthCalc"
             :zoom="zoom"
             @markerDblClick="dblClickOpenModal"
          >
          </intelyt-map>
          <intelyt-map-legend
            v-if="legendMarkers.length > 0"
            :markers="legendMarkers"
            :activeMarkers="activeMarkers"
          ></intelyt-map-legend>
        </intelyt-card>
        <devices-card v-if="showDeviceCard"></devices-card>
      </div>
      <div class="column">
        <intelyt-card>
          <template slot="header">Locations</template>
          <template slot="controls">
            <span v-for="filter in getFilters" :key="filter.col">
              <span class="tag is-primary boldtext" v-if="filter.active === true && filter.val.length !== 0">
                {{filter.name}}: {{getFilterValue(filter)}}
                <button class="delete is-small" @click="clearFilter(filter)"></button>
              </span>
              &nbsp;
            </span>
            <span class="icon">
              <base-icon name="filter" @click="openModal('filterLocationTable')" v-tooltip="'Filter Location'" />
            </span>
          </template>
          <base-table
            :data="locationDetails"
            :columns="columns"
            :options="options"
            :filter="filters.getActive()"
          >
          </base-table>
        </intelyt-card>
      </div>
    </div>
    <filter-table-modal
      :filters="filters.getAll()"
      :isActive="modalIsActive.filterLocationTable"
      :modalTitle="'Filter Location'"
      v-on:close="closeModal('filterLocationTable')"
      v-on:reset="filtersReset()"
    ></filter-table-modal>
  </div>
</template>

<script>
// import BaseIcon from '@/components/BaseIcon'
import DevicesCard from '@/components/DevicesCard'
import FilterTableModal from '@/modals/FilterTableModal'
import IntelytMapLegend from '@/components/IntelytMapLegend'
import MetricItem from '@/components/MetricItem'
import { mapActions } from 'vuex'
import {modalWindowManager, tableFilterManager} from '@/store/mixins'
import {centerText} from '@/store/formatters.js'
import {getMapWidth} from '@/store/helpers.js'

export default {
  components: {
    DevicesCard,
    FilterTableModal,
    IntelytMapLegend,
    MetricItem
  },
  computed: {
    columns: function () {
      const pageOptions = this.$store.state.configuration.pageOptions.locationList
      const devTypeList = this.$store.state.devices.deviceTypes
      const nameLink = function (row) {
        // return path and query string for router link
        return {path: 'location-detail', query: { id: row.shippingLocId }}
      }
      /* Column list will start with all defined columns and then filter based on settings
      * in pageOptions.columns which is set in configuration
      */
      const genericColumns = [
        {label: 'Name', id: 'name', link: nameLink},
        {label: 'Type', id: 'locType'},
        {label: 'Code', id: 'locCode'},
        {label: 'Region', id: 'locRegion'}
        // {name: 'Shipments', id: 'activeShip', link: shipLink, formatter: centerText}
      ]
      let columns = genericColumns.filter(col => pageOptions.columns.includes(col.id))
      const devColumns = [
        {label: 'iGates', id: 'gate', formatter: this.formatGateIcon, devType: '3'},
        {label: 'iChargers', id: 'charge', formatter: this.formatGateIcon, devType: '7'},
        {label: 'iTags', id: 'tag', formatter: centerText, devType: '1'},
        {label: 'iChimes', id: 'chime', formatter: centerText, devType: '2'},
        {label: 'iScans', id: 'scan', formatter: centerText, devType: '5'},
        {label: 'iBeacon', id: 'beacon', formatter: centerText, devType: '8'}
      ]
      let filteredDevCols = devColumns.filter(col => devTypeList.includes(col.devType))
      return columns.concat(filteredDevCols)
    },
    locationDetails: function () {
      const mapFn = loc => {
        // console.log('loc', loc)
        // const gates = this.$store.getters['devices/gateways/getIdsByLocation'](loc.name)
        const activeShip = Object.values(this.$store.state.shipments.all).filter(x => x.origin === loc.name).length
        const beacon = this.$store.getters['devices/beacons/getCountByLocation'](loc.name)
        const charge = this.$store.getters['devices/chargers/getStatusByLocation'](loc.name)
        const chime = this.$store.getters['locations/chimeCount'](loc.name)
        const gate = this.$store.getters['devices/gateways/getStatusByLocation'](loc.name)
        const scan = this.$store.getters['devices/scanners/getCountByLocation'](loc.name)
        const tag = this.$store.getters['locations/tagCount'](loc.name)
        return {activeShip, beacon, charge, chime, gate, tag, scan, ...loc}
      }
      const locs = Object.values(this.$store.state.locations.all).map(mapFn)
      // console.log('LOCATIONS: ', this.$store.getters['devices/scanners/getCountByLocation']('Santa Clara'))
      return locs
    },
    mapWidthCalc: function () {
      return getMapWidth(this.$parent.windowWidth, 0.4)
    },
    markers: function () {
      let markerList = []
      const activeMarkers = {...this.activeMarkers}
      let indx = 1
      for (let loc of Object.values(this.$store.state.locations.all)) {
        const locType = loc.locType.toLowerCase() || 'location'
        // If the locType is available in the activeMarkers and marker is enabled
        if (!this.activeMarkers[locType] || !this.activeMarkers[locType].active) {
          continue
        }
        const online = loc.enabled === '1' ? 'Online' : 'Offline'
        const marker = {
          id: indx,
          icon: locType,
          name: loc.name,
          position: {
            lat: Number.parseFloat(loc.latitude),
            lng: Number.parseFloat(loc.longitude)
          },
          infoText: `Name: ${loc.name}</br>Code: ${loc.locCode || '-'}</br>Type: ${loc.locType || 'Location'}</br>Status: ${online}`,
          timestamp: 0
        }
        indx += 1
        activeMarkers[locType].hide = false
        markerList.push(marker)
      }
      // console.log('Marker List: ', markerList)
      return markerList
    }
  },
  created () {
    this.$store.dispatch('locations/initializeAll')
    // this.$store.dispatch('geofences/initializeAll')
    this.$store.dispatch('devices/initializeDeviceTypeList')
    this.$store.dispatch('geofences/initializeRouteList')
    this.$store.dispatch('devices/initialize', {deviceType: 'gateways', force: true})
    this.$store.dispatch('devices/initialize', {deviceType: 'chargers', force: true})
    this.$store.dispatch('devices/initialize', {deviceType: 'scanners'})
    this.$store.dispatch('devices/initialize', {deviceType: 'beacons'})
    this.$store.dispatch('devices/getCounts')
    // fetch device counts for tags, chimes and gateways for bar charts
    this.$store.dispatch('devices/fetchSummaryCounts')
    this.$store.dispatch('updateFilterState', ['locationList', []])
    this.setFilterbyQuery()
    this.$store.dispatch('waypoints/initialize')
  },
  data () {
    const pageOptions = this.$store.state.configuration.pageOptions.locationList
    const reduceFn = (acc, cur) => {
      const curType = cur.locType.length > 0 ? cur.locType.toLowerCase() : 'location'
      if (!acc.includes(curType)) acc.push(curType)
      return acc
    }
    const legendMarkers = Object.values(this.$store.state.locations.all).reduce(reduceFn, [])
    const activeMarkers = {}
    // Loop through the marker item and set the active and hide properties for activeMarkers
    legendMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    /* Build data object */
    return {
      name: 'Locations & Inventory',
      activeMarkers,
      // columns: allColumns.filter(col => pageOptions.columns.includes(col.id)),
      filterConfig: pageOptions.tableFilters,
      filterKey: 'locationList',
      legendMarkers: legendMarkers,
      modalIsActive: {
        filterLocationTable: false
      },
      geofence: {},
      isNew: true,
      metrics: pageOptions.metrics,
      options: {
        paginate: false,
        sortable: true,
        sortIndex: 0,
        sortType: 'asc'
      },
      paths: [],
      selected: null,
      showDeviceCard: pageOptions.showDeviceCard,
      zoom: 2
    }
  },
  methods: {
    formatGateIcon (gate) {
      const counts = gate.split(':')
      let icons = ''
      if (gate === '0:0') return '0'
      if (parseInt(counts[0]) > 0) {
        icons += `<span class="tag is-success">${counts[0]}</span>`
      }
      if (parseInt(counts[1]) > 0) {
        icons += `<span class="tag is-danger">${counts[1]}</span>`
      }
      return icons
    },
    dblClickOpenModal (mrk) {
      console.info('dblClickOpenModal', mrk)
    },
    ...mapActions([
    ])
  },
  mounted () {},
  mixins: [modalWindowManager, tableFilterManager]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
