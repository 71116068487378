<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      v-on:close="close()"
    >
      <div class="content">
        <form name='reset-password-form' autocomplete="off">
          <div class="control" v-if="displayMessage">
            &nbsp;<br />&nbsp;<br />&nbsp;
            {{message}}
            <br />&nbsp;<br />&nbsp;<br />
          </div>
          <div>
            <div class="content" v-if="!displayMessage">
              To request a new password enter your username, associated email address and new password.  Upon validation, a confirmation will code will be sent to you.  This code is valid for 1 hour.
              <br /><br />
              Once you receive this code, select "Verify Password" and enter appropriate information to reset the password to the new value.
            </div>
            <div class="control is-horizontal" v-if="!displayMessage">
              <div class="control-label">
                <label class="label">Username</label>
              </div>
              <div class="control" v-if="!displayMessage">
                <ValidationProvider name="Username" :rules="{ required: true }"  v-slot="{ errors }">
                  <input class="input" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" type="text" v-model="username" name="username"/>
                  &nbsp;
                  <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="control is-horizontal" v-if="!displayMessage">
              <div class="control-label">
                <label class="label">Email Address</label>
              </div>
              <div class="control" v-if="!displayMessage">
                <ValidationProvider name="Email" :rules="{ required: true, email: true }"  v-slot="{ errors }">
                  <input class="input" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" type="text" v-model="email" name="email"/>
                  &nbsp;
                  <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="control is-horizontal" v-if="!displayMessage">
              <div class="control-label">
                <label class="label">New Password</label>
              </div>
              <div class="control">
                <ValidationProvider name="Password" :rules="passwordRule"  v-slot="{ errors }" vid="password">
                  <input class="input" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" v-model="password" type="password" placeholder="New Password" name="password"/>
                  &nbsp;
                  <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="control is-horizontal" v-if="!displayMessage">
              <div class="control-label">
                <label class="label">Confirm Password</label>
              </div>
              <div class="control">
                <ValidationProvider name="Confirm Password" rules="required|confirmed:password"  v-slot="{ errors }">
                  <input class="input" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" v-model="confirmPassword" type="password" placeholder="Confirm Password" name="confirmPassword"/>
                  &nbsp;
                  <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </form>
      </div>
      <template slot="footer">
        <button class="button is-primary" @click="request()" :disabled="invalid" v-if="!displayMessage">Request</button>
        <a class="button" @click="close()" v-if="!displayMessage">Cancel</a>
        <a class="button" @click="close()" v-if="displayMessage" >Close</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex'
import IntelytModal from '@/components/IntelytModal'
import { extend } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    IntelytModal
  },
  computed: {
    passwordRule: function () {
      // Return the validation rule from the configuration
      return this.$store.state.configuration.resetPasswordValidation
    }
  },
  created () {
    extend('verify_password', {
      message () {
        return `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. ! @ # _ etc)`
      },
      validate: (value) => {
        const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})')
        return strongRegex.test(value)
      }
    })
  },
  data () {
    return {
      title: 'Reset Password',
      activeTab: 'request',
      confirmation: '',
      displayMessage: false,
      email: '',
      message: '',
      password: '',
      confirmPassword: '',
      username: ''
    }
  },
  methods: {
    close () {
      this.email = ''
      this.displayMessage = false
      this.message = ''
      this.password = ''
      this.confirmPassword = ''
      this.username = ''
      this.$refs.observer.reset()
      this.$emit('close')
    },
    request () {
      // let responseMessage = 'Your request is being processed.'
      let payload = {
        email: this.email,
        password: this.password,
        username: this.username
      }

      this.$refs.observer.validate().then(result => {
        if (result) {
          this.$store.dispatch('resetPassword', payload).then(response => {
            this.message = response.status[0][0]
            this.displayMessage = true
          }).catch(e => {
            console.log(e)
          })
        }
      })
    },
    ...mapActions([
      'resetPassword'
    ])
  },
  // props: ['isActive'],
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
