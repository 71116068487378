import Vue from 'vue'
import moment from 'moment-timezone'

// initial state
const getDefaultState = () => {
  return {
    all: {}
  }
}

const state = getDefaultState()

// getters
const getters = {
  getCountByLocation: (state) => (locId) => {
    const fltr = (x) => { return x.location === locId }
    return Object.values(state.all).filter(fltr).length
  },
  getIdsByLocation: (state) => (locId) => {
    const fltr = (x) => { return x.location === locId }
    return Object.values(state.all).filter(fltr).map(x => x.macId)
  },
  getStatusByLocation: (state) => (locId) => {
    // return string with the number of online and offline
    // iGates in the following format: #online : #offline
    // Get all iGates at this location
    const fltr = (x) => { return x.location === locId }
    const gatesAtLoc = Object.values(state.all).filter(fltr)
    // get offline iGates
    const offlineFltr = x => {
      // If lastDataTime not provided, return true....
      if (!x.lastDataTime) return true
      /* Get users timezone converted last data time and compare to current time, return true if under threshold...
       * The lastDataTime is returned w/o a timezone so the system will assume it is in browsers local time.
       * This needs to be converted back to utc to compare to the current time.  Therefore we use
       * moment.tz.guess() to get browser timezone (not timezone selected by the user in their profile) and
       * compare this to the current time.  If difference is greater than threshold, return false.
       */
      const lastTime = moment.utc(x.lastDataTime, 'ddd MMM DD HH:mm:ss YYYY').tz(moment.tz.guess())
      const delay = moment.utc().diff(lastTime, 'minutes')
      // console.log('utcTime', x.name, delay)
      return delay > 5
    }
    const offline = gatesAtLoc.filter(offlineFltr).length
    const online = gatesAtLoc.length - offline
    return `${online}:${offline}`
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  SET_GATEWAYS (state, gateways) {
    for (let gate of gateways) {
      Vue.set(state.all, gate.macId, gate)
    }
  },
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
