<template>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">{{alertDef.name}}</p>
      <span
          class="tag is-size-7"
          :class="classCalc"
          @click="goToPage()"
       >{{count}}</span>
    </div>
  </div>
</template>

<script>
import router from '@/router'

export default {
  computed: {
    classCalc () {
      /* If a regular notice, show blue but add pointer if count > 0
       * If an alert and count > 0 show red & pointer or none show green */
      if (this.isNotice) {
        return this.ids === '' ? 'is-primary' : 'is-primary pointer'
      } else {
        return this.ids === '' ? 'is-success' : 'is-danger pointer'
      }
    },
    count () {
      /* if empty show 0 otherwise return length of split.
       *   note split empty string length = 1  */
      return this.ids === '' ? 0 : this.ids.split(',').length
    }
  },
  methods: {
    /* navigate to page provided in the filter ONLY if the folling is true:
     *   ids string is not empty i.e. there are values to filter on...
     *   the path is different OR the ids are different.  You cannot navigate to the same place.
     */
    goToPage () {
      const path = this.alertDef.path
      const newPath = !this.$route.path.includes(path) || this.$route.query.ids !== this.ids
      if (this.ids !== '' && newPath) {
        const query = {
          fltcol: this.alertDef.fltcol,
          fltval: this.alertDef.filter,
          fltnme: this.alertDef.fltnme,
          filterStates: this.alertDef.filterStates,
          ids: this.ids
        }
        if (this.mode) query.mode = this.mode
        router.push({path, query})
      }
    }
  },
  props: {
    alertDef: {
      type: Object,
      default: function () {
        return {name: '--', path: 'shipment-list'}
      }
    },
    ids: {
      type: String,
      default: ''
    },
    isNotice: {
      type: Boolean,
      default: false
    },
    mode: {
      type: [Boolean, String],
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .pointer {
    cursor: pointer;
  }
</style>
