<template>
  <intelyt-modal
    :isActive="isActive"
    :title="title"
    v-on:close="close()"
  >
    <div class="content">
      <div style="min-height: 100px;" >
        For security reasons, your login session is about to end. To stay signed in to the application, please click "Continue". Otherwise, you will automatically be signed out in {{remaining}} seconds.
      </div>
    </div>
    <template slot="footer">
      <a class="button is-primary" @click="close()">Continue</a>
      <a class="button"  @click="logout()" >Logout</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'

export default {
  components: {
    IntelytModal
  },
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    logout () {
      this.$emit('logoutAndClose')
    }
  },
  props: ['title', 'isActive', 'remaining']
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .modal-card {
    text-align: left;
    white-space: normal;
  }
</style>
