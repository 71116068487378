<template>
<footer class="footer">
  <div class="container">
    <div class="content has-text-centered">
      <p>
        <strong>iCONNECT</strong> by <a href="http://www.intelyt.com">INTELYT</a>&nbsp;&copy; 2015-{{currentYear}}. INTELYT is a registered trademark of ICONTROL INC. <a href="http://intelyt.com/privacy-policy" target="_blank" rel="noopener noreferrer">PRIVACY POLICY</a>

        <a class="icon" href="https://twitter.com/intelytglobal">
          <base-icon name="twitter" color="blue" size="16" />
        </a>
        <a class="icon" href="https://www.linkedin.com/company/12898531">
          <base-icon name="linkedin" color="blue" size="16" />
        </a>
      </p>
    </div>
  </div>
</footer>
</template>

<script>

export default {
  data () {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

</style>
