<template>
  <div>
    <div v-if="this.options.searchable">
      <div id="search-input-container">
        <label>
          <input type="search" id="search-input" class="form-control" placeholder="Search data"
            :value="searchInput"
            @input="(e) => {this.searchInput = e.target.value}">
        </label>
        <base-icon name="search" />
      </div>
    </div>
    <div id="listingtable">
    <table class="table" :class="styleOpts">
      <thead>
        <tr>
          <th v-for="(column, index) in columns" :key="index"
            @click="toggleSort(index, column)"
            :class="(options.sortable ? 'sorting ' : '')
              + (sortColumn === index ?
                (sortType === 'desc' ? 'sorting-desc' : 'sorting-asc')
                : '')
              + (column.numeric ? ' numeric' : '')"
            :style="{width: column.width ? column.width : 'auto'}">
            <template v-if="column.colClick">
              <div v-on:click="column.colClick(column)" v-html="colFormatData(column)"></div>
            </template>
            <template v-else-if="column.colFormatter">
              <div v-html="colFormatData(column)"></div>
            </template>
            <template v-else>
              {{column.label}}
            </template>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in paginated" :key="index">
          <template v-for="(columnitem, indx) in columns" >
          <template v-if="columnitem.controls">
              <td nowrap :key="indx">
                <template v-for="(control, inx) in columnitem.controls">
                    <base-icon :name="control.icon" color="link_blue" style="cursor: pointer" :key="inx"
                    @click="control.callback(item)"
                    v-tooltip="control.tooltip" v-if="control.show ? control.show(item) : true" />
                </template>
              </td>
            </template>
            <template v-else-if="columnitem.link">
              <td :key="indx"><router-link :to="columnitem.link(item)" v-html="formatData(columnitem, item)"></router-link></td>
            </template>
            <template v-else-if="columnitem.dblclick">
              <td :key="indx" style="cursor: pointer" v-on:dblclick="columnitem.dblclick(item)" v-html="formatData(columnitem, item)"></td>
            </template>
            <template v-else-if="columnitem.eventclick">
              <td :key="indx" style="cursor: pointer" v-on:click="columnitem.eventclick(item)" v-html="formatData(columnitem, item)"></td>
            </template>
            <template v-else-if="columnitem.tooltip">
              <td :key="indx"><span v-tooltip="{content: formatTooltip(columnitem, item)}" v-html="formatData(columnitem, item)"></span></td>
            </template>
            <template v-else>
              <template v-if="fixFirstCol && indx === 0">
                <th :key="indx" scope="row" v-html="formatData(columnitem, item)"></th>
              </template>
              <template v-else>
                <td :key="indx" v-html="formatData(columnitem, item)"></td>
              </template>
            </template>
          </template>
        </tr>
      </tbody>
    </table>
    </div>
    <div class="table-footer" v-if="options.paginate || loadMore.active">
      <div class="datatable-center" v-if="loadMore.active">
        <span v-if="loadMore.loading">Loading...</span>
        <button v-else @click="loadMore.callback()" class="load-more-btn">
          <base-icon :name="loadMore.icon.name" :tooltip="loadMore.icon.tooltip" :class="loadMore.icon.class"/>
          {{loadMore.icon.tooltip}} &nbsp;
        </button>
      </div>
      <div class="datatable-length" v-if="options.paginate">
        <label>
          <span>Rows per page:</span>
          <select class="browser-default" @change="onTableLength" v-model="currentPerPage">
            <option v-for="n in 5" :value="n * perPage" :key="n">{{n * perPage}}</option>
            <option value="-1">All</option>
          </select>
        </label>
      </div>
      <div class="datatable-info" v-if="options.paginate">
        {{(currentPage - 1) * currentPerPage ? (currentPage - 1) * currentPerPage + 1 : 1}}
          - {{currentPerPage == -1 ? 1 : Math.min(processedRows.length, currentPerPage * currentPage)}} of {{processedRows.length}}
      </div>
      <div class="pagination-control" v-if="currentPerPage != -1 && options.paginate">
        <a @click.prevent="previousPage" tabindex="0">
          <base-icon name="left" size="18" />
        </a>
        <a @click.prevent="nextPage" tabindex="0">
          <base-icon name="right" size="18" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'
import moment from 'moment-timezone'
import { exportAsCSV, generateIconSVG } from '@/store/helpers'

export default {
  computed: {
    paginated: function () {
      let paginatedRows = this.processedRows
      if (this.options.paginate) {
        const currentPage = parseInt(this.currentPage)
        const currentPerPage = parseInt(this.currentPerPage)
        paginatedRows = paginatedRows.slice((currentPage - 1) * currentPerPage, currentPerPage === -1 ? paginatedRows.length + 1 : currentPage * currentPerPage)
      }
      return paginatedRows
    },
    processedRows: function () {
      let sortedRows = []
      let filteredRows = []
      let searchedRows = []
      const options = {
        keys: this.columns.map(c => c.id),
        threshold: 0.5,
        distance: 100,
        getFn: function (obj, path) {
          if (Number.isInteger(obj[path])) {
            return JSON.stringify(obj[path])
          }
          return obj[path]
        }
      }
      // Enable searching of numbers (non-string)
      // Temporary fix of https://github.com/krisk/Fuse/issues/144
      if (this.options.searchable === 'exact' || this.searchType === 'exact') {
        // return only exact matches
        options.threshold = 0.15
        options.distance = 150
      }
      if (this.options.searchable && this.searchInput) {
        searchedRows = (new Fuse(this.data, options)).search(this.searchInput).map(d => d.item)
      } else if (this.searchText) {
        searchedRows = (new Fuse(this.data, options)).search(this.searchText).map(d => d.item)
      } else {
        searchedRows = this.data
      }

      // If single filter object is provided cast to Arrry
      const filters = Array.isArray(this.filter) ? this.filter : [this.filter]

      const filterArray = (arr, filters) => {
        return arr.filter((item) => filters.every((field) => {
          if (field.val === '') return true
          // If the filter value is an array, check the value is exist in the array, else return true
          if (Array.isArray(field.val)) {
            // If the filter value length = 0, return true(Do not filter the row)
            if (field.val.length === 0) {
              return true
            }
            let valueFound = false
            // Loop the each value to get the comma separated values, when using multi value checkbox
            // For example, alerts having comma separated value like separ, lowBatt. To get the both values availe on
            // filter, iterate the values one by one to match the record.
            field.val.forEach((value) => {
              if (item[field.col].toString().includes(value)) {
                valueFound = true
              }
            })
            return valueFound
          }
          return item[field.col].toString().includes(field.val)
        }))
      }
      filteredRows = filters.length > 0 ? filterArray(searchedRows, filters) : searchedRows
      if (this.options.sortable !== false) {
        sortedRows = filteredRows.sort((x, y) => {
          if (!this.columns[this.sortColumn]) {
            return 0
          }
          const modifier = this.sortType === 'desc' ? -1 : 1
          const col = this.columns[this.sortColumn].id
          // When the column value is a number or Number(value) is a number and it's not a moment date value
          if (typeof (x[col]) === 'number' || (!isNaN(x[col]) && !moment.isMoment(x[col]))) {
            // return x[col] < y[col] ? -1 * modifier : 1 * modifier
            return Number(x[col]) < Number(y[col]) ? -1 * modifier : 1 * modifier
          } else if (typeof (x[col]) === 'string' && x[col].length > 0) {
            // if the value is a moment date object then convert it to string
            const columnY = moment.isMoment(y[col]) ? moment.utc(y[col]).format() : y[col]
            return x[col].toLowerCase() < columnY.toLowerCase() ? -1 * modifier : 1 * modifier
          } else if (typeof (x[col]) === 'string' && x[col].length === 0) {
            // If the column value is null and sorting type is descending then return 0
            if (this.sortType === 'desc') {
              return 0
            } else if (this.sortType === 'asc') { // sorting type is ascending then return -1
              return -1
            }
            return modifier
          } else if (moment.isMoment(x[col])) {
            return moment(x[col]).isAfter(moment(y[col])) ? 1 * modifier : -1 * modifier
          } else {
            return 0
          }
        })
      } else {
        sortedRows = filteredRows
      }
      return sortedRows
    },
    styleOpts: function () {
      let style = ''
      if (this.isNarrow) style += ' is-narrow'
      if (this.isStriped) style += ' is-striped'
      if (this.fixHeader) style += ' is-sticky'
      return style
    }
  },
  data () {
    const newstate = this.$store.state
    return {
      // To preserve column alingment, blank icon as replacement to filtered icon
      blankIcon: generateIconSVG(newstate, {name: 'trash'}),
      currentPage: 1,
      currentPerPage: this.options.initialAll ? -1 : this.options.perPage,
      perPage: this.options.perPage,
      sortColumn: this.options.sortIndex,
      sortType: this.options.sortType,
      searchInput: ''
    }
  },
  /* Notes:
      - Allow for search window inside card header (external) or body (internal)
      - searchable controls internal search window valid options true or 'exact'
      - searchText & searchType are for external search
      - paginate & perPage must both be used in conjunction
  */
  props: {
    data: null,
    columns: null,
    alignIcons: {
      // If true, add spacing to align icons of similar type into columns
      type: Boolean,
      default: false
    },
    filter: {
      type: [Object, Array],
      default: () => []
    },
    isNarrow: {
      type: Boolean,
      default: false
    },
    isStriped: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default () {
        return {
          allInitial: false,
          filtered: false,
          initialAll: false, // show all on initial load
          paginate: false,
          perPage: 10,
          searchable: false, // true or 'exact' are valid inputs
          sortable: false,
          sortIndex: 0,
          sortType: 'asc',
          ignoreSorting: [] // Ignore sorting column index list
        }
      }
    },
    loadMore: {
      type: Object,
      default () {
        return {
          active: false,
          icon: {
            name: 'down',
            tooltip: 'Load More',
            class: 'icon-padding'
          },
          callback: function () { return false }
        }
      }
    },
    searchText: String,
    /* To fix the table header use this property and it's parent card component must have
    the scrolling & maxHeight properties. Check the DHUnitDetail component for reference */
    fixHeader: {
      type: Boolean,
      default: false
    },
    /* To fix the first column use this property and it's parent card component must have
    the scrolling & maxHeight properties. Check the DHUnitDetail component for reference */
    fixFirstCol: {
      type: Boolean,
      default: false
    },
    searchType: { // 'fuzzy' or 'exact' are valid input
      type: String,
      default: 'exact'
    }
  },
  methods: {
    colFormatData: function (col) {
      return col.colFormatter ? col.colFormatter(col) : col
    },
    formatData: function (col, itm) {
      return col.formatter ? col.formatter(itm[col.id]) : itm[col.id]
    },
    formatTooltip: function (col, itm) {
      return typeof col.tooltip === 'function' ? col.tooltip(itm) : itm[col.id]
    },
    generateCSV (csvFileName = 'output.csv') {
      // Remove item from filter array that the use clicked on
      const colLabels = this.columns.map(col => col.label).filter(col => col.length > 1)
      const colIds = this.columns.filter(col => col.label.length > 1).map(col => col)
      let csvData = []
      Object.values(this.processedRows).forEach(function (row, index) {
        csvData[index] = colIds.map((col) => {
          let cellText = row[col.id] || '-'
          if (col.exportFmt) {
            // call the export function with addition param export as true
            cellText = col.exportFmt(row[col.id], true)
          }
          return String(cellText).replace(/,/g, ';')
        }).join(',')
        // Set the cell format as plain text (general) for display the same format in table
        // (="") to set the general cell format for example (`="${cellValue}"`)
      })
      exportAsCSV(colLabels, csvData, csvFileName)
    },
    generateExcel (xlsFileName = 'output.xlsx') {
      var html = '<table><thead><tr>'
      var tshtml = ''
      const colLabels = []
      const colIds = []
      const coldata = this.columns
      Object.values(this.columns).forEach(function (row) {
        if (row.label.length > 1) {
          colLabels.push(row.label)
          html = html + '<th>' + row.label + '</th>'
          colIds.push(row)
        }
      })

      html = html + '</tr></thead><tbody>'

      Object.values(this.processedRows).forEach(function (row) {
        let i = 0
        html = html + '<tr>'
        for (i = 0; i < colIds.length; i++) {
          tshtml = coldata[i].formatter ? colIds[i].formatter(row[colIds[i].id]).replace(/<\/?[^>]+(>|$)/g, '') : row[colIds[i].id].replace(/<\/?[^>]+(>|$)/g, '')
          html = html + '<td>' + tshtml + '</td>'
        }
        html = html + '</tr>'
      })

      html = html + '</tbody></table>'

      var hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:application/vnd.ms-excel,' + encodeURI(html)
      hiddenElement.target = '_blank'
      hiddenElement.download = xlsFileName
      hiddenElement.click()
    },
    generatePrint () {
      var html = '<table><thead><tr>'
      var tshtml = ''
      const colLabels = []
      const colIds = []
      const coldata = this.columns
      Object.values(this.columns).forEach(function (row) {
        if (row.label.length > 1) {
          colLabels.push(row.label)
          html = html + '<th>' + row.label + '</th>'
          colIds.push(row)
        }
      })

      html = html + '</tr></thead><tbody>'

      Object.values(this.processedRows).forEach(function (row) {
        let i = 0
        html = html + '<tr>'
        for (i = 0; i < colIds.length; i++) {
          tshtml = coldata[i].formatter ? colIds[i].formatter(row[colIds[i].id]).replace(/<\/?[^>]+(>|$)/g, '') : row[colIds[i].id].replace(/<\/?[^>]+(>|$)/g, '')
          html = html + '<td>' + tshtml + '</td>'
        }
        html = html + '</tr>'
      })

      html = html + '</tbody></table>'
      var WinPrint = window.open('', '', 'left=0,top=0,toolbar=0,scrollbars=0,status=0')
      WinPrint.document.write(html)
      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    nextPage: function () {
      if (this.processedRows.length > this.currentPerPage * this.currentPage) {
        ++this.currentPage
      }
    },
    onTableLength: function (e) {
      this.currentPerPage = e.target.value
      this.currentPage = 1
    },
    previousPage: function () {
      if (this.currentPage > 1) {
        --this.currentPage
      }
    },
    toggleSort: function (index, column) {
      if (!this.options.sortable || Object.prototype.hasOwnProperty.call(column, 'ignoreSorting')) {
        return
      }
      if (this.sortColumn === index) {
        this.sortType = this.sortType === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortType = 'asc'
        this.sortColumn = index
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#invisible {
  opacity: 0.5;
}

tr.clickable {
  cursor: pointer;
}

#search-input {
  margin: 1px;
  color: rgba(0, 0, 0, .84);
}

#search-input-container {
  float: right;
}

.table-footer {
  height: 26px;
  padding-left: 24px;
  padding-right: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: center;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.74);
}
.datatable-center {
  font-size: 16px !important;
  margin: 0px auto;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  display: -webkit-flex;
  display: flex;
}
.table-footer .datatable-length {
  display: -webkit-flex;
  display: flex;
}
.table-footer .datatable-length select {
  outline: none;
}
.table-footer label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.74);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  /* works with row or column */
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.table-footer .select-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  /* works with row or column */
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.table-footer .datatable-info,
.table-footer .datatable-length {
  margin-right: 32px;
}
.table-footer .pagination-control {
  display: flex;
  -webkit-display: flex;
  margin: 0;
}
.table-footer .pagination-control a {
  color: rgba(0, 0, 0, 0.74);
  padding: 0 8px;
  font-size: 18px;
}
.table-footer .select-wrapper input.select-dropdown {
  margin: 0;
  border-bottom: none;
  height: auto;
  line-height: normal;
  font-size: 12px;
  width: 40px;
  text-align: right;
}
.table-footer select {
  background-color: transparent;
  width: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  height: auto;
  margin-left: 20px;
}
table th.sorting:after  {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
}
table th.sorting-asc:after {
  content: " ";
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
table th.sorting-desc:after {
  content: " ";
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.load-more-btn {
  border-radius: 20px;
  display: flex;
  padding-top: 5px;
  padding-bottom: 0px;
  cursor: pointer;
  outline: none;
  border: 1px solid;
}

.is-sticky th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.is-sticky th[scope=row] {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  background: #fff !important;
}

.is-sticky th:not([scope=row]) {
  position: -webkit-sticky;
  position: sticky;
  /* top: 30px; */
  background: #fff !important;
}

.is-sticky th:not([scope=row]):first-child {
  left: 0;
  z-index: 3;
}
</style>
