<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      :width="width"
      v-on:close="close()"
    >
      <form id="manageGroup">
        <div class="control" v-if="reportDefsLoaded">
          <table id="manageReports">
            <thead>
              <th>
                Report Name
              </th>
              <template v-for="group in Object.values(groupReports)">
                  <th style="width:150px;text-align:center" :key="group.groupName">
                    <b>{{group.groupName}}</b>
                  </th>
                </template>
            </thead>
            <template v-for="report in allReportsList">
              <tr :key="report">
                <td>
                  <span style="cursor:help" v-tooltip="allReportsObj[report].description"><b>{{allReportsObj[report].name}} </b></span>
                </td>
                <template v-for="groupId in Object.keys(groupReports)">
                  <td style="text-align:center" :key="groupId">
                    <input type="checkbox" :value="report" :disabled="isViewMode" :key="report" v-model="groupReports[groupId].reports">
                  </td>
                </template>
              </tr>
            </template>
          </table>
        </div>
      </form>
      <template slot="footer">
        <a class="button is-primary" @click="saveGroup()" :disabled="invalid" v-show="!isViewMode && btnEnabled">Update</a>
        <a class="button" @click="close()">Cancel</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
// import { mapState } from 'vuex'
import IntelytModal from '@/components/IntelytModal'
import {updateGroupProvider} from '@/store/providers'

export default {
  components: {
    IntelytModal
  },
  computed: {
    title: function () {
      return this.isViewMode ? 'View Reports' : 'Manage Reports'
    },
    allReportsList: function () {
      return Object.keys(this.$store.state.reports.all).sort()
    },
    allReportsObj: function () {
      return this.$store.state.reports.all
    },
    reportDefsLoaded: function () {
      return this.$store.state.reports.all && Object.keys(this.$store.state.reports.all).length > 0
    }
  },
  created () {
  },
  data () {
    return {
      btnEnabled: true,
      groupReports: {},
      width: 900
    }
  },
  methods: {
    close () {
      this.$refs.observer.reset()
      this.$emit('close')
    },
    saveGroup () {
      const vm = this
      this.$refs.observer.validate().then(result => {
        if (result) {
          vm.btnEnabled = false
          const promiseList = []
          for (const [groupId, group] of Object.entries(vm.groupReports)) {
            // Get new customPrefs object combining old version with newly select reports
            const oldPrefs = group.customPrefs ? JSON.parse(group.customPrefs) : {}
            const newPrefs = Object.assign(oldPrefs, {reports: group.reports})
            const updateObj = {
              property: {
                customPrefs: JSON.stringify(newPrefs)
              }
            }
            promiseList.push(updateGroupProvider(vm.$store.state, updateObj, groupId))
          }
          Promise.all(promiseList).then(responses => {
            Object.values(responses).forEach((res) => {
              const grpId = res.data[0].groupId
              // If update successful, set state for reports in appropriate group.
              // Get group name for property update
              const grp = vm.groupReports[grpId]
              const groupName = grp.groupName
              vm.$store.commit('company/UPDATE_GROUP_PROP', {groupName, prop: 'reports', value: grp.reports})
            })
            vm.btnEnabled = true
            vm.$emit('close')
          }).catch(e => {
            vm.btnEnabled = true
            console.log(e)
          })
        }
      })
    }
  },
  // props: ['isActive', 'isViewMode', 'reportGroups'],
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isViewMode: {
      type: Boolean,
      default: false
    },
    reportGroups: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    isActive: function (newInput) {
      if (newInput) {
        const groupReportInputs = {}
        Object.values(this.reportGroups).forEach((group) => {
          groupReportInputs[group.id] = {}
          groupReportInputs[group.id].reports = group.reports || []
          groupReportInputs[group.id].groupName = group.groupName
          groupReportInputs[group.id].customPrefs = group.customPrefs
        })
        this.groupReports = groupReportInputs
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#manageReports {
  min-width: 100%;
}
#manageReports th, #manageReports td {
  border: solid #dbdbdb;
  border-width: 0 0 1px;
  padding: .5em .75em;
  vertical-align: top;
}
</style>
