<template>
  <div @mousedown="dragMouseDown" class="center">
    <svg :height="svgHeight" :width="svgWidth" :viewBox="viewBox">
      <path class="plane" :d="pathStr" v-if="showNormal"/>
      <g v-for="(val, index) in lineData" :key="index">
        <line class="edge" :x1="val[0]" :y1="val[1]" :x2="val[2]" :y2="val[3]" />
      </g>
      <line class="shock" x1=120 y1=120 :x2="shkVect[0]" :y2="shkVect[1]" v-if="showShock"/>
      <line class="norm" x1=120 y1=120 :x2="normVect[0]" :y2="normVect[1]" v-if="showNormal"/>
      <line class="shock" x1=120 y1=120 :x2="movshkVect[0]" :y2="movshkVect[1]" v-if="showMoveShock"/>
    </svg>
    <div class="center" style="width:100%;text-align:center;">
      <!-- <button v-on:click="theta += 0.1">R</button>
      <button v-on:click="theta -= 0.1">L</button>
      <button v-on:click="phi -= 0.1">U</button>
      <button v-on:click="phi += 0.1">D</button>
      <button v-on:click="showShock = !showShock">Shock</button>
      <button v-on:click="showNormal = !showNormal">Norm</button>
      <button v-on:click="showPlane = !showPlane">Plane</button> -->
      <a @click="showShock = !showShock; showReset = true"><base-icon :style="'cursor:pointer'" :name="'shock'" :size="20"></base-icon></a>
      <a @click="showNormal = !showNormal; showReset = true"><base-icon :style="'cursor:pointer'" :name="'new_window'" :size="20"></base-icon></a>
      <a @click="moveLeft()" v-if="showLR"><base-icon :style="'cursor:pointer'" :name="'left'" :size="20"></base-icon></a>
      <a @click="play()" v-if="showPlay"><base-icon :style="'cursor:pointer'" :name="'play'" :size="20"></base-icon></a>
      <a @click="moveRight()" v-if="showLR"><base-icon :style="'cursor:pointer'" :name="'right'" :size="20"></base-icon></a>
      <a @click="stop()" v-if="!showPlay"><base-icon :style="'cursor:pointer'" :name="'circle_slash'" :size="20"></base-icon></a>
      <a @click="resetVars()" v-if="showReset"><base-icon :style="'cursor:pointer'" :name="'refresh'" :size="20"></base-icon></a>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  computed: {
    isGw: function () {
      return this.macId && this.macId.includes('E0_B0')
    },
    lineData: function () {
      //  https://www.khanacademy.org/computing/computer-programming/programming-games-visualizations/programming-3d-shapes/a/generating-3d-shapes
      //  https://www.petercollingridge.co.uk/blog/3d-svg/rotating-3d-svg-cube/
      //  https://github.com/petercollingridge/code-for-blog/blob/master/svg-other/3D/3D_cube.svg?short_path=aa28893
      //
      let edges = []
      let xCoords = []
      let yCoords = []
      let zCoords = []
      if (this.macId && this.macId.includes('E0_B0')) {
        // Groundwave Tag
        edges = [[0, 1], [1, 2], [2, 3], [3, 4], [4, 5], [5, 6], [6, 7], [7, 8], [8, 31], [31, 32], [32, 0], [9, 10], [10, 11], [11, 12], [12, 13], [13, 14], [14, 15], [15, 16], [16, 17], [17, 18], [18, 9], [19, 20], [20, 21], [21, 22], [22, 23], [23, 24], [24, 25], [25, 26], [26, 27], [27, 28], [28, 19], [0, 10], [10, 20], [1, 11], [11, 21], [2, 12], [12, 22], [3, 13], [13, 23], [4, 14], [14, 24], [5, 15], [15, 25], [6, 16], [16, 26], [7, 17], [17, 27], [8, 18], [18, 28], [9, 19], [18, 29], [29, 30], [30, 10], [29, 31], [30, 32]]
        zCoords = [-30, -30, -30, -30, -30, -30, -30, -30, -30, -20, -20, -20, -20, -20, -20, -20, -20, -20, -20, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, -20, -20, -30, -30]
        yCoords = [20, 30, 30, 20, 0, -20, -30, -30, -20, 0, 25, 40, 40, 25, 0, -25, -40, -40, -25, 0, 25, 40, 40, 25, 0, -25, -40, -40, -25, -15, 15, -15, 15]
        xCoords = [-50, -40, 40, 50, 55, 50, 40, -40, -50, -63, -56, -40, 40, 56, 63, 56, 40, -40, -56, -63, -56, -40, 40, 56, 63, 56, 40, -40, -56, -45, -45, -45, -45]
        // zCoords = [50, 40, -40, -50, -55, -50, -40, 40, 50, 63, 56, 40, -40, -56, -63, -56, -40, 40, 56, 63, 56, 40, -40, -56, -63, -56, -40, 40, 56, 45, 45, 45, 45]
      } else {
        // Enigma Style tag or chime
        edges = [[0, 1], [1, 2], [2, 3], [3, 4], [4, 5], [5, 6], [6, 7], [7, 0], [8, 9], [9, 10], [10, 11], [11, 12], [12, 13], [13, 14], [14, 15], [15, 8], [0, 8], [1, 9], [2, 10], [3, 11], [4, 12], [5, 13], [6, 14], [7, 15], [16, 17], [17, 18], [18, 19], [19, 16]]
        //zCoords = [-20, -20, -20, -20, -20, -20, -20, -20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20]
        zCoords = [20, 20, 20, 20, 20, 20, 20, 20, -20, -20, -20, -20, -20, -20, -20, -20, -20, -20, -20, -20]
        yCoords = [50, 50, -50, -50, -20, -20, 20, 20, 50, 50, -50, -50, -20, -20, 20, 20, 40, 40, -40, -40]
        // zCoords = [70, -70, -70, 70, 70, 40, 40, 70, 70, -70, -70, 70, 70, 40, 40, 70, 30, -60, -60, 30]
        xCoords = [-70, 70, 70, -70, -70, -40, -40, -70, -70, 70, 70, -70, -70, -40, -40, -70, -30, 60, 60, -30]
      }

      // Simple Cube
      // const edges = [[0,1],[2,3],[0,2],[1,3],[4,5],[6,7],[4,6],[5,7],[0,4],[1,5],[2,6],[3,7]]
      // const xCoords = [-70, 70, -70, 70, -70, 70, -70, 70]
      // const yCoords = [-70,- 70, 70, 70, -70, -70, 70, 70]
      // const zCoords = [-70, -70, -70, -70, 70, 70, 70, 70]
      const mapFn = (pt) => {
        const ct = Math.cos(this.theta)
        const st = Math.sin(this.theta)
        const cp = Math.cos(this.phi)
        const sp = Math.sin(this.phi)
        const x1 = this.center[0] + xCoords[pt[0]] * ct - yCoords[pt[0]] * sp * st + zCoords[pt[0]] * st * cp
        const y1 = this.center[1] - xCoords[pt[0]] * st * sp + yCoords[pt[0]] * cp + zCoords[pt[0]] * sp
        const x2 = this.center[0] + xCoords[pt[1]] * ct - yCoords[pt[1]] * sp * st + zCoords[pt[1]] * st * cp
        const y2 = this.center[1] - xCoords[pt[1]] * st * sp + yCoords[pt[1]] * cp + zCoords[pt[1]] * sp
        return [x1, y1, x2, y2]
      }
      return edges.map(mapFn)
    },
    movshkVect: function () {
      if (!this.shkObj) return [this.center[0], this.center[1]]
      let indx = this.counter % 32
      const allRMS = this.shkObj.RMS.map(x => x[1])
      const maxRMS = Math.max(...allRMS)
      const ct = Math.cos(this.theta)
      const st = Math.sin(this.theta)
      const cp = Math.cos(this.phi)
      const sp = Math.sin(this.phi)
      const x = (this.shkObj.X[indx][1] * ct - this.shkObj.Y[indx][1] * sp * st + this.shkObj.Z[indx][1] * st) * 80 / maxRMS
      const y = (-1 * this.shkObj.X[indx][1] * st * sp + this.shkObj.Y[indx][1] * cp + this.shkObj.Z[indx][1] * sp) * 80 / maxRMS
      return [x + this.center[0], y + this.center[0]]
    },
    shkVect: function () {
      let shkVector = [this.center[0], this.center[1]]
      if (this.shkObj) {
        const allRMS = this.shkObj.RMS.map(x => x[1])
        const maxRMS = Math.max(...allRMS)
        if (allRMS.includes(maxRMS)) {
          const maxIndx = allRMS.indexOf(maxRMS)
          const sX = this.shkObj.X[maxIndx][1] * this.vectLen / maxRMS
          const sY = this.shkObj.Y[maxIndx][1] * this.vectLen / maxRMS
          const sZ = this.shkObj.Z[maxIndx][1] * this.vectLen / maxRMS
          const ct = Math.cos(this.theta)
          const st = Math.sin(this.theta)
          const cp = Math.cos(this.phi)
          const sp = Math.sin(this.phi)
          let x = sX * ct - sY * sp * st + sZ * st
          let y = -1 * sX * st * sp + sY * cp + sZ * sp
          shkVector = [x + this.center[0], y + this.center[1]]
        }
      }
      return shkVector
    },
    normVect: function () {
      // Return origin (to make 0 length vector) if acceleration has not been defined
      if (!this.shkObj || !this.shkObj.acceleration || Math.max.apply(Math, this.shkObj.acceleration) < 0.0001) {
        return [this.center[0], this.center[1]]
      }
      const scale = 80
      const nv = this.normalize(this.shkObj.acceleration, scale)
      // console.log('acceleration', this.shkObj.acceleration, nv)
      // const nv = [0, 80, 0]
      const ct = Math.cos(this.theta)
      const st = Math.sin(this.theta)
      const cp = Math.cos(this.phi)
      const sp = Math.sin(this.phi)
      const nx = this.center[0] + nv[0] * ct - nv[1] * sp * st + nv[2] * st * cp
      const ny = this.center[1] - nv[0] * st * sp + nv[1] * cp + nv[2] * sp
      return [nx, ny]
    },
    pathStr: function () {
      // Return empty string if acceleration has not been defined
      if (!this.shkObj || !this.shkObj.acceleration || Math.max.apply(Math, this.shkObj.acceleration) < 0.0001) {
        return ''
      }
      // console.log('Accleration found: ', this.shkObj.acceleration, Math.max.apply(Math, this.shkObj.acceleration))
      const edges = [0, 1, 2, 3]
      const scale = 80
      const [x, y, z] = this.normalize(this.shkObj.acceleration, scale)
      // const xCoords = [-x, x, x, -x]
      // const yCoords = [y, -y, -y, y]
      // const zCoords = [-z, -z, z, z]
      const xCoords = [y + z, y - z, -y - z, -y + z]
      const yCoords = [x + z, -x + z, -x - z, x + -z]
      const zCoords = [x + y, x - y, -x - y, -x + y]
      const mapFn = (pt) => {
        const ct = Math.cos(this.theta)
        const st = Math.sin(this.theta)
        const cp = Math.cos(this.phi)
        const sp = Math.sin(this.phi)
        const x1 = parseInt(this.center[0] + xCoords[pt] * ct - yCoords[pt] * sp * st + zCoords[pt] * st * cp)
        const y1 = parseInt(this.center[1] - xCoords[pt] * st * sp + yCoords[pt] * cp + zCoords[pt] * sp)
        return [x1, y1]
      }
      const tr = edges.map(mapFn)
      return `M ${tr[0][0]} ${tr[0][1]} L ${tr[1][0]} ${tr[1][1]} L ${tr[2][0]} ${tr[2][1]} L ${tr[3][0]} ${tr[3][1]} L ${tr[0][0]} ${tr[0][1]}`
    },
    viewBox: function () {
      return `0 0 240 240`
    },
    test: function () {
      return this.sample
    }
  },
  data () {
    return {
      counter: 31,
      interval: null,
      mousePos: {x: null, y: null},
      theta: this.macId && this.macId.includes('E0_B0') ? -1.5 : 0.5,
      phi: this.macId && this.macId.includes('E0_B0') ? 1 : 2.75,
      showLR: false,
      showMoveShock: false,
      showNormal: true,
      showPlay: true,
      showReset: false,
      showShock: true,
      svgHeight: 300,
      svgWidth: 300,
      center: [120, 120, 120],
      vectLen: 80
    }
  },
  methods: {
    elementDrag: function (event) {
      // show reset button
      this.showReset = true
      event.preventDefault()
      // Adujst phi and theta based on movement...
      this.theta += (this.mousePos.x - event.clientX) / 100
      this.phi += (this.mousePos.y - event.clientY) / 100
      // Reset mouse position
      this.mousePos.x = event.clientX
      this.mousePos.y = event.clientY
    },
    closeDragElement () {
      document.onmouseup = null
      document.onmousemove = null
    },
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.mousePos.x = event.clientX
      this.mousePos.y = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    moveLeft: function () {
      this.counter -= 1
      this.$emit('updateCounter', this.counter % 32)
    },
    moveRight: function () {
      this.counter += 1
      this.$emit('updateCounter', this.counter % 32)
    },
    normalize: function (arr = [], scale = 1) {
      if (arr.length === 0) return [0, 0, 0]
      const len = arr.reduce((acc, cur) => { return acc + (cur * cur) }, 0)
      return arr.map(val => (val * scale) / Math.sqrt(len))
    },
    play: function () {
      this.showReset = true
      this.showPlay = false
      this.showShock = false
      this.showMoveShock = true
      this.showLR = false
      this.interval = setInterval(() => {
        this.counter += 1
        this.$emit('updateCounter', this.counter % 32)
      }, 300)
    },
    resetVars: function () {
      this.theta = this.macId && this.macId.includes('E0_B0') ? -1.5 : 0.5 // -1.5 GW   0.5 enig
      this.phi = this.macId && this.macId.includes('E0_B0') ? 1 : 2.75 // 1 GW     2.75 enig
      this.showNormal = true
      this.showShock = true
      this.showReset = false
      this.showMoveShock = false
      this.showLR = false
      this.showPlay = true
      this.$emit('updateCounter', 31)
      this.counter = 31
      clearInterval(this.interval)
    },
    stop: function () {
      this.showPlay = true
      this.showLR = true
      clearInterval(this.interval)
    }
  },
  mounted () {
  },
  props: {
    macId: String,
    shkObj: {
      type: [Boolean, Object],
      default: false
    }
  },
  watch: {
    // whenever selected shipment changes, this function will run
    isActive: {
      macId: function (newInput) {
        if (newInput) {
          // Window opened, check to see if the data already exists
          this.resetVars()
        }
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .center {
    margin: auto;
    width: 50%;
    padding: 5px;
  }
  .edge {
    fill: white;
    stroke: black;
    stroke-width: 1;
  }
  .norm {
    fill: white;
    stroke: #066CA9;
    stroke-width: 2;
  }
 .plane {
    fill: #066CA9;
    fill-opacity: 0.3;
    stroke: transparent;
  }
  .shock {
    fill: white;
    stroke: rgb(172, 20, 20);
    stroke-width: 3;
    stroke-linecap: round;
  }
</style>
