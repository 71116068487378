<template>
  <canvas class='canvas-gauges'></canvas>
</template>

<script>
import { LinearGauge, RadialGauge } from 'canvas-gauges'

let defaults = {
  temperature: {
    barBeginCircle: 30,
    tickSide: 'right',
    numberSide: 'left',
    needleSide: 'right',
    fontNumbersSize: 35,
    numbersMargin: -10,
    width: 70,
    height: 80,
    minValue: 30,
    maxValue: 120,
    majorTicks: [
      '30',
      '60',
      '90',
      '120'
    ],
    minorTicks: 1,
    highlights: [
      {from: 30, to: 60, color: 'rgba(0, 30, 120, .75)'},
      {from: 90, to: 120, color: 'rgba(200, 50, 50, .75)'}
    ],
    highlightsWidth: 10
  },
  humidity: {
    width: 80,
    height: 80,
    fontNumbersSize: 50,
    numbersMargin: -25,
    minValue: 0,
    maxValue: 100,
    majorTicks: [
      '0',
      '20',
      '40',
      '60',
      '80',
      '100'
    ],
    highlights: [
      {from: 0, to: 50, color: 'rgba(2, 109, 14, .75)'},
      {from: 50, to: 60, color: 'rgba(255, 200, 30, .75)'},
      {from: 60, to: 100, color: 'rgba(200, 50, 50, .75)'}
    ],
    highlightsWidth: 20
  },
  battery: {
    width: 100,
    height: 100,
    barBeginCircle: 0,
    tickSide: 'right',
    numberSide: 'left',
    needleSide: 'right',
    fontUnitsSize: 20,
    fontNumbersSize: 30,
    fontValueSize: 40,
    numbersMargin: -15,
    minValue: 0,
    maxValue: 100,
    majorTicks: [
      '0',
      '20',
      '40',
      '60',
      '80',
      '100'
    ],
    highlights: [
      {from: 0, to: 20, color: 'rgba(200, 50, 50, .75)'},
      {from: 20, to: 40, color: 'rgba(255, 200, 30, .75)'},
      {from: 40, to: 100, color: 'rgba(2, 109, 14, .75)'}
    ],
    highlightsWidth: 10
  },
  compressor: {
    width: 70,
    height: 70,
    barBeginCircle: 0,
    fontUnitsSize: 0,
    fontNumbersSize: 50,
    fontValueSize: 0,
    minValue: 0,
    maxValue: 500,
    numbersMargin: -45,
    majorTicks: [
      '0',
      '100',
      '200',
      '300',
      '400',
      '500'
    ],
    highlights: []
  }
}

let globals = {
  borders: false,
  borderShadowWidth: 0,
  fontUnitsSize: 30,
  fontNumbersSize: 30,
  fontValueSize: 30,
  valueBox: false
}

export default {
  props: {
    value: {
      // type: Number,
      default: 0
    },
    configuration: String,
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      chart: null,
      chartOptions: {}
    }
  },
  mounted () {
    // if (this.value) this.options.value = this.value
    this.chartOptions.renderTo = this.$el
    const options = this.chartOptions
    let gaugeOptions = Object.assign(
      globals,
      defaults[this.configuration],
      options,
      {value: this.value}
    )
    if (this.configuration === 'temperature') {
      this.chart = new LinearGauge(gaugeOptions).draw()
    } else if (this.configuration === 'humidity') {
      this.chart = new RadialGauge(gaugeOptions).draw()
    } else if (this.configuration === 'compressor') {
      this.chart = new RadialGauge(gaugeOptions).draw()
    } else {
      this.chart = new LinearGauge(gaugeOptions).draw()
    }
  },
  beforeDestroy () {
    this.chart.destroy()
  },
  watch: {
    options: function () {
      this.chartOptions = {...this.options}
    }
  }
}
</script>
