<template>
  <intelyt-modal
    :isActive="isActive"
    :title="messageObj.title"
    v-on:close="close()"
  >
    <div class="content">
      <div class="field-group">
        <div class="field" v-html="responseMessage">
        </div>
      </div>
    </div>
    <template slot="footer">
        <a class="button is-primary" v-if="showCommandBtn" @click="sendCommand()">{{actionButtonText}}</a>
        <a class="button" @click="close()">{{cancelBtnText}}</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'

export default {
  components: {
    IntelytModal
  },
  watch: {
    isActive: {
      handler: function (newInput) {
        if (newInput) {
          this.responseMessage = this.messageObj.message
        }
      }
    }
  },
  data () {
    return {
      responseMessage: this.messageObj.message,
      showCommandBtn: true,
      cancelBtnText: 'Cancel'
    }
  },
  methods: {
    close () {
      this.showCommandBtn = true
      this.cancelBtnText = 'Cancel'
      this.$emit('close')
    },
    sendCommand () {
      const vm = this
      const store = this.$store
      /**
       * Call the command function and print the response message
       */
      const callCommand = async function () {
        vm.showCommandBtn = false
        await vm.commandArgs.callbackFn(store, vm.commandArgs.params)
          .then((response) => {
            const message = response.message ? response.message : vm.messageObj.success
            vm.responseMessage = message
            vm.cancelBtnText = 'Close'
            if (vm.commandArgs.event) vm.$emit('emitFunction', vm.commandArgs.event)
          })
          .catch(e => {
            let errorMsg = vm.messageObj.error
            // If it is not a API rejected message and passed custom message then use it as it is
            if (!e.response && e.message && typeof e.message === 'string') {
              // errorMsg = e.message
              errorMsg = `${vm.messageObj.error}<br> ${e.message}`
              // Else get the API error details and concat with configured error message
            } else if (typeof e === 'object' && e.response.data.errors[0].detail) {
              errorMsg = `${vm.messageObj.error}<br> ${e.response.data.errors[0].detail}`
            }
            vm.responseMessage = `<p class='text-danger'>${errorMsg}</p>`
          })
      }
      callCommand()
    }
  },
  props: {
    isActive: Boolean,
    actionButtonText: {
      type: String,
      default: 'Proceed'
    },
    messageObj: {
      type: Object,
      default () {
        return {}
      }
    },
    commandArgs: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
 .text-danger {
    color: #dc3545;
  }
</style>
