<template>
<div class="datepicker" :class="{'datepicker-range':range,'datepicker__clearable':clearable&&text&&!disabled}">
  <input readonly :value="text" :class="[show ? 'focus' : '', inputClass]" :disabled="disabled" :placeholder="placeholder" :name="name" v-if="type!=='inline'"/>
  <a class="datepicker-close" @click.stop="cls"></a>
  <transition name="datepicker-anim">
    <div class="datepicker-popup" :class="[popupClass,{'datepicker-inline':type==='inline'}]" tabindex="-1" v-if="show||type==='inline'">
      <template v-if="range">
        <datepicker-calendar v-model="dates[0]" :left="true"></datepicker-calendar>
        <datepicker-calendar v-model="dates[1]" :right="true"></datepicker-calendar>
      </template>
      <template v-else>
        <datepicker-calendar v-model="dates[0]"></datepicker-calendar>
      </template>
      <div v-if="showButtons" class="datepicker__buttons">
        <button @click.prevent.stop="cancel" class="datepicker__button-cancel">{{this.local.cancelTip}}</button>
        <button @click.prevent.stop="submit" class="datepicker__button-select">{{this.local.submitTip}}</button>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import DatepickerCalendar from './DatepickerCalendar.vue'
export default {
  name: 'DatePicker',
  components: { DatepickerCalendar },
  props: {
    name: [String],
    inputClass: [String],
    popupClass: [String],
    value: [Date, Array, String],
    disabled: [Boolean],
    type: {
      type: String,
      default: 'normal'
    },
    rangeSeparator: {
      type: String,
      default: '~'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: [String],
    disabledDate: {
      type: Function,
      default: () => {
        return false
      }
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    local: {
      type: Object,
      default () {
        return {
          dow: 7, // Sunday is the first day of the week
          hourTip: 'Select Hour', // tip of select hour
          minuteTip: 'Select Minute', // tip of select minute
          secondTip: 'Select Second', // tip of select second
          yearSuffix: '', // suffix of head
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'), // months of head
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'), // months of panel
          weeks: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'), // weeks,
          cancelTip: 'Cancel', // text for cancel button for daterange picker
          submitTip: 'Submit' // text for submit button for daterange picker
        }
      }
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    dateRangeSelect: [Function]
  },
  data () {
    return {
      show: false,
      dates: this.vi(this.value)
    }
  },
  computed: {
    range () {
      return this.dates.length === 2
    },
    text () {
      const val = this.value
      const txt = this.dates.map(date => this.tf(date)).join(` ${this.rangeSeparator} `)
      if (Array.isArray(val)) {
        return val.length > 1 ? txt : ''
      } else {
        return val ? txt : ''
      }
    }
  },
  watch: {
    value () {
      this.dates = this.vi(this.value)
    }
  },
  methods: {
    get () {
      return Array.isArray(this.value) ? this.dates : this.dates[0]
    },
    cls () {
      this.$emit('clear')
      this.$emit('input', this.range ? [] : '')
    },
    vi (val) {
      if (Array.isArray(val)) {
        return val.length > 1 ? val.map(item => new Date(item)) : [new Date(), new Date()]
      } else {
        return val ? new Array(new Date(val)) : [new Date()]
      }
    },
    ok (leaveOpened) {
      const $this = this
      $this.$emit('input', $this.get())
      !leaveOpened && !$this.showButtons && setTimeout(() => {
        $this.show = $this.range
      })
    },
    tf (time, format) {
      const year = time.getFullYear()
      const month = time.getMonth()
      const day = time.getDate()
      const hours24 = time.getHours()
      const hours = hours24 % 12 === 0 ? 12 : hours24 % 12
      const minutes = time.getMinutes()
      const seconds = time.getSeconds()
      const milliseconds = time.getMilliseconds()
      const dd = t => ('0' + t).slice(-2)
      const map = {
        YYYY: year,
        MM: dd(month + 1),
        MMM: this.local.months[month],
        MMMM: this.local.monthsHead[month],
        M: month + 1,
        DD: dd(day),
        D: day,
        HH: dd(hours24),
        H: hours24,
        hh: dd(hours),
        h: hours,
        mm: dd(minutes),
        m: minutes,
        ss: dd(seconds),
        s: seconds,
        S: milliseconds
      }
      return (format || this.format).replace(/Y+|M+|D+|H+|h+|m+|s+|S+/g, str => map[str])
    },
    dc (e) {
      this.show = this.$el.contains(e.target) && !this.disabled
    },
    submit () {
      this.$emit('confirm', this.get())
      this.show = false
    },
    cancel () {
      this.$emit('cancel')
      this.show = false
    }
  },
  mounted () {
    document.addEventListener('click', this.dc, true)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.dc, true)
  }
}
</script>

<style>
.datepicker {
  display: inline-block;
  position: relative;
}
.datepicker:before {
  content: '';
  display: block;
  position: absolute;
  width: 34px;
  height: 100%;
  top: 0;
  right: 0;
  background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" width="25" fill="grey" class="icons"><path d="M286.12,32.08H13.88C6.25,32.08,0,39.38,0,48.31V232.79C0,241.72,6.25,249,13.88,249H286.12c7.63,0,13.88-7.3,13.88-16.23V48.31C300,39.38,293.75,32.08,286.12,32.08ZM275.66,229.79H24.34c-4.2,0-7.64-4-7.64-8.93V70.7H283.3V220.86C283.3,225.77,279.86,229.79,275.66,229.79Z"/></svg>') no-repeat;
}
.datepicker-close {
  display: none;
  position: absolute;
  width: 34px;
  height: 100%;
  top: 0;
  right: 0;
  cursor: pointer;
}
.datepicker-close:before {
  display: block;
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  text-align: center;
  background: #ccc;
  color: #fff;
  border-radius: 50%;
  background:#ccc url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3IDciIHdpZHRoPSI3IiBoZWlnaHQ9IjciPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik01LjU4LDVsMi44LTIuODFBLjQxLjQxLDAsMSwwLDcuOCwxLjZMNSw0LjQxLDIuMiwxLjZhLjQxLjQxLDAsMCwwLS41OC41OGgwTDQuNDIsNSwxLjYyLDcuOGEuNDEuNDEsMCwwLDAsLjU4LjU4TDUsNS41OCw3LjgsOC4zOWEuNDEuNDEsMCwwLDAsLjU4LS41OGgwWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEuNSAtMS40OCkiIHN0eWxlPSJmaWxsOiNmZmYiLz48L3N2Zz4NCg==') no-repeat 50% 50%;
}
.datepicker__clearable:hover:before {
  display: none;
}
.datepicker__clearable:hover .datepicker-close{
  display: block;
}
.datepicker-close:hover:before{
  background-color: #afafaf;
}
.datepicker>input {
  color: #666;
  transition: all 200ms ease;
  border: 1px solid #e5e5e5;
  height: 34px;
  box-sizing: border-box;
  outline: none;
  padding: 0 34px 0 12px;
  font-size: 14px;
  width: 100%;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.datepicker>input.focus {
  border-color: #3bb4f2;
  -webkit-box-shadow: 0 0 5px rgba(59, 180, 242, .3);
  box-shadow: 0 0 5px rgba(59, 180, 242, .3);
}
.datepicker>input:disabled {
  cursor: not-allowed;
  background-color: #ebebe4;
  border-color: #e5e5e5;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.datepicker-popup {
  position: absolute;
  transition: all 200ms ease;
  opacity: 1;
  transform: scaleY(1);
  transform-origin: center top;
  font-size: 12px;
  background: #fff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 1px 6px rgba(99, 99, 99, 0.2);
  margin-top: 2px;
  outline: 0;
  padding: 5px;
  overflow: hidden;
  z-index: 999
}
.datepicker-inline{
  position: relative;
  margin-top: 0;
}
.datepicker-range {
  min-width: 325px
}
.datepicker-range .datepicker-popup{
  width: 403px;
}
.datepicker-bottom {
  float: left;
  width: 100%;
  text-align: right;
}
.datepicker-btn {
  padding: 5px 10px;
  background: #1284e7;
  color: #fff;
  border-radius: 2px;
  display: inline-block;
  cursor: pointer;
}
.datepicker-anim-enter-active {
    transform-origin: 0 0;
    animation: datepicker-anim-in .2s cubic-bezier(.23, 1, .32, 1)
}
.datepicker-anim-leave-active {
    transform-origin: 0 0;
    animation: datepicker-anim-out .2s cubic-bezier(.755, .05, .855, .06)
}
.datepicker__buttons {
  display: block;
  text-align: right;
}
.datepicker__buttons button {
  display: inline-block;
  font-size: 13px;
  border: none;
  cursor: pointer;
  margin: 10px 0 0 5px;
  padding: 5px 15px;
  color: #ffffff;
}
.datepicker__buttons .datepicker__button-select {
  background: #1284e7;
}
.datepicker__buttons .datepicker__button-cancel {
  background: #666;
}
@keyframes datepicker-anim-in {
    0% {
        opacity: 0;
        transform: scaleY(.8)
    }
    to {
        opacity: 1;
        transform: scaleY(1)
    }
}
@keyframes datepicker-anim-out {
    0% {
        opacity: 1;
        transform: scaleY(1)
    }
    to {
        opacity: 0;
        transform: scaleY(.8)
    }
}
</style>
