<template>
  <intelyt-modal
    :isActive="isActive"
    :title="title"
    :width="width"
    v-on:close="close()"
  >
    <div>
      <form id="editShpAclFrm">
        <div class="field-group">
          <div class="field">
            <label class="label">Scanner</label>
            <div class="select select-block">
              <select v-model="scannerVal" @change="getScannerData()" v-bind:disabled="scannerVal !== ''">
                <option value="">Select scanner</option>
                <option v-for="scanner in scanners" :key="scanner" :value="scanner">{{scanner}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field-group" v-show="scannerVal !== ''">
          <div class="field">
            <label class="label">ID</label>
            <p class="control"><svg id="barcode"></svg></p>
          </div>
          <div class="field" v-if="shipmentVerify === true">
            <p class="has-text-success"><base-icon :name="'check'" :color="'green'"></base-icon> Shipment id has been validated.</p>
          </div>
          <div class="columns">
            <div class="column" v-if="iChimeAdds.length > 0">
              <label class="label"><u>iChimes to Add</u></label>
              <div class="content">
                <ul>
                  <li v-for="(iChimeAdd, inx) in iChimeAdds" :key="inx">{{iChimeAdd.lastDeviceID}}</li>
                </ul>
              </div>
            </div>
            <div class="column" v-if="iChimeRemoves.length > 0">
              <label class="label"><u>iChimes to Remove</u></label>
              <div class="content">
                <ul>
                  <li v-for="(iChimeRemove, inx) in iChimeRemoves" :key="inx">{{iChimeRemove.lastDeviceID}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="columns"><p class="column" v-html="notifyMsg"></p></div>
        </div>
      </form>
    </div>
    <template slot="footer">
      <a class="button" @click="close()">Close</a>
    </template>
  </intelyt-modal>
</template>

<script>
import { mapState } from 'vuex'
import IntelytModal from '@/components/IntelytModal'
import JsBarcode from 'jsbarcode'
import moment from 'moment-timezone'
export default {
  components: {
    IntelytModal
  },
  computed: {
    scanners: function () {
      return Object.keys(this.$store.state.devices.scanners)
    },
    ...mapState({
      shipments: 'shipments'
    })
  },
  data () {
    return {
      count: 0,
      title: 'Edit Shipment ACL',
      timer: '',
      jobTimer: '',
      scannerVal: '',
      selectedJobId: 0,
      iChimeAdds: [],
      iChimeRemoves: [],
      lastScanHistory: [],
      notifyMsg: '',
      shipmentVerify: false,
      width: 800
    }
  },
  methods: {
    close () {
      this.scannerVal = ''
      this.notifyMsg = ''
      this.selectedJobId = 0
      this.iChimeAdds = []
      this.iChimeRemoves = []
      this.shipmentVerify = false
      clearInterval(this.timer)
      clearInterval(this.jobTimer)
      this.$emit('close')
    },
    fetchiChimesData () {
      const vm = this
      let jobNumber = parseInt(this.selectedJobId)
      // jobNumber = parseInt('219734386')
      /**
       * fetch message type
       */
      if (this.scannerVal !== '' && jobNumber > 0) {
        this.notifyMsg = 'Searching iChimes...'
        const payload2 = {
          macId: this.scannerVal,
          msgType: 1,
          pktType: 20,
          where: ''
        }
        this.$store.dispatch('shipments/fetchMessageTypeData', payload2).then(response => {
          let filteredResponse = Object.values(response).filter(dev => parseInt(dev.Job) > 0 && dev['Device ID'] !== '00_00_00_00_00_00_00_00')
          let iChimeSet = new Set()
          const mapFn = function (device) {
            device[1]['lastDeviceID'] = device[1]['Device ID'].slice(-11)
            return device[1]
          }
          // Sort the entries order by serverTime
          const sortFn = function (a, b) {
            return (moment(a.serverTime) < moment(b.serverTime)) ? 1 : -1
          }
          // Removed the Duplication entries
          const removeDuplication = function (el) {
            const duplicate = iChimeSet.has(el['Device ID'])
            iChimeSet.add(el['Device ID'])
            return !duplicate
          }
          // const filterByJob = filteredResponse.filter(dev => parseInt(dev.Job) === jobNumber)
          const filterByJob = filteredResponse.filter(dev => {
            return parseInt(dev.Job) === jobNumber
          })
          // let filteredChimes = Object.entries(filterByJob).map(mapFn).sort(sortFn).filter(removeDuplication).filter(device => moment.utc(device.serverTime) > now)
          let filteredChimes = Object.entries(filterByJob).map(mapFn).sort(sortFn).filter(removeDuplication)
          //  iChimes Add Section
          vm.iChimeAdds = filteredChimes.filter(device => device.Status === 'A') // get iChimes add data with status is 'A' and sort a iChimeAdd data order by serverTime as DESC. Finally, removed the duplication by device id
          vm.iChimeRemoves = filteredChimes.filter(device => device.Status === 'D') // get iChimes remove data with status is 'D' and sort a iChimeRemove data order by serverTime as DESC. Finally, removed the duplication by device id
          vm.notifyMsg = (Object.values(vm.iChimeAdds).length === 0 && Object.values(vm.iChimeRemoves).length === 0) ? '<div class="has-text-danger">iChimes not found</div>' : ''
        }).catch(e => {
          console.error('ERROR: ', e)
          vm.iChimeAdds = []
          vm.iChimeRemoves = []
          vm.notifyMsg = ''
        })
      }
    },
    getJobData (now) {
      const vm = this
      const subGuid = this.guid.substring(0, 8)
      const payload1 = {
        macId: this.scannerVal,
        msgType: 1,
        pktType: 20
      }
      this.shipmentVerify = false
      if (this.scannerVal !== '') {
        this.notifyMsg = `Searching job details... ${this.count}`
        this.count += 1
        this.$store.dispatch('shipments/fetchMessageTypeData', payload1).then(response => {
          console.log('reponse: ', response)
          const filterGUID = Object.values(response).filter(data => data.Value.includes(`#G#${subGuid}`) && parseInt(data.Job) !== 0 && moment.utc(data.serverTime) > now)
          vm.selectedJobId = (Object.values(filterGUID).length > 0) ? filterGUID[0].Job : 0
          if (vm.selectedJobId === 0) {
            clearInterval(vm.jobTimer)
            vm.jobTimer = setInterval(function () { vm.getJobData(now) }, 5000)
            return
          }
          vm.shipmentVerify = true
          clearInterval(vm.jobTimer)
          let callAPI = function () {
            vm.fetchiChimesData(now)
          }
          vm.fetchiChimesData(now)
          clearInterval(vm.timer)
          vm.timer = setInterval(callAPI, 5000)
        }).catch(e => {
          console.error('ERROR: ', e)
          vm.notifyMsg = ''
          clearInterval(vm.timer)
          clearInterval(vm.jobTimer)
          // vm.jobTimer = setInterval(function () { vm.getJobData(now) }, 5000)
        })
      }
    },
    getScannerData () {
      let now = moment.utc(new Date())
      this.getJobData(now)
      // this.fetchiChimesData(now)
    },
    refreshData () {
      // const shpmnt = this.$store.state.shipments.all[this.guid]
      const barcodeId = `#G#${this.guid.substring(0, 18)}`
      JsBarcode('#barcode', barcodeId, {
        width: 1.7,
        height: 50,
        displayValue: false
      })
      this.iChimeAdds = []
      this.iChimeRemoves = []
      this.selectedJobId = 0
      this.notifyMsg = ''
      this.shipmentVerify = false
      clearInterval(this.timer)
      clearInterval(this.jobTimer)
    }
  },
  props: ['isActive', 'guid'],
  watch: {
    // whenever guid changes, this function will run
    isActive: {
      handler: function () {
        this.refreshData()
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.inputsec
{
  display: inline-block
}
.select.select-block
{
  display: block
}
.select.select-block select
{
  width: 100%
}
</style>
