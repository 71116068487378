<template>
  <div style="padding:10px;" class="has-text-centered">
    <div class="dynamic-icons">
      <template v-for="(marker, idx) in markers">
        <span v-if="markerObj[marker] && !markerObj[marker].hide" :key="idx" class='legend-controls' @click="toggleLegendIcon(marker)" :style="{opacity: !markerObj[marker].active ? 0.6 : 1}">
          <base-icon :name="iconConfig[marker].name" :color="iconConfig[marker].color" :size="iconConfig[marker].size" />
          {{iconConfig[marker].label}} &nbsp;
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      iconConfig: this.$store.state.configuration.mapLegendIcons,
      markerObj: {}
    }
  },
  methods: {
    toggleLegendIcon: function (marker) {
      this.markerObj[marker].active = !this.markerObj[marker].active
      this.$emit('updateActiveMarkers', this.markerObj)
    }
  },
  mounted () {
    this.markerObj = {...this.activeMarkers}
  },
  // Markers to control the icons and activeMarkers to toggle the controls to on/off
  props: ['markers', 'activeMarkers'],
  watch: {
    activeMarkers: {
      handler: function () {
        this.markerObj = {...this.activeMarkers}
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.legend-controls {
  cursor: pointer;
  font-size: 14px;
}
</style>
