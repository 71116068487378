<template>
  <intelyt-modal
    :isActive="isActive"
    :title="title"
    v-on:close="close()"
  >
    <div class="content">
      <div class="field-group">
        <div class="field" v-if="!isHtml">
          {{message}}
        </div>
        <div class="field" v-if="isHtml" v-html="message">
        </div>
      </div>
    </div>
    <template slot="footer">
      <a class="button" @click="close()">Close</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'

export default {
  components: {
    IntelytModal
  },
  computed: {
  },
  data () {
    return {
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  },
  props: {
    isActive: Boolean,
    isHtml: {
      type: Boolean,
      default: false
    },
    message: String,
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
