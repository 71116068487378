<template>
  <div
      :class="[classname]"
      :id = "'___charts___' + _uid">
  </div>

</template>

<script>
// Echarts import
// source: https://github.com/abdullah/vue-echarts/blob/master/src/components/Echartsv.vue
import * as echarts from 'echarts'
// import Vue from 'vue'

export default {
  components: {
  },
  computed: {
    spinnerIsActive: function () {
      // If there is data, do not show spinner
      return !this.options.series[0].data
    }
  },
  props: {
    options: {
      type: Object,
      required: true,
      twoWay: true,
      validator: function (value) {
        return typeof value === 'object'
      }
    },
    classname: {
      type: String,
      default: function () {
        console.log('Classnames not found in props')
        return '___charts___'
      }
    },
    dblClickHandler: {
      type: String,
      default: 'default'
    },
    parentWidth: {
      type: Number,
      default: 0
    },
    resizable: {
      type: Boolean,
      default: true
    },
    showLoadingIcon: {
      type: Boolean,
      default: false
    },
    // If you mutate the data bound to options while retaining the object reference, setOption will be called with notMerge: false.
    // Otherwise, if you bind a new object to options, setOption will be called with notMerge: true
    notMerge: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ec_vue_charts: null
    }
  },
  // When change data trigger updateCharts().
  watch: {
    classname: function (val, oldVal) {
      console.log('---------------------- classname changed -------------------------')
      console.log('class new:: ', val, oldVal)
      this.updateCharts()
    },
    options: function () {
      // console.log('---------------------- options changed -------------------------')
      // console.log(val)
      this.updateCharts()
    },
    parentWidth: function () {
      // console.log('Val - Old Val -> ', val, oldVal)
      this.handleResize()
    }
  },
  updated () {
    // console.log('---------------------- options updated -------------------------')
    // console.log(this.options)
    this.updateCharts()
    if (this.options.series[0].data.length > 0) {
      this.ec_vue_charts.hideLoading()
    }
  },
  mounted () {
    this.renderCharts()
    this.setHandler()
  },
  methods: {
    /*
    @param null
    @return void
    */
    renderCharts: function () {
      // console.log('rendering: ', this.options)
      this.ec_vue_charts = echarts.init(document.getElementById('___charts___' + this._uid))
      this.ec_vue_charts.setOption(this.options, this.notMerge)
      // this.ec_vue_charts.showLoading()
      if (this.showLoadingIcon) {
        this.ec_vue_charts.showLoading()
      }
      // console.log('Opts: ', this.ec_vue_charts.getOption())
    },
    /*
    @param null
    @return void
    */
    updateCharts: function () {
      this.ec_vue_charts.setOption(this.options, this.notMerge)
    },
    handleResize: function () {
      if (this.resizable) {
        console.log('tried to resize')
        let opts = {width: 'auto'}
        this.ec_vue_charts.resize(opts)
      }
    },
    setHandler: function () {
      this.ec_vue_charts.on('dblclick', (params) => {
        // Whne element is double clicked, set the chartClick[handler] property in state to
        // value of click
        // console.log('UID: ', this._uid, this.dblClickHandler)
        const data = {
          timestamp: params.data[0],
          value: params.data[1],
          randSeed: Math.random()
        }
        if (params.data[2]) {
          data.entryId = params.data[2]
        }
        let clickPayload = {
          key: 'lastClick',
          value: this.dblClickHandler
        }
        let handlerPayload = {
          key: this.dblClickHandler,
          value: data
        }
        this.$store.commit('updateChartClickData', clickPayload)
        this.$store.commit('updateChartClickData', handlerPayload)
      })
    }
  }
}
</script>
<style>
  .___charts___{
    height: 350px;
  }
 /*  .spnnr {
    display:block;
    margin-left: auto;
    margin-right: auto;
  } */
</style>
