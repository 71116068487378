<template>
  <intelyt-modal
    :isActive="isActive"
    :title="title"
    v-on:close="close()"
  >
    <div class="content">
      <div class="field-group">
        <template  v-for="filter in filters">
          <div class="field" v-if="filter.hidden !== true" :key="filter.col">
            <label class="label">{{filter.name}}</label>
            <template v-if="filter.opts">
              <template v-if="filter.type === 'checkbox'">
                <template v-for="opt in filter.opts">
                  <p :key="opt.val">
                    <label class="checkbox">
                      <input type="checkbox" :value="opt.val" v-model="filter.val">
                      {{opt.name}}
                    </label>
                  </p>
                </template>
              </template>
              <template v-else>
                <div class="select" :class="{ 'is-multiple': filter.type === 'multiSelect' }">
                  <select v-model="filter.val" :multiple="filter.type === 'multiSelect'">
                    <option value="">-- Not Filtered --</option>
                    <template v-for="opt in filter.opts">
                      <option :value="(typeof opt.val !== 'undefined') ? opt.val : opt" :key="(typeof opt.val !== 'undefined') ? opt.val : opt">{{(typeof opt.name !== 'undefined') ? opt.name : opt}}</option>
                    </template>
                  </select>
                </div>
              </template>
            </template>
            <input class="input"
              :class="{'input': true}"
              type="text"
              v-model="filter.val"
              v-else
            >
          </div>
        </template>
      </div>
    </div>
    <template slot="footer">
      <a class="button is-primary" @click="commit()">Update</a>
      <a class="button" @click="close()">Cancel</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'

export default {
  components: {
    IntelytModal
  },
  computed: {
  },
  data () {
    return {
      title: this.modalTitle
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    commit () {
      this.$emit('reset')
      this.$emit('close')
    }
  },
  props: ['isActive', 'filters', 'modalTitle']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
