<template>
  <p :class="`c-json-p c-line-${lineType}`" :style="getIndent(level)">
    <span class="c-json-mark">{{ line }}</span>
    <span :class="`c-of-${lineType}`"></span>
    <span class="c-json-content">
      <span v-if="showIndex" class="c-json-key">{{ name }}: </span>
      <span :class="`c-json-${type}`">{{ value }}</span>
      <span class="c-json-comma">{{ needComma ? ',' : '' }}</span>
    </span>
  </p>
</template>
<script>
import { getIndent } from './utils.js';
export default {
  props: {
    name: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [Array, String],
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    line: {
      type: Number,
      default: 1
    },
    showIndex: {
      type: Boolean,
      default: false
    },
    needComma: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 1
    },
    lineType: {
      type: String,
      default: 'none'
    },
    lastLineType: {
      type: String,
      default: 'none'
    },
    lastLine: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getIndent: getIndent,
  },
};
</script>
