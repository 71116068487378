<template>
<div class="has-dropdown" @mouseenter="mouseOn()" @mouseleave="mouseOff()" >
  <slot></slot>
  <div class="alert-dropdown box" v-if="isVisible && items.length >0">
    <ul>
      <li v-for="item in items" :key="item.entryId">
        <intelyt-alert  :alert=item></intelyt-alert>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    mouseOn () {
      this.isVisible = true
    },
    mouseOff () {
      // if (!this.isToggled) {
      this.isVisible = false
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
  /* wrapper */
  .has-dropdown input[type="checkbox"] {
    display: none;
  }

  .has-dropdown input[type="checkbox"]:checked ~ .dropdown {
    display: block;
  }

  /* dropdown basic */
  .alert-dropdown {
    display: block;
    position: absolute;
    z-index: 9999;
    margin-left:-230px;
  }

  /* dropdown optional styling */
  .alert-dropdown li:hover {
    background-color: #eee;
    cursor: pointer;
  }

  .alert-dropdown li {
    width: 350px;
  }
  
</style>
