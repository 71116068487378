<template>
  <div>
    <div class="field" v-if="searchable">
      <label class="label">{{label}}  <base-icon name="information" size="15" v-if="tooltip && tooltip.length > 0" v-tooltip="tooltip" /></label>
      <ValidationProvider :name="label" :rules="formRules"  v-slot="{ errors }">
        <div class="select">
          <input type="text" class="input" name="macID" v-model="macID" list="devices" autocomplete="off">
          <datalist id="devices">
            <option value="">-- Select {{label}} --</option>
            <option v-for="mac in availableDevices" :key="mac">{{mac.substring(12)}}</option>
          </datalist>
        </div>
        <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
    <div class="field" v-else>
      <label class="label">{{label}}  <base-icon name="information" size="15" v-if="tooltip && tooltip.length > 0" v-tooltip="tooltip" /></label>
      <ValidationProvider :name="label" :rules="formRules"  v-slot="{ errors }">
        <div class="select">
          <select v-model="macID" name="macID">
            <option value="">-- Select {{label}} --</option>
            <option v-for="mac in availableDevices" :value="mac" :key="mac">{{mac.substring(12)}}</option>
          </select>
        </div>
        <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeviceSelect',
  computed: {
    availableDevices () {
      return this.$store.state.devices[this.devType].available
    }
  },
  data () {
    return {
      macID: ''
    }
  },
  props: {
    default: String,
    mode: String,
    label: String,
    devType: {
      type: String,
      default: 'tags'
    },
    reset: Boolean,
    searchable: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ''
    },
    formRules: Object
  },
  watch: {
    reset (val) {
      if (val) {
        // If reset is true, then set the value as blank
        // If the default value is passed, then set the value
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        this.macID = this.default || ''
      }
    },
    macID (val) {
      const mac = val.length > 16 ? val : `00_1B_AD_00_${val}`
      this.$store.commit('SET_FORM_FIELD', {key: 'device', value: mac, mode: this.mode})
    }
  }
}
</script>
