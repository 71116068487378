<template>
  <intelyt-modal
    :isActive="isActive"
    :title="title"
    v-on:close="close()"
  >
    <div class="content" v-if="!submitted">
      Please confirm that you wish to delete this {{type}}:
      <br />&nbsp;
      <br />
      <div v-if="type === 'Asset'">
        Asset ID: {{clientShipmentId}} <br />
        Device: {{macId}} <br />
        Type: {{customer}} <br />
        Date Created: {{createDate}} <br />
        System ID: {{guid}}
      </div>
      <div v-if="type !== 'Asset'">
        Shipment ID: {{clientShipmentId}} <br />
        Device: {{macId}} <br />
        Customer: {{customer}} <br />
        Origin: {{origin}} <br />
        Destination: {{destination}} <br />
        Date Created: {{createDate}} <br />
        System ID: {{guid}}
      </div>
    </div>
    <div class="content is-danger" v-if="submitted">
      <intelyt-message
         :title="messageTitle"
         :body="message"
         :level="messageLevel"
         :icon="messageIcon">
      </intelyt-message>
    </div>
    <template slot="footer">
      <a class="button is-primary" @click="confirm()" v-if="!submitted">Confirm Delete</a>
      <a class="button" @click="close()" v-if="!submitted">Cancel</a>
      <a class="button" @click="close()" v-if="submitted" >Close</a>
    </template>
  </intelyt-modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import {capitalize, shortDate} from '@/store/formatters.js'
import {generateIconSVG, getShpmntDeviceType} from '@/store/helpers'
import IntelytMessage from '@/components/IntelytMessage'
import IntelytModal from '@/components/IntelytModal'
import router from '../router'

export default {
  components: {
    IntelytMessage,
    IntelytModal
  },
  computed: {
    templateStr: function () {
      return this.templateFile || this.$store.state.configuration.templateFiles.manualDeleteShipment
    },
    ...mapState({
      company: 'company',
      shipments: 'shipments'
    })
  },
  data () {
    // let type = this.shipments.all[this.guid].class
    return {
      title: '',
      clientShipmentId: '',
      createDate: '',
      customer: '',
      destination: '',
      deviceType: 'tags',
      macId: '',
      message: '',
      messageBody: '',
      messageIcon: '',
      messageLevel: '',
      origin: '',
      submitted: false
    }
  },
  methods: {
    close () {
      this.message = ''
      this.messageBody = ''
      this.messageIcon = ''
      this.messageLevel = ''
      this.submitted = false
      this.$emit('close')
    },
    confirm () {
      // console.log('Delete Shipment/Asset: ', this.templateStr)
      let deletePayload = {
        template: this.templateStr,
        mac: this.macId,
        guid: this.guid,
        runByGUID: true
      }
      this.runActivity(deletePayload).then(() => {
        let icon = {
          name: 'check',
          color: 'green',
          size: 20
        }
        // console.log('Response: ', response)
        // remove shipment from store
        this['shipments/DELETE_SHIPMENT'](this.guid)
        this.messageIcon = generateIconSVG(this.$store.state, icon)
        this.messageLevel = 'success'
        this.messageTitle = `Removal of ${this.type} was successful`
        this.message = ''
        this.submitted = true
        // Add the device mac id to the available list
        this.$store.dispatch('devices/updateAvailableList', {deviceId: this.macId, deviceType: this.deviceType, action: 'add'})
        this.deviceType = 'tags'
        router.push(this.$store.state.user.landingPage)
      })
        .catch(e => {
          let icon = {
            name: 'bang',
            color: 'yellow',
            size: 20
          }
          console.log('ERROR: ', e.response.data)
          this.messageIcon = generateIconSVG(this.$store.state, icon)
          this.messageTitle = `Removal of ${this.type} failed`
          this.messageLevel = 'warning'
          this.message = e.response.data.createResultObj[0]
          this.submitted = true
        })
    },
    refreshData () {
      if (!this.shipments.all[this.guid]) return
      const shipment = this.shipments.all[this.guid]
      // console.log('refreshData called', this.shipments.all[this.guid])
      this.title = 'Delete ' + capitalize(shipment.class)
      this.clientShipmentId = shipment.clientShipmentId
      this.createDate = shortDate(shipment.createDate)
      this.customer = shipment.customer
      this.destination = shipment.destination
      /* For repo jobs, the mac id = 'none' in this case need to
         set to default repo mac id */
      this.macId = shipment.macId === 'none' ? this.$store.state.configuration.siteOptions.repoMacId : shipment.macId
      this.origin = shipment.origin
      this.deviceType = getShpmntDeviceType(this.$store.state, shipment.configType)
      this.submitted = false
      // console.log('refreshData called', this.macId)
    },
    ...mapActions([
      'runActivity'
    ]),
    ...mapMutations([
      'shipments/DELETE_SHIPMENT'
    ])
  },
  mounted () {
    // Refresh data if a guid is defined, otherwise it will reset when the guid changes
    if (this.guid) {
      this.refreshData()
    }
  },
  props: {
    isActive: Boolean,
    guid: String,
    templateFile: {
      type: [ String, Boolean ],
      default: false
    },
    type: String
  },
  watch: {
    // whenever model close/opens, this function will run
    isActive: {
      handler: function () {
        this.refreshData()
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
