<template>
  <gmap-map
    :center="center"
    :zoom="this.calc_zoom"
    :style="style"
    :options="options"
    @zoom_changed="getCurrentZoom"
  >
    <template v-if="mapZoom < maxCellAouZoom">
      <template v-for="(waypoint, wpIndex) in waypoints">
        <gmap-circle v-if="waypoint.visible" :key="`wpIndex${wpIndex}`"
          :center="waypoint.center"
          :radius="waypoint.radius"
          :options="waypoint.options"
          :editable="waypoint.editable"
          @mouseover="toggleInfoWindow(waypoint)"
          @mouseout="toggleInfoWindow(waypoint)"
          :draggable="false">
        </gmap-circle>
    </template>
    </template>
    <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen=false">
        <span v-html="infoContent"></span>
    </gmap-info-window>
    <gmap-cluster :maxZoom="clusterZoomCutoff">
      <gmap-marker v-for="(mrk, mrkIndex) in markers" :key="`mrkIndex${mrkIndex}`"
          :position="mrk.position"
          :clickable="true"
          :draggable="mrk.draggable ? mrk.draggable : false"
          :icon="getIcon[mrk.icon] || getIcon['default']"
          @dragend="updateMarker($event.latLng)"
          @mouseover="toggleInfoWindow(mrk)"
          @mouseout="toggleInfoWindow(mrk)"
          @click="handleMarkerClick(mrk)"
          @dblclick="handleMarkerDblClick(mrk)"
      ></gmap-marker>
    </gmap-cluster>
    <gmap-polyline v-for="(pathObj, pathIndex) in pathList" :key="`pathIndex${pathIndex}`"
        :path="pathObj.path"
        :options="pathObj.options"
        :editable="pathObj.editable || false"
        @click="handlePathClick(pathObj)"
        @dblclick="handlePathDblClick(pathObj)"
        @path_changed="updatePolylines($event)"
    ></gmap-polyline>
    <gmap-polygon v-if="polygons"
        :paths="polygons.paths"
        :editable="polygons.editable"
        :options="polygons.options"
        @paths_changed="updatePolygon($event)"
    >
    </gmap-polygon>
    <div slot="visible">
    </div>
  </gmap-map>
</template>

<script>
import {getMinMaxLatLng} from '@/store/helpers'

export default {
  components: {
  },
  computed: {
    calc_zoom: function () {
      // https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds
      if (this.zoomFixed) {
        return this.zoom
      }
      // if (this.zoom > 0) { return this.zoom }
      const WORLD_DIM = { height: 256, width: 256 }
      // var ZOOM_MAX = 21
      const ZOOM_MAX = 18

      let maxLat = -90
      let minLat = 90
      let maxLng = -180
      let minLng = 180
      if (this.markers.length > 0) {
        for (let value of this.markers) {
          if (minLat > value.position.lat) { minLat = value.position.lat }
          if (maxLat < value.position.lat) { maxLat = value.position.lat }
          if (minLng > value.position.lng) { minLng = value.position.lng }
          if (maxLng < value.position.lng) { maxLng = value.position.lng }
        }
      }
      if (this.polygons && this.polygons.paths.length > 0) {
        for (let value of this.polygons.paths[0]) {
          if (minLat > value.lat) { minLat = value.lat }
          if (maxLat < value.lat) { maxLat = value.lat }
          if (minLng > value.lng) { minLng = value.lng }
          if (maxLng < value.lng) { maxLng = value.lng }
        }
      }
      // If polyline points available get the max lat and lng values
      if (this.pathList && this.pathList.length > 0) {
        for (let value of this.pathList[0].path) {
          if (minLat > value.lat) { minLat = value.lat }
          if (maxLat < value.lat) { maxLat = value.lat }
          if (minLng > value.lng) { minLng = value.lng }
          if (maxLng < value.lng) { maxLng = value.lng }
        }
      }

      function latRad (lat) {
        var sin = Math.sin(lat * Math.PI / 180)
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
      }

      function zoom (mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
      }

      var ne = {lat: maxLat, lng: maxLng}
      var sw = {lat: minLat, lng: minLng}

      var latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI

      var lngDiff = ne.lng - sw.lng
      var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360

      var latZoom = zoom(this.mapWidth / 2, WORLD_DIM.height, latFraction)
      var lngZoom = zoom(this.mapWidth, WORLD_DIM.width, lngFraction)
      let outputZoom = Math.min(latZoom, lngZoom, ZOOM_MAX)
      return outputZoom > 1 ? outputZoom : 1
    },
    center: function () {
      let minMax = [0, 0, 0, 0]
      if (this.markers.length > 0) {
        if (this.currentZoom) {
          let currentLocation = this.markers.filter(loc => { return loc.icon === 'currentLocationIcon' })
          if (currentLocation.length === 0) {
            currentLocation = this.markers.filter(loc => { return loc.icon === 'origin' })
          }
          if (currentLocation.length > 0) {
            return currentLocation[0].position
          }
        }
        if (this.customCenter) {
          return this.customCenter.location
        }
        let points = this.markers.map(mrk => mrk.position)
        minMax = getMinMaxLatLng(points)
      } else if (this.polygons && this.polygons.paths && this.polygons.paths.length > 0 && this.polygons.paths[0].length > 0) {
        minMax = getMinMaxLatLng(this.polygons.paths[0])
      } else if (this.pathList.length > 0) {
        minMax = getMinMaxLatLng(this.pathList[0].path)
      }
      let avgLat = (minMax[0] + minMax[1]) / 2
      let avgLng = (minMax[2] + minMax[3]) / 2
      if (Math.abs(minMax[2] - minMax[3]) > 180) {
        // The average will cross the date line and not the Grenwich mean
        // thus average longitude will be cetered more arount +/- 180
        if (avgLng > 0) {
          avgLng -= 180
        } else {
          avgLng += 180
        }
      }
      return {lat: avgLat, lng: avgLng}
    },
    style: function () {
      let width = this.mapWidth
      let height = this.mapWidth / this.heightRatio
      let styleText = 'width: ' + width + 'px; height: ' + height + 'px;'
      return styleText
    }
  },
  data () {
    const AIRPLANE = {
      path: 'M 8,-2 8,-5 10,-7 10,-10 0,-8 0,-12 10,-18 10,-22 A 3 3, 0, 1 1, 16 -22 L16,-18 26,-12 26,-8 16,-10 16,-7 19,-5 19,-2 13,-4 z',
      scale: 0.9,
      strokeWeight: 1.5,
      fillOpacity: 0.4
    }
    const CHECKERED_FLAG = {
      path: 'M 0,0 0,-30 6,-30 6,-25 1,-25 1,0 M 1,-20 6,-20 6,-15 0,-15 M 6,-25 11,-25 11,-20 6,-20 z M 10,-30 15,-30 15,-25 10,-25 z M 10,-20 15,-20 15,-15 10,-15 z',
      scale: 1,
      fillOpacity: 1,
      strokeWeight: 0.8
    }
    const CIRCLE = {
      path: 'M -2,0a2,2 0 1,0 4,0a2,2 0 1,0 -4,0',
      scale: 1.5,
      fillOpacity: 1
    }
    const DEFAULT = {
      path: 'M149.54,278s12.29-20.51,27.14-46.74h0S220,151.8,226,133a81.91,81.91,0,1,0-152.92,0c6.07,19.86,55.28,109.48,55.28,109.48C140.39,263.19,149.54,278,149.54,278ZM86.65,126.79h0A67,67,0,1,1,213,125.26l-.13.44-.19.49c-.46,1.29-1,2.55-1.48,3.8-13.43,32.87-62,121.62-62,121.62S94.86,155.63,86.65,126.79 z',
      scale: 0.1,
      fillOpacity: 1
    }
    const FLAG_CUTOUT = {
      path: 'M 0,0 0,-30 17,-30 13,-25 17,-20 0,-20',
      scale: 1,
      fillOpacity: 1,
      strokeWeight: 0.5
    }
    const FLAG_SIMPLE = {
      path: 'M 0,0 0,-30 15,-30 15,-20 0,-20',
      scale: 1,
      fillOpacity: 1
    }
    const DIAMOND = {
      path: 'M -5,0 0,-5 5,0 0,5 z',
      scale: 1,
      fillOpacity: 1
    }
    const ALERT = {
      path: `M0-20c-3.5,0-6.4,2.9-6.4,6.4c0,0.8,0.1,1.6,0.4,2.3c0.5,1.6,4.3,8.5,4.3,8.5C-0.7-1.2,0,0,0,0s1-1.6,2.1-3.6l0,0c0,0,3.4-6.2,3.9-7.7c0.3-0.7,0.4-1.5,0.4-2.3C6.4-17.1,3.5-20,0-20C0-20,0-20,0-20z M1-5.7C0.7-5.4,0.4-5.3,0-5.3c-0.4,0-0.7-0.1-1-0.4c-0.6-0.6-0.6-1.5,0-2.1c0.3-0.3,0.6-0.4,1-0.4c0.8,0,1.5,0.7,1.4,1.5C1.4-6.3,1.3-6,1-5.7z M1.8-14.5L1.8-14.5L0.9-9.3c0,0.1-0.1,0.2-0.2,0.2h-1.4c-0.1,0-0.2-0.1-0.2-0.2l-0.9-5.2v-2.8c0-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0h3.3c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0L1.8-14.5z`,
      scale: 1,
      fillOpacity: 1
    }
    const PENNANT = {
      path: 'M 0,0 0,-30 18,-24 0,-18',
      scale: 1,
      fillOpacity: 1
    }
    const BLACK = {strokeColor: 'black', fillColor: 'black'}
    const ORANGE = {strokeColor: '#DE6A12', fillColor: '#DE6A12'}
    const BLUE = {strokeColor: '#074cbc', fillColor: '#074cbc'}
    const GREEN = {strokeColor: 'green', fillColor: 'green'}
    const RED = {strokeColor: '#B40404', fillColor: '#B40404'}
    // Get the max zoom value configured to compare the map's zoom value
    const maxCellAouZoom = this.$store.state.configuration.siteOptions.maxCellAouZoom
    return {
      statusText: '',
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      // optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      getIcon: {
        airport: Object.assign({}, ORANGE, AIRPLANE),
        BoxTruck: Object.assign({}, GREEN, FLAG_CUTOUT),
        cell: Object.assign({}, GREEN, CIRCLE),
        currentLocationIcon: Object.assign({}, BLUE, FLAG_CUTOUT),
        destination: Object.assign({}, BLACK, CHECKERED_FLAG),
        destinationIcon: Object.assign({}, BLACK, CHECKERED_FLAG),
        gps: Object.assign({}, BLUE, CIRCLE),
        igate: Object.assign({}, ORANGE, CIRCLE),
        iGateOnline: Object.assign({}, GREEN, CIRCLE),
        iGateOffline: Object.assign({}, RED, CIRCLE),
        geofence: Object.assign({}, GREEN, PENNANT),
        lastCell: Object.assign({}, RED, CHECKERED_FLAG),
        lastCharger: Object.assign({}, ORANGE, CHECKERED_FLAG),
        lastGps: Object.assign({}, GREEN, CHECKERED_FLAG),
        lastIGate: Object.assign({}, BLUE, CHECKERED_FLAG),
        location: Object.assign({}, GREEN, PENNANT),
        locationOnline: Object.assign({}, GREEN, PENNANT),
        locationOffline: Object.assign({}, RED, PENNANT),
        office: Object.assign({}, RED, FLAG_SIMPLE),
        origin: Object.assign({}, GREEN, FLAG_SIMPLE),
        redDiamond: Object.assign({}, RED, DIAMOND),
        shipment: Object.assign({}, BLUE, DIAMOND),
        SprinterVan: Object.assign({}, BLUE, FLAG_CUTOUT),
        Trailer: Object.assign({}, ORANGE, FLAG_CUTOUT),
        warehouse: Object.assign({}, BLUE, FLAG_CUTOUT),
        waypointIcon: Object.assign({}, RED, FLAG_CUTOUT),
        alertIcon: Object.assign({}, RED, ALERT),
        default: Object.assign({}, RED, DEFAULT)
      },
      mapZoom: 1,
      maxCellAouZoom
    }
  },
  methods: {
    handleMarkerClick: function (mrk) {
      this.$emit('markerClick', mrk)
    },
    handleMarkerDblClick: function (mrk) {
      this.$emit('markerDblClick', mrk)
    },
    handlePathClick: function (path) {
      this.$emit('pathClick', path)
    },
    handlePathDblClick: function (path) {
      this.$emit('pathDblClick', path)
    },
    handleLegendClick: function () {},
    toggleInfoWindow: function (marker, idx = null) {
      /* If marker is standard marker, it has position and infoText, if it is
       * a circle it uses center and name (for text)
       */
      this.infoWindowPos = marker.position || marker.center
      this.infoContent = marker.infoText || marker.name
      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    updatePolygon: function (mvcArray) {
      const i = 0
      let path = []
      for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
        let point = mvcArray.getAt(i).getAt(j)
        path.push({lat: point.lat(), lng: point.lng()})
      }
      this.$emit('polygon_changed', path)
    },
    // Called on updating the polyline. Will pass the updated points to the parent component
    updatePolylines: function (polyLine) {
      let path = []
      for (let j = 0; j < polyLine.getLength(); j++) {
        let point = polyLine.getAt(j)
        path.push({lat: point.lat(), lng: point.lng()})
      }
      this.$emit('polyline_changed', path)
    },
    // Called on updating the marker. Will pass the updated points to the parent component
    updateMarker: function (evnt) {
      const position = {lat: evnt.lat(), lng: evnt.lng()}
      this.$emit('marker_moved', position)
    },
    getCurrentZoom (zoom) {
      // Google Maps zoom value
      this.mapZoom = zoom
    }
  },
  props: {
    markers: Array,
    mapWidth: {
      type: Number,
      default: 800
    },
    heightRatio: {
      type: Number,
      default: 1.6
    },
    waypoints: {
      type: Array,
      default: () => []
    },
    clusterZoomCutoff: {
      type: Number,
      default: 1
    },
    options: {
      type: Object,
      default: () => ({})
    },
    pathList: {
      type: Array,
      default: () => []
    },
    polygons: {
      type: Object
    },
    zoomFixed: {
      type: Boolean,
      default: false
    },
    zoom: {
      type: Number,
      default: 4
    },
    currentZoom: {
      type: Boolean,
      default: false
    },
    customCenter: {
      type: [Boolean, Object],
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
