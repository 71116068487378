import Vue from 'vue'
import Vuex from 'vuex'
import { state } from './state'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'
// import plugins from './plugins'
import alerts from './modules/alerts'
import company from './modules/company'
import devices from './modules/devices'
import geofences from './modules/geofences'
import locations from './modules/locations'
import metrics from './modules/metrics'
import reports from './modules/reports'
import shipments from './modules/shipments'
// import tags from './modules/tags'
import warehouses from './modules/warehouses'
import waypoints from './modules/waypoints'
import weather from './modules/weather'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersist({
  key: 'intelyt',
  storage: window.localStorage
})

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    alerts,
    company,
    devices,
    geofences,
    locations,
    metrics,
    reports,
    shipments,
    warehouses,
    waypoints,
    weather
  },
  strict: debug,
  plugins: [vuexLocal.plugin]
})

export default store
