<template>
  <div>
    <level-bar>{{pageName}}</level-bar>
    <div class="column">
      <intelyt-card>
        <template slot="header">{{itemName}}s</template>
        <template slot="controls">
          <base-icon name="binoculars" @click="openModal('shipmentFind')" v-tooltip="'Find Archived Shipments'" class="icon-padding" v-if="showFindShipment"/>
          &nbsp;
          <base-icon name="add_barcode" @click="openModal('barcode')" v-tooltip="'Create Barcode'" class="icon-padding" v-if="showBarcode"/>
          &nbsp;
          <base-icon name="add" @click="openModal('addEdit')" v-tooltip="newShpTitle" v-if="showAdd"/>
        </template>
        <base-table
          :data="tableData"
          :columns="tableColumns"
          :options="tableOptions"
          :loadMore="loadMoreOpts"
        >
        </base-table>
      </intelyt-card>
    </div>
    <add-edit-modal
      :title="newShpTitle"
      :formElements="newShpFields"
      :isActive="modalIsActive.addEdit"
      :required="requiredFields"
      :tooltipConfig="tooltips"
      :callback="createShipmentCallback"
      v-on:close="closeModal('addEdit')"
    >
    </add-edit-modal>
    <barcode-modal
      :isActive="modalIsActive.barcode"
      v-on:close="closeModal('barcode')"
    >
    </barcode-modal>
    <delete-shipment-modal
      :isActive="modalIsActive.deleteShipment"
      :guid="selectedGuid"
      :type="itemName"
      :templateFile="deleteTemplate"
      v-on:close="closeModal('deleteShipment')"
    >
    </delete-shipment-modal>
    <edit-shipment-acl-modal
      :isActive="modalIsActive.editShipmentAcl"
      :guid="selectedGuid"
      v-on:close="closeModal('editShipmentAcl')"
    ></edit-shipment-acl-modal>
    <send-command-modal
      :messageObj="cmdModal.messageObj"
      :isActive="modalIsActive.sendCommand"
      :actionButtonText="cmdModal.button"
      :commandArgs="{callbackFn: cmdModal.callback, params: cmdModal.params, event: cmdModal.event}"
      v-on:emitFunction="emitFunctionCallback"
      v-on:close="closeModal('sendCommand')"
    >
    </send-command-modal>
    <shipment-find-modal
      :isActive="modalIsActive.shipmentFind"
      :tabConfig="tabConfig"
      :redirect="redirectFind"
      :redirectPath="`${mode}-detail`"
      :title="`Find ${itemName}`"
      :cfgType="configType"
      v-on:close="closeModal('shipmentFind')"
      v-on:openMsgModal="showShpDetails"
    >
    </shipment-find-modal>
    <show-message-modal
      :message="msgBody"
      :title="msgTitle"
      :is-html=true
      :isActive="modalIsActive.showMessage"
      v-on:close="closeModal('showMessage')"
    >
    </show-message-modal>
  </div>
</template>

<script>
// Modals
import AddEditModal from '@/modals/AddEditModal'
import BarcodeModal from '@/modals/BarcodeModal'
import DeleteShipmentModal from '@/modals/DeleteShipmentModal'
import EditShipmentAclModal from '@/modals/EditShipmentAclModal'
import ShipmentFindModal from '@/modals/ShipmentFindModal'
// Formatters, helpers, etc.
import { chimeAcl, capitalize, chimeList, deviceLink, getProvisionerName, relativeDate, shortDayTime } from '@/store/formatters.js'
import { generateIconSVG, getACLCommands, getCommandList, getShpmntDeviceType, getMessageObject, getDateFormat, lookupProjectMsgHelper, lookupProjectIdHelper } from '@/store/helpers'
// Mixins & Vuex
import { mapActions } from 'vuex'
import { modalWindowManager } from '@/store/mixins'
// External Libraries
import moment from 'moment-timezone'

export default {
  name: 'ActivationPage',
  beforeDestroy () {
    clearInterval(this.timer)
  },
  components: {
    AddEditModal,
    BarcodeModal,
    DeleteShipmentModal,
    EditShipmentAclModal,
    ShipmentFindModal
  },
  computed: {
    configType () {
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      return this.pageConfig.configType || 0
    },
    deleteTemplate () {
      // Set default template for delete actions and commands and override if page defines alternate.
      let delTempl = this.$store.state.configuration.templateFiles.manualDeleteShipment
      if (this.pageConfig.deleteTemplate) delTempl = this.$store.state.configuration.templateFiles[this.pageConfig.deleteTemplate]
      return delTempl
    },
    tooltips () {
      return this.$store.state.messages.activation.tooltip || {}
    },
    itemName () {
      // return header for list - use mode + 's'
      const mode = this.$route.query.mode
      return capitalize(mode)
    },
    newShpFields () {
      if (!this.pageConfig.addShipmentForm) return []
      return this.pageConfig.addShipmentForm.fields || []
    },
    newShpTitle () {
      if (!this.pageConfig.addShipmentForm) return ''
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      return this.pageConfig.addShipmentForm.label || ''
    },
    pageName () {
      // return title for page - use mode + 'Activation'
      return `${this.itemName} Activation`
    },
    redirectFind () {
      // If redirectFind is set to false, the find modal will show message, otherwise it
      // will redirect to the detail page
      // generally the false for reposition message
      // return this.pageConfig.hasOwnProperty('redirectFind') ? this.pageConfig.redirectFind : true
      return Object.prototype.hasOwnProperty.call(this.pageConfig, 'redirectFind') ? this.pageConfig.redirectFind : true
    },
    showAdd () {
      const isCpnyAdmin = this.$store.state.user.roles.includes('Company Administrator')
      const isProvisioner = this.$store.state.user.roles.includes('Provisioner')
      const formExists = this.pageConfig.addShipmentForm
      return formExists && (isCpnyAdmin || isProvisioner)
    },
    showBarcode () {
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      return this.pageConfig.showBarcode || false
    },
    showFindShipment () {
      // findShipmentFields.length > 0 or findShipmentFields exists
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      return this.pageConfig.findShipmentFields || false
    },
    tabConfig () {
      return this.pageConfig.findShipmentFields || []
    },
    tableColumns () {
      /* Table Columns are generated by taking list in configuration and mapping the additional parameters
       * found in allTableColumns - they are either used directly or the label is provided by config
       * the rest of the function includes formatters for manipulation of data visibility
       */
      // Get state of page
      const thisState = this.$store.state
      const thisMode = this.mode
      const thisPageConfig = this.pageConfig
      // Create function to display icons which indicate activation state
      // Formatter for activation message
      const actvMsg = function (actv) {
        const message = (typeof actv !== 'undefined' && typeof actv.msg !== 'undefined') ? actv.msg : '-'
        return `<div style="max-width:250px;">${message}</div>`
      }
      // skipcq: JS-D1001.  Allow documentation comment
      const actvIcon = function (actv) {
        const svgSpacer = '<svg width="7" height="30"></svg>'
        const icons = {
          none: { name: 'circle_stroke', color: 'grey' },
          good: { name: 'check', color: 'green' },
          warn: { name: 'alert', color: 'red' },
          error: { name: 'circle_slash', color: 'red' },
          working: { name: 'triangle', color: 'yellow' }
        }
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        const save = actv.save || 'none'
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        const comm = actv.comm || 'none'
        return `${generateIconSVG(thisState, icons[save])}${svgSpacer}${generateIconSVG(thisState, icons[comm])}`
      }
      // Formatter to format macId and add link
      // skipcq: JS-D1001.  Allow documentation comment
      const deviceType = getShpmntDeviceType(this.$store.state, thisPageConfig.configType)
      // skipcq: JS-D1001.  Allow documentation comment
      const getDeviceLink = function (mac) {
        return deviceLink(thisState, mac, deviceType)
      }
      const shipIdLink = function (row) {
        // return path and query string for router link
        return {path: 'detail', query: { id: row.guid, mode: thisMode }}
      }
      const showDelete = function (row) {
        /* Show delete if one of the following is true:
            1. repo job - any user - any time
            2. other job - shippingState is below threshold
        */
        if (thisMode === 'reposition') {
          // Delete is always possible
          return true // thisState.user.roles.includes('Company Administrator')
        }
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        const deleteLimit = thisPageConfig.deleteLimit || 3
        return parseInt(row.shippingState) < parseInt(deleteLimit)
      }
      // skipcq: JS-D1001.  Allow documentation comment
      const showEditACL = function () {
        return thisState.configuration.siteOptions.editShipmentACL
      }
      const showHideDetailIcon = function (row) {
        // Used to determine if show details icon should be visible
        return row.selected
      }
      const showShowDetailIcon = function (row) {
        // Used to determine if show details icon should be visible
        return !row.selected
      }
      const showProjLookup = function (row) {
        // Used to determine if lookup icon should be visible
        return row.clientShipmentId === 'None'
      }
      const showRebuildAcl = function (row) {
        // Used to determine if rebuild ACL icon should be visible
        return row.activation ? row.activation.showRebuild : false
        // Used to determine if rebuild ACL icon should be visible
        // const rebuildTime = row.lastStageCmdTime ? moment.utc(row.lastStageCmdTime) : moment()
        // const timeSinceRebuild = moment().diff(rebuildTime, 'minutes')
        // return row.shippingState == 2 && (timeSinceRebuild > 3 || !row.lastStageCmdTime)
      }
      const showBuildAcl = function (row) {
        // Used to determine if build ACL icon should be visible
        return row.activation ? row.activation.showRebuild : false
        // const rebuildTime = row.lastStageCmdTime ? moment.utc(row.lastStageCmdTime) : moment()
        // const timeSinceRebuild = moment().diff(rebuildTime, 'minutes')
        // return row.shippingState == 2 && (timeSinceRebuild > 3 || !row.lastStageCmdTime)
      }
      const shpStatusStr = function (id) {
        // Used to show human readable status
        // use stateDefs.shipment, stateDefs.asset, etc.
        const shipStateDefs = thisState.stateDefs[thisMode] || {}
        // if no match send '-'
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        return shipStateDefs[id] || '-'
      }
      // Get users timezone for use in column labels
      const tmzn = this.$store.state.user.timezone || moment.tz.guess()
      const timezone = moment().tz(tmzn).format('z')
      // Get icons for controls
      const allControlIcons = {
        delete: {icon: 'trash', callback: this.deleteShipment, tooltip: 'Delete', show: showDelete},
        editAcl: {icon: 'refresh_square', callback: this.editShipmentAcl, tooltip: 'Edit Shipment ACL', show: showEditACL},
        hideDetail: {icon: 'collapse', callback: this.toggleChimeVis, tooltip: 'Hide Detail', show: showHideDetailIcon},
        lookupProjectId: {icon: 'search', callback: this.lookupProjectId, tooltip: 'Lookup Project Id', show: showProjLookup},
        rebuildAcl: {icon: 'refresh', callback: this.rebuildAcl, tooltip: 'Rebuild ACL', show: showRebuildAcl},
        buildAcl: {icon: 'refresh', callback: this.buildAcl, tooltip: 'Build ACL', show: showBuildAcl},
        showDetail: {icon: 'expand', callback: this.toggleChimeVis, tooltip: 'Show Detail', show: showShowDetailIcon}
      }
      const icons = thisPageConfig.controls || []
      const controlIcons = icons.map(ctrl => allControlIcons[ctrl])
      // All potential columns in activation pages
      const allColumns = {
        acl: {label: 'ACL (Exp/Act)', id: 'acl', formatter: chimeAcl},
        actIcons: {label: '', id: 'activation', formatter: actvIcon},
        actStatus: {label: 'Status', id: 'activation', formatter: actvMsg},
        chimes: {label: 'iChimes', id: 'chimeDisplay', dblclick: this.toggleChimeVis, formatter: chimeList},
        chimeList: {label: 'Chimes', id: 'chimeDisplay', formatter: chimeList},
        crateNum: {label: 'Crate #', id: 'customInt'},
        createDate: {label: `Create (${timezone})`, id: 'createDate', formatter: shortDayTime},
        createDateRelative: {label: `Create (${timezone})`, id: 'createDate', formatter: relativeDate},
        commDate: {label: `Commission (${timezone})`, id: 'commDate', formatter: shortDayTime},
        ctrIcons: {label: '', id: 'icons', controls: controlIcons},
        customer: {label: 'Customer', id: 'customer'},
        destination: {label: 'Destination', id: 'destination'},
        device: {label: 'Device ID', id: 'macId', formatter: getDeviceLink},
        notes: {label: 'Notes', id: 'notes'},
        origin: {label: 'Origin', id: 'origin'},
        provisioner: {label: 'Provisioner', id: 'pickItemNotes', formatter: getProvisionerName},
        shipmentId: {label: 'Shipment ID', id: 'clientShipmentId', link: shipIdLink},
        shipIdNoLink: {label: 'Shipment ID', id: 'clientShipmentId'},
        status: {label: 'Status', id: 'shippingState', colId: 'shippingState', formatter: shpStatusStr},
        tagList: {label: 'Tags', id: 'tagRepoDisplay', formatter: chimeList}
      }
      /* Create mapping function to map colum list to use all columns, including label replacement
       * If item is an object, use name to lookup field label for the label
       * Otherwise, use string as lookup and get label from standard all columns object.
       */
      const mapFn = function (col) {
        if (typeof col === 'object') {
          const column = allColumns[col.name]
          column.label = col.label
          return column
        } else {
          return allColumns[col]
        }
      }
      const columns = thisPageConfig.tableColumns || []
      return columns.map(mapFn)
    },
    tableData () {
      /* Table data use shipment list filtered by date range and config type */
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const lookback = this.pageConfig.lookbackDays || 30
      const configType = this.pageConfig.configType ? parseInt(this.pageConfig.configType) : 0
      // skipcq: JS-D1001.  Allow documentation comment
      const filterFn = shpmnt => {
        const dateDiff = moment().diff(moment(shpmnt.createDate), 'days')
        return parseInt(shpmnt.configType) === configType && dateDiff < lookback
      }
      const actShp = Object.values(this.$store.state.shipments.all).filter(filterFn)
      return actShp
    },
    /* Table options use one variable from configuraiton - sortIndex */
    tableOptions () {
      const ops = {
        sortable: true,
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        sortIndex: this.pageConfig.sortIndex || 2,
        sortType: 'desc'
      }
      return ops
    }
  },
  created () {
    /* Initilize gateways to show filtered view of shipments
     * if the user has filterShipments permission.
     */
    if (this.$store.state.user.permissions.includes('filterShipments')) {
      this.$store.dispatch('devices/initialize', {deviceType: 'gateways'})
    }
    /* Load scanners if they have not been previously loaded and configuration
     * has permission set for ACL editing.
     * Used for the list in the editAclModal to select scanner to use to edit ACL
     */
    if (this.$store.state.configuration.siteOptions.editShipmentACL) {
      this.$store.dispatch('devices/initialize', {deviceType: 'scanners'})
    }
    /* Load Locations if they are not itialized */
    if (Object.values(this.$store.state.locations.all).length === 0) {
      this.$store.dispatch('locations/initializeAll')
    }
    // get the messages for the activation page
    this.$store.dispatch('getPageMessage', 'activation')
  },
  data () {
    const mode = this.$route.query.mode
    const pageConfig = this.$store.state.configuration.pageOptions[`${mode}Activation`]
    // Get the required filed list for the add edit form
    // If found, use it. Else set default static list fields
    // skipcq: JS-W1044.  Allow Logical operator can be refactored to optional chain
    const requiredFields = pageConfig.addShipmentForm && pageConfig.addShipmentForm.requiredFields ? pageConfig.addShipmentForm.requiredFields : ['script', 'device', 'shipmentId', 'origin', 'destination']
    return {
      requiredFields,
      counter: 0,
      cmdModal: {},
      loadMoreOpts: {
        active: true,
        loading: false,
        icon: {
          name: 'down',
          tooltip: 'Load More',
          class: 'icon-padding'
        },
        callback: this.loadMore
      },
      modalIsActive: {
        addEdit: false,
        barcode: false,
        deleteShipment: false,
        sendCommand: false,
        showMessage: false
      },
      mode,
      msgBody: '',
      msgTitle: '',
      pageConfig,
      selectedGuid: ''
    }
  },
  methods: {
    deleteShipment (row) {
      this.selectedGuid = row.guid
      this.openModal('deleteShipment')
    },
    editShipmentAcl (row) {
      // Set selectedGuid to guid of shipment to be edited and open the edit ACL modal.
      this.selectedGuid = row.guid
      this.openModal('editShipmentAcl')
    },
    fetchShipmentUpdates () {
      // Wakes periodically to update page data
      this.counter += 1
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const cfgType = this.pageConfig.configType || 0
      const args = {cfgType}
      // If max rebuild time value found in the configuration, then add 2 minutes to it
      // and pass it as bias value to updateShipmentList
      const maxRebuildTime = this.$store.state.configuration.siteOptions.maxRebuildTime
      if (maxRebuildTime) args.bias = maxRebuildTime + 2
      // this.$store.dispatch('shipments/updateShipmentList')
      this.$store.dispatch('shipments/updateShipmentList', args)
    },
    loadMore () {
      /* Load another batch of shipment records based on createDate.  To get list,
       * call updatelist with useCreateDate set to true. */
      this.loadMoreOpts.loading = true
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const cfgType = this.pageConfig.configType || 0
      const args = {cfgType, useCreateDate: true}
      this.$store.dispatch('shipments/updateShipmentList', args).then(response => {
        this.loadMoreOpts.active = Object.values(response).length > 0
        this.loadMoreOpts.loading = false
      })
    },
    lookupProjectId (row) {
      const mode = this.$route.query.mode
      // Capitalize the first letter
      const shipmentType = mode.charAt(0).toUpperCase() + mode.slice(1)
      const msgObj = lookupProjectMsgHelper(row)
      // getMessageObject will return object/Boolean(false)
      const getMessageObj = getMessageObject(this.$store.state, 'activation', 'lookupProjectId', {...msgObj, shipmentType})
      this.cmdModal.messageObj = getMessageObj
      this.cmdModal.callback = lookupProjectIdHelper
      this.cmdModal.params = {row}
      this.modalIsActive.sendCommand = true
    },
    /**
     * Get itag and ichimes from device response
     * @param {Object} msg - device response
     */
    parseMessage (msg) {
      // const inventory = msg.substring(1).slice(0, -1)
      // const chimeRegex = /(C1_B1_[2-7][A-F0-9_]{4})/g
      // const tagRegex = /(C1_B1_1[A-F0-9_]{4})/g
      const chimeRegex = new RegExp(this.$store.state.configuration.deviceConfig.iChimeRegex, 'g')
      const tagRegex = new RegExp(this.$store.state.configuration.deviceConfig.iTagRegex, 'g')
      const chimes = msg.match(chimeRegex)
      const tags = msg.match(tagRegex)
      let out = '<div style="font-weight: bold;">iTags: </div><div>'
      out += (tags && tags.length > 0) ? tags.join(' - ') : '-'
      out += '</div>'
      out += '<div style="font-weight: bold;">iChimes: </div><div>'
      out += (chimes && chimes.length > 0) ? chimes.join(' - ') : '-'
      out += '</div>'
      return out
    },
    createShipmentCallback (formValues) {
      const vm = this
      return new Promise((resolve, reject) => {
        vm.$store.dispatch('shipments/createShipment', formValues).then((response) => {
          resolve(response)
        }).catch(e => {
          // skipcq: JS-0002.  Allow console.error
          console.error('ERROR: createShipmentCallback error ', e)
          // const retVal = {'status': 417, 'message': `${args.error}:<br> ${e}`}
          reject(e)
        })
      })
    },
    buildAclCallback (store, args) {
      const promise = new Promise((resolve, reject) => {
        store.dispatch('sendCommands', args).then(() => {
          // const retVal = {'status': 200, 'message': args.success}
          resolve({})
        }).catch(e => {
          // skipcq: JS-0002.  Allow console.error
          console.error('ERROR: buildAclCallback error ', e)
          // const retVal = {'status': 417, 'message': `${args.error}:<br> ${e}`}
          reject(e)
        })
      })
      return promise
    },
    buildAcl (row) {
      const mode = this.mode
      // Shipment object may have had updates since last page update.  Call updateSingleShipiment
      // to get the latest details before sending commands.
      this.$store.dispatch('shipments/updateSingleShipment', row.guid).then((response) => {
        // Get chime list from shipment
        const chimes = Object.keys(response.chimes)
        const commands = [...this.$store.state.configuration.commands.buildACL]
        // Replacement Object preparation
        const replacerObj = {
          count: chimes.length,
          broadcast: response.broadcast,
        }
        // deviceIdList for the command API
        const deviceIdList = [response.macId]
        if (commands.includes('{{chimeList}}')) {
          const chimeCmdList = getACLCommands(chimes)
          replacerObj.chimeList = chimeCmdList
        }
        // Get the Command list for the API for the chimes
        const commandList = getCommandList(commands, replacerObj)
        const payload = {
          deviceIdList,
          commandList
        }
        // Capitalize the first letter
        const shipmentType = mode.charAt(0).toUpperCase() + mode.slice(1)
        // getMessageObject will return object/Boolean(false)
        const getMessageObj = getMessageObject(this.$store.state, 'activation', 'buildAcl', {shipmentType})
        this.cmdModal.event = {event: 'updateLastStageCmdTime', arg: response.guid}
        this.cmdModal.messageObj = getMessageObj
        this.cmdModal.callback = this.buildAclCallback
        this.cmdModal.params = payload
        this.modalIsActive.sendCommand = true
      }).catch(e => {
        // skipcq: JS-0002.  Allow console.error
        console.error('Error', e)
      })
    },
    /**
     * This is callback method to add the message details to be displayed on message modal.
     * Origin, Destination, Create Date, iTags and iChimes
     */
    showShpDetails (shp) {
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const fullMacId = shp.macId || '-'
      // Populate temp messages & open modal
      this.msgTitle = `${this.itemName} - ${shp.clientShipmentId}`
      this.msgBody = `Fetching inventory for: ${fullMacId}`
      this.closeModal('shipmentFind')
      this.modalIsActive.showMessage = true
      let message = `<div style="font-weight: bold;">Origin: </div>${shp.origin}
      <div style="font-weight: bold;">Destination: </div>${shp.dest}
      <div style="font-weight: bold;">Create Date: </div>${moment(shp.createDate, 'ddd MMM D HH:mm:ss YYYY').format(`${getDateFormat()} HH:mm z`)}`
      this['shipments/fetchDevicesByGuid'](shp.guid).then(response => {
        message += this.parseMessage(response.data[0].devices)
        this.msgBody = message
      }).catch(e => {
        // skipcq: JS-0002.  Allow console.error
        console.error('ERROR in fetchDevicesByGuid', e)
        message += this.parseMessage('')
        this.msgBody = message
      })
    },
    toggleChimeVis (shipment) {
      this['shipments/toggleChimeVisibility'](shipment)
    },
    rebuildAclCallback (store, args) {
      const payload = {
        template: this.$store.state.configuration.templateFiles.rebuildACL,
        mac: args.row.macId,
        guid: args.row.guid,
        runByGUID: true
      }
      const promise = new Promise((resolve, reject) => {
        store.dispatch('runActivity', payload).then(() => {
          // const retVal = {'status': 200, 'message': args.success}
          resolve({})
        }).catch(e => {
          // skipcq: JS-0002.  Allow console.error
          console.error('ERROR: rebuildAclCallback error ', e)
          // const retVal = {'status': 417, 'message': `${args.error}:<br> ${e}`}
          reject(e)
        })
      })
      return promise
    },
    rebuildAcl (row) {
      const mode = this.mode
      // Capitalize the first letter
      const shipmentType = mode.charAt(0).toUpperCase() + mode.slice(1)
      // getMessageObject will return object/Boolean(false)
      const getMessageObj = getMessageObject(this.$store.state, 'activation', 'rebuildAcl', {shipmentType})
      this.cmdModal.messageObj = getMessageObj
      this.cmdModal.event = {event: 'updateLastStageCmdTime', arg: row.guid}
      this.cmdModal.callback = this.rebuildAclCallback
      this.cmdModal.params = {row}
      this.modalIsActive.sendCommand = true
    },
    // emitFunctionCallback - Generic function called after command executed from send command modal
    // The received event will be executed from the generic event function
    emitFunctionCallback (eventObj) {
      // eventObj.arg - The input required for the receiver function
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const eventArg = eventObj.arg || ''
      if (this[eventObj.event]) this[eventObj.event](eventArg)
    },
    updateLastStageCmdTime (guid) {
      const shipment = {...this.$store.state.shipments.all[guid]}
      const entryId = shipment.entryIdSPL
      const vm = this
      vm.$store.dispatch('shipments/updateLastStageCmdTime', entryId).then((response) => {
        const data = {
          lastStageCmdTime: response.updateTime
        }
        vm.$store.commit('shipments/UPDATE_SHIPMENT', {id: guid, data})
      })
    },
    ...mapActions([
      'loadCommandFile',
      'runActivity',
      'shipments/toggleChimeVisibility',
      'shipments/fetchDevicesByGuid'
    ])
  },
  mixins: [modalWindowManager],
  mounted () {
    /* Load shipment records if no records of the appropriate type have been previously loaded. */
    // skipcq:JS-W1043 - Skip redundant literal in a logical expression
    const cfgType = this.pageConfig.configType || 0
    const assets = Object.values(this.$store.state.shipments.all).filter(shp => shp.cfgType === cfgType)
    if (assets.length === 0) {
      this.loadMore()
    }
    // Set refresh timer based on configuration setting
    // skipcq:JS-W1043 - Skip redundant literal in a logical expression
    const refreshTimer = this.pageConfig.refreshTimer || 20
    this.timer = setInterval(this.fetchShipmentUpdates, refreshTimer * 1000)
  },
  watch: {
    $route (to) {
      // Every time query parameters change, load additional items (if required) and then update
      // the mode and page configuration
      this.mode = to.query.mode
      this.pageConfig = this.$store.state.configuration.pageOptions[`${this.mode}Activation`]
      this.loadMore()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.is-title {
    text-transform: capitalize;
}
</style>
