<template>
  <i v-html="svgContent" @click="handleClick"></i>
</template>

<script>
import {
  generateIconSVG
} from '@/store/helpers'

export default {
  computed: {
    svgContent: function () {
      let icon = {
        name: this.name,
        color: this.color,
        size: this.size
      }
      return generateIconSVG(this.$store.state, icon)
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  },
  props: {
    color: {
      type: String,
      default: 'black'
    },
    name: {
      type: String,
      default: 'search'
    },
    size: {
      type: [String, Number],
      default: '24'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
