<template>
    <div class="message is-danger" :class="messageClass" v-show="isVisible">
      <span v-if="this.icon !== ''" v-html="icon"></span>
      <span style="font-weight: bold;">{{title}}</span>
      <p>{{body}}</p>
    </div>
</template>

<script>

export default {
  computed: {
    messageClass: function () {
      if (this.level === 'warning') {
        return 'icon-red'
      } else if (this.level === 'success') {
        return 'icon-green'
      } else {
        return 'icon-red'
      }
    }
  },
  props: {
    title: String,
    body: String,
    icon: {
      type: String,
      default: ''
    },
    level: {
      type: String,
      default: 'normal',
      validator: function (value) {
        // The value must match one of these strings
        return ['success', 'warning', 'normal'].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      isVisible: true,
      isExpanded: true
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
