<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      v-on:close="close()"
    >
      <form id="manageUser">
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">User Name</label>
          </div>
          <div class="control">
            <ValidationProvider name="User Name" :rules="{regex: /^(\w|-|)+$/ }"  v-slot="{ errors }">
              <input class="input" disabled type="text" v-model="userData.loginName"/>
              <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Name</label>
          </div>
          <div class="control">
            <ValidationProvider name="Name" :rules="{regex: /^(\w|\s|-|\,)+$/ }"  v-slot="{ errors }">
              <input class="input" type="text" v-model="userData.fullName"/>
              <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Email</label>
          </div>
          <div class="control">
            <ValidationProvider name="Email" :rules="{email: true}"  v-slot="{ errors }">
              <input class="input" type="text" v-model="userData.emailAddress"/>
              <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Enabled</label>
          </div>
          <div class="control">
            <input type="checkbox" v-model="userData.enabled"/>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Temperature</label>
          </div>
          <div class="control">
            <label class="radio">
              <input type="radio" id="Celsius" value="Celsius" v-model="temperatureUnit">
              <label for="Celsius">Celsius &nbsp;</label>
              <input type="radio" id="Fahrenheit" value="Fahrenheit" v-model="temperatureUnit">
              <label for="Fahrenheit">Fahrenheit</label>
            </label>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Time Zone</label>
          </div>
          <div class="select">
            <select v-model="userTimezone">
              <option v-for="timezone in configuration.timezones" :value="timezone.name" :key="timezone.name">{{timezone.display}}</option>
            </select>
          </div>
        </div>
        <!-- <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Roles</label>
          </div>
          <div class="control">
            <template v-for="roles in getGroupList('core')">
              <input type="checkbox" :id="roles.value" :value="roles.value" :key="roles.value" v-model="groupData.core">
              <label :key="roles.value+'for'" :for="roles.value">{{roles.key}} &nbsp;<br /></label>
            </template>
          </div>
        </div> -->
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Profile</label>
          </div>
          <div class="control select">
            <select v-model="groupData.login">
              <option value="">Select Profile</option>
              <option v-for="roles in getGroupList('login')" :key="roles.value" :value="roles.value">{{roles.key}}</option>
            </select>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Reports</label>
          </div>
          <div class="control select">
            <select v-model="groupData.report">
              <option value="">Select Report Set</option>
              <option v-for="roles in getGroupList('report')" :key="roles.value" :value="roles.value">{{roles.key}}</option>
            </select>
          </div>
        </div>
      </form>
      <template slot="footer">
        <a class="button is-primary" @click="saveUser()" :disabled="invalid">Update</a>
        <a class="button" @click="close()">Cancel</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex'
import IntelytModal from '@/components/IntelytModal'
import {getUsersProvider, updateUserProvider} from '@/store/providers'

export default {
  components: {
    IntelytModal
  },
  computed: {
    ...mapState({
      configuration: 'configuration',
      user: 'user'
    })
  },
  created: function () {
    this.$store.dispatch('createConfigObj', 'timezones')
  },
  data () {
    return {
      groupData: {
        core: [],
        login: '',
        report: ''
      },
      temperatureUnit: 'Fahrenheit',
      title: this.isNew ? 'Create User' : 'Manage User',
      userTimezone: 'Etc/GMT',
      userData: {}
    }
  },
  methods: {
    close () {
      this.$refs.observer.reset()
      this.$emit('close')
    },
    getGroupList (type) {
      // Categorize the groups based on type
      // Returns a list groups (from all of those defined for the company) which match the type input.
      // Ex: if input is 'core' output is ["Company Administrator", "Provisioner", "Designer"]
      const groupData = this.$store.state.company.groups
      const groups = []
      for (const [key, val] of Object.entries(groupData)) {
        if (val.type === type) {
          const value = (val.customPrefs && val.customPrefs.class) ? val.customPrefs.class : key
          groups.push({key, value})
        }
      }
      return groups
    },
    saveUser () {
      const vm = this
      this.$refs.observer.validate().then(result => {
        if (result) {
          const newGroupData = [...vm.groupData.core, ...vm.groupData.other]
          // If the core group is selected, then add it to the group list
          if (vm.groupData.login && vm.groupData.login.length > 1) {
            newGroupData.push(vm.groupData.login)
          }
          // If the report group is selected, then add it to the group list
          if (vm.groupData.report && vm.groupData.report.length > 1) {
            newGroupData.push(vm.groupData.report)
          }
          // Update the temperatureUnit value in customPrefs
          const customPrefs = vm.userData.propertyMap.customPrefs ? JSON.parse(vm.userData.propertyMap.customPrefs) : {}
          customPrefs.temperatureUnit = vm.temperatureUnit
          if (vm.userTimezone !== '') {
            customPrefs.timezone = vm.userTimezone
          }
          const userData = {
            username: vm.userData.loginName,
            emailAddress: vm.userData.emailAddress,
            enabled: vm.userData.enabled,
            groupList: newGroupData,
            fullName: vm.userData.fullName,
            propertyMap: {
              customPrefs: JSON.stringify(customPrefs)
            }
          }
          updateUserProvider(vm.$store.state, userData).then(() => {
            // Retrieve and update the latest users value in the state
            getUsersProvider(vm.$store.state).then(resp => {
              vm.$store.commit('setUsers', resp.data.userInfo)
              vm.$emit('close')
            })
          }).catch(e => {
            console.error('ERROR: ', e)
          })
        }
      })
    }
  },
  // props: ['isActive', 'isNew', 'userObj'],
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    userObj: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    isActive: function (newInput) {
      if (newInput && !this.isNew) {
        this.userData = this.userObj
        // Categorize the core, login and report groups
        const coreGp = this.getGroupList('report')
        const coreGroups = this.getGroupList('core').map(role => role.value)
        const loginGroups = this.getGroupList('login').map(role => role.value)
        const reportGroups = this.getGroupList('report').map(role => role.value)
        console.log('groups: ', coreGp, loginGroups, reportGroups)
        // Assign the default values
        const userLoginGroups = loginGroups.length > 0 ? this.userData.groupList.filter(grp => loginGroups.indexOf(grp) > -1) : []
        const userReportGroups = reportGroups.length > 0 ? this.userData.groupList.filter(grp => reportGroups.indexOf(grp) > -1) : []
        const coreList = coreGroups.length > 0 ? this.userData.groupList.filter(grp => coreGroups.indexOf(grp) > -1) : []
        const loginGroup = userLoginGroups.length > 0 ? userLoginGroups[0] : ''
        const reportGroup = userReportGroups.length > 0 ? userReportGroups[0] : ''
        const otherGroups = this.userData.groupList.filter(grp => ![...coreGroups, ...loginGroups, ...reportGroups].includes(grp))
        console.log('Other Groups: ', this.userData.groupList, otherGroups)
        const groupData = {
          core: coreList,
          login: loginGroup,
          report: reportGroup,
          other: otherGroups
        }
        this.groupData = Object.assign({}, this.groupData, groupData)
        const customPrefs = this.userData.propertyMap && this.userData.propertyMap && this.userData.propertyMap.customPrefs ? JSON.parse(this.userData.propertyMap.customPrefs) : {}
        // console.log('customPrefs', customPrefs)
        this.temperatureUnit = customPrefs.temperatureUnit || 'Fahrenheit'
        this.userTimezone = customPrefs.timezone || ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
