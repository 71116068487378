<template>
  <div style="width: 50%;">
    <div class="field">
      <label class="label">{{label}}  <base-icon name="information" size="15" v-if="tooltip && tooltip.length > 0" v-tooltip="tooltip" /></label>
      <input class="input" :disabled="disabled" type="text" v-model="searchVal" name="searchVal" autocomplete="off">
      <span class="help">Enter last 2 digits of the Device <span v-if="macID.length > 0" class="device-record" @click="resetField()"><a>Reset</a></span></span>
    </div>
    <div class="content">
      <div class="filterlsit">
        <table border="0" width="100%" v-if="queryResults.length > 0 && showQueryResults">
          <tr v-for="result in queryResults" :key="result.macId" :data-id="result.macId" @click="selectDevice(result)" class="device-record">
            <td>{{result.macId}} ({{result.percent}})</td>
          </tr>
        </table>
      </div>
      <div v-show="searchStatus.length > 0">
        {{searchStatus}}
      </div>
    </div>
  </div>
</template>

<script>
import { getQueryProvider } from '../../store/providers'
export default {
  name: 'DeviceSearch',
  data () {
    return {
      macID: '',
      searchStatus: '',
      searchVal: '',
      disabled: false,
      showQueryResults: false,
      queryResults: []
    }
  },
  methods: {
    resetField () {
      // When the user clicks reset, Empty the fields
      this.macID = ''
      this.searchVal = ''
      this.disabled = false
    },
    selectDevice (deviceData) {
      // When the user selects a records from result list, The set the macId from the record
      this.macID = deviceData.macId
      this.searchVal = deviceData.macId
      this.disabled = true
      this.showQueryResults = false
    }
  },
  props: {
    default: {
      type: [Number, String],
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    devType: {
      type: String,
      default: '2'
    },
    tooltip: {
      type: String,
      default: ''
    },
    reset: Boolean
  },
  watch: {
    reset (val) {
      if (val) {
        // If reset is true, then set the value as blank
        // If the default value is passed, then set the value
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        this.macID = this.default || ''
        this.searchVal = ''
        this.disabled = false
      }
    },
    macID (val) {
      const mac = val.length > 16 ? val : `00_1B_AD_00_${val}`
      this.$store.commit('SET_FORM_FIELD', {key: 'device', value: mac, mode: this.mode})
    },
    searchVal (val) {
      const vm = this
      vm.queryResults = []
      // If the input have 2 charaters, the search the devices
      if (val.length === 2) {
        vm.searchStatus = 'Searching...'
        vm.disabled = true
        // For now static values. Later will be pulled from config
        const fullV = '4.1'
        const emptyV = '3.6'
        // Query payload construction
        const companyId = String(vm.$store.state.company.id)
        const params = [emptyV, fullV, emptyV, companyId, vm.devType, val]
        const payload = {
          query: 'searchAvailableDevices',
          params,
        }
        // Get the query results and return the option list
        getQueryProvider(vm.$store.state, payload).then((res) => {
          vm.disabled = false
          vm.searchStatus = ''
          vm.showQueryResults = true
          vm.queryResults = Object.values(res.data)
          // If no records found, the display empty records string
          if (vm.queryResults.length === 0) vm.searchStatus = 'No device found for the given input...'
        }).catch(e => {
          // skipcq: JS-0002.  Allow console.error
          console.error('Search Available devices ERROR: ', e)
          vm.searchStatus = e
          // Reset the fields
          vm.disabled = false
          vm.showQueryResults = false
          vm.queryResults = []
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.device-record {
  cursor: pointer;
}
</style>
