<template>
    <div id="surround">
      <div id="spacer"></div>
      <vue-slider
        id="slider"
        v-model="value"
        :data="data"
        :disabled="disabled"
        :marks="false"
        :lazy="true"
        :tooltip-formatter="tooltipFormatter"
        :tooltipPlacement="['left', 'right']"
        tooltip="always"
      >
      </vue-slider>
    </div>
</template>

<script>
import vueSlider from 'vue-slider-component'
import moment from 'moment-timezone'
import { getDateFormat, stringTemplateParser } from '@/store/helpers.js'

export default {
  components: {
    vueSlider
  },
  computed: {
    // tbd: function () {
    //   return'Null'
    // }
    getDataValue () {
      // This function returns both the data values and the value (selected range)
      // for the slider - it is run when the object is created.
      //
      // Steps and selectec come from intervals - if number they are the same, if array they
      // use the apporpriate array values
      const steps = Array.isArray(this.intervals) ? this.intervals[0] : this.intervals
      const selected = Array.isArray(this.intervals) ? this.intervals[1] : this.intervals
      // Start and End are based on end values, number of steps and type of steps
      const end = this.end || moment.utc().valueOf()
      const start = moment.utc(end).subtract(steps, this.units).valueOf()
      const intervalDuration = parseInt((end - start) / steps)
      // Build total interval [data] and selected interval [value] and return as array
      let data = [start]
      for (let i = 1; i <= steps; i++) {
        data.push(start + (i * intervalDuration))
      }
      // calc the initial range (val) using the item from data that is the appropriate number (i.e. selected)
      // of intervals back (star) and the last value in data (end)
      const val = [data[data.length - (selected + 1)], data[data.length - 1]]
      return [data, val]
    }
  },
  created () {
    const dataValue = this.getDataValue
    this.data = dataValue[0]
    this.value = dataValue[1]
    // this.$emit('sliderChange', this.value)
  },
  data: function () {
    return {
      value: [],
      data: []
    }
  },
  methods: {
    tooltipFormatter (val) {
      // Get default values from state
      const defaultFormats = this.$store.state.configuration.sliderFormats
      const long = getDateFormat()
      const short = getDateFormat('short')
      // set default value of custom format or simple default
      let frmtStr = this.customFormat || long
      // If there is no custom format and the value is defined in state, override the default
      if (!this.customFormat && defaultFormats[this.units]) {
        frmtStr = stringTemplateParser(defaultFormats[this.units], {long, short})
      }
      const tz = this.$store.state.user.timezone || moment.tz.guess()
      return moment.utc(val).tz(tz).format(frmtStr)
    }
  },
  props: {
    // Optional prop to use externally defined format value, otherwise formatter is based on
    // default values in this component
    customFormat: {
      type: [String, Boolean],
      default: false
    },
    // Disbale the slider based on this property
    disabled: {
      type: Boolean,
      default: false
    },
    // End timestamp of the slider - if false use now()
    end: {
      type: [Number, Boolean],
      default: false
    },
    // number of intervals to calculate, if a number, the initial value range will be all intervals
    // if an array, the values with be [all intervals, selected intervals]
    intervals: {
      type: [Number, Array],
      default: 7
    },
    // units to use for interval calculations etc. Allowed values are any
    // acceptable momentjs duraiton, ex: 'hours', 'days'
    units: {
      type: String,
      default: 'days'
    }
  },
  watch: {
    // whenever modal opens, look for value of entryIdSPL - this is NOT ready when modal first loads
    value: {
      handler: function (newInput) {
        let range = [...newInput]
        // When unit is set as days, then use the start date time is start of the day
        // end date value is end time for that like
        // start date -> {{startDate 00:00:00}}
        // end date -> {{endDate 11:59:59}}
        if (this.units === 'days') {
          const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
          const start = Number(moment(range[0]).tz(tmz).startOf('date').format('x'))
          const end = Number(moment(range[1]).tz(tmz).endOf('date').format('x'))
          range = [start, end]
        }
        this.$emit('sliderChange', range)
      },
      deep: true
    },
    getDataValue: {
      handler: function (dataValue) {
        this.data = dataValue[0]
        this.value = dataValue[1]
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  #surround {
    height: 55px;
    width: 90%;
    margin: auto;
  }
  #spacer {
    height: 30px;
  }
</style>
