import Vue from 'vue'
import Router from 'vue-router'
import ActivationPage from '@/views/ActivationPage'
import AdministrationPage from '@/views/AdministrationPage'
import AssetDashboard from '@/views/AssetDashboard'
// import Dashboard from '@/views/Dashboard'
import DetailPage from '@/views/DetailPage'
import DeviceDetail from '@/views/DeviceDetail'
import DeviceDashboard from '@/views/DeviceDashboard'
import DHUnitDetail from '@/views/DHUnitDetail'
import GeofenceList from '@/views/GeofenceList'
import LocationList from '@/views/LocationList'
import LocationDetail from '@/views/LocationDetail'
import ReportList from '@/views/ReportList'
import ShipmentDashboard from '@/views/ShipmentDashboard'
import ShipmentList from '@/views/ShipmentList'
import ShipmentPublic from '@/views/ShipmentPublic'
import UserList from '@/views/UserList'
import WarehouseDashboard from '@/views/WarehouseDashboard'
import WarehouseDetail from '@/views/WarehouseDetail'
import LoginPage from '@/views/LoginPage'
import ErrorPage from '@/views/ErrorPage'
import store from '@/store'
import {hasRole} from '@/store/helpers.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      meta: { Auth: true, pageName: 'shipmentDashboard', hasMode: true },
      name: 'DefaultDashboard',
      component: ShipmentDashboard
    },
    {
      path: '/activation',
      meta: { Auth: true, pageName: 'Activation', hasMode: true },
      name: 'ActivationPage',
      component: ActivationPage
    },
    {
      path: '/asset-dashboard',
      meta: { Auth: true, pageName: 'Dashboard', hasMode: true },
      name: 'AssetDashboard',
      component: AssetDashboard
    },
    {
      path: '/detail',
      meta: { Auth: true, pageName: 'Detail', hasMode: true },
      name: 'DetailPage',
      component: DetailPage
    },
    // {
    //   path: '/dashboard',
    //   meta: { Auth: true },
    //   name: 'Dashboard',
    //   component: Dashboard
    // },
    {
      path: '/device-detail',
      meta: { Auth: true, pageName: 'deviceDetail' },
      name: 'DeviceDetail',
      component: DeviceDetail
    },
    {
      path: '/device-dashboard',
      meta: { Auth: true, pageName: 'deviceDashboard' },
      name: 'DeviceDashboard',
      component: DeviceDashboard
    },
    {
      path: '/dh-detail',
      meta: { Auth: true, pageName: 'DHUnitDetail' },
      name: 'DHUnitDetail',
      component: DHUnitDetail
    },
    {
      path: '/error',
      meta: { Auth: false },
      name: 'ErrorPage',
      component: ErrorPage
    },
    {
      path: '/geofences',
      meta: { Auth: true, roles: ['Company Administrator', 'User'], pageName: 'routeDetail' },
      name: 'GeofenceList',
      component: GeofenceList
    },
    {
      path: '/location-detail',
      meta: { Auth: true, roles: ['Company Administrator', 'User'], pageName: 'locationDetail' },
      name: 'LocationDetail',
      component: LocationDetail
    },
    {
      path: '/location-list',
      meta: { Auth: true, roles: ['Company Administrator', 'User'], pageName: 'locationList' },
      name: 'LocationList',
      component: LocationList
    },
    {
      path: '/login',
      meta: { Auth: false },
      name: 'LoginPage',
      component: LoginPage
    },
    {
      path: '/public',
      meta: { Auth: false, pageName: 'shipmentPublic' },
      name: 'ShipmentPublic',
      component: ShipmentPublic
    },
    {
      path: '/redirect',
      meta: { Auth: true },
      redirect: '/'
    },
    {
      path: '/reports',
      meta: { Auth: true },
      name: 'ReportList',
      component: ReportList
    },
    {
      path: '/shipment-dashboard',
      meta: { Auth: true, pageName: 'Dashboard', hasMode: true },
      name: 'ShipmentDashboard',
      component: ShipmentDashboard
    },
    {
      path: '/shipment-list',
      meta: { Auth: true, pageName: 'List', hasMode: true },
      name: 'ShipmentList',
      component: ShipmentList
    },
    {
      path: '/users',
      meta: { Auth: true, roles: ['Company Administrator'] },
      name: 'UserList',
      component: UserList
    },
    {
      path: '/administration',
      meta: { Auth: true, pageName: 'administration', roles: ['Company Administrator'] },
      name: 'AdministrationPage',
      component: AdministrationPage
    },
    {
      path: '/warehouse-dashboard',
      meta: { Auth: true, pageName: 'warehouseDashboard' },
      name: 'WarehouseDashboard',
      component: WarehouseDashboard
    },
    {
      path: '/warehouse-detail',
      meta: { Auth: true, pageName: 'warehouseDetail' },
      name: 'WarehouseDetail',
      component: WarehouseDetail
    }
  ]
})

/**  Route check logic:
Does Page Exist?
|- No --> link to 'Not Found'
|- Yes -> Auth Required?
   |- Yes -> User Authorized?
   |  |- No -> Login
   |  |- Yes -> Roles OK?
   |     |- No -> 403
   |     |- Yes -> next()
   |- No -> Is page 'Login'?
      |- Yes -> Auth = Null & Login
      |- No -> next()
*/
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next({path: '/error', query: { code: '404', fullPath: from.fullPath }})
  } else {
    if (to.meta.Auth) {
      // Page requires authorization and valid session time
      // If the time since the last session time is greater than the allowed duration
      // the session is considered as expired
      const sessionTime = parseInt(localStorage.getItem('session time')) || 0
      const sessionDuration = parseInt(store.state.configuration.timers.sessionDuration) || 0
      const expiredSession = (Math.round(Date.now() / 1000) - sessionTime) > sessionDuration
      if (!store.state.auth.status || expiredSession) {
        // User not authorized or the session is expired
        store.commit('setLastRequestedPage', to.fullPath)
        next({path: '/login'})
      } else {
        const loadPageDetails = function () {
          // If the query param have mode, use it. Else set the mode default to shipment. 
          const mode = to.query.mode ? to.query.mode : 'shipment'
          // If query has mode parameter or the page uses mode, prefix the mode to the page name. Else use the page name as it is
          const pageName = to.query.mode || to.meta.hasMode ? `${mode}${to.meta.pageName}` : to.meta.pageName
          // If the page name is already added in the state then proceed to the page
          if (!pageName || store.state.configuration.pageOptions[pageName]) {
            next()
          } else {
            // Fetch the page configurations and it's overrides
            store.dispatch('loadPageDetails', pageName).then(() => {
              // Get the page configuration from the state
              const pageOptions = store.state.configuration.pageOptions[pageName]
              // If the page configurations have metrics, the dispatch loadMetrics request
              if (pageOptions && pageOptions.metrics) {
                store.dispatch('metrics/loadMetrics', pageOptions.metrics)
              }
              next()
            }).catch(e => {
              // skipcq: JS-0002.  Allow console.error
              console.error('ERROR: Router page config load error', e)
              next({path: '/error', query: { code: '403', fullPath: from.fullPath }})
            })
          }
        }
        localStorage.setItem('session time', Math.round(Date.now() / 1000)) // Session time update
        // check for roles... & available pages
        if (hasRole(store.state.user.roles, to.meta.roles)) {
          if (to.meta.pageName === 'deviceDetail') {
            const type = to.query.type || 'tags'
            // If the device or device properties not available in the state, then get the device details and redirect to the page
            if (!store.state.devices[type].all[to.query.id] || !store.state.devices[type].all[to.query.id].properties) {
              store.dispatch('devices/getSingleDeviceDetails', {deviceType: type, deviceId: to.query.id}).then(() => {
                loadPageDetails()
              }).catch(e => {
                // skipcq: JS-0002.  Allow console.error
                console.error('ERROR: Router page config load error', e)
                next({path: '/error', query: { code: '403', fullPath: from.fullPath }})
              })
            } else {
              loadPageDetails()
            }
          } else {
            loadPageDetails()
          }
        } else {
          next({path: '/error', query: { code: '403', fullPath: from.fullPath }})
        }
      }
    } else {
      if (!from.name && to.name && to.name !== 'LoginPage' && to.name !== 'ShipmentPublic' && to.name !== 'ErrorPage') {
        store.commit('setLastRequestedPage', to.fullPath)
        next({path: '/login'})
      }
      if (to.path === '/login') {
        store.commit('setAuth', false)
        next()
      } else {
        next()
      }
    }
  }
})

export default router
