<template>
  <div>
    <level-bar>{{pagename}}</level-bar>
    <!-- METRICS -->
    <div class="box">
        <nav class="level">
          <metric-item :metricData="metric" v-for="(metric,idx) in metrics" :key="idx">
          </metric-item>
        </nav>
    </div>
    <div class="columns">
      <div class="column">
        <intelyt-card>
          <template slot="header">Warehouse Current Status</template>
          <base-table
             :data="activeWarehouses"
             :columns="warehouseColumns"
             :options="options">
          </base-table>
        </intelyt-card>
      </div>
      <div class="column is-narrow">
        <intelyt-card>
          <template slot="header">Warehouse Locations</template>
            <intelyt-map
             :clusterZoomCutoff="zoomCutoff"
             :markers="mapMarkers"
             :mapWidth="mapWidthCalc"
             @markerDblClick="handleMapDblClick"
             :zoom="zoom">
          </intelyt-map>
          <intelyt-map-legend
            v-if="markers.length > 0"
            :markers="markers"
            :activeMarkers="activeMarkers"
          ></intelyt-map-legend>
        </intelyt-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { getMapWidth } from '@/store/helpers'
import {localStorageMixin, sessionManager} from '@/store/mixins'
import IntelytMapLegend from '@/components/IntelytMapLegend'
import MetricItem from '@/components/MetricItem'
import router from '@/router'

export default {
  components: {
    IntelytMapLegend,
    MetricItem
  },
  computed: {
    activeWarehouses (state) {
      return Object.values(state.warehouses.all)
    },
    warehouseListLen () {
      return Object.keys(this.$store.state.warehouses.all).length
    },
    // Get the markers list for the map
    mapMarkers () {
      const activeMarkers = {...this.activeMarkers}
      if (!activeMarkers.warehouse || !activeMarkers.warehouse.active) return []
      const markerList = this.getObjectMarkers(this.warehouses.all)
      // If atleast one warehouse found, then show the warehouse legend in list
      if (markerList.length > 0) activeMarkers.warehouse.hide = false
      return markerList
    },
    mapWidthCalc: function () {
      return getMapWidth(this.$parent.windowWidth, 0.4)
    },
    ...mapState({
      warehouses: 'warehouses'
    }),
    // mix the getters into computed with object spread operator
    ...mapGetters({
      getObjectMarkers: 'getObjectMarkers'
    })
  },
  created () {
    this.$store.dispatch('warehouses/initialize')
  },
  methods: {
    handleMapDblClick (point) {
      console.log('dbl clk', point)
      router.push({path: 'warehouse-detail', query: { id: point.queryId }})
    },
    ...mapActions([
    ])
  },
  data () {
    const nameLink = function (row) {
      // return path and query string for router link
      return {path: 'warehouse-detail', query: { id: row.macId }}
    }
    const gtIndicator = (count) => function (row) {
      if (row === undefined) {
        return '<td></td>'
      } else if (row < count) {
        return '<td><span class="tag is-success">' + row + '</span></td>'
      } else {
        return '<td><span class="tag is-danger">' + row + '</span></td>'
      }
    }
    const gt8 = gtIndicator(8)
    const gt1 = gtIndicator(1)
    // Populate columns array for Warehouse Current Status
    // Fixed + Dynamic (for past 7 days)
    let warehouseColumns = [
      {label: 'Building', id: 'name', link: nameLink},
      {label: 'Active Alerts', id: 'activeAlerts', formatter: gt1},
      {label: 'Temperature', id: 'temperature'},
      {label: 'Humidity', id: 'humidity'}
    ]
    for (let i = -6; i <= 0; i += 1) {
      let day = new Date(Date.now() + (864e5 * i))
      let formattedDay = (day.getMonth() + 1) + '/' + day.getDate()
      let idVal = 'highHumidHours' + (i * -1)
      warehouseColumns.push({label: formattedDay, id: idVal, formatter: gt8})
    }
    const pageConfig = this.$store.state.configuration.pageOptions.warehouseDashboard
    const activeMarkers = {}
    const pageMarkers = pageConfig.map && pageConfig.map.markers ? pageConfig.map.markers : []
    pageMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    return {
      activeMarkers,
      pagename: 'Warehouse Dashboard',
      markers: pageConfig.map.markers,
      metrics: pageConfig.metrics,
      options: {
        paginate: false,
        perPage: 10,
        searchable: false,
        sortable: true,
        sortIndex: 0,
        sortType: 'asc'
      },
      warehouseColumns: warehouseColumns,
      zoom: 6,
      zoomCutoff: 8
    }
  },
  mixins: [localStorageMixin, sessionManager],
  mounted () {
  },
  watch: {
    // whenever selected asset changes, this function will run
    warehouseListLen: {
      handler: function () {
        console.log('Update based on change to number of wh object')
        this.$store.dispatch('warehouses/updateAll')
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
