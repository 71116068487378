<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      :width="width"
      v-on:close="close()"
    >
      <form id="manageBeacon">
        <div class="columns">
          <div class="column">
            <div class="block">
              <div class="control-label">
                <label class="label">Name</label>
              </div>
              <div class="control">
                <ValidationProvider name="Name" :rules="{required: true, regex: /^([A-Z]|[a-z]|[0-9]|\s)+$/}" v-slot="{ errors }">
                  <input style="width:50%" :disabled="!isAdmin" class="input" type="text" v-model="beaconData.name"/>
                  <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="block">
              <div class="control-label">
                <label class="label">Location</label>
              </div>
              <div class="control select">
                <select :disabled="!isAdmin" v-model="beaconData.location">
                  <option :key="locId" :value="locId" v-for="(location, locId) in locations">{{location.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="block">
              <div class="control-label">
                <label class="label">Radius</label>
              </div>
              <div class="control">
                <ValidationProvider name="Radius" :rules="{required: true, numeric: true}" v-slot="{ errors }">
                  <input style="width:50%" :disabled="!isAdmin" class="input" type="number" v-model="beaconData.radius"/>
                  <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="block">
              <div class="control-label">
                <label class="label">Zoom</label>
              </div>
              <div class="control">
                <ValidationProvider name="Zoom" :rules="{required: true, numeric: true, max_value: 22}" v-slot="{ errors }">
                  <input style="width:50%" :disabled="!isAdmin" class="input" type="number"  min="10" max="22" v-model="beaconData.zoom"/>
                  <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <intelyt-map
            :markers="markers"
            :polygons="polygons"
            :waypoints="[beaconData]"
            :mapWidth="850"
            :zoom="beaconData.zoom"
            :zoomFixed="true"
            :options="{zoomControl: true, scrollwheel: true}"
            @marker_moved="updateMarkerPosition($event)"
          >
          </intelyt-map>
        </div>
      </form>
      <template slot="footer">
        <a class="button is-primary" v-if="isAdmin" v-show="buttonEnabled" @click="updateBeacon()" :disabled="invalid">Update</a>
        <a class="button" @click="close()">Cancel</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'
import {round, isJSON} from '@/store/helpers.js'

export default {
  components: {
    IntelytModal
  },
  computed: {
    title: function () {
      return this.beaconData.beacon ? `Manage Beacon - ${this.beaconData.beacon.slice(-11)}` : 'Manage Beacon'
    },
    markers: function () {
      if (!this.beaconData.center) {
        return []
      }
      let marker = {
        id: 0,
        icon: 'geofence',
        name: this.beaconData.name,
        draggable: this.isAdmin,
        position: {
          lat: Number.parseFloat(this.beaconData.center.lat),
          lng: Number.parseFloat(this.beaconData.center.lng)
        },
        infoText: `Name: ${this.beaconData.name}`,
        timestamp: 0
      }
      return [marker]
    },
    polygons: function () {
      // Get the location value from the beacon object
      const locationId = this.beaconData && this.beaconData.location ? this.beaconData.location : false
      const location = this.$store.state.locations.all[locationId]
      // If location is not found, return empty array
      if (!location) {
        return {editable: false, paths: []}
      }
      // Get the fence detail from the location object
      const notes = isJSON(location.notes) ? JSON.parse(location.notes) : {}
      const fenceId = notes && notes.locId ? notes.locId : ''
      const fence = fenceId && this.$store.state.geofences.all[fenceId] ? this.$store.state.geofences.all[fenceId] : {}
      const points = fence && fence.coordinates ? fence.coordinates : []
      const options = {
        geodesic: true,
        strokeColor: '#3399ff',
        fillColor: '#3399ff',
        strokeOpacity: 1,
        strokeWeight: 2,
        icons: []
      }
      return {editable: false, paths: [points], options}
    }
  },
  data () {
    const locations = this.$store.state.locations.all
    const isAdmin = this.$store.state.user.permissions.includes('editBeacons')
    return {
      beaconData: {},
      buttonEnabled: true,
      isAdmin,
      width: 900,
      locations
    }
  },
  methods: {
    close () {
      this.$refs.observer.reset()
      this.$emit('close')
    },
    updateBeacon () {
      const vm = this
      this.$refs.observer.validate().then(result => {
        if (result) {
          this.buttonEnabled = false
          const payload = {
            name: this.beaconData.name,
            shippingLocId: this.beaconData.location,
            entryId: parseInt(this.beaconData.id),
            innerRadius: this.beaconData.zoom,
            outerRadius: this.beaconData.radius,
            latitude: round(this.beaconData.center.lat, 7),
            longitude: round(this.beaconData.center.lng, 7)
          }
          this.$store.dispatch('waypoints/updateWaypoint', payload).then(() => {
            vm.buttonEnabled = true
            vm.close()
          })
        }
      })
    },
    updateMarkerPosition (position) {
      this.beaconData.center = position
    }
  },
  // props: ['isActive', 'selectedBeacon'],
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    selectedBeacon: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    isActive: function (newInput) {
      if (newInput) {
        this.beaconData = {...this.selectedBeacon}
      }
    },
    'beaconData.radius': {
      handler: function () {
        this.beaconData.radius = parseInt(this.beaconData.radius)
      },
      deep: true
    },
    'beaconData.zoom': {
      handler: function () {
        this.beaconData.zoom = parseInt(this.beaconData.zoom)
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
