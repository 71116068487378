import Vue from 'vue'
import baseMetrics from '../../../static/metrics.json'
import { getDynamicQueryParams } from '../helpers'
import {
  getQueryProvider
} from '../providers'
const BASE_METRICS = JSON.stringify(baseMetrics)

/**
 * @returns default metric state object
 */
const getDefaultState = () => {
  return {
    all: {}
  }
}

// initial state
const metricState = getDefaultState()

// getters
const getters = {}

// actions
const actions = {
  /**
   * Runs query for each metric from the provided list of metrics.
   * metric data for users dashboard and other pages and send the values to
   * to {@link setMetrics} for processing and addition to the state <br>
   * Notes:
   *  - query is always metric name + 'Metric'
   *  - companyId is alwaysthe last parameter for the query
   * @param {object} store
   * @param {List} metrics  - List of metrics to be processed
   * @param {String} companyId  - String value of companyId
   * @param {Object} args -
   */
  initialize ({ commit, rootState }, {metrics, force = true} = {}) {
    /**
     * Object that contains all of the queries and required parameters for the
     * set of defined metrics.
     */
    const assetCfgType = '3'
    const vehicleCfgType = '4'
    const queries = {
      activeCrates: {q: 'activeCratesMetric', p: [assetCfgType, String(rootState.company.id)]},
      activeTotes: {q: 'activeTotesMetric', p: [assetCfgType, String(rootState.company.id)]},
      activeVehicles: {q: 'monitoredAssetsMetric', p: [vehicleCfgType, String(rootState.company.id)]},
      assetDuration: {q: 'assetDurationMetric', p: [assetCfgType, String(rootState.company.id)]},
      intactShipments: {q: 'intactShipmentsMetric', p: [String(rootState.company.id)]},
      monitoredAssets: {q: 'monitoredAssetsMetric', p: [assetCfgType, String(rootState.company.id)]},
      onTimeDepartures: {q: 'onTimeDeparturesMetric', p: [String(rootState.company.id)]},
      totalShipments: {q: 'totalShipmentsMetric', p: [String(rootState.company.id)]},
      unmonitoredAssets: {q: 'unmonitoredAssetsMetric', p: [assetCfgType, String(rootState.company.id)]}
    }
    // Initilize should run by default.  If force is false, the method won't execute
    if (force) {
      /**
       * Build promise list from all metrics that have a query defined in the queries object.
       * These promises will collect metric data from databse and this is applied usin SET_METRIC
       */
      const promiseList = metrics.filter(m => Object.keys(queries).includes(m)).map(m => getQueryProvider(rootState, {query: queries[m].q, params: queries[m].p}))
      Promise.all(promiseList).then(responses => {
        responses.forEach(met => commit('SET_METRIC', met.data[0]))
      }).catch(e => {
        // skipcq: JS-0002 Allow console error
        console.error(e)
      })
    }
  },
  // Load the metrics in state using company configuration
  loadMetrics ({ commit, rootState }, metrics = []) {
    // If no metric item found, then return early
    if (metrics.length === 0) return

    // add the module in the initialized modules array in state to reset on logging out
    if (!rootState.initialized.includes('metrics')) {
      commit('setInitializedModule', 'metrics', { root: true })
    }

    // Get the default metric configuration
    const defaultMetricsObj = JSON.parse(BASE_METRICS)

    // Get the default metric configuration
    const sourceConfig = defaultMetricsObj.sources

    // Default metric and source values
    const defaultMetrics = defaultMetricsObj.items

    // Metric configured for the company
    const companyMetrics = rootState.company.metrics.items || {}

    let sources = []
    const metricConfig = {}

    // Filter the metric items that are not available or not configured for the company
    // metrics = metrics.filter(metricItm => companyMetrics.items[metricItm])

    // Loop the each metric item and merge with the default and company metric configurations
    metrics.forEach((itm) => {
      // If metric item not found in the default config, then skip the record
      if (!Object.prototype.hasOwnProperty.call(defaultMetrics, itm)) return
      let metricItm = defaultMetrics[itm]
      if (companyMetrics[itm]) metricItm = Object.assign(defaultMetrics[itm], companyMetrics[itm])
      // If metric have source item, then compare with company source configuration
      // if (metricItm.hasOwnProperty('source')) {
      if (Object.prototype.hasOwnProperty.call(metricItm, 'source')) {
        // if the source item is not configured for the company, then skip the record
        if (!sourceConfig[metricItm.source]) return
        metricConfig[itm] = metricItm
        sources.push(metricItm.source)
      } else { // If source is not configured, assue it's static metric item and store in state
        commit('SET_METRIC', {key: itm, data: metricItm})
      }
    })

    // Get the unique source list
    sources = new Set(sources)

    // The token replacement object which contains the token values
    const tokenValues = {
      companyId: String(rootState.company.id)
    }

    // Query list to store the query items
    const queryList = []

    // Loop the source item and prepare the query payload for the metrics
    sources.forEach(src => {
      const payload = sourceConfig[src]
      // const query = sourceConfig[src].query
      // payload.params = payload.params.map(prm => replaceToken(prm))
      payload.params = getDynamicQueryParams(payload.params, tokenValues)
      queryList.push(getQueryProvider(rootState, payload))
    })
    const srcArr = [...sources]

    // Execute the promise list and get the metrics response
    Promise.all(queryList).then(responses => {
      responses.forEach((rsp, idx) => {
        // mtrcValues - Returned response from query
        const mtrcValues = Object.values(rsp.data[0])
        // mtrcKeys - Key list from the query response
        const mtrcKeys = Object.keys(rsp.data[0])
        // mtrcNames - Get the metric names from the source
        const mtrcNames = sourceConfig[srcArr[idx]].names || []
        // Loop through the each values and get the metric item name
        // using it's index value and store the metric name and value in the state
        for (const mtrcIdx in mtrcValues) {
          if (!Object.prototype.hasOwnProperty.call(mtrcValues, mtrcIdx)) continue
          // Get the metric value using index
          const mtrcVal = mtrcValues[mtrcIdx]
          // Get the metric name using the index value
          const mtrcKey = mtrcNames[mtrcIdx] || mtrcKeys[mtrcIdx]
          // Get the metric configuration using the metric name/key
          if (metricConfig[mtrcKey]) {
            const mtrcItm = metricConfig[mtrcKey]
            // If the query returns blank value,
            // then sets the default value from configuration
            if (mtrcVal !== '') mtrcItm.value = mtrcVal
            // If the value is a number, and it's in float format, then convert it into integer value
            if (Number(mtrcVal) !== 'NaN' && Number.isSafeInteger(Number(mtrcVal))) mtrcItm.value = parseInt(mtrcItm.value, 10)
            commit('SET_METRIC', {key: mtrcKey, data: mtrcItm})
          }
        }
      })
    }).catch(e => {
      console.error(e)
    })
  }
}

// mutations
const mutations = {
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  /**
   * Sets metric information for in state using response passed by
   * to {@link intializeMetrics}<br>
   * object key is field name
   * object value is field value
   * @param {Object} state  - Vuex store state object
   * @param {Object} metric  - Response payload
   */
  /* SET_METRIC (state, metric) {
    // console.log('METRIC: ', metric)
    const entries = Object.entries(metric)
    Vue.set(state.all, entries[0][0], entries[0][1])
  }, */
  SET_METRIC (state, metric) {
    // console.log('METRIC: ', metric)
    // const entries = Object.entries(metric)
    Vue.set(state.all, metric.key, metric.data)
  },
  SET_SOURCE (state, source) {
    Vue.set(state.sources, source.key, source.data)
  }
}

export default {
  namespaced: true,
  state: metricState,
  getters,
  actions,
  mutations
}
