import {
// getQueryProvider
} from '../../providers'
import Vue from 'vue'

// initial state
const getDefaultState = () => {
  return {
    all: {}
  }
}

const state = getDefaultState()

// getters
const getters = {
  getCountByLocation: (state) => (locId) => {
    const fltr = (x) => { return x.location === locId }
    return Object.values(state.all).filter(fltr).length
  },
  getIdsByLocation: (state) => (locId) => {
    const fltr = (x) => { return x.location === locId }
    return Object.values(state.all).filter(fltr).map(x => x.macId)
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  SET_SCANNERS (state, scanners) {
    for (let scan of scanners) {
      Vue.set(state.all, scan.macId, scan)
    }
  },
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
