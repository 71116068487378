<template>
  <Component
    :is="needFormat(type) ? 'ComplexTree' : 'NormalTree'"
    :name="name"
    :value="value"
    :type="type"
    :line="line"
    :showIndex="showIndex"
    :needComma="needComma"
    :level="level"
    :lineType="lineType"
    :lastLineType="lastLineType"
    :lastLine="lastLine"
  />
</template>

<script>
import { needFormat } from './utils.js';
import ComplexTree from './complexTree.vue';
import NormalTree from './normalTree.vue';
export default {
  props: {
    name: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [Array, String],
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    line: {
      type: Number,
      default: 1
    },
    showIndex: {
      type: Boolean,
      default: false
    },
    needComma: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 1
    },
    lineType: {
      type: String,
      default: 'none'
    },
    lastLineType: {
      type: String,
      default: 'none'
    },
    lastLine: {
      type: Number,
      default: 1
    }
  },
  components: {
    ComplexTree,
    NormalTree,
  },
  methods: {
    needFormat: needFormat,
  },
};
</script>
