import {
  getTableEntriesProvider,
  updateTableEntryProvider
} from '../providers'
import moment from 'moment-timezone'
// import {round} from '@/store/helpers.js'

import Vue from 'vue'

const getDefaultState = () => {
  return {
    all: {}
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {}

// actions
const actions = {
  /**
   * Gathers all of the waypoints adds objects to the Vuex store.<br>
   * Retrives information using {@link getTableEntriesProvider}.<br>
   * @returns {Promise} Promise object
   */
  initialize ({commit, state, rootState}, {force = false} = {}) {
    // Execute the function, when the state have no value or forced to initialize again
    if (!force && Object.values(state.all).length !== 0) {
      return
    }
    // add the module in the initialized modules array in state to reset on logging out
    if (!rootState.initialized.includes('waypoints')) {
      commit('setInitializedModule', 'waypoints', { root: true })
    }
    console.log('--- initialize Waypoints ---')
    // const whereClause = ` companyId = ${rootState.company.id}`
    const payload = {
      tableName: 'shippingWaypoints',
      where: 'waypointType = 1',
      fields: [],
      sort: 'entryId DESC'
    }
    console.log('Waypoints Paylaod: ', payload)
    getTableEntriesProvider(rootState, payload)
      .then(response => {
        console.log('Waypoints: ', response)
        for (let key in Object.values(response.data)) {
          commit('SET_WAYPOINT', response.data[key])
          // compareDate = moment.max(compareDate, moment.utc(response.data[key].createDate, 'ddd MMM D HH:mm:ss YYYY'))
        }
        // Reset value for lastRecords alert date & time based off max value found
        // commit('SET_LAST_UPDATE', compareDate)
      })
      .catch(e => {
        console.log(e)
      })
  },
  /**
   * Update the given waypoint details. After updating the waypoint
   * retrieve the detail and update the state.
   * @param {object} state - Vuex state
   * @param {object} payload - Input payload for the waypoint
   * @returns {Promise} Promise object
   *
  */
  updateWaypoint ({commit, rootState}, payload) {
    const tableName = 'shippingWaypoints'
    let updateObj = {
      entryId: {
        type: 'integer',
        value: parseInt(payload.entryId)
      },
      lastUpdate: {
        type: 'date_time',
        value: moment.utc().format('YYYY-MM-DD HH:mm:ss')
      },
      name: {
        type: 'string',
        value: String(payload.name)
      },
      latitude: {
        type: 'string',
        value: String(payload.latitude)
      },
      longitude: {
        type: 'string',
        value: String(payload.longitude)
      },
      outerRadius: {
        type: 'string',
        value: String(payload.outerRadius)
      },
      innerRadius: {
        type: 'string',
        value: String(payload.innerRadius)
      },
      shippingLocId: {
        type: 'string',
        value: String(payload.shippingLocId)
      }
    }
    const updateData = {
      id: parseInt(payload.entryId),
      data: updateObj
    }
    // send update for shippingWaypoints
    return new Promise((resolve, reject) => {
      updateTableEntryProvider(rootState, tableName, updateData)
        .then(response => {
          const reqPayload = {
            tableName: 'shippingWaypoints',
            where: `entryId = ${parseInt(payload.entryId)}`,
            fields: [],
            sort: 'entryId DESC'
          }
          getTableEntriesProvider(rootState, reqPayload)
            .then(res => {
              commit('SET_WAYPOINT', res.data[0])
              resolve(response)
            })
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }
}

// mutations
const mutations = {
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  /**
   * Adds a new alert object to state
   * @param {object} state - Vuex state
   * @param {object} alert - Alert data payload
   */
  SET_WAYPOINT (state, wp) {
    const locId = parseInt(wp.shippingLocId) > 0 ? wp.shippingLocId : wp.motionTimer
    const waypoint = {
      beacon: wp.beaconId,
      id: wp.entryId,
      center: {
        lat: parseFloat(wp.latitude),
        lng: parseFloat(wp.longitude)
      },
      code: wp.displayName,
      location: parseInt(locId),
      name: wp.name,
      radius: parseInt(wp.outerRadius),
      type: parseInt(wp.waypointType) === 1 ? 'Beacon' : 'Geo',
      visible: true,
      options: {strokeColor: '#FF0000', fillColor: '#FF0000', strokeWeight: 0.5, fillOpacity: 0.4},
      zoom: parseInt(wp.innerRadius)
    }
    Vue.set(state.all, wp.displayName, waypoint)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
