<template>
  <div class="c-json-line">
    <p
      :class="`c-json-p c-line-${lineType}`"
      @click="setVisiable"
      :style="getIndent(level)"
    >
      <span class="c-json-mark">{{ line }}</span>
      <span :class="`c-of-${lineType}`"></span>
      <span class="c-json-content">
        <span v-if="showIndex" class="c-json-key">{{ name }}: </span>
        <span class="c-json-pt">{{ isArray(type) ? '[' : '{' }}</span>
      </span>
      <span v-show="!visiable" class="c-json-pt"
        >{{ isArray(type) ? '...]' : '...}' }}{{ needComma ? ',' : '' }}</span
      >
    </p>
    <div :style="{ display: visiable ? 'block' : 'none' }">
      <slot v-for="(item, index) in value">
        <Component
          :is="needFormat(item.type) ? 'ComplexTree' : 'NormalTree'"
          :key="index"
          :level="level + 1"
          :name="item.name"
          :value="item.value"
          :type="item.type"
          :line="item.line"
          :showIndex="item.showIndex"
          :needComma="item.needComma"
          :lineType="item.lineType"
          :lastLineType="item.lastLineType"
          :lastLine="item.lastLine"
        />
      </slot>
      <p
        class="c-json-feet"
        :class="`c-json-p c-line-${lineType}`"
        :style="getIndent(level)"
      >
        <span v-if="lastLine" class="c-json-mark">{{ lastLine }}</span>
        <span v-if="lastLineType" :class="`c-of-${lastLineType}`"></span>
        <span class="c-json-pt"
          >{{ isArray(type) ? ']' : '}' }}{{ needComma ? ',' : '' }}</span
        >
      </p>
    </div>
  </div>
</template>
<script>
import { isArray, getIndent, getType, needFormat } from './utils.js';
import NormalTree from './normalTree.vue';
export default {
  name: 'ComplexTree',
  props: {
    name: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [Array, String],
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    line: {
      type: Number,
      default: 1
    },
    showIndex: {
      type: Boolean,
      default: false
    },
    needComma: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 1
    },
    lineType: {
      type: String,
      default: 'none'
    },
    lastLineType: {
      type: String,
      default: 'none'
    },
    lastLine: {
      type: Number,
      default: 1
    }
  },
  components: {
    NormalTree,
  },
  data() {
    return {
      visiable: false,
    };
  },
  methods: {
    setVisiable() {
      this.visiable = !this.visiable;
    },
    isArray: isArray,
    getIndent: getIndent,
    getType: getType,
    needFormat: needFormat,
  },
};
</script>
