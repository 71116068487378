<template>
  <div>
    <!-- METRICS -->
    <div class="box">
        <nav class="level" v-if="shipment.class === 'Asset'">
         <div class="level-item has-text-centered">
            <div>
              <p class="heading">Asset ID </p>
              <p class="title">{{shipment.id}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Asset Type</p>
              <p class="title">{{shipment.customer}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Device</p>
              <p class="title">{{shipment.macId.substring(12)}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Origin</p>
              <p class="title">{{shipment.origin}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Create Date</p>
              <p class="title">{{shipment.createDate}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Last Comms</p>
              <p class="title">{{shipment.lastMessage}}</p>
            </div>
          </div>
        </nav>
        <nav class="level" v-else>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Shipment ID </p>
              <p class="title">{{shipment.id}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Origin </p>
              <p class="title">{{shipment.origin}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Destination</p>
              <p class="title">{{shipment.destination}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Status</p>
              <p class="title">{{shipment.shippingStateStr}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Depart Date</p>
              <p class="title">{{shipment.departDate}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Delivery Date</p>
              <p class="title">{{shipment.decommDate}}</p>
            </div>
          </div>
        </nav>
    </div>
    <div class="columns">
      <div class="column is-6">
        <intelyt-card>
          <template slot="header">{{shipment.class}} Locations</template>
          <template slot="controls">
            <span>
              <div class="control">
                <span class="field" style="font-weight:bold;">
                  <span  v-tooltip="'Zoom To Current Location'">
                    <base-icon name="location" @click="changeCurrentLocation()" />
                  </span>
                </span>
              </div>
            </span>
          </template>
          <div v-if="dataloaded">
            <intelyt-map
              :markers="getMapMarkers"
              :pathList="getPaths(guid, sliderValue, activeMarkers, 0)"
              :mapWidth="mapWidthCalc"
              :currentZoom="currentZoom"
              :customCenter="customCenter"
              :zoomFixed="zoomFixed"
              :zoom="zoomValue"
            ></intelyt-map>
            <intelyt-map-legend
              v-if="dataloaded && markers.length > 0"
              :markers="markers"
              :activeMarkers="activeMarkers"
            ></intelyt-map-legend>
          </div>
        </intelyt-card>
      </div>
      <div class="column is-4">
        <base-slider v-on:sliderChange="updateRange" :disabled="isDisabled" :intervals="sliderInterval" :end="sliderEnd" :units="sliderUnit"></base-slider>
        <intelyt-card>
          <template slot="header">Location History</template>
          <div v-if="dataloaded">
            <base-table
              :data="getLocationData()"
              :columns="locationColumns"
              :options="options"
              :isNarrow="true">
            </base-table>
          </div>
        </intelyt-card>
        <intelyt-card :scrolling="true" maxHeight="300px">
          <template slot="header">Notifications</template>
          <template v-for="alert in alerts(guid)">
            <intelyt-alert :alert=alert :key="alert.entryId"></intelyt-alert>
          </template>
        </intelyt-card>
      </div>
      <div class="column is-2">
        <intelyt-card style="chartWidthCalc">
          <template slot="header">Historic Asset Conditions</template>
          <template v-if="dataloaded">
            <div v-for="(echart, idx) in assetCondCharts" :key="idx">
              <base-chart
                :classname="echart.classname"
                :options='getChartData(`${echart.key}ChartOptions`)'
                :dblClickHandler="echart.ClickHandler"
                :parentWidth='parentWidth'>
              </base-chart>
            </div>
          </template>
        </intelyt-card>
      </div>
    </div>
  </div>
</template>

<script>
import {shortDate, cityState} from '@/store/formatters.js'
import { buildTempAndHumidChartOptions, getMapWidth, isBetween, getDateFormat } from '@/store/helpers'
import {chartOptionFactory} from '@/store/factories'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment-timezone'
import IntelytMapLegend from '@/components/IntelytMapLegend'
import BaseSlider from '@/components/BaseSlider'

export default {
  components: {
    BaseSlider,
    IntelytMapLegend
  },
  computed: {
    batteryChartOptions: function () {
      // Set variable items for chart
      const title = 'Battery Level'
      const subtitle = '' // this.slider.value[0] + ' to ' + this.slider.value[1]
      const yAxis = [
        {name: 'Voltage', formatter: 'none', ymax: 4.5, ymin: 3, splitLine: {show: false}}
      ]
      let vMapPieces = ['battery']
      let series = [
        {
          name: 'Voltage',
          data: this.getShipmentTimeSeries(this.shipment.guid, this.sliderValue, 'battery')
        }
      ]
      // Get chart object
      // legend bottom 5
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {subtitle, yAxis, vMapPieces, tooltip: 'datetime', colors: chartConfig.colors.lineChart, legend: {bottom: 5}}
      return chartOptionFactory(chartConfig, title, series, options, {}, tmz)
    },
    chartWidthCalc: function () {
      // return round(document.documentElement.clientWidth * 0.7, 0)
      let width = this.$parent.windowWidth * 0.95
      if (this.$parent.windowWidth > 981) {
        width = this.$parent.windowWidth * 0.3
      }
      return 'width: ' + width + 'px;'
    },
    // Get the markers list for the map
    getMapMarkers () {
      const markers = this.getMarkers(this.shipment.guid, this.sliderValue, this.activeMarkers)
      this.setActiveMarkers(markers.activeMarkers)
      return markers.timeSeriesData
    },
    parentWidth: function () {
      return this.$parent.windowWidth
    },
    shipment: function () {
      let guid = this.$route.query.id.substring(0, 36)
      const thisShipment = this.$store.state.shipments.all[guid]
      let shipmentData = {}
      if (thisShipment) {
        this.setSliderValues(thisShipment)
        let lastEvent = thisShipment.lastEventUpdate
        lastEvent = (lastEvent) ? moment(lastEvent).fromNow() : lastEvent
        shipmentData = {
          guid: guid,
          id: thisShipment.clientShipmentId,
          macId: thisShipment.macId,
          origin: thisShipment.origin,
          destination: thisShipment.destination,
          shippingStateStr: this.$store.state.stateDefs.shipping[thisShipment.shippingState] || '-',
          createDate: shortDate(thisShipment.createDate),
          decommDate: shortDate(thisShipment.decommDate),
          departDate: shortDate(thisShipment.departDate),
          lastMessage: lastEvent,
          customer: thisShipment.customer,
          class: thisShipment.class,
          mode: thisShipment.mode
        }
      }
      return shipmentData
    },
    mapWidthCalc: function () {
      return getMapWidth(this.$parent.windowWidth, 0.48)
    },
    tempHumidChartOptions: function () {
      // Set variable items for chart
      this.setChartData('temperatureChartData', this.getShipmentTimeSeries(this.shipment.guid, this.sliderValue, 'temperature'))
      // const humidityExists = this.$store.state.shipments.all[this.shipment.guid].tsData[0] && this.$store.state.shipments.all[this.shipment.guid].tsData[0].hasOwnProperty('humidity')
      const humidityExists = this.$store.state.shipments.all[this.shipment.guid].tsData[0] && Object.prototype.hasOwnProperty.call(this.$store.state.shipments.all[this.shipment.guid].tsData[0], 'humidity')
      let humidtyData = []
      if (humidityExists) {
        this.setChartData('humidityChartData', this.getShipmentTimeSeries(this.shipment.guid, this.sliderValue, 'humidity'))
        humidtyData = this.humidityChartData
      }
      const isC = this.$store.state.user.temperatureUnit === 'Celsius'
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {
        temp: this.temperatureChartData,
        humid: humidtyData,
        isC,
        chartConfig,
        tmz
      }
      return buildTempAndHumidChartOptions(options)
    },
    ...mapGetters({
      alerts: 'alerts/getAlertsByGuid',
      getMarkers: 'shipments/getSingleShipmentMarkers',
      getPaths: 'shipments/getSingleShipmentPaths',
      getShipmentTimeSeries: 'shipments/getShipmentTimeSeries'
    }),
    ...mapState({
      shipments: 'shipments'
    })
  },
  created () {
    // this.loadConfig('test')
    this.fetchGenericShipmentDetails({query: this.$route.query.id, markers: this.markers}).then(() => {
      this.dataloaded = true
    })
  },
  data () {
    const assetCondCharts = [
      {key: 'tempHumid', classname: 'chart-style', ClickHandler: ''},
      {key: 'battery', classname: 'chart-style', ClickHandler: ''}
    ]
    const highlightLocationIcon = function (location) {
      return location.activeLocation
    }

    const showLocationIcon = function (location) {
      return !location.activeLocation
    }
    /* Formatters and tooltips  for Location History table */
    const lngDateFmt = getDateFormat()
    const shrtDateFmt = getDateFormat('short')
    const endDateTooltip = function (row) {
      if (row.count === 1) return '-'
      return shortDate(row.lastDatetime, `${lngDateFmt} HH:mm:ss`)
    }
    const fmtDate = function (dt) {
      return shortDate(dt, `${shrtDateFmt} HH:mm`)
    }
    const startDateTooltip = function (row) {
      return shortDate(row.timestamp, `${lngDateFmt} HH:mm:ss`)
    }
    const trimLocation = function (location) {
      const locationStr = cityState(location)
      return (locationStr === '') ? '-' : locationStr.substr(0, 10) + '...'
    }
    const locationControlIcons = [
      {icon: 'location_arrived', callback: this.zoomToLocation, tooltip: 'Zoom out', show: highlightLocationIcon},
      {icon: 'location_dot', callback: this.zoomToLocation, tooltip: 'Zoom to location', show: showLocationIcon}
    ]
    const displayLocation = function (location) {
      if (typeof location.locationString === 'undefined') return ''
      const locationStr = cityState(location.locationString)
      return (locationStr === '') ? '-' : locationStr
    }
    const locationColumns = [
      {label: 'First', id: 'datetime', formatter: fmtDate, tooltip: startDateTooltip},
      {label: 'Last', id: 'lastDatetime', formatter: fmtDate, tooltip: endDateTooltip},
      {label: '#', id: 'count'},
      {label: 'Location', id: 'locationString', formatter: trimLocation, tooltip: displayLocation},
      {label: 'Type', id: 'locationType'},
      {label: '', id: 'locationType', controls: locationControlIcons}
    ]
    // let pageOptions = this.$store.state.configuration.defaultCompany.pageOptions.shipmentDetail
    const markers = ['alerts', 'origin', 'destination', 'currentLocation', 'geofence', 'Cell', 'GPS', 'iGate']
    const activeMarkers = {}
    markers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    return {
      // msg: this.$parent.windowWidthCompute,
      activeMarkers,
      assetCondCharts: assetCondCharts,
      currentZoom: false,
      customCenter: false,
      guid: this.$route.query.id.substring(0, 36),
      markers: markers,
      status: 'status',
      locationData: {},
      options: {
        paginate: true,
        sortable: true,
        sortType: 'desc',
        sortIndex: 0,
        perPage: 5
      },
      // guid: this.$route.query.id.substring(0, 36),
      user: this.$route.query.id.substring(37),
      // sliderValue: [StartDate, endDate],
      isDisabled: false,
      sliderValue: [moment.utc().valueOf(), moment.utc().valueOf()],
      sliderEnd: moment.utc().valueOf(),
      sliderInterval: [30, 7],
      // sliderInterval: [30, 7],
      sliderUnit: 'days',
      zoomFixed: false,
      zoomValue: -1,
      locationColumns: locationColumns,
      dataloaded: false
    }
  },
  methods: {
    ...mapActions([
      'fetchGenericShipmentDetails',
      'loadConfig'
    ]),
    changeCurrentLocation () {
      // If this.customCenter value exists, set the zoomFixed value as false to avoid the zoom out issue
      if (this.customCenter) {
        this.zoomFixed = false
      }
      this.customCenter = false
      this.currentZoom = !this.currentZoom
      this.zoomFixed = !this.zoomFixed
      this.zoomValue = (this.currentZoom ? 10 : -1)
    },
    getChartData: function (key) {
      return this[key]
    },
    getLocationData: function () {
      if (!this.dataloaded) return []
      Object.values(this.$store.state.shipments.all[this.shipment.guid].locData).forEach((loc) => {
        if (typeof loc.activeLocation === 'undefined') {
          loc.activeLocation = false
        }
        this.locationData[loc.entryId] = loc
      })
      return Object.values(this.locationData).filter(isBetween(this.sliderValue))
      // return Object.values(this.locationData)
    },
    // Replace the activeMarkers data property with updated marker values
    setActiveMarkers: function (activeMarkers) {
      this.activeMarkers = activeMarkers
    },
    setSliderValues (shpmnt) {
      this.sliderEnd = shpmnt.decommDate && shpmnt.decommDate !== '-' ? moment.utc(shpmnt.decommDate).valueOf() : moment.utc().valueOf()
      let endDate = !this.dataloaded ? moment.utc().valueOf() : shpmnt.decommDate && shpmnt.decommDate !== '-' ? moment.utc(shpmnt.decommDate).valueOf() : moment.utc().valueOf()
      let StartDate = !this.dataloaded ? moment.utc().subtract(7, 'days').valueOf() : moment(shpmnt.createDate, 'MM-DD-YY').subtract(1, 'days').valueOf()
      const diff = moment(endDate).utc().diff(StartDate, 'days') + 1
      this.sliderInterval = [30, diff]
    },
    /** Zoom the clicked cell point in location history table
     * @param {location} Object - Clicked location row Object
     */
    zoomToLocation (location) {
      this.currentZoom = false
      if (this.customCenter && this.customCenter.entryId !== location.entryId) {
        this.locationData[this.customCenter.entryId].activeLocation = false
      }
      this.locationData[location.entryId].activeLocation = !location.activeLocation
      this.zoomFixed = location.activeLocation
      this.zoomValue = (location.activeLocation ? 14 : -1)
      const centerObject = {
        entryId: location.entryId,
        location: {lat: parseFloat(location.latitude), lng: parseFloat(location.longitude)}
      }
      this.customCenter = location.activeLocation ? centerObject : false
    },
    setChartData: function (key, data) {
      this[key] = data
    },
    updateRange (range) {
      this.sliderValue = range
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .chart-style {
    height: 300px;
    float: none;
    clear: both;
  }
</style>
