<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      v-on:close="close()"
    >
      <div class="content">
        <label class="label">Subject</label>
        <p :class="{ 'control': true }">
          <ValidationProvider name="Subject" :rules="{required: true, max: 75, regex: /^(\w|\s|-)+$/}"  v-slot="{ errors }">
            <input :class="{'input': true, 'is-danger': errors[0] }" name="subject" type="text" v-model="newAlert.subject">
            <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </p>
        <label class="label">Message</label>
        <p :class="{ 'control': true }">
          <ValidationProvider name="Message" :rules="{required: true, max: 75, regex: /^(\w|\s|-|\.|\?|\,)+$/}"  v-slot="{ errors }">
            <textarea class="textarea" :class="{'is-danger': errors[0] }" name="message" v-model="newAlert.message"></textarea>
            <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </p>
        <div class="label">
        <!-- <input type="checkbox" name="sendEmail" v-model="sendEmail">Generate Email -->
        </div>
      </div>
      <template slot="footer">
        <a class="button is-primary" @click="submit()" :disabled="!valid && invalid">Submit</a>
        <a class="button" @click="close()">Cancel</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import IntelytModal from '@/components/IntelytModal'

export default {
  components: {
    IntelytModal
  },
  computed: {
    alertEventList: function () {
      let events = []
      for (let key of Object.keys(this.alertEvent)) {
        let event = {
          id: key,
          icon: this.alertEvent[key].icon,
          name: this.alertEvent[key].name
        }
        if (key > 100 && key < 200) {
          events.push(event)
        }
      }
      return events
    },
    valid: function () {
      return this.newAlert.subject.length > 0 && this.newAlert.message.length > 0
    },
    ...mapState({
      alertEvent: state => state.configuration.alertEvent
    })
  },
  data () {
    const clientShipmentId = this.$store.state.shipments.all[this.$route.query.id] ? this.$store.state.shipments.all[this.$route.query.id].clientShipmentId : ''
    let subject = clientShipmentId.length > 0 ? `Message - ${clientShipmentId}` : ''
    return {
      title: 'Create Message',
      newAlert: {
        subject,
        message: '',
        type: 'Question'
      },
      sendEmail: false
    }
  },
  methods: {
    close () {
      const clientShipmentId = this.$store.state.shipments.all[this.$route.query.id] ? this.$store.state.shipments.all[this.$route.query.id].clientShipmentId : ''
      let subject = clientShipmentId.length > 0 ? `Message - ${clientShipmentId}` : ''
      this.newAlert.subject = subject
      this.newAlert.message = ''
      this.newAlert.type = '103'
      this.$refs.observer.reset()
      this.$emit('close')
    },
    submit () {
      let alertPayload = {
        ackBy: 'system',
        alertEvent: 103,
        alertLevel: 1,
        alertSource: 'Manual User Input',
        companyId: this.$store.state.company.id,
        createdBy: this.$store.state.user.fullName,
        data: '',
        enabled: 0,
        guid: this.$route.query.id,
        latitude: 0.0,
        longitude: 0.0,
        message: this.newAlert.message,
        subject: this.newAlert.subject,
        sendEmail: this.sendEmail
      }
      this.$refs.observer.validate().then(result => {
        if (result) {
          this.$store.dispatch('alerts/createAlert', alertPayload).then(() => {
            // console.log('CreateAlertCall Response', response)
            this.close()
          }).catch(e => {
            console.log(e)
          })
        }
      })
    },
    ...mapActions([
      'createAlert'
    ])
  },
  props: ['isActive']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
