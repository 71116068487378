<template>
  <div>
    <level-bar>{{name}}</level-bar>
    <!-- METRICS -->
    <div class="box">
        <nav class="level">
          <metric-item :metricData="metric" v-for="(metric,idx) in metrics" :key="idx">
          </metric-item>
        </nav>
    </div>
    <div class="columns">
      <div class="column">
        <intelyt-card>
          <template slot="header">Tags</template>
          <template slot="controls">
            <span v-for="filter in getFilters" :key="filter.col">
              <span class="tag is-primary boldtext" v-if="filter.active === true && filter.val.length !== 0">
                {{filter.name}}: {{getFilterValue(filter)}}
                <button class="delete is-small" @click="clearFilter(filter)"></button>
              </span>
              &nbsp;
            </span>
            <span style="display:flex; align-content:center;">
              <label style="min-width:125px;">
                <input style="width:100%;" type="search" id="search-input" class="form-control" placeholder="Search Data"
                  :value="searchInput"
                  @input="(e) => {this.searchInput = e.target.value}">
              </label>
              <span class="icon-search is-right" style="padding-top:5px;">
                <base-icon name="search" size="18" color="grey"/>
              </span>
            </span>
            <span class="icon icon-padding" style="padding-right:5px;">
              <base-icon name="download_csv"  @click="downloadCSV()" v-tooltip="'Download CSV'" class="icon-padding"/>
              <base-icon name="filter" @click="openModal('filterTable')" v-tooltip="'Filter Devices'" class="icon-padding"/>
            </span>
          </template>
          <base-table
                 :data="tagList"
                 ref="tagListTable"
                 :searchText="searchInput"
                 :columns="tagColumns"
                 :filter="filters.getActive()"
                 :options="options">
          </base-table>
        </intelyt-card>
      </div>
      <div class="column">
        <intelyt-card>
          <template slot="header">All Tags</template>
          <intelyt-map
            :markers="getMarkers"
            :mapWidth="mapWidthCalc"
            :currentZoom="false"
            :zoomFixed="false"
            :zoom="-1"
          >
          </intelyt-map>
          <intelyt-map-legend
            v-if="markers.length > 0"
            :markers="markers"
            :activeMarkers="activeMarkers"
          ></intelyt-map-legend>
        </intelyt-card>
      </div>
    </div>
    <filter-table-modal
      :filters="filters.getAll()"
      :isActive="modalIsActive.filterTable"
      :modalTitle="'Filter Devices'"
      v-on:close="closeModal('filterTable')"
      v-on:reset="filtersReset()"
    >
    </filter-table-modal>
  </div>
</template>

<script>
import IntelytMapLegend from '@/components/IntelytMapLegend'
import MetricItem from '@/components/MetricItem'
import FilterTableModal from '@/modals/FilterTableModal'
import { mapActions, mapGetters } from 'vuex'
import {getMapWidth} from '@/store/helpers.js'
import {tableFilterManager, modalWindowManager} from '@/store/mixins'
// import {chartOptionFactory} from '@/store/factories'
import {
  batteryIcon,
  formatHumidity,
  formatTemperature,
  formatStatus,
  lastAssetUpdateIcon,
  shortMac,
  shortDateTime
} from '@/store/formatters.js'

export default {
  components: {
    FilterTableModal,
    IntelytMapLegend,
    MetricItem
  },
  computed: {
    tagList: function () {
      // console.log('DeviceType:', deviceType)
      return Object.values(this.$store.state.devices[this.deviceType].all)
    },
    // Get the markers if position is not NAN and Undefined
    getMarkers: function () {
      if (!this.activeMarkers.currentLocation || !this.activeMarkers.currentLocation.active) {
        return []
      }
      const activeMarkers = {...this.activeMarkers}
      // console.log('Markers: ', this.getTagMarkers)
      const mapFn = function (dev) {
        // If no icon name is found, then assign currentLocationIcon
        if (!dev.icon) dev.icon = 'currentLocationIcon'
        return dev
      }
      const markers = this.getTagMarkers(this.deviceType).filter(icon => icon.position.lat && icon.position.lng).map(mapFn)
      // If atleast one currentLocation found, then show the currentLocation legend in list
      if (markers.length > 0 && activeMarkers.currentLocation.hide) {
        activeMarkers.currentLocation.hide = false
        this.setActiveMarkers(activeMarkers)
      }
      return markers
    },
    mapWidthCalc: function () {
      return getMapWidth(this.$parent.windowWidth, 0.6)
    },
    ...mapGetters({
      getTagMarkers: 'getTagMarkers'
    })
  },
  created () {
    this.$store.dispatch('shipments/updateShipmentList')
    this.$store.dispatch('devices/initializeChargerAndGatewayLocations')
    // this.updateGateways()
    // this.populateAlertsAndNotifications()
    this.updateTagz()
    this.$store.dispatch('updateFilterState', ['deviceDashboard', []])
    this.setFilterbyQuery()
  },
  data () {
    const nameLink = function (row) {
      // return path and query string for router link
      return {path: 'device-detail', query: { id: row.macId, type: row.deviceType }}
    }
    // Battery tooltip implementation
    const batteryToolTip = function (row) {
      return `Charge: ${row.charge * 100}%<br>Voltage: ${row.battery}V`
    }
    const pageConfig = this.$store.state.configuration.pageOptions.deviceDashboard
    // Battery Icon implementation
    const batteryIconFrmt = function (voltage) {
      return batteryIcon(voltage, pageConfig.deviceType)
    }
    const allTagColumns = {
      macId: {id: 'macId', link: nameLink, formatter: shortMac},
      temperature: {id: 'temperature', formatter: formatTemperature},
      humidity: {id: 'humidity', formatter: formatHumidity},
      status: {id: 'status', formatter: formatStatus},
      battery: {id: 'battery', formatter: batteryIconFrmt, tooltip: batteryToolTip},
      vehicleType: {id: 'vehicleType'},
      vehicleId: {id: 'vehicleId'},
      lastMessageIcon: {id: 'lastComms', formatter: lastAssetUpdateIcon},
      lastMessageDate: {id: 'lastComms', formatter: shortDateTime}
    }
    const tagColumns = pageConfig.tableColumns.map(col => { return {label: col.label, ...allTagColumns[col.id]} })
    const activeMarkers = {}
    // Loop through the marker item and set the active and hide properties for activeMarkers
    const pageMarkers = pageConfig.map && pageConfig.map.markers ? pageConfig.map.markers : []
    pageMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    return {
      name: 'iTags',
      activeMarkers,
      deviceType: pageConfig.deviceType,
      filterConfig: pageConfig.tableFilters,
      filterKey: 'deviceDashboard',
      markers: pageConfig.map.markers,
      metrics: pageConfig.metrics,
      modalIsActive: {
        filterTable: false
      },
      options: {
        paginate: true,
        perPage: 10,
        sortable: true,
        sortIndex: 0,
        sortType: 'asc'
      },
      searchInput: '',
      showLoading: false,
      tagColumns
    }
  },
  methods: {
    downloadCSV: function () {
      this.$refs.tagListTable.generateCSV('tagList.csv')
    },
    // Replace the activeMarkers data property with updated marker values
    setActiveMarkers: function (activeMarkers) {
      this.activeMarkers = activeMarkers
    },
    updateTagz: function () {
      this.$store.dispatch('devices/initialize', {deviceType: this.deviceType, force: true})
      // this.$store.dispatch('devices/initialize', {deviceType: 'tags', force: true})
    },
    ...mapActions([
      // 'updateShipments',
      // 'updateGateways',
      // 'populateAlertsAndNotifications',
    ])
  },
  mixins: [tableFilterManager, modalWindowManager],
  watch: {
    $route (to, from) {
      console.log('Route: ', to, from)
      // react to route changes...
      this.setFilterbyQuery()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
