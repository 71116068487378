<template>
  <div>
    <div class="no_border" v-html="tableHTML">
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    tableHTML () {
      let output = '<table style="border:none;"><tr>'
      for (const [idx, item] of Object.entries(this.items)) {
        const incIdx = parseInt(idx) + 1
        const spn = item.span || 1
        const noWrap = item.value && item.value.length < 25 ? 'white-space:nowrap;' : ''
        output += `<td style="font-weight:bold; width:1%; white-space:nowrap; border:none; padding:3px 2px">${item.label}</td>`
        output += `<td colspan=${spn} style="border:none; padding:3px 2px; ${noWrap}">${item.value}</td>`
        if (incIdx % this.numColumns === 0 || this.numColumns === 1 || spn > 1) {
          output += '</tr> <tr>'
        }
      }
      output += '</tr></table>'
      return output
    }
  },
  data () {
    return {
    }
  },
  /* props: [
    'items',
    'numColumns'
  ], */
  props: {
    items: {
      type: Array,
      default: () => []
    },
    numColumns: {
      type: String,
      default: '1'
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  tr td:first-child{
    width:1%;
    white-space:nowrap;
  }
  .no_border >>> .content table td {
    border: none;
  }
</style>
