<template>
  <div>
    <level-bar>{{name}}</level-bar>
    <!-- METRICS -->
    <div class="box">
        <nav class="level">
          <metric-item :metricData="metric" v-for="(metric,idx) in metricList" :key="idx">
          </metric-item>
        </nav>
    </div>
    <div class="rows">
      <div class="row is-full">
        <div class="columns">
          <div class="column">
            <!-- Shipment Box with links to filterd shipments and search icon -->
            <intelyt-card>
              <template slot="header">Active {{itemName}}s</template>
              <template slot="subheader"></template>
              <template slot="controls">
                <div class="field has-addons">
                  <span style="margin-right: 15px;"><base-icon name="binoculars" @click="openModal('shipmentFind')" v-tooltip="`Find Archived ${itemName}s`" class="icon-padding"/></span>
                </div>
              </template>
              <nav class="level columns">
                <alert-display v-for="shipState in shipStates" :key="shipState" class="column"
                    :alertDef="shipmentFilters[shipState]"
                    :ids="shipmentIds[shipState] || ''"
                    :isNotice="true"
                    :mode="mode"
                ></alert-display>
              </nav>
            </intelyt-card>
            <!-- Alert Box with links to filterd shipments -->
            <intelyt-card>
              <template slot="header">Active Security Alerts</template>
              <template slot="subheader"></template>
              <nav class="level columns">
                <alert-display v-for="alertId in securityAlerts" :key="alertId" class="column"
                    :alertDef="alertFilterDef[alertId]"
                    :ids="alertIds[alertId] || ''"
                    :mode="mode"
                ></alert-display>
              </nav>
            </intelyt-card>
            <!-- Alert Box with links to filterd shipments -->
            <intelyt-card>
              <template slot="header">Active {{itemName}} Alerts</template>
              <template slot="subheader"></template>
              <nav class="level columns">
                <alert-display v-for="alertId in shipmentAlerts" :key="alertId" class="column"
                    :alertDef="alertFilterDef[alertId]"
                    :ids="alertIds[alertId] || ''"
                    :mode="mode"
                ></alert-display>
              </nav>
            </intelyt-card>
            <devices-card></devices-card>
          </div>
          <div class="column" is-narrow>
          <intelyt-card>
            <template slot="header">Network & {{itemName}}s</template>
            <template slot="controls"></template>
            <intelyt-map
              :markers="markers"
              :zoom="zoom"
              :mapWidth="mapWidth"
              :heightRatio="mapHeightRatio"
              @markerDblClick="openTarget"
              :v-if="markers"
            ></intelyt-map>
            <intelyt-map-legend
              v-if="legendMarkers.length > 0"
              :markers="legendMarkers"
              :activeMarkers="activeMarkers"
            ></intelyt-map-legend>
          </intelyt-card>
          </div>
        </div>
      </div>
    </div>
    <div class="rows">
      <div class="row is-full">
        <div class="columns">
          <div class="column is-half">
            <intelyt-card>
              <template slot="header">Daily Commissioned {{itemName}}s</template>
              <base-chart classname='chart-style headmap-chart-style'
                :options='heatmapOptions'
                :showLoadingIcon='false'
              >
              </base-chart>
            </intelyt-card>
          </div>
        </div>
      </div>
    </div>
    <show-message-modal
      :message="deviceStatusMessage.message"
      :title="deviceStatusMessage.title"
      :isActive="modalIsActive.showMessage"
      v-on:close="closeModal('showMessage')"
    >
    </show-message-modal>
    <shipment-find-modal
      :isActive="modalIsActive.shipmentFind"
      :tabConfig="tabConfig"
      :redirectPath="'shipment-detail'"
      :title="'Find Shipment'"
      :redirect="true"
      v-on:close="closeModal('shipmentFind')"
    >
    </shipment-find-modal>
  </div>
</template>

<script>
import AlertDisplay from '@/components/AlertDisplay'
import DevicesCard from '@/components/DevicesCard'
import IntelytMapLegend from '@/components/IntelytMapLegend'
import MetricItem from '@/components/MetricItem'
import ShipmentFindModal from '@/modals/ShipmentFindModal'
import router from '@/router'
import { mapActions } from 'vuex'
import { modalWindowManager } from '@/store/mixins'
import {capitalize, shortDate} from '@/store/formatters.js'
import moment from 'moment-timezone'

export default {
  components: {
    AlertDisplay,
    DevicesCard,
    IntelytMapLegend,
    MetricItem,
    ShipmentFindModal
  },
  computed: {
    alertIds: function () {
      return this.$store.state.alerts.ids[0] || {}
    },
    heatmapOptions: function () {
      const data = this.heatmapData
      const options = {
        tooltip: {},
        visualMap: {
          min: this.heatmapMinMax[0],
          max: this.heatmapMinMax[1],
          type: 'piecewise',
          orient: 'vertical',
          left: 'right',
          top: 0,
          textStyle: {
            color: '#000'
          },
          inRange: {
            color: ['#c7e1fa', '#004080']
            // color: ['#B2D3E7', '#337AB8']
          }
        },
        calendar: {
          top: 25,
          left: 40,
          right: 100,
          cellSize: ['auto', 13],
          range: this.dateRange,
          itemStyle: {
            borderWidth: 0.5
          },
          yearLabel: {show: false}
        },
        series: {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: data
        }
      }
      return options
    },
    markers: function () {
      const markerFunct = (obj, idx, icon, trgt, id = false, txt = false) => {
        const mrk = {
          id: idx,
          icon: icon,
          name: obj.name,
          targetId: id || obj.shippingLocId,
          targetType: trgt,
          position: {
            lat: Number.parseFloat(obj.latitude),
            lng: Number.parseFloat(obj.longitude)
          },
          infoText: txt || `Name: ${obj.name}</br>Type: ${obj.locType}</br>Code: ${obj.locCode}`,
          timestamp: 0
        }
        return mrk
      }
      const activeMarkers = {...this.activeMarkers}
      const offlnLocs = Object.values(this.$store.state.devices.gateways.all).filter(this.offlineFltr).map(gw => gw.location)
      let markerList = []
      let indx = 1
      let icon = ''
      let marker = {}

      for (let loc of Object.values(this.$store.state.locations.all)) {
        // If location name is available in the offline location list And
        // offline location marker available in active marker and marker is active the add the offline marker
        if (offlnLocs.includes(loc.name) && this.activeMarkers.offlineLoc && this.activeMarkers.offlineLoc.active) {
          icon = 'iGateOffline'
          marker = markerFunct(loc, indx, icon, 'loc')
          markerList.push(marker)
          indx += 1
          // show the offline marker
          activeMarkers.offlineLoc.hide = false
        } else if (!offlnLocs.includes(loc.name) && this.activeMarkers.onlineLoc && this.activeMarkers.onlineLoc.active) {
          // If location name is not available in the offline location list And
          // online location marker available in active marker and marker is active the add the online marker
          icon = 'iGateOnline'
          marker = markerFunct(loc, indx, icon, 'loc')
          markerList.push(marker)
          indx += 1
          // show the onlineLoc marker
          activeMarkers.onlineLoc.hide = false
        }
      }
      // If shipments marker available in active marker and marker is active the add the shipments marker
      if (this.activeMarkers.shipments && this.activeMarkers.shipments.active) {
        /* Get all non-repo shipments that have been commissioned.
           Do not show saved shipments since they do not have lat/lng yet */
        const shpmnts = Object.values(this.$store.state.shipments.all).filter(shp => shp.type !== 'Repo').filter(shp => shp.shippingState > 1)
        console.log('show shipments ---', shpmnts)
        for (let shp of shpmnts) {
          let txt = `ID: ${shp.clientShipmentId}</br>Origin: ${shp.origin}`
          txt += `</br> Destination: ${shp.destination}`
          marker = markerFunct(shp, indx, 'shipment', 'shp', shp.guid, txt)
          markerList.push(marker)
          indx += 1
        }
        // If atleast one shpmnts found, then show the shpmnts legend in list
        if (shpmnts.length > 0) activeMarkers.shipments.hide = false
      }
      this.setActiveMarkers(activeMarkers)
      return markerList
    },
    metricList: function () {
      return this.metrics
    },
    shipmentIds: function () {
      return this.$store.state.shipments.ids
    }
  },
  created () {
    console.log('-------- Start SHIPMENT Dashboard Created -------')
    // initilize gateways to show online/offline status
    this.$store.dispatch('devices/initialize', {deviceType: 'gateways'})
    // fetch shipment details to show counts of departed, saved, etc. and map
    // this.$store.dispatch('shipments/updateShipmentList')
    this.$store.dispatch('shipments/loadIds')
    // this.$store.dispatch('shipments/updateShipments')
    // fetch device counts for tags, chimes and gateways for bar charts
    this.$store.dispatch('devices/fetchSummaryCounts')
    // get alert counts
    // this.$store.dispatch('alerts/initialize')
    // fetch locations for map online and offline iGates
    this.$store.dispatch('locations/initializeAll')
    // fetch alert counts for alert countLinks
    this.$store.dispatch('alerts/loadCounts')
    this.$store.dispatch('alerts/loadIds')
    // this.$store.dispatch('geofences/initializeAll')
    this.$store.dispatch('geofences/initializeRouteList')
    // fetch shipments counts for headmaps chart
    const companyId = String(this.$store.state.company.id)
    const payloadObj = {companyId: companyId, stateDate: this.dateRange[0], endDate: this.dateRange[1]}
    this['shipments/fetchShipmentCountByDay'](payloadObj).then((response) => {
      let pointValues = []
      this.heatmapData = Object.entries(response.data).map(x => {
        pointValues.push(x[1].count)
        return [x[1].createDate, x[1].count]
      })
      this.heatmapMinMax = [Math.min(...pointValues), Math.max(...pointValues)]
    }).catch((e) => {
      console.log('fetchShipmentCountByDay Error', e)
    })
    console.log('-------- Completed SHIPMENT Dashboard Created -------')
  },
  data () {
    const mode = this.$route.query.mode || 'shipment'
    const itemName = capitalize(mode)
    const pageConfig = this.$store.state.configuration.pageOptions[`${mode}Dashboard`]
    const alertsObj = this.$store.state.configuration.alertEvent
    let alerts = []
    /* Previously used only one alert definition, that have all the alert filter values.
    Now we moved the configurations into the alertEvent and defaultAlertFilterDef objects.
    Instead of loading one big alertEvent object, We are taking the securityAlerts & shipmentAlerts
    available for the logged in user and create one new object by merging the defaultAlertFilterDef and
    the alert Object. */
    alerts.push(...pageConfig.securityAlerts)
    alerts.push(...pageConfig.shipmentAlerts)
    const defaultDefs = this.$store.state.configuration.defaultAlertFilterDef
    const alertFilterDef = {}
    alerts.forEach((alertId) => {
      const alertObj = alertsObj[alertId]
      // If the alert object have shipmentPath use it, else use the defaultDefs.shipmentPath
      const path = alertObj.shipmentPath || defaultDefs.shipmentPath
      const filterStates = alertObj.filterStates || defaultDefs.filterStates
      alertFilterDef[alertId] = Object.assign({path, filterStates}, defaultDefs, alertObj)
    })
    const endDate = shortDate(moment(), 'YYYY-MM-DD')
    const stateDate = shortDate(moment(endDate).subtract(1, 'years'), 'YYYY-MM-DD')
    const activeMarkers = {}
    // Loop through the marker item and set the active and hide properties for activeMarkers
    const pageMarkers = pageConfig.map && pageConfig.map.markers ? pageConfig.map.markers : []
    pageMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    return {
      name: `${itemName} Dashboard`,
      activeMarkers,
      alertFilterDef: alertFilterDef,
      custRef: '',
      deviceStatusMessage: {
        message: 'Default Message',
        title: 'Default Title'
      },
      dateRange: [stateDate, endDate],
      heatmapData: [],
      heatmapMinMax: [0, 0],
      itemName,
      legendMarkers: pageConfig.map.markers,
      mapWidth: 1025,
      mapHeightRatio: 2,
      mac: '',
      metrics: pageConfig.metrics,
      modalIsActive: {
        showMessage: false,
        shipmentFind: false
      },
      mode,
      options: {},
      securityAlerts: pageConfig.securityAlerts,
      shipmentAlerts: pageConfig.shipmentAlerts,
      shipmentFilters: this.$store.state.configuration.shipmentFilters,
      shipStates: pageConfig.shipStates,
      tabConfig: pageConfig.findShipmentFields,
      zoom: 2,
      zoomFixed: true
    }
  },
  methods: {
    filterShipments (state) {
      const qry = {fltcol: state.fltcol, fltval: state.fltval, fltnme: state.fltnme}
      router.push({path: state.path, query: qry})
    },
    offlineFltr (x) {
      const delay = moment().diff(x.lastMessageGateway, 'minutes')
      // console.log('LAS MESSAGE QQWOY: ', x.lastMessageGateway, delay)
      return isNaN(delay) ? true : delay > 360
    },
    openTarget (mrk) {
      if (mrk.targetType === 'loc') {
        router.push({path: 'location-detail', query: { id: mrk.targetId }})
      } else if (mrk.targetType === 'shp') {
        router.push({path: 'detail', query: { id: mrk.targetId, mode: 'shipment' }})
      } else {
        console.log('Got a problem here...')
      }
    },
    sepShipments (state) {
      const qry = {fltcol: 'securityState', fltval: state, fltnme: 'Status'}
      router.push({path: 'shipment-list', query: qry})
    },
    // Replace the activeMarkers data property with updated marker values
    setActiveMarkers (activeMarkers) {
      this.activeMarkers = activeMarkers
    },
    shipmentDetails (id) {
      const qry = {fltcol: 'clientShipmentId', fltval: id, fltnme: 'Customer Ref'}
      router.push({path: 'shipment-list', query: qry})
    },
    ...mapActions([
      'shipments/fetchShipmentCountByDay',
      'devices/getStatus'
    ])
  },
  mixins: [modalWindowManager]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .dummyInline{
    vertical-align: top;
    display:inline-block;
  }
  .smalltable {
    font-size: 0.85rem;
    line-height: 10px;
    height: 10px;
  }
  .smalltd {
    border: none;
  }
  .chart-style.headmap-chart-style{
    height: 175px;
  }
</style>
