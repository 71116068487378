<template>
  <div>
    <div class="field">
      <label class="label">Tracking Reference</label>
      <div class="columns">
        <div class="column is-4">
          <div class="select">
            <select v-model="billOfLadingType">
              <option value="">Select Type</option>
              <option v-for="type in bolTypes" :key="type" :value="type">{{type}}</option>
            </select>
          </div>
        </div>
        <div class="column inputsec is-8">
          <ValidationProvider :name="validatorFieldName" :rules="selectValidator"  v-slot="{ errors }">
            <input class="input" :class="{'input': true, 'is-danger': errors[0] }" type="text" v-model="billOfLadingValue" name="billOfLadingValue">
            <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate/dist/vee-validate.full'
export default {
  name: 'BillOfLading',
  created () {
    const vm = this
    extend('bolValidation', {
      message () {
        const trackingRefType = vm.billOfLadingType
        const trackingRefValue = vm.billOfLadingValue
        let message = ''
        // let regexformat = (trackingRefType === 'BOL') ? 'COSU6103622780' : 'HLBU1509039'
        if (trackingRefType === 'BOL') {
          message = `The Tracking Reference[${trackingRefType}] field must be correct format like XXXX123456789`
        } else {
          message = `The Tracking Reference[${trackingRefType}] field must be correct format like XXXU123456789`
          if (trackingRefValue.charAt(3) !== 'U') {
            message = `The Tracking Reference[${trackingRefType}] field 4'th character must be 'U'`
          }
        }
        return message
      },
      validate: (value) => new Promise(resolve => {
        const trackingRefType = vm.billOfLadingType
        const trackingRefValue = vm.billOfLadingValue
        // skipcq: JS-A1002.  Allow Regex range is too permissive
        const regexval = (trackingRefType === 'BOL') ? /^([A-z]{4}[0-9]{3,24})/i : /^([A-z]{3}[U][0-9]{7})/i
        let valid = regexval.test(value)
        if (trackingRefType === 'BOL' && valid && trackingRefValue.length > 7) {
          const splittedValue = trackingRefValue.slice(7, trackingRefValue.length)
          valid = Number(splittedValue)
        }
        resolve({
          valid
        })
      })
    })
  },
  data () {
    return {
      billOfLadingType: '',
      entryIdBOL: '',
      billOfLadingValue: '',
      validatorFieldName: 'Tracking Reference',
      selectValidator: { max: 5, regex: /^(\w|\s|-)+$/ },
      bolTypes: this.$store.state.configuration.bolTypes
    }
  },
  props: {
    default: Object,
    mode: String,
    reset: Boolean
  },
  watch: {
    reset (val) {
      if (val) {
        this.$store.commit('SET_FORM_FIELD', {key: 'itemType', value: 100, mode: this.mode})
        // If reset is true, then set the value as blank
        // If the default value is passed, then set the value
        this.billOfLadingType = this.default?.billOfLadingType ? this.default.billOfLadingType : ''
        this.billOfLadingValue = this.default?.billOfLadingValue ? this.default.billOfLadingValue : ''
        this.entryIdBOL = this.default?.entryIdBOL ? this.default.entryIdBOL : 0
      } else { // Else reset the values on closing the modal
        this.billOfLadingType = ''
        this.billOfLadingValue = ''
        this.entryIdBOL = ''
      }
    },
    billOfLadingType: {
      handler (newInput) {
        if (!this.reset) return
        this.validatorFieldName = newInput !== '' ? `Tracking Reference[${newInput}]` : 'Tracking Reference'
        if (newInput === 'BOL') {
          this.selectValidator = { min: 7, max: 24, bolValidation: true, required: true }
        } else if (newInput === 'Ocean Container') {
          this.selectValidator = { length: 11, bolValidation: true, required: true }
        } else if (newInput === 'HAWB' || newInput === 'MAWB') {
          this.selectValidator = { digits: 11, required: true }
        } else {
          this.selectValidator = { max: 5, regex: /^(\w|\s|-)+$/ }
        }
        this.$store.commit('SET_FORM_FIELD', {key: 'billOfLadingType', value: newInput, mode: this.mode})
      },
      deep: true
    },
    billOfLadingValue: {
      handler (newInput) {
        if (!this.reset) return
        this.$store.commit('SET_FORM_FIELD', {key: 'billOfLadingValue', value: newInput, mode: this.mode})
      },
      deep: true
    },
    entryIdBOL: {
      handler (newInput) {
        if (!this.reset) return
        this.$store.commit('SET_FORM_FIELD', {key: 'entryIdBOL', value: newInput, mode: this.mode})
      },
      deep: true
    }
  }
}
</script>
