 <template>
  <div>
    <level-bar>{{name}}</level-bar>
    <div class="columns">
       <div class="column">
        <intelyt-card>
          <template slot="header">{{routeTitle}}</template>
          <template slot="controls">
          <span style="display:flex; align-content:center;">
            <span class="select selectedRoute">
              <select v-model="selectedRouteId">
                <option value="0">Selected Route</option>
                <option v-for="route in routeData.routes" :key="route.mapId" :value="route.mapId">{{route.name}}</option>
              </select>
            </span>
            <base-icon name="add" @click="openAddModal('Route')" v-tooltip="'Create Routes'"  v-if="isAdmin" />
          </span>
          </template>
          <intelyt-map
             :polygons="polygons"
             :pathList="getPolylineList"
             :markers="markers"
             :mapWidth="mapWidthCalc"
             @markerDblClick="openGeozoneEditModal"
             :zoom="-1"
          >
          </intelyt-map>
          <intelyt-map-legend
            v-if="legendMarkers.length > 0"
            :markers="legendMarkers"
            :activeMarkers="activeMarkers"
          ></intelyt-map-legend>
        </intelyt-card>
      </div>
      <div class="column">
        <intelyt-card :cardOptions="cardOptions">
          <template slot="header">Route Details</template>
          <intelyt-list :items="routeDetails" numColumns=1>
            </intelyt-list>
        </intelyt-card>
        <intelyt-card :cardOptions="[]">
          <template slot="header">Route Geozones</template>
          <div class="tabs is-boxed">
            <ul class="noMarginleft">
              <li v-for="(tab, idx) in geozoneTypes" :key="idx" :class="{'is-active': (activeTab === tab.typeId)}"><a @click="activateTab(tab.typeId)">{{tab.name}} ({{routeData.geozones[tab.typeId].length}})</a></li>
            </ul>
          </div>
          <div class="content">
            <div v-for="(tab, idx) in geozoneTypes" :key="idx" :id="idx" class="content-tab" v-show="activeTab === tab.typeId">
              <div class="field-group">
                <div class="field">
                  <base-table
                    :columns="columns"
                    :data="routeData.geozones[tab.typeId]"
                    :options="options"
                  >
                  </base-table>
                </div>
              </div>
            </div>
          </div>
        </intelyt-card>
      </div>
      <div class="column">
        <intelyt-card :cardOptions="zoneCardOptions">
          <template slot="header">All Geozones</template>
          <template slot="controls">
            <label style="float: right;">
              <input type="search" autocomplete="off" id="search-input" class="form-control" placeholder="Search Fence"
                  :value="searchInput"
                  @input="(e) => {this.searchInput = e.target.value}">
            </label>
            <span class="icon-search is-right" style="padding-top:5px;">
              <base-icon name="search" size="18" color="grey"/>
            </span>
          </template>
        <base-table
          :columns="allFenceColumns"
          :data="allFenceData"
          :searchText="searchInput"
          :options="options"
        >
        </base-table>
        </intelyt-card>
      </div>
    </div>
    <edit-geofence-modal
      :mode="mode"
      :type="modalType"
      :zoneType="zoneType"
      :selected="selected"
      :geofence="geofence"
      :isActive="modalIsActive.editGeofence"
      v-on:close="closeEditModal"
      v-on:setMapId="setMapId"
      ref="geofenceModal"
    >
    </edit-geofence-modal>
    <send-command-modal
      :messageObj="cmdModal.messageObj"
      :isActive="modalIsActive.sendCommand"
      :actionButtonText="'Confirm'"
      :commandArgs="{callbackFn: cmdModal.callback, params: cmdModal.params}"
      v-on:close="closeModal('sendCommand')"
    >
    </send-command-modal>
  </div>
</template>

<script>
import EditGeofenceModal from '@/modals/EditGeofenceModal'
import IntelytMapLegend from '@/components/IntelytMapLegend'
import { mapActions, mapState } from 'vuex'
import {astShpmntDataFormat} from '@/store/formatters.js'
import {centroid, getMapWidth, generateIconSVG, buildMarker, round, getMessageObject} from '@/store/helpers.js'
import { modalWindowManager } from '@/store/mixins'
import moment from 'moment-timezone'

export default {
  components: {
    EditGeofenceModal,
    IntelytMapLegend
  },
  computed: {
    allFenceData: function () {
      const allFences = Object.values(this.$store.state.geofences.all).filter(zone => zone.mapConfigNameType === 'Geozone')
      const sortedFences = allFences.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
      return sortedFences
    },
    /**
     * Generate Route detail card option
     * @returns {Array} options - Returns card option values on array of object
     */
    cardOptions: function () {
      let options = []
      if (this.activeFence !== '*' && this.isAdmin) {
        options = [{icon: 'edit', text: 'Edit Route', optionArgs: {callbackFn: this.openEditModal, params: ''}}]
        const modalTitle = (parseInt(this.activeFence.enabled) === 1) ? 'Disable Route' : 'Enable Route'
        const ctrlIcon = (parseInt(this.activeFence.enabled) === 1) ? 'cancel' : 'check'
        options.push({icon: ctrlIcon, text: modalTitle, optionArgs: {callbackFn: this.openStatustModal, params: this.activeFence}})
      }
      return options
    },
    zones: function () {
      return this.$store.state.geofences.all
    },
    /**
     * filteredFences - returns the geozone list for the
     * selected route
     */
    filteredFences: function () {
      const fences = this.zones
      if (this.activeFence === '*') {
        return []
      } else if (this.activeFence.class === 'fence') {
        return [this.activeFence]
      } else {
        // const assoc = this.activeFence.associations.map(fnc => fnc.id)
        const assoc = this.activeFence.associationList
        // const filteredFences = Object.values(fences).filter(fnc => assoc.includes(fnc.mapId))
        const filteredFences = assoc.map(mapId => { return fences[mapId] })
        return filteredFences
      }
    },
    mapWidthCalc: function () {
      return getMapWidth(this.$parent.windowWidth, 0.4)
    },
    /**
     * Generate geozones, origins and destination markers/flag from selected route
     */
    markers: function () {
      let markerList = []
      let indx = 1
      // If geofence available in activeMarkers and the geofence icon is active
      const zones = this.activeMarkers.geofence && this.activeMarkers.geofence.active ? this.filteredFences : []
      const activeMarkers = {...this.activeMarkers}
      for (let zone of zones) {
        const zoneConfig = this.$store.state.configuration.geozoneTypes[zone.mapConfigTypeId]
        // If the mapConfigTypeId has the hideMarker setting, the don't add it to marker list
        if (!zone || zone.mapConfigNameType === 'Route' || zoneConfig.hideMarker) {
          continue
        }
        const status = zone.enabled ? 'Active' : 'Deactivated'
        let center = centroid(zone.coordinates)
        let marker = {
          id: indx,
          icon: 'geofence',
          name: zone.name,
          mapId: zone.mapId,
          position: {
            lat: Number.parseFloat(center.lat),
            lng: Number.parseFloat(center.lng)
          },
          infoText: `Name: ${zone.name}</br>Status: ${status}`,
          timestamp: 0
        }
        indx += 1
        markerList.push(marker)
      }
      // If atleast one geofence found, then show the geofence legend in list
      if (markerList.length > 0) activeMarkers.geofence.hide = false
      const usertmzn = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const addMark = (val, type) => {
        let formatDate = moment.utc(val.createDate, 'ddd MMM D HH:mm:ss YYYY')
        const originObj = {
          class: type,
          datetime: formatDate.isValid() ? formatDate.format('YYYY-MM-DD HH:mm:ss') : moment(val.createDate, 'YYYY-MM-DD HH:mm:ss'),
          latitude: val.latitude,
          longitude: val.longitude,
          origin: val.name,
          destination: val.name,
          timestamp: formatDate.isValid() ? formatDate.format('x') : moment(val.createDate, 'YYYY-MM-DD HH:mm:ss').format('x')
        }
        indx += 1
        markerList.push(buildMarker(originObj, indx, usertmzn))
      }
      if (this.activeFence !== '*') {
        let locations = this.activeFence.notes !== '' ? JSON.parse(this.activeFence.notes) : ''
        if (typeof locations === 'object') {
          const origins = this.activeMarkers.origin.active ? locations.origins : []
          // If atleast one origin found, then show the origin legend in list
          if (origins.length > 0) activeMarkers.origin.hide = false
          const destinations = this.activeMarkers.destination.active ? locations.destinations : []
          // If atleast one destination found, then show the destination legend in list
          if (destinations.length > 0) activeMarkers.destination.hide = false
          for (let originKey in origins) {
            if (!origins[originKey] || !this.locations[origins[originKey]]) continue
            addMark(this.locations[origins[originKey]], 'origin')
          }
          for (let desKey in destinations) {
            if (!destinations[desKey] || !this.locations[destinations[desKey]]) continue
            addMark(this.locations[destinations[desKey]], 'destination')
          }
        }
      }
      // Update the activeMarker values
      this.setActiveMarkers(activeMarkers)
      return markerList
    },
    polygons: function () {
      if (!this.activeMarkers.geofence || !this.activeMarkers.geofence.active) {
        return {editable: false, paths: []}
      }
      let polygonList = []
      const allZones = {...this.$store.state.geofences.all}
      /**
       * getAssociations - This method is used to get the nested associations for the given route
       * It's a recursive method to iterate the each associations and it's nested associations until
       * it founds no associations
       */
      const getAssociations = (id) => {
        const associations = allZones[id] ? [...allZones[id].associationList] : false
        if (associations) {
          for (const assoc of associations) {
            associations.push(...getAssociations(assoc))
          }
          return associations
        }
        return false
      }
      let nestedAssoc = []
      this.filteredFences.forEach((zone) => {
        nestedAssoc.push(zone.mapId)
        const getNestedAssoc = getAssociations(zone.mapId)
        if (getNestedAssoc.length > 0) {
          nestedAssoc.push(...getNestedAssoc)
        }
      })
      for (let mapId of nestedAssoc) {
        const zone = allZones[parseInt(mapId)]
        if (!zone || !zone.coordinates || zone.coordinates.length === 0 || parseInt(zone.mapConfigTypeId) === 0) {
          continue
        }
        let polygon = []
        for (let node of zone.coordinates) {
          const position = {
            lat: Number.parseFloat(node.lat),
            lng: Number.parseFloat(node.lng)
          }
          polygon.push(position)
        }
        polygonList.push(polygon)
      }
      return {editable: false, paths: polygonList}
    },
    getPolylineList: function () {
      if (!this.activeMarkers.geofence || !this.activeMarkers.geofence.active) {
        return []
      }
      const pointOption = {
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 0.5,
        strokeWeight: 5,
        icons: []
      }
      let pathList = []
      const allZones = {...this.$store.state.geofences.all}
      /**
       * getAssociations - This method is used to get the nested associations for the given route
       * It's a recursive method to iterate the each associations and it's nested associations until
       * it founds no associations
       */
      const getAssociations = (id) => {
        const associations = allZones[id] ? [...allZones[id].associationList] : false
        if (associations) {
          for (const assoc of associations) {
            associations.push(...getAssociations(assoc))
          }
          return associations
        }
        return false
      }
      let nestedAssoc = []
      this.filteredFences.forEach((zone) => {
        nestedAssoc.push(zone.mapId)
        const getNestedAssoc = getAssociations(zone.mapId)
        if (getNestedAssoc.length > 0) {
          nestedAssoc.push(...getNestedAssoc)
        }
      })
      for (let mapId of nestedAssoc) {
        const zone = allZones[parseInt(mapId)]
        if (!zone || !zone.coordinates || zone.coordinates.length === 0 || parseInt(zone.mapConfigTypeId) !== 0) {
          continue
        }
        let points = []
        for (let node of zone.coordinates) {
          const position = {
            lat: Number.parseFloat(node.lat),
            lng: Number.parseFloat(node.lng)
          }
          points.push(position)
        }
        pathList.push({path: points, options: pointOption, editable: false})
      }
      return pathList
    },
    /**
     * Display the selected route details in second column
     */
    routeDetails: function () {
      // Get the options of specific route details fields
      const columns = this.pageOptions.details || []
      // this.activeFence = this.zones[this.selectedRouteId] || {}
      let geoRouteData = ''
      if (parseInt(this.selectedRouteId) > 0) {
        if (!this.zones[this.selectedRouteId].initialized) {
          this.getRouteDetails({mapId: this.selectedRouteId})
          // return []
        }
        geoRouteData = this.getLocationDetails(this.zones[this.selectedRouteId])
      }
      let retData = astShpmntDataFormat(this.$store.state, columns, geoRouteData)
      this.setActiveFence(this.selectedRouteId)
      return retData
    },
    /**
     * Generate route data, geofence data with map config type
     * @returns {Object} - Return the routes and geozone data
     */
    routeData: function () {
      const allRoutes = Object.values(this.$store.state.geofences.all).filter(zone => zone.mapConfigNameType === 'Route')
      const sortedRoutes = allRoutes.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
      /* All geofences assocated with this route are those in associationList - use this list to
       * generate an list of geofence lists grouped by type, for example [[gz1,gz2], [], [gz4,gz5,gz6]]
       * Note: There must be an entry for all geozone types, even if empty so first build list of empty maps
       * for the function to populate.
       */
      const geofenceIds = (this.activeFence !== '*') ? this.activeFence.associationList : []
      // create empty array with number of elements same as length of geozoneTypes
      // TODO - this will fall down if types isn't a sequential list starting at 0 - FIX IT
      // let geozones = this.geozoneTypes.map(x => []) // Array(this.geozoneTypes.length)
      let geozones = this.geozoneTypes.map(() => []) // Array(this.geozoneTypes.length)
      geofenceIds.forEach((id) => {
        const zone = this.$store.state.geofences.all[id]
        geozones[zone.mapConfigTypeId].push(zone)
      })
      return {routes: sortedRoutes, geozones: geozones}
    },
    ...mapState({})
  },
  created () {
    // this.$store.dispatch('geofences/initializeAll')
    this.$store.dispatch('geofences/initializeRouteList')
    this.$store.dispatch('geofences/initializeAssocisationsList')
    // fetch locations for map online and offline iGates
    this.$store.dispatch('locations/initializeAll')
    // If query id avaliable then Select route option will autometically selected
    this.selectedRouteId = (typeof this.$route.query.id !== 'undefined') ? parseInt(this.$route.query.id) : 0
    // get the messages for the geofences page
    this.$store.dispatch('getPageMessage', 'geofences')
  },
  data () {
    const newState = this.$store.state
    let pageOptions = this.$store.state.configuration.pageOptions.routeDetail
    const geozoneTypes = Object.values(this.$store.state.configuration.geozoneTypes).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
    const locations = this.$store.state.locations.all
    // const isAdmin = this.$store.state.user.roles.includes('Company Administrator')
    const isAdmin = this.$store.state.user.permissions.includes('editRoutes')
    const zoneCardOptions = []
    const ZoneTypeList = {}
    Object.values(this.$store.state.configuration.geozoneTypes).forEach((val) => {
      ZoneTypeList[val.typeId] = val
      if (isAdmin && val.editable) {
        zoneCardOptions.push({id: `add${val.name}`, icon: val.icon, text: `Create ${val.name}`, optionArgs: {callbackFn: this.openCreateZoneModal, params: val.typeId}})
      }
    })
    // Generate status icon with color and size
    const statusIcons = (status) => {
      const icon = {
        name: 'circle_fill',
        color: (status === 'Enabled') ? 'green' : 'red',
        size: 18
      }
      return `<div align="center">${generateIconSVG(newState, icon)}</div>`
    }
    const zoneTypeName = (typeId) => {
      return ZoneTypeList[typeId] ? ZoneTypeList[typeId].name : '-'
    }
    const showEditIcon = function (zone) {
      return isAdmin && ZoneTypeList[zone.mapConfigTypeId] ? ZoneTypeList[zone.mapConfigTypeId].editable : false
    }
    const controlIcons = [
      {icon: 'edit', callback: this.openZoneEditModal, tooltip: 'Edit Association', show: showEditIcon}
    ]
    const activeMarkers = {}
    // Loop through the marker item and set the active and hide properties for activeMarkers
    const pageMarkers = pageOptions.map && pageOptions.map.markers ? pageOptions.map.markers : []
    pageMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    // View link for the geofences
    const viewLink = function (val) {
      return `<a>${val}</a>`
    }
    return {
      name: 'Routes',
      activeFence: '*',
      activeMarkers,
      activeTab: geozoneTypes[0].typeId || 0,
      activeParentTab: 'routeFences',
      allFenceColumns: [
        {label: '', id: 'id', controls: controlIcons},
        {label: 'Name', id: 'name', formatter: viewLink, eventclick: this.openViewZoneModal},
        {label: 'Description', id: 'description'},
        {label: 'Type', id: 'mapConfigTypeId', formatter: zoneTypeName},
        {label: 'Enabled', id: 'status', formatter: statusIcons}
      ],
      columns: [
        {label: 'Name', id: 'name'},
        {label: 'Description', id: 'description'},
        {label: 'Enabled', id: 'status', formatter: statusIcons}
      ],
      cmdModal: {},
      geozoneTypes,
      geofence: {},
      // isNew: true,
      isAdmin: isAdmin,
      legendMarkers: pageOptions.map.markers,
      mode: 'add',
      modalType: 'Route',
      modalIsActive: {
        editGeofence: false,
        sendCommand: false
      },
      locations,
      options: {
        paginate: true,
        perPage: 10,
        sortable: true,
        searchable: false
      },
      pageOptions,
      paths: [],
      routeTitle: 'Route',
      searchInput: '',
      selected: null,
      selectedRouteId: 0,
      zoneCardOptions: zoneCardOptions,
      zoneType: -1 // zone type value for the manage geo zone modal
    }
  },
  methods: {
    /**
     * Activate the clicked tab and show it's content
     * @param {tabId} String - Clicked tab id of the tab
     * Note: Check current tab is activated or not. If not then active the tab
     * Reset the tab fields while change the tab
     */
    activateTab (tabId) {
      if (this.activeTab !== tabId) {
        this.activeTab = tabId
      }
    },
    /**
     * Close the active modal
     */
    closeModal (modal) {
      this.modalIsActive[modal] = false
    },
    /**
     * Selected route id after route created
     */
    setMapId (mapId) {
      this.selectedRouteId = mapId
    },
    /**
     * This function is callback method, it will trigger on when close the add/edit the route and geozones modal
     * @param {Number} mapid - get mapid from close modal
     */
    closeEditModal (mapId) {
      this.selected = ''
      // this.isNew = true
      const geofences = this.zones[mapId] || {mapConfigNameType: ''}
      this.mode = 'add'
      if (typeof geofences.mapConfigNameType !== 'undefined' && geofences.mapConfigNameType === 'Route') {
        this.selectedRouteId = mapId
      }
      this.closeModal('editGeofence')
    },
    /**
     * This method used to change the route status
     * @param {Object} store - vuex store object
     * @param {Object} args - get selected route values, successs and error message
     * @returns {Object} promise - to return the success or faild report
     */
    changeRouteStatus: function (store, args) {
      const vm = this
      const payload = {
        enabled: (parseInt(args.route.enabled) === 1) ? 0 : 1,
        status: (parseInt(args.route.enabled) === 1) ? 'Disabled' : 'Enabled'
      }
      const statePayload = {...args.route, ...payload}
      const associations = Object.values(args.route.associations).map(assoc => assoc.id)
      const mappedPoints = Object.values(args.route.coordinates).map(pt => {
        return {lat: round(pt.lat, 7), lng: round(pt.lng, 7)}
      })
      const altNames = typeof args.route.alternateNames === 'string' ? args.route.alternateNames.split(',') : args.route.alternateNames
      // Build payload required for submission
      const updatePayload = {
        mapName: args.route.name,
        companyName: this.$store.state.company.name,
        mapId: args.route.mapId,
        map: {
          description: args.route.description,
          subMapID: associations,
          altNames: altNames,
          mapConfigNameType: args.route.mapConfigNameType,
          mapConfigTypeId: '0',
          notes: args.route.notes,
          points: mappedPoints,
          enabled: !args.route.enabled
        }
      }
      const promise = new Promise(function (resolve, reject) {
        vm.editRouteMap([false, updatePayload, statePayload]).then(() => {
          vm.activeFence = vm.$store.state.geofences.all[updatePayload.mapId] || '*'
          resolve({})
        }).catch(e => {
          // If error, set error message and leave modal open for correction
          reject(e)
          // this.errorMessage = e.response.data.errors[0].detail
        })
      })
      return promise
    },
    setActiveFence (mapId) {
      const fence = (mapId > 0 && this.zones[mapId].initialized) ? this.zones[mapId] : '*'
      this.$store.commit('geofences/RESET_ACTIVE', '*')
      this.routeTitle = (fence.name) ? fence.name : 'Route'
      this.activeFence = fence
    },
    /**
     * getLocationDetails - Returns the location details for the selected route
     */
    getLocationDetails (routeData) {
      let fenceData = {}
      fenceData.name = routeData.name
      fenceData.description = routeData.description
      fenceData.status = routeData.status
      fenceData.roundTrip = 'False'
      fenceData.origins = '-'
      fenceData.destinations = '-'
      const notes = routeData.notes
      if (notes === '') {
        return fenceData
      }
      const tripData = JSON.parse(notes)
      fenceData.roundTrip = tripData.roundTrip ? 'True' : 'False'
      let OriginNames = tripData.origins.filter(locationID => this.locations[locationID]).map(locationID => this.locations[locationID].name)
      let destinationNames = tripData.destinations.filter(locationID => this.locations[locationID]).map(locationID => this.locations[locationID].name)
      OriginNames = OriginNames ? OriginNames.join(', ') : '-'
      destinationNames = destinationNames ? destinationNames.join(', ') : '-'
      fenceData.origins = OriginNames
      fenceData.destinations = destinationNames
      return fenceData
    },
    openAddModal (typ) {
      this.mode = 'add'
      this.modalType = typ
      this.selected = 0
      this.geofence = {}
      this.zoneType = -1
      // Call method to reset values in geofence
      this.$refs.geofenceModal.resetGeofence()
      this.modalIsActive['editGeofence'] = true
    },
    openCreateZoneModal (zoneType) {
      this.mode = 'add'
      this.modalType = 'Geozone'
      this.selected = 0
      this.geofence = {}
      this.zoneType = zoneType
      // Call method to reset values in geofence
      this.$refs.geofenceModal.resetGeofence()
      this.modalIsActive['editGeofence'] = true
    },
    openGeozoneEditModal (zone) {
      if (zone.icon === 'geofence' && parseInt(zone.mapId) > 0) {
        this.openEditModal(this.zones[zone.mapId])
      }
    },
    openStatustModal (route) {
      const statusTxt = (parseInt(route.enabled) === 1) ? 'Disable' : 'Enable'
      // getMessageObject will return object/Boolean(false)
      const getMessageObj = getMessageObject(this.$store.state, 'geofences', 'openStatus', {statusTxt})
      this.cmdModal.messageObj = getMessageObj
      this.cmdModal.callback = this.changeRouteStatus
      this.cmdModal.params = {route}
      this.modalIsActive.sendCommand = true
    },
    openEditModal (mrk, view = false) {
      this.mode = view ? 'view' : 'edit'
      this.$refs.geofenceModal.resetGeofence()
      const mrkRoute = (mrk !== '') ? mrk : this.activeFence
      this.selected = mrkRoute.mapId
      this.geofence = this.zones[mrkRoute.mapId]
      this.modalType = this.geofence.mapConfigNameType || 'Route'
      this.zoneType = parseInt(this.geofence.mapConfigTypeId)
      this.modalIsActive['editGeofence'] = true
    },
    openViewZoneModal (zone) {
      this.getRouteDetails({mapId: zone.mapId}).then(() => {
        this.openEditModal(this.zones[zone.mapId], true)
      })
    },
    openZoneEditModal (zone) {
      this.getRouteDetails({mapId: zone.mapId}).then(() => {
        this.openEditModal(this.zones[zone.mapId])
      })
    },
    resetGeofenceList () {
      this.$store.commit('geofences/RESET_ACTIVE', '*')
      this.activeFence = '*'
    },
    // Replace the activeMarkers data property with updated marker values
    setActiveMarkers: function (activeMarkers) {
      this.activeMarkers = activeMarkers
    },
    ...mapActions({
      editRouteMap: 'geofences/editRouteMap',
      getRouteDetails: 'geofences/getRouteDetails'
    })
  },
  mixins: [modalWindowManager],
  watch: {
    $route (to) {
      this.selectedRouteId = (typeof to.query.id !== 'undefined') ? parseInt(to.query.id) : 0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.tabs ul.noMarginleft .is-active a {
    font-weight: bold;
    color: #000;
}
.tabs ul.noMarginleft {
  margin-left: 0px;
  margin-top: 5px;
}
.selectedRoute {
  margin-right: 20px;
}
</style>
