import {hasRole, isJSON} from './helpers.js'
import {state as defaultState} from './state.js'
import config from '../../static/config.json'
import Vue from 'vue'
import moment from 'moment-timezone'

// Creating string representation of state to reset the state values during logout
const BASE_STATE = JSON.stringify(defaultState)

/**
 * Clears the lastRequestedPage value from state
 * @param {Object} state - Vuex state
 */
export const clearLastRequestedPage = (state) => {
  Vue.set(state, 'lastRequestedPage', '')
}

/**
 * Sets the last requested page in state
 * @param {Object} state - Vuex state
 * @param {String} page - Page url
 */
export const setLastRequestedPage = (state, page) => {
  Vue.set(state, 'lastRequestedPage', page)
}

/**
 * Sets the authentication error message
 * @param {Object} state - Vuex state
 * @param {String} msg - Message String
 */
export const setAuthError = (state, msg) => {
  Vue.set(state.auth, 'error', msg)
}

/**
 * Add any object defined in static/config.json to configuration ofject in state.<br>
 * Called by setConfig function in App.vue
 * @param {Object} state - Vuex state
 * @param {Object} configItm - Configuration object defined in static file config.json
 * found in static folder
*/
export const setConfig = (state, configItm) => {
  const proto = window.location.protocol
  const host = window.location.hostname
  // configItm.baseUrl = proto + '//' + host + '/api/'
  configItm.baseUrl = `${proto}//${host}/api/`
  for (const configItem of Object.keys(configItm)) {
    Vue.set(state.configuration, configItem, configItm[configItem])
  }
}

/**
 * Add any object defined in static/config.json to configuration ofject in state.<br>
 * Called by setFont function in App.vue
 * @param {Object} state - Vuex state
 * @param {Object} FontConfig - Configuration object defined in static file config.json
 * found in static folder
*/
export const setFont = (state, FontConfig) => {
  for (const configItem of Object.keys(FontConfig)) {
    Vue.set(state.fontSVG, configItem, FontConfig[configItem])
  }
}

/**
 * Gets offset times from configuration and sets date object in
 * lastRecords value if state for each.
 * @param {object} state  - Vuex store state object
 */
export const setInitialOffsets = (state) => {
  for (const [key, val] of Object.entries(state.configuration.lastRecordOffsets)) {
    const qty = parseInt(val.quantity)
    state.lastRecords[key] = moment().subtract(qty, val.unit)
    if (state[key] && state[key].lastUpdate) {
      state[key].lastUpdate = moment().subtract(qty, val.unit)
    }
  }
  state.shipments.lastUpdate = {}
}

/**
 * Sets metric information for in state using response passed by
 * to {@link intializeMetrics}<br>
 * @param {Object} state  - Vuex store state object
 * @param {Object} metric  - Response payload
 */
export const setMetric = (state, metric) => {
  const entries = Object.entries(metric)
  Vue.set(state.metrics, entries[0][0], entries[0][1])
}

/**
 * Updates configuration using any value sent in overrides argument<br>
 * The path and parameter are passed in as the key and value of a group/list of elements to be replaced.
 * The function will split the key into an array using the dot as a delimiter.  It then takes the last element
 * as the parameter to be replaced.  If there are any other elements, a reducer is used to find the nested state
 * parameter.  For example, if key is 'stateDefs.shipping', the value will be applied to
 * state.stateDefs using parameter 'shipping'
 * @param {Object} overrides - Object with is a group of state values to be replaced.  Each item has a
 * key string and value parameter.  The string represents the element to be replaced as a dot separated
 * string.  The value is any valid Javascript type.
*/
export const setOverrides = (state, overrides) => {
  for (const [key, value] of Object.entries(overrides)) {
    const fullPath = key.split('.')
    const param = fullPath.pop()
    const path = fullPath.reduce((o, p) => o ? o[p] : null, state)
    Vue.set(path, param, value)
  }
  // console.log('Override - ShippingState: ', state.stateDefs)
  // console.log('Override - Timers: ', state.configuration)
  // console.log('Override - State: ', state)
}

export const clearState = (state) => {
  // Reset the state values using the BASE_STATE from state.js file
  for (const [key, value] of Object.entries(JSON.parse(BASE_STATE))) {
    if (['fontSVG'].includes(key)) {
      continue
    }
    Vue.set(state, key, value)
  }
  // Set the default configuration on logging out.
  // config variable is the value from the config.json file
  setConfig(state, config)
}

/**
 * Reset to default state for edit form object
 */
export const RESET_EDIT_FORM_OBJ = (state) => {
  Vue.set(state.form, 'edits', {})
}

/**
 * Reset to default state for new form object
 */
export const RESET_FORM_OBJ = (state) => {
  Vue.set(state.form, 'new', {})
}

/**
 * Updates the user auth status in state
 * @param {Object} state - Vuex state object
 * @param {Boolean} status - Sets true/false auth status
 */
export const setAuth = (state, status = true) => {
  state.auth.status = status
}

/**
* Sets form field value
* @param {Object} params - object with key and value to be added.
* The key value may contain . separator to look up the nested level state object.
* So for that, If key value contains the separator(.), we will split and create an array from that.
* If there is only one item in the array, there is no need of looking the nested level object value.
* Will directly apply the given key and value to the shipment.new/edits object.
* If more that one item found in the split array, We need to iterate the state object
* to get the path for the end item and assign the value to that path.
*/
export const SET_FORM_FIELD = (state, {key, value, mode = 'add'} = {}) => {
  let path = mode === 'add' ? state.form.new : state.form.edits
  /* const getStatePath = getNestedStatePath(statePath, key)
  Vue.set(getStatePath.path, getStatePath.key, value) */
  const fullPath = key.split('.')
  // If only one item found, then directly apply the key and value to the state object.
  if (fullPath.length === 1) {
    Vue.set(path, key, value)
  } else if (fullPath.length > 1) { // If more than one item found
    // get the field name from the key value
    const param = fullPath.pop()
    // Loop through the each item and assign the value.
    // If the attribute not found in the state, then create it as empty {}
    fullPath.forEach((attr) => {
      if (!path[attr]) Vue.set(path, attr, {})
      path = path[attr]
    })
    Vue.set(path, param, value)
  }
}

/**
 * Updates the Menu config details in state
 * @param {Object} state - Vuex state object 
 * @param {Object} response - Response object from API
 */
export const setMenuConfig = (state, response) => {
  const menu = []
  for (const menuItem of response.items) {
    if (hasRole(state.user.roles, menuItem.roles)) menu.push(menuItem)
  }
  if (state.user.reports && state.user.reports.length > 0) {
    menu.push({name: 'Reports', isActive: true, href: '/reports'})
  }
  if (state.user.roles.includes('Company Administrator')) {
    menu.push({name: 'Administration', isActive: true, href: '/administration'})
  }
  Vue.set(state, 'menu', menu)
}

/**
 * Sets state values for the pageOptions for the given page name
 * @param {object} state - Vuex state
 * @param {object} payload - Update payload
 * @param {string} payload.pageName - Apply the page configuration for the given page name
 */
export const setPageOptions = (state, payload) => {
  Vue.set(state.configuration.pageOptions, payload.pageName, payload.pageOptions)
}

/**
 * Sets state values for highHumidHours0 - highHumidHours6<br>
 * Data is in form of offset & count.  Offset is number of days back (from today) the count is applicable to.
 * Count is the number of hours where humidity exceeded threshold.
 * @param {object} state - Vuex state
 * @param {object} payload - Update payload
 * @param {Array} payload.temps - Array of objects that contain date offset and count of overage hours.
 * @todo Collapse into setWarehouseDetails. This will require promiseAll in update
 */
export const setShipment = (state, payload) => {
  /*
    set shipment list from fetchShipmentIds action
  */
  const pickList = payload.data
  const decommDate = pickList.decommDate ? moment.utc(pickList.decommDate, 'ddd MMM DD HH:mm:ss YYYY') : ''
  const commDate = pickList.commDate ? moment.utc(pickList.commDate, 'ddd MMM DD HH:mm:ss YYYY') : ''
  const destRE = /address:([\w|\s|,]+);/i
  // let destAddress = destRE.test(payload.data.customText) ? destRE.exec(payload.data.customText)[1] : ''
  const destAddress = destRE.exec(payload.data.customText)
  const typeRE = /type:([\w|\s-]+);/i
  // let type = typeRE.test(payload.data.customText) ? typeRE.exec(payload.data.customText)[1] : 'unknown'
  const type = typeRE.exec(payload.data.customText)
  const imageRE = /image:([\w|\s|-]+\.(png|jpg|gif|bmp));/i
  // let image = imageRE.test(payload.data.customText) ? imageRE.exec(payload.data.customText)[1] : 'unknown'
  const image = imageRE.exec(payload.data.customText)
  //   let destAddress = ''
  // let hasAddress = destRE.test(payload.data.customData3)
  // if (hasAddress) {
  //   let destArr = destRE.exec(payload.data.customData3)
  //   destAddress = destArr[1]
  // }
  const shipmentData = {
    // Classification details
    class: (pickList.transitMode) ? pickList.transitMode.includes('Asset') ? 'Asset' : 'Shipment' : '',
    mode: (pickList.transitMode) ? pickList.transitMode.includes('Asset') ? '-' : pickList.transitMode : '',
    type: type ? type[1] : 'unknown',
    // Client Data
    clientShipmentId: pickList.customData,
    customer: pickList.customData2,
    destination: pickList.dest,
    notes: pickList.customData3,
    // Location details
    destinationAddress: destAddress ? destAddress[1] : '',
    destinationCoords: {
      lat: Number.parseFloat(pickList.destLatitude),
      lng: Number.parseFloat(pickList.destLongitude)
    },
    origin: pickList.origin,
    originCoords: {
      lat: Number.parseFloat(pickList.originLatitude),
      lng: Number.parseFloat(pickList.originLongitude)
    },
    // Configuration and Properties
    entryIdSPL: pickList.entryId,
    guid: pickList.guid,
    image: image ? image[1] : 'unknown',
    macId: pickList.macId,
    radius: pickList.aclScanned,
    // dates
    // database output format:  Wed Jun 14 22:05:36 2017
    createDate: moment.utc(pickList.createDate, 'ddd MMM DD HH:mm:ss YYYY'),
    lastUpdate: moment.utc(pickList.lastUpdate, 'ddd MMM DD HH:mm:ss YYYY'),
    lastEventUpdate: moment.utc(pickList.createDate, 'ddd MMM DD HH:mm:ss YYYY'),
    commDate,
    decommDate,
    // message data
    tsData: [],
    locData: [],
    tiltShockData: []
  }
  Vue.set(state.shipments, payload.id, shipmentData)
}
export const setPublicShipment = (state, payload) => {
  /*
    set shipment list from fetchShipmentIds action
  */
  const pickList = payload.data
  const decommDate = pickList.decommDate ? moment.utc(pickList.decommDate, 'ddd MMM DD HH:mm:ss YYYY') : ''
  const commDate = pickList.commDate ? moment.utc(pickList.commDate, 'ddd MMM DD HH:mm:ss YYYY') : ''
  const destRE = /address:([\w|\s|,]+);/i
  // let destAddress = destRE.test(payload.data.customText) ? destRE.exec(payload.data.customText)[1] : ''
  const destAddress = destRE.exec(payload.data.customText)
  const typeRE = /type:([\w|\s-]+);/i
  // let type = typeRE.test(payload.data.customText) ? typeRE.exec(payload.data.customText)[1] : 'unknown'
  const type = typeRE.exec(payload.data.customText)
  const imageRE = /image:([\w|\s|-]+\.(png|jpg|gif|bmp));/i
  // let image = imageRE.test(payload.data.customText) ? imageRE.exec(payload.data.customText)[1] : 'unknown'
  const image = imageRE.exec(payload.data.customText)
  //   let destAddress = ''
  // let hasAddress = destRE.test(payload.data.customData3)
  // if (hasAddress) {
  //   let destArr = destRE.exec(payload.data.customData3)
  //   destAddress = destArr[1]
  // }
  const shipmentData = {
    // Classification details
    class: (pickList.transitMode) ? pickList.transitMode.includes('Asset') ? 'Asset' : 'Shipment' : '',
    mode: (pickList.transitMode) ? pickList.transitMode.includes('Asset') ? '-' : pickList.transitMode : '',
    type: type ? type[1] : 'unknown',
    configType: pickList.configType,
    // Client Data
    clientShipmentId: pickList.customData,
    customer: pickList.customData2,
    destination: pickList.dest,
    notes: pickList.customData3,
    // Location details
    destinationAddress: destAddress ? destAddress[1] : '',
    destinationCoords: {
      lat: Number.parseFloat(pickList.destLatitude),
      lng: Number.parseFloat(pickList.destLongitude)
    },
    origin: pickList.origin,
    originCoords: {
      lat: Number.parseFloat(pickList.originLatitude),
      lng: Number.parseFloat(pickList.originLongitude)
    },
    // Configuration and Properties
    entryIdSPL: pickList.entryId,
    guid: pickList.guid,
    image: image ? image[1] : 'unknown',
    macId: pickList.macId,
    radius: pickList.aclScanned,
    // dates
    // database output format:  Wed Jun 14 22:05:36 2017
    createDate: moment.utc(pickList.createDate, 'ddd MMM DD HH:mm:ss YYYY'),
    lastUpdate: moment.utc(pickList.lastUpdate, 'ddd MMM DD HH:mm:ss YYYY'),
    lastEventUpdate: moment.utc(pickList.createDate, 'ddd MMM DD HH:mm:ss YYYY'),
    commDate,
    decommDate,
    // message data
    tsData: [],
    locData: [],
    tiltShockData: []
  }
  Vue.set(state.shipments.all, payload.id, shipmentData)
}

// skipcq: JS-0044.  Allow Cyclomatic complexity more than allowed
export const setUser = (state, result) => {
  // filterShipments
  const groups = result.groups
  // Get all groups defined for company and collapse custom prefs into base level parameters
  for (const grpName in groups) {
    if (groups[grpName].customPrefs && isJSON(groups[grpName].customPrefs)) {
      groups[grpName] = Object.assign(groups[grpName], JSON.parse(groups[grpName].customPrefs))
    }
  }
  // Get valuse for landingPage, menu and filterShipments based on the first (and only) login group
  // Default values are:
  //   landing page - shipment-dashboard
  //   menu - default
  //   filterShipments - false
  const loginGroups = Object.values(groups).filter(g => g.type.toLowerCase() === 'login') || []
  const landingPage = loginGroups.length > 0 ? loginGroups[0].landingPage : 'shipment-dashboard'
  // Add the user permissions from the login group
  // const permissions = loginGroups.length > 0 && loginGroups[0].hasOwnProperty('permissions') ? loginGroups[0].permissions : []
  const permissions = loginGroups.length > 0 && Object.prototype.hasOwnProperty.call(loginGroups[0], 'permissions') ? loginGroups[0].permissions : []
  const menu = loginGroups.length > 0 ? loginGroups[0].menu : 'default'
  // Check the login group exists for the user and the group has the filterShipments attribute value
  // If it have the filterShipments attribute, then assign it, else set false as default
  // const filterShipments = loginGroups.length > 0 && loginGroups[0].hasOwnProperty('filterShipments') ? loginGroups[0].filterShipments : false
  const filterShipments = loginGroups.length > 0 && Object.prototype.hasOwnProperty.call(loginGroups[0], 'filterShipments') ? loginGroups[0].filterShipments : false
  // Get valuse for reports from the report group
  // Default is empty list
  const reportGroups = Object.values(groups).filter(g => g.type.toLowerCase() === 'report') || []
  const reports = reportGroups.length > 0 && Object.prototype.hasOwnProperty.call(reportGroups[0], 'reports') ? reportGroups[0].reports : []
  // Get name of first group, if any, that has type login
  // const loginGroup = result.groups ? Object.entries(result.groups).filter(grp => grp[1].type.toLowerCase() === 'login') : []
  // let userClass = loginGroup[0] ? loginGroup[0][0] : false
  // console.log('login response: ', userClass, loginGroup)
  // Set default timezone.  Override if one found in config..
  let timezone = 'Etc/GMT'
  let temperatureUnit = 'Fahrenheit'
  let dateFormat = 'default'
  // Process users customPrefs
  let props = {}
  try {
    props = JSON.parse(result.propertyMap.customPrefs)
    // override timezone with props value if it exists
    if (props.timezone) timezone = props.timezone
    if (props.dateFormat) dateFormat = props.dateFormat
    // override temperatureUnit value with props value if it exists
    if (Object.prototype.hasOwnProperty.call(props, 'temperatureUnit')) temperatureUnit = props.temperatureUnit
  } catch (e) {
    // console.log('No Custom Props Set For User')
    // console.log('JSON Parse Error -----> ', err)
  }
  // Add roles based on groups found
  const groupList = result.groupList || Object.keys(result.groups)
  // let roles = ['User', ...result.roles]
  const roles = ['User']
  if (groupList.includes('Provisioner')) { roles.push('Provisioner') }
  if (groupList.includes('Company Administrator')) { roles.push('Company Administrator') }
  if (groupList.includes('Designer')) { roles.push('Designer') }
  // Devine user object
  state.user = {
    companyName: result.companyName,
    dateFormat,
    emailAddress: result.emailAddress,
    filterShipments,
    fullName: result.fullName,
    groups: result.groupList,
    landingPage,
    loginName: result.loginName,
    menu,
    notifyList: result.notifyList,
    phoneNumber: result.phoneNumber,
    permissions,
    propertyMap: result.propertyMap,
    reports,
    roles,
    smsCarrier: result.smsCarrier,
    temperatureUnit,
    timezone,
    userId: result.entryId
  }
}

/**
 * Set list of users in state
 * @param {Object} state - Vuex state
 * @param {Object} userInfo - User info
 */
export const setUsers = (state, userInfo) => {
  Vue.set(state.company, 'users', userInfo)
}

/**
 * Sets the gateway details in state
 * @param {Object} state - Vuex state object
 * @param {Object} gwInfo - Gateway Info
 */
export const setUsersGateways = (state, gwInfo) => {
  Vue.set(state.user, 'gateways', gwInfo)
}

export const setWarVersion = (state, version) => {
  /*
    set device list from setWarVersion action
  */
  state.warVersion = version
}

/**
 * Updates state for existing shipment record on a param by param basis
 * @param {object} state - Vuex state
 * @param {Object} payload - Data payload of shipment data
 */
export const updatePublicShipmentState = (state, payload) => {
  for (const key in payload.data) {
    if (Object.prototype.hasOwnProperty.call(payload.data, key)) {
      Vue.set(state.shipments.all[payload.id], key, payload.data[key])
    }
  }
}

/**
 * Updates state for existing shock data record
 * @param {object} state - Vuex state
 * @param {Object} payload - Data payload of shock data
 */
export const updateShockEvent = (state, payload) => {
  Vue.set(state, 'shockEvent', payload)
}

export const updateUser = (state, payload) => {
  /*
    set user details after updateUser action successfully changes record in db
  */
  for (const key in payload) {
    if (key !== 'propertyMap') {
      Vue.set(state.user, key, payload[key])
    }
  }
  if (payload.propertyMap) {
    const prefs = JSON.parse(payload.propertyMap.customPrefs)
    for (const key in prefs) {
      if (Object.prototype.hasOwnProperty.call(prefs, key)) {
        Vue.set(state.user, key, prefs[key])
      }
    }
  }
}

/**
 * Updates the state object with the chart ckick details
 * @param {Object} state - Vuex state object
 * @param {Object} payload - Payload Object
 */
export const updateChartClickData = (state, payload) => {
  Vue.set(state.chartClick, payload.key, payload.value)
}

/**
 * Update the filter values to vuex filterList state
 * Create a filter type if not exist in vuex filterList state
 * @param {Object} state -  Vuex state
 * @param {Object} payload - Input payload from filters
 */

export const UPDATE_FILTERS = (state, payload) => {
  if (typeof state.filterList[payload.type] === 'undefined' || payload.reset) {
    Vue.set(state.filterList, payload.type, {})
  }
  const filter = payload.filters
  if (filter.length > 0) {
    for (const key in filter) {
      if (typeof filter[key].col !== 'undefined') {
        Vue.set(state.filterList[payload.type], filter[key].col, Object.assign({}, filter[key]))
      }
    }
  }
}

/**
 * Add a Gateway names to vuex chargerGatewayNames state
 * @param {Object} state  -  Vuex state
 * @param {Object} gatewayNames - Response data from chargerAndGatewayNames API
 */

export const setChargerGateways = (state, gatewayNames) => {
  if (gatewayNames) {
    Object.values(gatewayNames).forEach(gateway => {
      Vue.set(state.chargerGatewayNames, gateway.macId, gateway)
    })
  }
}

/**
 * Create/Update the Object values to vuex configuration state
 * @param {Object} state -  Vuex state
 * @param {Object} payload - Input payload from alertGroups
 */

export const SET_CONFIG_OBJECT = (state, payload) => {
  const objType = payload.objType
  if (typeof state.configuration[objType] === 'undefined') {
    Vue.set(state.configuration, objType, {})
  }
  Vue.set(state.configuration[objType], payload.key, Object.assign({}, payload.value))
}

/**
 * Removes the config item from the state
 * @param {Object} state - Vuex state object
 * @param {String} removeItem - Key item to remove
 */
export const removeConfiguration = (state, removeItem) => {
  Vue.delete(state.configuration, removeItem)
}

// add the loaded modules in the state to reset on logging out
export const setInitializedModule = (state, module) => {
  if (!state.initialized.includes(module)) {
    const initializedModules = state.initialized
    initializedModules.push(module)
    Vue.set(state, 'initialized', initializedModules)
  }
}

/**
 * Add any object defined in static/config.json to configuration ofject in state.<br>
 * Called by setFont function in App.vue
 * @param {Object} state - Vuex state
 * @param {Object} config - Configuration object defined in static file config.json
 * found in static folder
*/
export const setMessages = (state, payload) => {
  Vue.set(state.messages, payload.pageName, payload.msg)
}
