<template>
  <intelyt-modal
    :isActive="isActive"
    :title="modalTitle"
    :copyLinkTxt="copyLinkTxt"
    :uniqueId="uniqueId"
    v-on:close="close()"
  >
    <section class="modal-card-body">
      <div class="content">
        <!-- <table class="table table-bordered" v-if="deviceDetail" v-html="currentDevice"></table> -->
        <div v-if="chimeDetails.length > 0">
          <intelyt-list :items="chimeDetails" numColumns=1></intelyt-list>
          <base-chart classname='chart-style-tilt-shock'
                      :options='tiltChartOptions'
                      v-if="showShockTiltChart('tilt')"
                      dblClickHandler='tilt'
                      :showLoadingIcon='showLoadingIcon'
            ></base-chart>
            <base-chart classname='chart-style-tilt-shock'
                      :options='shockChartOptions'
                      v-if="showShockTiltChart('shock')"
                      dblClickHandler='shock'
                      :showLoadingIcon='showLoadingIcon'
            ></base-chart>
          <base-chart classname='chart-style'
                    :options='batteryChartOptions'
                    :showLoadingIcon='showLoadingIcon'
          ></base-chart>
        </div>
        <p v-else v-html="deviceStatusMessage"></p>
      </div>
    </section>
    <template slot="footer">
      <a class="button" @click="close()">Close</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'
import {calcDeviceCharge, round, isBetween, getUrl} from '@/store/helpers.js'
import {chartOptionFactory, scatterChartOptionFactory} from '@/store/factories'
import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment-timezone'

export default {
  components: {
    IntelytModal
  },
  computed: {
    batteryChartOptions: function () {
      // Set variable items for chart
      // console.log('getting battery data')
      const thisState = this.$store.state
      const mapBatt = x => {
        if (typeof x[1][this.deviceDetail.shortMac] !== 'undefined') {
          // Calculate the charge value
          const batValue = calcDeviceCharge(thisState, {voltage: x[1][this.deviceDetail.shortMac].split(',')[0].split(':')[1]})
          return [parseInt(x[0]), round(batValue, 2)]
        }
      }
      const mapCount = x => {
        if (typeof x[1][this.deviceDetail.shortMac] !== 'undefined') {
          const countValue = x[1][this.deviceDetail.shortMac].split(',')[1].split(':')[1]
          return [parseInt(x[0]), parseInt(countValue / 100)]
        }
      }
      let data = this.$store.state.shipments.all[this.guid].chimeData || {}
      const battData = Object.entries(data).map(mapBatt)
      const countData = Object.entries(data).map(mapCount)
      const title = 'Chime Battery Level'
      const subtitle = '' // this.slider.value[0] + ' to ' + this.slider.value[1]
      const yAxis = [
        {name: 'Charge', ymax: 1, ymin: 0},
        {name: 'Connection Index', ymin: 0}
      ]
      const vMapPieces = ['charge']
      let series = [
        {
          name: 'Charge',
          data: battData
        },
        {
          name: 'Connection Index',
          data: countData,
          yAxisIndex: 1
        }
      ]
      // Get chart object
      const options = {subtitle, yAxis, vMapPieces, tooltip: 'datetime', legend: {bottom: 5}}
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      return chartOptionFactory(chartConfig, title, series, options, {}, tmz)
    },
    chimeDetails: function () {
      let chimesDetails = []
      if (Object.values(this.chimeStatus).length > 0) {
        const chimeDetailOpts = {
          macId: {label: 'MAC ID', value: this.deviceDetail.shortMac},
          note: {label: 'SKU', value: this.deviceDetail.note},
          name: {label: 'Name', value: this.chimeStatus.name},
          parent: {label: 'Parent', value: this.inventoryParent(this.chimeStatus)},
          status: {label: 'Status', value: this.chimeStatus.status}
        }
        const fieldList = this.$store.state.configuration.pageOptions.chimeDetailModal.fields || []
        // Loop the each config item. If the config is an object, then overwrite the label value from configuration
        // Else return the config based on the key value
        const mapFn = function (key) {
          if (typeof key === 'object') {
            let column = chimeDetailOpts[key.name]
            column.label = key.label
            return column
          } else {
            return chimeDetailOpts[key]
          }
        }
        chimesDetails = fieldList.map(mapFn)
        if (this.$store.state.configuration.siteOptions.lookupJobParent) {
          chimesDetails.push({label: 'Associated Asset', value: this.associatedAssetLink})
        }
      }
      return chimesDetails
    },
    copyLinkTxt: function () {
      if (!this.deviceDetail && !this.deviceDetail.medMac) {
        return ''
      }
      const macId = `00_1B_AD_00_${this.deviceDetail.medMac}`
      return getUrl(`${this.$route.fullPath}&m=chimeDetail&mac=${macId}`)
    },
    uniqueId: function () {
      return this.deviceDetail.medMac || ''
    },
    tiltChartOptions: function () {
      const title = 'Tilt'
      let minTime = Number(this.chartStart.format('x'))
      let maxTime = Number(this.chartEnd.format('x'))
      const data = this.getTiltShock([moment(this.chartStart), moment(this.chartEnd)], 'tilt')
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      return scatterChartOptionFactory(title, data, minTime, maxTime, {}, tmz)
    },
    shockChartOptions: function () {
      const title = 'Shock'
      let minTime = Number(this.chartStart.format('x'))
      let maxTime = Number(this.chartEnd.format('x'))
      const data = this.getTiltShock([moment(this.chartStart), moment(this.chartEnd)], 'shock')
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      return scatterChartOptionFactory(title, data, minTime, maxTime, {}, tmz)
    },
    ...mapState(['shockEvent']),
    ...mapGetters({
      getShipmentTimeSeries: 'shipments/getShipmentTimeSeries'
    })
  },
  data () {
    let chartStart = moment()
    let chartEnd = moment()
    return {
      associatedAssetLink: '-',
      chartEnd,
      chartStart,
      chimeStatus: {},
      deviceStatusMessage: '',
      modalTitle: 'Chime Detail',
      parentChild: false, // Default assumption - no parent/child relationship
      showLoadingIcon: false
    }
  },
  methods: {
    close () {
      // this.batteryChartOptions = []
      this.$emit('close')
    },
    inventoryParent (res) {
      if (typeof this.$store.state.chargerGatewayNames[res.inventoryLastParent] !== 'undefined') {
        const getParent = this.$store.state.chargerGatewayNames[res.inventoryLastParent]
        return `${getParent.deviceTypeStr} ${res.inventoryLastParent} (${getParent.location})`
      }
      return res.inventoryLastParent
    },
    // Get the associated asset guid for the selected shipment and chime macID
    lookupJobParent: function () {
      this['shipments/lookupJobParent']([`00_1B_AD_00_${this.deviceDetail.medMac}`, this.guid]).then(response => {
        if (response.data && response.data[0]) {
          // Start of shock and tilt charts is commission datetime of the shipment.
          // End is decomm datetime or now if it is not available
          this.chartStart = moment.utc(response.data[0].commDate)
          // set parent/child identifier to true since relationship was found
          this.parentChild = true
          if (response.data[0].decommDate) this.chartEnd = moment.utc(response.data[0].decommDate)
          // build link to assoicated asset
          const guid = response.data[0].guid
          const linkPath = getUrl('/detail?mode=asset&id=') + guid
          this.associatedAssetLink = `<a href="${linkPath}">${guid.substring(0, 8)}</a>`
        }
      }).catch(e => {
        this.associatedAssetLink = `<p class='text-danger'>${e.detail}</p>`
      })
    },
    updateChimeStatus: function () {
      if (typeof this.deviceDetail.medMac !== 'undefined') {
        this['devices/getStatus'](`00_1B_AD_00_${this.deviceDetail.medMac}`).then(response => {
          this.deviceStatusMessage = ''
          this.chimeStatus = response
          // If no Parent/Child relationship use the shipment start and end times for the charts.
          if (this.parentChild) {
            const shipment = this.$store.state.shipments.all[response.guid]
            this.chartStart = moment.utc(shipment.commDate)
            if (shipment.decommDate) this.chartEnd = shipment.decommDate
          }
        }).catch(e => {
          this.chimeStatus = {}
          this.deviceStatusMessage = `<p class='text-danger'>${e.detail}</p>`
        })
      }
    },
    getTiltShock: function (range, type) {
      const mapShock = x => {
        if (typeof x[1][this.deviceDetail.shortMac] !== 'undefined') {
          const shockValue = x[1][this.deviceDetail.shortMac].split(',')[0].split(':')[1]
          return [parseInt(x[0]), round(shockValue, 2)]
        }
      }
      const mapTilt = x => {
        if (typeof x[1][this.deviceDetail.shortMac] !== 'undefined') {
          const tiltValue = x[1][this.deviceDetail.shortMac].split(',')[1].split(':')[1]
          return [parseInt(x[0]), parseInt(tiltValue)]
        }
      }
      // const shockTilt = this.$store.state.shipments.all[this.$route.query.id].tiltShockData
      // return shockTilt.filter(x => x.shockMagnitude > 5)
      let timeSeriesData = []
      let tiltShockData = this.$store.state.shipments.all[this.guid].chimeShockTilt || {}
      timeSeriesData = type === 'tilt' ? Object.entries(tiltShockData).map(mapTilt).filter(x => x && x[1] > 0) : Object.entries(tiltShockData).map(mapShock).filter(x => x && x[1] > 0)
      return timeSeriesData.filter(isBetween(range))
    },
    // Show the chart, that is listed in the configuration
    showShockTiltChart: function (type) {
      return this.chartList && this.chartList.includes(type)
    },
    ...mapActions(['shipments/fetchChimeData','shipments/fetchChimeShockAndTiltData', 'shipments/lookupJobParent', 'devices/getStatus'])
  },
  props: {
    isActive: {
      type: Boolean
    },
    guid: {
      type: String,
      required: true
    },
    chartList: {
      type: [Boolean, Array],
      default: false
    },
    chimeCount: {
      type: Number,
      default: 1
    },
    deviceDetail: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    // whenever selected asset changes, this function will run
    isActive: {
      handler: function (newInput) {
        if (newInput) {
          this.deviceStatusMessage = ''
          const parent = this.deviceDetail.devProps && this.deviceDetail.devProps.parent ? this.deviceDetail.devProps.parent : ''
          if (!this.$store.state.shipments.all[this.guid].chimeData) {
            // modal has been activated, get data...
            const shipment = this.$store.state.shipments.all[this.guid]
            this['shipments/fetchChimeData']([shipment.macId, this.guid, this.chartList, parent])
          }
          if (this.chartList.includes('shock') || this.chartList.includes('tilt')) {
            this['shipments/fetchChimeShockAndTiltData']([this.guid, parent])
          }
          // If the company have the access to lookup the linked asset for the chime,
          // then make the request and get the linked asset id
          if (this.$store.state.configuration.siteOptions.lookupJobParent) {
            this.lookupJobParent()
          }
          this.updateChimeStatus()
        }
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.chart-style {
  height: 300px;
  float: none;
  clear: both;
}
.chart-style-tilt-shock {
  height: 100px;
  float: none;
  clear: both;
}
</style>
