<template>
  <div class="bgimg">
    <div class="content has-text-centered">
      <form method="POST" action="/login" @submit.prevent="login">
        <div class="columns vertical-center pad-top ">
          <div class="column is-4 is-offset-4">
            <div class="box" v-if="loginMode==='standard'">
              <div v-show="error" style="color:red; word-wrap:break-word;">{{ error }}</div>
              <h1 class="is-title is-bold">Login</h1>
              <br>
              <label class="label">Username</label>
              <p class="control">
                <input class="input" v-model="username" placeholder="username" data-test="uname-fld">
              </p>
              <label class="label">Password</label>
              <p class="control">
                <input class="input" autocomplete="off" v-model="password" type="password" placeholder="password" data-test="pword-fld">
              </p>
              <p>
                <!-- <span>{{ errorMessage }}</span> -->
                <span v-html="errorMessage"></span>
                <span><spinner-element class="spnnr" :status="spinnerIsActive" ></spinner-element></span>
              </p>
            <p>&nbsp;</p>
              <p class="control buttons">
                <button type="submit" class="button is-primary" :disabled='disableLoginButton' data-test="subm-btn">Login</button>
                <button type="reset" class="button is-default" @click="resetLoginForm()" data-test="canx-btn">Cancel</button>
              </p>
            <hr>
              <p class="control">
                <a @click="openModal('retrieveUsername')">Recover Username</a>
                &nbsp;&nbsp;
                <a @click="openModal('resetPassword')">Reset Password</a>
                &nbsp;&nbsp;
                <a @click="openModal('verifyPassword')">Verify Password</a>
              </p>
            </div>
            <div class="box" v-if="loginMode==='sso'" style="height: 300px;">
              <h1 class="is-title is-bold">Login</h1>
              <p style="text-align:center;">
                <!-- <span>{{ errorMessage }}</span> -->
                <span v-html="errorMessage"></span>
              </p>
              <!-- <div class="is-align-items-center" style="height:200px;"></div> -->
              <div style="position: absolute;top: 50%;left: 48%;">
                <button :disabled="!ssoEnabled" @click.prevent.stop="loginSSO()" class="button is-primary">Login</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <verify-password-modal
      :isActive="modalIsActive.verifyPassword"
      v-on:close="closeModal('verifyPassword')"
      v-on:verifyCreds="verifyPassword"
    >
    </verify-password-modal>
    <reset-password-modal
      :isActive="modalIsActive.resetPassword"
      v-on:close="closeModal('resetPassword')"
    >
    </reset-password-modal>
    <retrieve-username-modal
      :isActive="modalIsActive.retrieveUsername"
      v-on:close="closeModal('retrieveUsername')"
    >
    </retrieve-username-modal>
  </div>

</template>

<script>
import VerifyPasswordModal from '@/modals/VerifyPasswordModal'
import ResetPasswordModal from '@/modals/ResetPasswordModal'
import RetrieveUsernameModal from '@/modals/RetrieveUsernameModal'
import SpinnerElement from '@/components/SpinnerElement'
import {mapActions, mapGetters, mapState} from 'vuex'
import { modalWindowManager } from '@/store/mixins'
import {getUrl, ssoLogin} from '../../src/store/helpers.js'

export default {
  components: {
    VerifyPasswordModal,
    ResetPasswordModal,
    RetrieveUsernameModal,
    SpinnerElement
  },
  computed: {
    errorMessage: function () {
      if (this.auth.error.message) {
        // return `${this.getAuth.error.message} (${this.getAuth.error.code})`
        return `${this.auth.error.message}`
      } else {
        return ''
      }
    },
    disableLoginButton: function () {
      return (this.username.length === 0 || this.password.length === 0)
    },
    loginMode: function () {
      // If the query param have code, then the mode is sso
      // Return it early
      if (this.$route.query.code) return 'sso'
      const mode = this.$route.query.mode || false
      // If sso config found for the host and the mode query param is not available
      // The mode will be sso. Otherwise considered as standard login page mode
      return this.ssoConfig && !mode ? 'sso' : 'standard'
    },
    ssoConfig: function () {
      return this.$store.state.configuration.loginRedirect
    },
    // spinner active after first login - if no error is present
    spinnerIsActive: function () {
      return !this.firstAttempt && this.authError.length === 0
    },
    ...mapGetters([
      'getAuth',
      'isLoggedIn'
    ]),
    ...mapState({
      auth: 'auth',
      authError: state => state.auth.error
    })
  },
  created () {
    const code = this.$route.query.code || false
    // If the host config is not initialized, then initialize
    if (!this.$store.state.configuration.hostConfiginitialized) this.setHostConfig()
    // If the code value is found in the URL, then make the login request with the code
    if (code) {
      // Set the login mode as sso
      // this.loginMode = 'sso'
      // Disable the button
      this.ssoEnabled = false
      // Prepare the redirect URI path
      const redirectUri = getUrl('/login')
      const clientId = window.location.host.split('.')[0]
      // Make the login request with the payload
      this.$store.dispatch('login', {code, clientId, redirectUri})
    }
    // Get the logged out value from local storage
    // The logged value set to true, during the logout process
    // After logout the page will be redirected to the login page(Current page)
    // If the loggedOut value is true, the set it's value as false to prevent infinite loop
    // Then delete the local storage to get the initial state values
    // Then refresh the page to load the initial state values
    const loggedOut = localStorage.getItem('loggedOut')
    if (loggedOut === 'true') {
      localStorage.setItem('loggedOut', false)
      localStorage.removeItem('intelyt')
      window.location.reload()
    }
  },
  data () {
    return {
      username: '',
      password: '',
      rememberMe: false,
      error: null,
      modalIsActive: {
        resetPassword: false,
        retrieveUsername: false,
        verifyPassword: false
      },
      resetPasswordModalIsActive: false,
      retrieveUsernameModalIsActive: false,
      verifyPasswordModalIsActive: false,
      firstAttempt: true,
      ssoEnabled: true
    }
  },
  methods: {
    login () {
      this.firstAttempt = false
      this.$store.dispatch('login', {...this.$data})
      this.password = ''
    },
    resetLoginForm () {
      const statestore = this.$store
      this.username = ''
      this.password = ''
      statestore.state.initialized.forEach(module => {
        console.log('Resetting: ', module)
        statestore.commit(`${module}/RESET_STATE`)
      })
      statestore.commit('clearState')
      statestore.commit('setAuthError', ' ')
      console.log('Reset and logout')
      // this.$store.dispatch('resetFrm')
    },
    loginSSO () {
      const ssoConfig = this.ssoConfig
      // If sso config not found, don't proceed
      if (!ssoConfig) return
      // this.$store.dispatch('ssoLogin', ssoConfig)
      ssoLogin(ssoConfig)
    },
    verifyPassword: function () {
      this.verifyPasswordModalIsActive = false
    },
    async setHostConfig () {
      // Get the config json file from src
      const element = document.getElementById('hostConfig')
      const src = element.src
      // Read the config data
      const response = await fetch(src)
      const config = await response.json()
      // If atleast one overridable value found, the initialize the override process
      if (Object.keys(config).length > 0) {
        this.$store.dispatch('overrideHostConfig', config)
      }
    },
    ...mapActions([
      'logout'
    ])
  },
  mixins: [modalWindowManager]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.bgimg {
  background-image: url('/static/images/world-map-blue.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.is-title {
  text-transform: capitalize;
}
.spnnr {
  display:block;
  margin-left: auto;
  margin-right: auto;
}
.pad-top {
  padding: 100px;
}
</style>
