<template>
  <intelyt-modal
    :isActive="isActive"
    :title="title"
    v-on:close="close()"
  >
    <div class="content">
      <div class="field" v-if="Object.keys(helpContent).length > 0">
        <div class="select">
          <select v-model="helpKey">
            <option value="">Select Help Option</option>
            <option v-for="optKey in Object.keys(helpContent)" :key="optKey" :value="optKey"> {{ helpContent[optKey].question }}</option>
          </select>
        </div>
        <!-- {{ helpContent[helpKey].content }} -->
        <div class="help-content" v-if="helpKey && helpContent && helpContent[helpKey] && helpContent[helpKey].answer">
          <!-- skipcq: JS-0693.  Allow v-html -->
          <div class="output" v-html="helpContent[helpKey].question"></div>
          <!-- skipcq: JS-0693.  Allow v-html -->
          <div class="output" v-html="helpContent[helpKey].answer"></div>
        </div>
      </div>
    </div>
    <template slot="footer">
      <a class="button" @click="close()">Close</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'

export default {
  name: 'HelpModal',
  components: { IntelytModal },
  data () {
    return {
      helpContent: {},
      helpKey: ''
    }
  },
  emits: ['close'],
  methods: {
    close () {
      this.helpKey = ''
      this.$emit('close')
    }
  },
  mounted () {
    // const modFile = new File([""], '../../static/help/help00.js')
    // console.debug('file', modFile)
    const vm = this
    import('../../static/help/help').then(module => {
      const sortable = Object.entries(module.helpContent())
        .sort((a, b) => (a.order > b.order) ? -1 : 1)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
      vm.helpContent = sortable
      // vm.helpContent = module.helpContent()
      // .sort((a, b) => (a.order > b.order) ? 1 : -1)
    })
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Help'
    }
  },
  watch: {
    helpContent: {
      handler (helpObj) {
        this.helpKey = Object.keys(helpObj).length > 0 ? Object.keys(helpObj)[0] : ''
      }
    }
  }
}
</script>

<style>

.output {
  margin-top: 10px;
}

</style>