<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      :width="width"
      v-on:close="close()"
    >
      <form id="manageLocation">
        <div class="columns">
          <div class="column">
            <div class="block">
              <div class="control-label">
                <label class="label">Name</label>
              </div>
              <div class="control">
                <!-- <ValidationProvider name="Name" :rules="{required: true, regex: /^([A-Z]|[a-z]|[0-9]|\s)+$/}" v-slot="{ errors }"> -->
                <ValidationProvider name="Name" :rules="{required: true}" v-slot="{ errors }">
                  <input style="width:75%" class="input" type="text" v-model="locationData.name"/>
                  <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="block">
              <div class="control-label">
                <label class="label">Address 1 </label>
              </div>
              <div class="control">
                <input style="width:75%" class="input" type="text" v-model="locationData.locAddress1"/>
              </div>
            </div>
            <div class="block">
              <div class="control-label">
                <label class="label">City</label>
              </div>
              <div class="control">
                <input style="width:75%" class="input" type="text" v-model="locationData.locCity"/>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="block">
              <div class="control-label">
                <label class="label">Type</label>
              </div>
              <div class="control">
                  <div class="select">
                    <select v-model="locationData.locType" name="locType">
                      <option value="">-- Select Type --</option>
                      <option v-for="(location, index) in locationTypes" :value="location" :key="index">{{location}}</option>
                    </select>
                  </div>
              </div>
            </div>
            <div class="block">
              <div class="control-label">
                <label class="label">Address 2</label>
              </div>
              <div class="control">
                <input style="width:75%" class="input" type="text" v-model="locationData.locAddress2"/>
              </div>
            </div>
            <div class="block">
              <div class="control-label">
                <label class="label">State</label>
              </div>
              <div class="control">
                <input style="width:75%" class="input" type="text" v-model="locationData.locState"/>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="block">
              <div class="control-label">
                <label class="label">Region</label>
              </div>
              <div class="control">
                <input style="width:75%" class="input" type="text" v-model="locationData.locRegion"/>
              </div>
            </div>
            <div class="block">
              <div class="control-label">
                <label class="label">Country</label>
              </div>
              <div class="control">
                <input style="width:75%" class="input" type="text" v-model="locationData.locCountry"/>
              </div>
            </div>
            <div class="block">
              <div class="control-label">
                <label class="label">Zip</label>
              </div>
              <div class="control">
                <input style="width:75%" class="input" type="text" v-model="locationData.locZip"/>
              </div>
            </div>
          </div>
        </div>
        <div class="block" v-show="locPoints.length === 0">
          <label class="label">Add Layout</label>
            <p class="control" >
              <gmap-autocomplete
                class="input"
                :value="locationString"
                @place_changed="setLocation"
                placeholder="Enter Location Address">
              </gmap-autocomplete>
            </p>
        </div>
        <div class="block">
          <intelyt-map
            :markers="markers"
            :polygons="polygons"
            :mapWidth="950"
            :zoom="locPoints.length > 0 ? 20 : 5"
            :zoomFixed="true"
            @polygon_changed="updatePolygon($event)"
            @marker_moved="updateMarkerPosition($event)"
          >
          </intelyt-map>
        </div>
      </form>
      <template slot="footer">
        <div>
          <div class="content" v-if="errMsgTxt.length > 1">
            <p class="has-text-danger">Error: {{errMsgTxt}}</p>
          </div>
          <div>
            <a class="button is-primary" v-show="buttonEnabled" @click="updateLocation()" :disabled="invalid">Update</a>
            <a class="button" @click="close()">Cancel</a>
          </div>
        </div>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'
import {isJSON, centroid, round} from '@/store/helpers.js'
import moment from 'moment-timezone'
import {mapActions} from 'vuex'

export default {
  components: {
    IntelytModal
  },
  computed: {
    title: function () {
      return this.locationData.name ? `Manage Location - ${this.locationData.name}` : 'Manage Location'
    },
    markers: function () {
      if (!this.locationData.latitude) {
        return []
      }
      let marker = {
        id: 0,
        icon: 'geofence',
        name: this.locationData.name,
        draggable: true,
        position: {
          lat: Number.parseFloat(this.locationData.latitude),
          lng: Number.parseFloat(this.locationData.longitude)
        },
        infoText: `Name: ${this.locationData.name}`,
        timestamp: 0
      }
      return [marker]
    },
    polygons: function () {
      console.log('this.locationData', this.locationData)
      const options = {
        geodesic: true,
        strokeColor: '#3399ff',
        fillColor: '#3399ff',
        strokeOpacity: 1,
        strokeWeight: 2,
        icons: []
      }
      return {editable: true, paths: [this.locPoints], options}
    }
  },
  data () {
    const locations = this.$store.state.locations.all
    const locationTypes = ['Airport', 'Destination', 'Location', 'Office', 'Origin', 'Warehouse']
    return {
      errMsgTxt: '',
      buttonEnabled: true,
      locationString: '',
      locationTypes,
      locationData: {},
      locPoints: [],
      mapConfigType: 5,
      width: 1000,
      locations
    }
  },
  methods: {
    close () {
      this.$refs.observer.reset()
      this.locPoints = []
      this.locationString = ''
      this.$emit('close')
    },
    setLocation (place) {
      // Called when new address is entered into form - sets up default diamond shape to
      // give user a starting place to begin editing
      const offsets = [[0, 0.0005], [0.0005, 0], [0, -0.0005], [-0.0005, 0]]
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      this.locationString = place.formatted_address
      this.locPoints = offsets.map(ofst => { return {lat: lat + ofst[0], lng: lng + ofst[1]} })
    },
    updateMarkerPosition (position) {
      // Update the location object with returned lat and lng values
      this.locationData.latitude = round(position.lat, 5)
      this.locationData.longitude = round(position.lng, 5)
    },
    updateLocation () {
      const vm = this
      this.$refs.observer.validate().then(result => {
        if (result) {
          const zoneTypeConfig = Object.values(this.$store.state.configuration.geozoneTypes).filter(type => parseInt(this.mapConfigType) === type.typeId)[0]
          const notes = isJSON(vm.locationData.notes) ? JSON.parse(vm.locationData.notes) : {}
          const updateLocationDetails = function (locId = '') {
            // If location id is found, update the values in the notes value in location object
            if (locId !== '') {
              notes.locId = locId
              vm.locationData.notes = JSON.stringify(notes)
            }
            vm.$store.dispatch('locations/updateLocation', vm.locationData).then(() => {
              vm.$emit('close')
            }).catch(e => {
              const errObj = e.response.data.errors[0]
              vm.errMsgTxt = `${errObj.code}: ${errObj.detail}`
            })
          }
          // If there is no coordinates, update the location details alsone and close the modal
          if (this.locPoints.length === 0) {
            updateLocationDetails('')
            return
          }
          // If the coordinates found, create or update the geofence
          const fenceId = notes && notes.locId ? notes.locId : ''
          const fence = fenceId && vm.$store.state.geofences.all[fenceId] ? vm.$store.state.geofences.all[fenceId] : {}
          const pointIdList = vm.locPoints.map(pt => {
            return {lat: round(pt.lat, 7), lng: round(pt.lng, 7)}
          })
          const createdDate = moment.utc().format('ddd MMM D HH:mm:ss YYYY')
          let center = centroid(pointIdList)
          // State payload to update values in state
          let statePayload = {
            center: center,
            coordinates: pointIdList,
            createDate: createdDate,
            description: fence.description ? fence.description : vm.locationData.name,
            enabled: fence.enabled ? fence.enabled : 1,
            associations: fence.associations ? fence.associations : [],
            associationList: fence.associationList ? fence.associationList : [],
            alternateNames: fence.alternateNames ? fence.alternateNames : '',
            lastUpdate: createdDate,
            mapId: fenceId,
            mapConfigNameType: fence.mapConfigNameType ? fence.mapConfigNameType : 'Geozone',
            mapConfigTypeId: fence.mapConfigTypeId ? fence.mapConfigTypeId : 5,
            notes: fence.notes ? fence.notes : '',
            pointIdList: fence.pointIdList ? fence.pointIdList : '',
            name: fence.name ? fence.name : vm.locationData.name,
            class: fence.class ? fence.class : 'fence',
            status: fence.status ? fence.status : 'Enabled',
            pointCount: pointIdList.length,
            initialized: true
          }
          let mappedPoints = []
          if (vm.locPoints.length > 0) {
            mappedPoints = vm.locPoints.map(pt => {
              return {latitude: round(pt.lat, 7), longitude: round(pt.lng, 7), pointType: zoneTypeConfig.pointType}
            })
          }
          // Updatepayload to create/update the values in DB
          const updatePayload = {
            mapName: fence.name ? fence.name : vm.locationData.name,
            companyName: vm.$store.state.company.name,
            mapId: fenceId,
            map: {
              description: fence.description ? fence.description : '',
              subMapID: fence.subMapID ? fence.subMapID : [],
              altNames: fence.altNames ? fence.altNames : [],
              mapConfigNameType: fence.mapConfigNameType ? fence.mapConfigNameType : 'Geozone',
              mapConfigTypeId: fence.mapConfigTypeId ? fence.mapConfigTypeId : 5,
              notes: fence.notes ? fence.notes : '',
              points: mappedPoints,
              enabled: true
            }
          }
          const isNew = (fenceId === '')
          this.editRouteMap([isNew, updatePayload, statePayload]).then(response => {
            updateLocationDetails(response.mapId)
          }).catch(e => {
            console.error('ERROR: ', e)
            updateLocationDetails('')
          })
        }
      })
    },
    updatePolygon (points) {
      this.locPoints = points
    },
    ...mapActions({
      editRouteMap: 'geofences/editRouteMap'
    })
  },
  props: ['isActive', 'selectedLocation'],
  watch: {
    isActive: function (active) {
      // Reset the error msg text
      this.errMsgTxt = ''
      if (!active) {
        return
      }
      this.locationData = {...this.selectedLocation}
      this.mapConfigType = this.locationData.mapConfigType ? this.locationData.mapConfigType : 5
      const notes = isJSON(this.locationData.notes) ? JSON.parse(this.locationData.notes) : {}
      const fenceId = notes && notes.locId ? notes.locId : false
      if (!fenceId) {
        return
      }
      const fence = fenceId ? this.$store.state.geofences.all[fenceId] : {}
      this.locPoints = fence && fence.coordinates ? fence.coordinates : []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
