<template>
    <div class="card" v-show="isVisible"  v-click-outside="closeCardOptions">
      <header class="card-header" >
        <p class="card-header-title">
          <slot name="header">Default Title</slot>
          <span class="subheader"><slot name="subheader"></slot></span>
        </p>
        <div class="card-header-icon" v-show="cardOptions.length > 0 && isExpanded">
          <!-- is-active class used to auto open on dropdown -->
          <div class="dropdown nomarginleft" v-bind:class="{ 'is-active': isCardOptActive}">
            <div class="dropdown-trigger">
              <base-icon name="details" @click="toggleCardOptions" v-tooltip="'Options'" aria-haspopup="true" aria-controls="dropdown-menu" />
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <ul class="dropdown-content">
                <li class="dropdown-item" v-for="item of cardOptions" @click="cardOptionCallback(item)" :key="item.text">
                 <base-icon :name="item.icon" :size="18"></base-icon> {{item.text}}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-header-icon" v-show="reportOptions.length > 0 && isExpanded">
          <!-- is-active class used to auto open on dropdown -->
          <div class="dropdown nomarginleft" v-bind:class="{ 'is-active': showReportOptions}">
            <div class="dropdown-trigger">
              <base-icon name="report" @click="toggleReportOptions" v-tooltip="'Reports'" aria-haspopup="true" aria-controls="dropdown-report-menu" />
            </div>
            <div class="dropdown-menu" id="dropdown-report-menu" role="menu">
              <ul class="dropdown-content">
                <li class="dropdown-item" v-for="item of reportOptions" @click="cardOptionCallback(item)" :key="item.text">
                 <base-icon :name="item.icon" :size="18"></base-icon> {{item.text}}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-header-icon" v-show="isExpanded">
          <slot name="controls"></slot>
        </div>
        <a class="card-header-icon" @click="isExpanded = ! isExpanded" v-show="!isExpanded">
          <span class="icon">
            <base-icon name="down" size="18" />
          </span>
          </a>
          <a class="card-header-icon" @click="isExpanded = ! isExpanded" v-show="isExpanded">
          <span class="icon">
            <base-icon name="up" size="18" />
          </span>
        </a>
      </header>
      <div class="card-content" :style="styleObject" v-show="isExpanded">
        <div class="content">
          <slot></slot>
        </div>
      </div>
      <footer class="card-footer" v-show="isExpanded">
        <slot name="footer"></slot>
      </footer>
    </div>
</template>

<script>
export default {
  computed: {
    styleObject: function () {
      let styles = {}
      if (this.scrolling) {
        // styles = {
        //   'max-height': this.maxHeight,
        //   'overflow-y': 'scroll'
        // }
        if (this.maxHeight) {
          styles['max-height'] = this.maxHeight
          styles['overflow-y'] = 'scroll'
          // If the maxHeight property is set then remove the padding for
          // the header to fix the content visibilty style issue behind the cards.
          styles['padding-top'] = '0px'
        }
        if (this.maxWidth) {
          styles['max-width'] = this.maxWidth
          styles['overflow-x'] = 'scroll'
        }
      }
      return styles
    }
  },
  methods: {
    toggleCardOptions () {
      // to toggle the card options, when clicking the icon
      this.isCardOptActive = !this.isCardOptActive
    },
    toggleReportOptions () {
      // to toggle the card options, when clicking the icon
      this.showReportOptions = !this.showReportOptions
    },
    cardOptionCallback (item) {
      this.closeCardOptions()
      item.optionArgs.callbackFn(item.optionArgs.params)
    },
    closeCardOptions: function () {
      // to hide the card dropdown options on clicking outside
      this.isCardOptActive = false
      this.showReportOptions = false
    },
    closeReportOptions: function () {
      // to hide the card dropdown options on clicking outside
      this.showReportOptions = false
    }
  },
  data () {
    return {
      isVisible: true,
      isExpanded: true,
      isCardOptActive: false,
      showReportOptions: false
    }
  },
  props: {
    body: null,
    maxHeight: {
      type: String,
      default: '100px'
    },
    maxWidth: {
      type: String,
      default: ''
    },
    cardOptions: {
      type: [Array, Boolean],
      default: false
    },
    reportOptions: {
      type: [Array, Boolean],
      default: false
    },
    scrolling: {
      type: Boolean,
      default: false
    },
    title: String
  },
  directives: {
    'click-outside': {
      bind: function (el, binding) {
        // Define Handler and cache it on the element
        const handler = (e) => {
          if (!el.contains(e.target) && el !== e.target) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        // add Event Listeners
        document.addEventListener('click', handler)
      },
      unbind: function (el) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.subheader {
  padding-left: 4px;
  opacity: 0.7;
  font-weight: normal;
  font-size: 80%;
}
.nomarginleft
{
  margin-left: 0;
  z-index: inherit;
}
.card {
  margin-bottom: 15px;
}
.card-content {
  padding: 0.5rem;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(128,128,128,0.8);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(196,196,196,0.4);
}

</style>
