import {
  getTableEntriesProvider,
  getQueryProvider,
  updateTableEntryProvider
} from '../providers'
import moment from 'moment-timezone'
import Vue from 'vue'

/**
* Inital State
* adding origin and destination pins.
* @param {object} all - object containing all initialized locations. Key is id
* @parameter {Array} offline - list of names of any locations with offline
* iGates. Set in gateway initiaization
*/
// initial state
const getDefaultState = () => {
  return {
    all: {}
  }
}

const state = getDefaultState()

// getters
const getters = {
  chargersAndGates: (state, getters, rootState) => (locId, devType) => {
    // If counts are not yet loaded, bail out with result 0
    if (Object.keys(rootState.devices.counts).length === 0) return 0
    // Define filter function to filter by lcoation id
    const fltr = x => { return x.location === locId }
    // get array of chargers and gateways
    let count = 0
    const chgAtLoc = Object.values(rootState.devices.chargers.all).filter(fltr).map(x => x.macId)
    const gatesAtLoc = Object.values(rootState.devices.gateways.all).filter(fltr).map(x => x.macId)
    const allDevs = [...gatesAtLoc, ...chgAtLoc]
    // console.log('ALL DVS: ', allDevs, devType, locId)
    // Iterate over devices and add up sum of device for specified type
    allDevs.forEach(mac => {
      // console.log('MAC: ', mac, rootState.devices.counts)
      if (rootState.devices.counts[mac]) {
        count += parseInt(rootState.devices.counts[mac][devType] || 0)
      } else {
        console.log('Did not find: ', mac)
      }
    })
    return count
  },
  chimeCount: (state, getters) => (locId) => {
    return getters.chargersAndGates(locId, '2')
  },
  tagCount: (state, getters) => (locId) => {
    return getters.chargersAndGates(locId, '1')
  }
}

// actions
const actions = {
  async initializeAll ({ commit, state, dispatch, rootState }, {force = false} = {}) {
    // Execute the function, when the state have no value or forced to initialize again
    if (!force && Object.values(state.all).length !== 0) {
      return
    }
    // add the module in the initialized modules array in state to reset on logging out
    if (!rootState.initialized.includes('locations')) {
      commit('setInitializedModule', 'locations', { root: true })
    }
    const locationsPayload = {
      tableName: 'shippingLocations',
      where: `enabled = 1`,
      fields: []
    }
    getTableEntriesProvider(rootState, locationsPayload).then(response => {
      const mapFn = x => x
      commit('SET_LOCATIONS', Object.values(response.data).map(mapFn))
      if (rootState.configuration.siteOptions.metrics.includes('avgCommTime')) dispatch('calculatAverageSecondsToCommission')
    }).catch(e => {
      console.log(e)
    })
  },
  calculatAverageSecondsToCommission ({commit, rootState}) {
    const payload = {
      query: 'avgSecondsToCommission',
      params: ['100', String(rootState.company.id)]
    }
    getQueryProvider(rootState, payload).then(response => {
      Object.values(response.data).forEach(data => {
        commit('SET_AVG_SEC_TO_COMM', {avg: data.diff, id: data.location})
      })
    }).catch(e => {
      console.log('calculatAverageSecondsToCommission error', e)
    })
  },
  /**
   * Update the given location details. After updating the location
   * retrieve the detail and update the state.
   * @param {object} state - Vuex state
   * @param {object} payload - Input payload for the location
   * @returns {Promise} Promise object
   *
  */
  updateLocation ({commit, rootState}, payload) {
    const tableName = 'shippingLocations'
    let updateObj = {
      lastUpdate: {
        type: 'date_time',
        value: moment.utc().format('YYYY-MM-DD HH:mm:ss')
      },
      name: {
        type: 'string',
        value: String(payload.name)
      },
      latitude: {
        type: 'string',
        value: String(payload.latitude)
      },
      locAddress1: {
        type: 'string',
        value: String(payload.locAddress1)
      },
      locAddress2: {
        type: 'string',
        value: String(payload.locAddress2)
      },
      locCity: {
        type: 'string',
        value: String(payload.locCity)
      },
      locState: {
        type: 'string',
        value: String(payload.locState)
      },
      locZip: {
        type: 'string',
        value: String(payload.locZip)
      },
      locCountry: {
        type: 'string',
        value: String(payload.locCountry)
      },
      locRegion: {
        type: 'string',
        value: String(payload.locRegion)
      },
      locType: {
        type: 'string',
        value: String(payload.locType)
      },
      longitude: {
        type: 'string',
        value: String(payload.longitude)
      },
      notes: {
        type: 'string',
        value: String(payload.notes)
      }
    }
    const updateData = {
      id: parseInt(payload.entryId),
      data: updateObj
    }
    // send update for shippingLocations
    return new Promise((resolve, reject) => {
      updateTableEntryProvider(rootState, tableName, updateData)
        .then(response => {
          resolve(response)
          const reqPayload = {
            tableName: 'shippingLocations',
            where: `entryId = ${parseInt(payload.entryId)}`,
            fields: [],
            sort: 'entryId DESC'
          }
          getTableEntriesProvider(rootState, reqPayload).then(res => {
            commit('SET_LOCATIONS', [res.data[0]])
            resolve(response)
          })
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }
}

// mutations
const mutations = {
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  SET_AVG_SEC_TO_COMM (state, payload) {
    Vue.set(state.all[payload.id], 'avgSecToComm', payload.avg)
  },
  SET_LOCATIONS (state, locations) {
    console.log('LOCATION DATA: ', locations)
    const genRegexObj = /(\w+:[\w .-]+)[\f\n\r]?/g
    const isJSON = function (str) {
      try {
        return (JSON.parse(str) && !!str)
      } catch (e) {
        return false
      }
    }
    for (let loc of locations) {
      // Add any proerties from Notes field
      if (loc.notes.length > 0) {
        if (isJSON(loc.notes)) {
          console.log(' IS JSON: ', loc.name, loc.notes, JSON.parse(loc.notes))
          Object.assign(loc, JSON.parse(loc.notes))
        } else if (genRegexObj.test(loc.notes)) {
          genRegexObj.lastIndex = 0
          for (const entry of loc.notes.match(genRegexObj)) {
            const values = entry.split(':')
            loc[values[0]] = values[1].trim()
          }
        }
      }
      // If customPrefs exists and it is a JSON string take the mapOverlay property
      // from the custompref. If mapOverlay property exists, then apply it in location object
      Vue.set(state.all, loc.shippingLocId, loc)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
