<template>
  <div>
    <level-bar>{{name}}</level-bar>
    <intelyt-card>
      <template slot="header">Users</template>
        <base-table
          :data="getUsers()"
          :columns="columns"
          :options="options"
        >
        </base-table>
      </intelyt-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      company: 'company'
    })
  },
  created () {
    // this.fetchUsers()
  },
  data () {
    return {
      name: 'User Administration',
      columns: [
        {label: 'Username', id: 'loginName'},
        {label: 'Full Name', id: 'fullName'},
        {label: 'Email Address', id: 'email'},
        {label: 'Phone', id: 'phone'},
        {label: 'Active', id: 'enabled'},
        {label: 'Notification Prefs', id: 'notifyString'},
        {label: 'Timezone', id: 'timezone'},
        {label: 'Props', id: 'propertyMap'}
      ],
      options: {
        paginate: false
      },
      legendIcons: ['current', 'origin'],
      latitude: 53.4,
      longitude: -28,
      radius: 2,
      zoom: 13
    }
  },
  methods: {
    getUsers () {
      let users = []
      for (let user of this.company.users) {
        let prefs = user.propertyMap.customPrefs ? JSON.parse(user.propertyMap.customPrefs) : {}
        user.timezone = prefs.timezone
        user.notifyString = user.notifyList.join()
        users.push(user)
      }
      return users
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
