<template>
  <intelyt-modal
    :isActive="isActive"
    :title="title"
    v-on:close="close()"
  >
  <div class="tabs is-boxed">
    <ul>
      <li v-for="(tab, idx) in filterTabs" :key="idx" :class="{'is-active': (activeTab === idx)}"><a @click="activateTab(idx)">{{tab.name}}</a></li>
    </ul>
  </div>
  <div class="content">
    <div v-for="(tab, idx) in filterTabs" :key="idx" :id="idx" class="content-tab" v-show="activeTab === idx">
      <div class="field-group">
        <div class="field">
          <p v-html="tab.description"></p>
          <input class="input" :class="{'input': true}" type="text" v-model="tab.value" v-on:keyup="checkForResults(tab.checkLength)" />
        </div>
      </div>
    </div>
  </div>
    <div class="content">
      <div class="filterlsit">
        <table border="0" width="100%" v-if="showQueryResults">
          <tr v-for="result in queryResults" :key="result.id" :data-id="result.guid" @click="openDetails(result)" class="shipmentRow">
            <td>{{result.clientShipmentId}} [{{result.shortMacId}}] {{result.origin}} -> {{result.dest}} - {{result.createDate | shipmentDateFormat}}</td>
          </tr>
        </table>
      </div>
      <div v-show="searchStatus.length > 0">
        {{searchStatus}}
      </div>
    </div>
    <template slot="footer">
      <a class="button" @click="close()">Cancel</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'
import {shortDate} from '@/store/formatters.js'
import {getDateFormat} from '@/store/helpers.js'
import router from '../router'
import moment from 'moment-timezone'

export default {
  components: {
    IntelytModal
  },
  data () {
    /**
     * Field config is used to define the tab configuration for the given tabConfig property.
     * The tabConfig keys will be iterated to get the tab configuration.
     */
    const filedConfig = {
      'customData': {checkLength: 3, description: 'Enter at least 4 characters of the field.', query: {type: 'table', where: `customData LIKE '%{searchValue}%' AND companyId = '${this.$store.state.company.id}' AND`, max: 50}},
      'customData2': {checkLength: 3, description: 'Enter at least 4 characters of the field.', query: {type: 'table', where: `customData2 LIKE '%{searchValue}%' AND`, max: 50}},
      'iChime': {checkLength: 4, description: 'Enter last characters of the device ID (i.e. 39_01)', query: {type: 'query', queryName: 'shipmentsByChimeId', max: 50}},
      'macId': {checkLength: 4, description: 'Enter last characters of the device ID (i.e. 12_24)', query: {type: 'table', where: `macId LIKE '%{searchValue}%' AND`, max: 50}},
      'customData3': {checkLength: 3, description: 'Enter at least 4 digits of the Tote ID', query: {type: 'table', where: `customData3 LIKE '%{searchValue}%' AND`, max: 50}},
      'repoITag': {checkLength: 4, description: 'Enter last characters of the device ID (i.e. 12_24)', query: {type: 'query', queryName: 'FindRepoShpTagsAndChimes', max: 50, queryParams: [String(this.$store.state.company.id), '1']}},
      'repoIChime': {checkLength: 4, description: 'Enter last characters of the device ID (i.e. 39_01)', query: {type: 'query', queryName: 'FindRepoShpTagsAndChimes', max: 50, queryParams: [String(this.$store.state.company.id), '2']}}
    }
    const filterTabs = {}
    const config = {...this.tabConfig}
    const fieldList = Object.keys(config) // Tab keys to get the configuration from the fieldConfig object
    fieldList.forEach((fieldName) => {
      const tabItem = filedConfig[fieldName]
      tabItem.name = config[fieldName] // Assign the label from the page configuration
      tabItem.value = '' // Default value will be blank for all the fields
      filterTabs[fieldName] = tabItem
    })
    return {
      queryResults: [],
      searchStatus: '',
      dataInterval: '',
      searchStr: '',
      showQueryResults: false,
      activeTab: fieldList[0],
      defaultTab: fieldList[0],
      searchiTagStr: '',
      searchiChimeStr: '',
      filterTabs: filterTabs
    }
  },
  filters: {
    /**
     * Convert a date to custom date format
     * @param {date} Date - Date input
     * @returns - Return the converted date in 'DD-MMM-YY' format
     */
    shipmentDateFormat (date) {
      const shpData = moment(date, 'ddd MMM D HH:mm:ss YYYY')
      return date ? shortDate(shpData, getDateFormat()) : ''
    }
  },
  methods: {
    /**
     * Activate the clicked tab and show it's content
     * @param {tabId} String - Clicked tab id of the tab
     * Note: Check current tab is activated or not. If not then active the tab
     * Reset the tab fields while change the tab
     */
    activateTab (tabId) {
      if (this.activeTab !== tabId) {
        this.filterTabs[this.activeTab].value = '' // Clear previous active tab field value
        this.activeTab = tabId
        this.resetFields()
      }
    },
    /**
     * Search and list out the shipments based on shipment id or device id or chime id
     * @param {e} Event - Event of entering input text field
     * Get the shipment list after 1 seconds if search value is above 3 character
     */
    checkForResults (checkLen = 3) {
      const txtval = this.filterTabs[this.activeTab].value.trim()
      this.filterTabs[this.activeTab].value = txtval
      // this[refs] = txtval
      const self = this
      this.searchStatus = ''
      this.queryResults = []
      // ClearTimeout for previous runQuery
      if (this.dataInterval) {
        this.searchStatus = ''
        clearTimeout(this.dataInterval)
      }
      if (txtval.length > checkLen) {
        this.searchStatus = 'Searching ...'
        // Active runQuery after 1 seconds
        this.dataInterval = setTimeout(function () {
          // self.searchStatus = ''
          self.runQuery(txtval)
          self.showQueryResults = true
        }, 1000)
      } else {
        this.searchStatus = ''
        this.showQueryResults = false
      }
    },
    /**
     * Reset the tab fields
     * First tab should be activated on when close the modal window
     */
    close () {
      this.resetFields()
      this.filterTabs[this.activeTab].value = ''
      this.activeTab = this.defaultTab
      this.$emit('close')
    },
    /**
     * Reset the tab fileds and search result
     */
    resetFields () {
      this.searchStr = ''
      this.searchiTagStr = ''
      this.searchiChimeStr = ''
      clearTimeout(this.dataInterval)
      this.queryResults = []
      this.showQueryResults = false
      this.searchStatus = ''
    },
    /**
     * Fetch the shipment data based on value of actived tab field
     * @param {value} String - Value of search field
     * If no result found then display 'No results found.' text
     */
    runQuery () {
      const self = this
      const fltr = this.resultFilter
      this.$store.dispatch('shipments/fetchShipmentList', [this.filterTabs[this.activeTab], this.cfgType]).then(function (result) {
        const filterResult = result.filter(fltr)
        self.queryResults = filterResult
        self.searchStatus = filterResult.length > 0 ? '' : 'No results found.'
      }).catch(e => {
        console.log(e)
      })
    },
    /**
     * Show the shipment details while click the result item
     * @param {val} String - Searched shipment id value
     * @param {macId} String - Searched macId value
     * @param {guid} String - guid value from clicked row result
     * {updateSingleShipment} - If not redirect update the selected shipment item to state object
     * After redirect to shipemnt details page for the clicked guid
     * If redirect enabled return shpment data to parent component
     */
    openDetails (shp) {
      if (this.redirect) {
        const path = this.redirectPath.split('-')
        router.push({path: path[1], query: { id: shp.guid, mode: path[0] }}) // Redirect to shipment details page
        return true
      }
      this.queryResults = []
      this.$emit('openMsgModal', shp)
      this.close()
    }
  },
  mounted: function () {
    // console.log('this.$refs', this.$refs)
    // this.$refs.searchStr.focus()
  },
  props: {
    isActive: Boolean,
    tabConfig: {
      type: [Object, Array],
      default: () => []
    },
    redirect: Boolean,
    title: String,
    cfgType: {
      type: Number,
      default: 0
    },
    redirectPath: {
      type: String,
      default: 'ShipmentDetail'
    },
    resultFilter: {
      type: Function,
      default: () => {
        return true
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.shipmentRow
{
  cursor: pointer;
}
</style>
