<template>
  <div>
    <div class="control is-horizontal">
      <div class="control-label">
        <label class="label">{{label}}</label>
      </div>
      <div class="control">
        <ValidationProvider :name="label" :rules="formRules" v-slot="{ errors }">
          <input type="checkbox" v-model="fieldValue"/>
          <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  data () {
    return {
      fieldValue: ''
    }
  },
  props: {
    default: String,
    label: String,
    mode: {
      type: String,
      default: 'add'
    },
    field: {
      type: String,
      default: ''
    },
    formRules: Object,
    reset: Boolean
  },
  watch: {
    reset (val) {
      if (val) {
        // If reset is true, then set the value as blank
        // If the default value is passed, then set the value
        this.fieldValue = this.default
      }
    },
    'fieldValue': {
      handler (val) {
        // console.debug('fieldValue', val, this.field)
        if (this.field) this.$store.commit('SET_FORM_FIELD', {key: this.field, value: val, mode: this.mode})
      },
      deep: true
    }
  }
}
</script>
