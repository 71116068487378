<template>
  <div>
    <svg :height="svgHeight" :width="svgWidth" :viewBox="viewBox">
        <g v-for="(val, index) in chartData" :key="index">
          <text x="0" :y="(17 * index) + 8">{{ val.label }}:</text>
          <rect :x="labelLen * 8" :y="17 * index" :width="(val.value * 50) / dataTotal" height="15"
  :style="val.style" />
          <text
                :x="((val.value * 50)/ dataTotal) + valueTextOffset"
                :y="(17 * index) + 8"
          >{{ val.value }}
          </text>
        </g>
      </svg>
  </div>
</template>

<script>

export default {
  components: {},
  computed: {
    // dataTotal: function () {
    //   return 10 // this.initialValues.reduce((acc, val) => { acc + val.value }, 0)
    // },
    viewBox: function () {
      return `0 0 ${this.svgWidth} ${this.svgHeight}`
    }
  },
  data () {
    return {
      chartData: [],
      dataTotal: 0,
      labelLen: 0,
      colors: ['green', '#cd5c5c', '#6495ED', 'goldenrod', 'thistle', 'lightgra'],
      sortedValues: [],
      svgHeight: 0,
      svgWidth: 0,
      valueTextOffset: 0
    }
  },
  methods: {
    calculateChartData: function () {
      let maxLableLen = 0
      this.sortedValues.forEach((val) => {
        maxLableLen = Math.max(val.label.length, maxLableLen)
        const color = val.color || 'blue'
        const style = `fill:${color};stroke:${color};stroke-width:1;fill-opacity:0.5;stroke-opacity:0.9`
        const data = {value: val.value, label: val.label, style: style}
        this.chartData.push(data)
      })
      this.dataTotal = this.sortedValues[0].value
      this.labelLen = maxLableLen
      this.svgHeight = this.sortedValues.length * 20
      this.svgWidth = (maxLableLen * 8) + 50 + 40
      this.valueTextOffset = (maxLableLen * 8) + 5
    },
    sortInitialValues: function () {
      const chartData = [...this.initialValues]
      this.sortedValues = chartData.sort((a, b) => b.value - a.value)
    }
  },
  mounted () {
    this.sortInitialValues()
    this.calculateChartData()
  },
  props: ['initialValues', 'scale']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  text {
    font: 14px Verdana, Helvetica, Arial, sans-serif;
    text-anchor: start;
    alignment-baseline: middle;
  }
</style>
