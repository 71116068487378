<template>
  <intelyt-modal
    :isActive="isActive"
    :title="title"
    :width=800
    v-on:close="close()"
  >
    <div class="content">
      <div class="field-group">
        <div class="field">
          <label class="label">Barcode Text</label>
            <input class="input"
                   :class="{'input': true}"
                   type="text"
                   v-model="barcodeInput"
            >
        </div>
        <div class="field" v-if="prependText.length > 0">
          <label class="checkbox">
            <input  type="checkbox" name="includePreText" v-model="includePreText"> Include Project Identifier?
          </label>
        </div>
        <br>
        <br>
        <p><svg id="barcode"></svg></p>
      </div>
    </div>
    <template slot="footer">
      <a class="button" @click="close()">Close</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'
import JsBarcode from 'jsbarcode'

export default {
  components: {
    IntelytModal
  },
  computed: {},
  data () {
    const shipmentPrefix = this.$store.state.company.shipmentPrefix || ''
    return {
      title: 'Barcode Generator',
      barcodeInput: '',
      includePreText: shipmentPrefix.length > 0,
      prependText: shipmentPrefix
    }
  },
  methods: {
    buildBarcode () {
      const prepend = this.includePreText ? this.prependText : ''
      const bcStr = prepend + this.barcodeInput
      const bcInput = bcStr.length > 0 ? bcStr : '<blank>'
      JsBarcode('#barcode', bcInput)
    },
    close () {
      this.barcodeText = ''
      this.$emit('close')
    }
  },
  props: ['isActive', 'guid'],
  watch: {
    // whenever barcode changes, this function will run
    barcodeInput: function () {
      this.buildBarcode()
    },
    // rebuild if choice of including test changes
    includePreText: function () {
      this.buildBarcode()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
