<template>
  <div>
    <div class="field">
      <div class="control">
        <input class="input" type="hidden" v-model="fieldVal" name="id">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fieldVal: ''
    }
  },
  props: {
    default: {
      type: [Number, String],
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: ''
    },
    reset: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    reset (val) {
      if (val) {
        this.fieldVal = ''
        // If reset is true, then set the value as blank
        // If the default value is passed, then set the value
        this.fieldVal = this.default || ''
      }
    },
    'fieldVal': {
      handler (val) {
        if (this.field) this.$store.commit('SET_FORM_FIELD', {key: this.field, value: val, mode: this.mode})
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
