<template>
  <div>
    <level-bar>{{name}}</level-bar>
    <div class="columns">
      <div class="column is-6">
        <intelyt-card>
          <template slot="header">Users</template>
          <template slot="controls">
            <span style="display:flex; align-content:center; padding-left:5px;">
              <label>
                <input type="search" id="search-input" class="form-control" placeholder="Search Users"
                    :value="searchTextUsers"
                    @input="(e) => {this.searchTextUsers = e.target.value}">
              </label>
              <span class="icon-search is-right" style="padding-top:5px;">
                  <base-icon name="search" size="18" color="grey"/>
                </span>
                <!-- <base-icon name="add"  @click="addUser()" v-tooltip="'Add User'" class="icon-padding"/> -->
            </span>
          </template>
          <base-table
            :data="getUsers()"
            :columns="columns"
            :options="userTblOptions"
            :searchText="searchTextUsers"
            :searching="false"
          >
          </base-table>
        </intelyt-card>
        <intelyt-card maxHeight="500px" :scrolling="true" v-if="$store.state.user.permissions.includes('viewConfig')">
          <template slot="header">Configurations</template>
          <template slot="controls">
            <base-icon name="download" v-if="!isDisabled" @click="downloadConfig()" v-tooltip="'Download Config'" />
          </template>
          <div class="json-content">
            <vue-json-compare :oldData="defaultConfig" :newData="companyConfig"></vue-json-compare>
          </div>
        </intelyt-card>
      </div>
      <div class="column is-4">
        <intelyt-card v-if="getBeaconList().length > 0">
          <template slot="header">Beacons</template>
          <template slot="controls">
            <span style="display:flex; align-content:center; padding-left:5px;">
              <label>
                <input type="search" id="search-input" class="form-control" placeholder="Search Beacon"
                    :value="searchTextBeacons"
                    @input="(e) => {this.searchTextBeacons = e.target.value}">
              </label>
              <span class="icon-search is-right" style="padding-top:5px;">
                <base-icon name="search" size="18" color="grey"/>
              </span>
            </span>
          </template>
          <base-table
            :data="getBeaconList()"
            :columns="beaconColumns"
            :options="options"
            :searchText="searchTextBeacons"
            :searching="false"
          >
          </base-table>
        </intelyt-card>
        <!-- <intelyt-card v-if="getLocationList.length > 0"> -->
        <intelyt-card>
          <template slot="header">Locations</template>
          <template slot="controls">
            <span style="display:flex; align-content:center; padding-left:5px;">
              <label>
                <input type="search" id="search-input" class="form-control" placeholder="Search Location"
                    :value="searchTextLocations"
                    @input="(e) => {this.searchTextLocations = e.target.value}">
              </label>
              <span class="icon-search is-right" style="padding-top:5px;">
                <base-icon name="search" size="18" color="grey"/>
              </span>
            </span>
          </template>
          <base-table
            :data="getLocationList"
            :columns="locationColumns"
            :options="userTblOptions"
            :searchText="searchTextLocations"
            :searching="false"
          >
          </base-table>
        </intelyt-card>
        <intelyt-card v-if="hasAssetConfig">
          <template slot="header">Asset Configurations</template>
          <template slot="controls">
            <span style="display:flex; align-content:center; padding-left:5px;">
              <label>
                <input type="search" id="search-input" class="form-control" placeholder="Search Asset Config"
                    :value="searchTextAstConfig"
                    @input="(e) => {this.searchTextAstConfig = e.target.value}">
              </label>
              <span class="icon-search is-right" style="padding-top:5px;">
                <base-icon name="search" size="18" color="grey"/>
              </span>
            </span>
            <base-icon name="add"  @click="addAssetConfig()" v-tooltip="'Add Asset Config'" class="icon-padding"/>
          </template>
          <base-table
            :data="getAssetConfigList"
            :columns="assetConfigColumns"
            :options="assetCfgOptions"
            :searchText="searchTextAstConfig"
            :searching="false"
          >
          </base-table>
        </intelyt-card>
      </div>
      <div class="column is-2">
        <!-- <intelyt-card v-if="getGroups('report').length > 0"> -->
        <intelyt-card>
          <template slot="header">Reports</template>
          <template slot="controls">
            <span style="display:flex; align-content:center; padding-left:5px;">
              <span class="icon-search is-right" style="padding-top:5px;">
                <base-icon name="view" @click="viewReports()" v-tooltip="'View Reports'"/>
                <base-icon name="edit" @click="manageReports()" v-tooltip="'Manage Reports'"/>
              </span>
            </span>
          </template>
          <base-table
            :data="getGroups('report')"
            :columns="reportColumns"
            :options="options"
            :searching="false"
          >
          </base-table>
        </intelyt-card>
      </div>
    </div>
    <manage-asset-config-modal
      :isActive="modalIsActive.manageAssetConfig"
      :assetConfig="assetConfig"
      :isNew="newAssetConfig"
      v-on:close="closeModal('manageAssetConfig')"
    >
    </manage-asset-config-modal>
    <manage-beacon-modal
      :isActive="modalIsActive.manageBeacon"
      :selectedBeacon="selectedBeacon"
      v-on:close="closeModal('manageBeacon')"
    >
    </manage-beacon-modal>
    <manage-location-modal
      :isActive="modalIsActive.manageLocation"
      :selectedLocation="selectedLocation"
      v-on:close="closeModal('manageLocation')"
    >
    </manage-location-modal>
    <manage-user-modal
      :isActive="modalIsActive.manageUser"
      :userObj="selectedUser"
      v-on:close="closeModal('manageUser')"
    >
    </manage-user-modal>
    <manage-reports-modal
      :isActive="modalIsActive.manageReports"
      :isViewMode="isViewMode"
      :reportGroups="getGroups('report')"
      v-on:close="closeModal('manageReports')"
    >
    </manage-reports-modal>
    <add-edit-modal
      :title="addConfigForm.label"
      :mode="formMode"
      :callback="saveAssetConfigCallback"
      :formElements="addConfigForm.fields"
      :defaults="assetConfig"
      :required="[]"
      :isActive="modalIsActive.addEdit"
      v-on:close="closeModal('addEdit')"
    >
    </add-edit-modal>
    <a id="downloadConfig" style="display:none"></a>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import AddEditModal from '@/modals/AddEditModal'
import ManageAssetConfigModal from '@/modals/ManageAssetConfigModal'
import ManageBeaconModal from '@/modals/ManageBeaconModal'
import ManageUserModal from '@/modals/ManageUserModal'
import ManageLocationModal from '@/modals/ManageLocationModal'
import ManageReportsModal from '@/modals/ManageReportsModal'
import vueJsonCompare from '@/components/compare/vueJsonCompare.vue'
import {modalWindowManager, tableFilterManager} from '@/store/mixins'
import {generateIconSVG, isJSON} from '@/store/helpers'
import { getStaticDataProvider, updateCompanyProvider } from '../store/providers'
import config from '../../static/config.json'

export default {
  components: {
    AddEditModal,
    ManageAssetConfigModal,
    ManageBeaconModal,
    ManageLocationModal,
    ManageUserModal,
    ManageReportsModal,
    vueJsonCompare
  },
  computed: {
    assetConfigColumns() {
      const manageLink = function (txt) {
        return `<a>${txt}</a>`
      }
      const opts = this.$store.state.configuration.pageOptions.administration.assetConfigCols || []
      /* const filteredOptions = opts.map(mapFn)
      return Object.values(filteredOptions) */
      const formFields = this.$store.state.configuration.pageOptions.administration.assetConfigForm.fields
      const fldObj = {}
      formFields.forEach((fld) => fldObj[fld.field] = fld)
      const out = opts.map((opt) => { return {label: fldObj[opt].label, id: opt} })
      if (out[0]) {
        out[0].formatter = manageLink
        out[0].eventclick = this.manageAssetConfig
      }
      return out
    },
    hasAssetConfig () {
      const prefs = isJSON(this.$store.state.company.customPrefs) ? JSON.parse(this.$store.state.company.customPrefs) : {}
      // return prefs.hasOwnProperty('assetTypes')
      return Object.prototype.hasOwnProperty.call(prefs, 'assetTypes')
    },
    getAssetConfigList () {
      const prefs = isJSON(this.$store.state.company.customPrefs) ? JSON.parse(this.$store.state.company.customPrefs) : {}
      // let assetTypes = prefs.hasOwnProperty('assetTypes') ? Object.values(prefs.assetTypes) : []
      let assetTypes = Object.prototype.hasOwnProperty.call(prefs, 'assetTypes') ? Object.values(prefs.assetTypes) : []
      assetTypes = assetTypes.map((type, idx) => {
        type.configIndex = idx.toString()
        return type
      })
      // console.log('assetTypes', assetTypes)
      return assetTypes
    },
    getLocationList () {
      return Object.values(this.$store.state.locations.all)
    },
    ...mapState({
      company: 'company'
    })
  },
  created () {
    this.$store.dispatch('waypoints/initialize')
    this.$store.dispatch('geofences/initializeRouteList')
    this.$store.dispatch('geofences/initializeAssocisationsList')
    this.$store.dispatch('devices/initialize', {deviceType: 'beacons'})
    this.setUserObj()
    this.$store.dispatch('reports/initialize')
    if (this.$store.state.user.permissions.includes('viewConfig')) {
      // Load the default configuration
      const defaultConfig = {...config}
      // Get current company pattern
      const companyPattern = this.$store.state.company.pattern
      // Get the current company configuration
      const currentConfig = {...this.$store.state.configuration}
      // Load the default and current company pattern configuration
      getStaticDataProvider(this.$store.state, {path: `pageOptions`}).then((res) => {
        // Get the default page options
        const defaultPageOptions = res.default
        // Get the current company's page options
        const patternPageOptions = res[companyPattern]
        // Loop through the each page option from the default page option configuration
        // And override, if the current company's page option configuration have the changes
        // And store it in the company's pageoptions object for comparission
        Object.keys(defaultPageOptions).forEach(pageName => {
          // Default page options for the current page name
          const defPageCfg = defaultPageOptions[pageName]
          // If Company's page options have the current page name, then merge with default page options
          const overrideCfg = patternPageOptions[pageName] ? Object.assign({}, defPageCfg, patternPageOptions[pageName]) : defPageCfg
          patternPageOptions[pageName] = overrideCfg
        })
        // Apply the overriden page options for the company
        currentConfig.pageOptions = Object.assign({}, currentConfig.pageOptions, patternPageOptions)
        // Apply the default page option values
        defaultConfig.pageOptions = Object.assign({}, defaultConfig.pageOptions, defaultPageOptions)
        // Apply the values to the root objects
        this.companyConfig = currentConfig
        this.defaultConfig = defaultConfig
      })
    }
  },
  data () {
    const manageLink = function (user) {
      return `<a>${user}</a>`
    }
    const reportCount = function (reports) {
      return reports ? reports.length : 0
    }
    const locations = this.$store.state.locations.all
    const locationName = function (locID) {
      return locations[locID].name
    }
    const shortMacID = function (macID) {
      return macID.length > 0 ? `<a>${macID.slice(-11)}</a>` : '-'
    }
    // const allGroups = {...this.$store.state.company.groups}
    const allGroups = JSON.parse(JSON.stringify({...this.$store.state.company.groups}))
    // Get all groups defined for company and collapse custom prefs into base level parameters
    for (const grpName in allGroups) {
      if (!allGroups[grpName].reports && allGroups[grpName].customPrefs && isJSON(allGroups[grpName].customPrefs)) {
        allGroups[grpName] = Object.assign(allGroups[grpName], JSON.parse(allGroups[grpName].customPrefs))
      }
    }
    const state = this.$store.state
    const statusIcon = function (enabled) {
      let icon = { name: 'cancel', color: 'red' }
      if (enabled) {
        icon = { name: 'check', color: 'green' }
      }
      return generateIconSVG(state, icon)
    }
    const addConfigForm = this.$store.state.configuration.pageOptions.administration.assetConfigForm || {}
    return {
      assetConfig: {},
      name: 'Administration',
      allGroups,
      addConfigForm,
      beaconColumns: [
        {label: 'MAC ID', id: 'beacon', formatter: shortMacID, eventclick: this.manageBeacon},
        {label: 'Name', id: 'name'},
        {label: 'Location', id: 'location', formatter: locationName}
      ],
      columns: [
        {label: 'Username', id: 'loginName', formatter: manageLink, eventclick: this.manageUser},
        {label: 'Name', id: 'fullName'},
        {label: 'Enabled', id: 'enabled', formatter: statusIcon},
        {label: 'Email Address', id: 'emailAddress'},
        {label: 'Profile', id: 'loginGroup'},
        {label: 'Reports', id: 'reportGroup'}
      ],
      companyConfig: {},
      defaultConfig: {},
      formMode: 'add',
      isDisabled: false,
      isViewMode: false,
      locationColumns: [
        {label: 'Name', id: 'name', formatter: manageLink, eventclick: this.manageLocation},
        {label: 'Type', id: 'locType'},
        {label: 'Region', id: 'locRegion'}
      ],
      modalIsActive: {
        addEdit: false,
        manageAssetConfig: false,
        manageBeacon: false,
        manageLocation: false,
        manageReports: false,
        manageUser: false
      },
      newAssetConfig: false,
      options: {
        paginate: false
      },
      assetCfgOptions: {
        sortable: true,
        sortIndex: 2,
        sortType: 'asc'
      },
      reportColumns: [
        {label: 'Group', id: 'groupName'},
        {label: 'Reports', id: 'reports', formatter: reportCount}
      ],
      searchTextAstConfig: '',
      searchTextBeacons: '',
      searchTextLocations: '',
      searchTextUsers: '',
      searchTextReports: '',
      selectedBeacon: {},
      selectedGroup: {},
      selectedLocation: {},
      selectedUser: {},
      userTblOptions: {
        paginate: true,
        sortable: true,
        perPage: 10,
        sortIndex: 0,
        sortType: 'asc'
      }
    }
  },
  methods: {
    addUser () {
      this.setUserObj()
      this.modalIsActive.manageUser = true
    },
    addAssetConfig () {
      this.assetConfig = {}
      this.newAssetConfig = true
      this.formMode = 'add'
      this.modalIsActive.addEdit = true
    },
    downloadConfig () {
      // Hide the download button
      this.isDisabled = true
      // Prepare the download data
      const configData = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.companyConfig, null, 2))
      const dlAnchorElem = document.getElementById('downloadConfig')
      dlAnchorElem.setAttribute("href", configData)
      dlAnchorElem.setAttribute("download", "configData.json")
      dlAnchorElem.click()
      // Enable the download button
      setTimeout(() => { this.isDisabled = false }, 1500)
    },
    getBeaconList () {
      const beacons = Object.keys(this.$store.state.devices.beacons.all)
      const waypoints = Object.values(this.$store.state.waypoints.all).filter(wp => beacons.includes(wp.beacon))
      return waypoints
    },
    getGroups (grp = '') {
      // Get groups with type that matches input and return as a list.  Map the key of the object
      // to the 'groupName' parameter of the object returned in the list.  Used for table display of
      // a single group type.
      const mapFn = (grpObj) => {
        return Object.assign(grpObj[1], {groupName: grpObj[0]})
      }
      const groupList = Object.entries(this.allGroups).filter(x => x[1].type === grp).map(mapFn)
      return groupList
    },
    getUsers () {
      let users = []
      const reportGroups = this.getGroups('report').map(group => group.groupName)
      const loginGroups = this.getGroups('login').map(group => group.groupName)
      for (let user of this.company.users) {
        // Separate the login and report groups from user's group list
        const userLoginGroups = loginGroups.length > 0 ? user.groupList.filter(grp => loginGroups.indexOf(grp) > -1) : []
        const userReportGroups = reportGroups.length > 0 ? user.groupList.filter(grp => reportGroups.indexOf(grp) > -1) : []
        const loginGroup = userLoginGroups.length > 0 ? userLoginGroups[0] : ''
        const reportGroup = userReportGroups.length > 0 ? userReportGroups[0] : ''
        let prefs = user.propertyMap.customPrefs ? JSON.parse(user.propertyMap.customPrefs) : {}
        user.loginGroup = loginGroup
        user.reportGroup = reportGroup
        user.timezone = prefs.timezone
        user.notifyString = user.notifyList.join()
        users.push(user)
      }
      return users
    },
    manageAssetConfig (config) {
      this.assetConfig = {...config}
      this.newAssetConfig = false
      // this.modalIsActive.manageAssetConfig = true
      this.formMode = 'edit'
      this.modalIsActive.addEdit = true
    },
    saveAssetConfigCallback (assetDetail) {
      const vm = this
      return new Promise(function (resolve, reject) {
      let prefs = isJSON(vm.$store.state.company.customPrefs) ? JSON.parse(vm.$store.state.company.customPrefs) : {}
      // let assetTypes = prefs.hasOwnProperty('assetTypes') ? Object.values(prefs.assetTypes) : []
      let assetTypes = Object.prototype.hasOwnProperty.call(prefs, 'assetTypes') ? Object.values(prefs.assetTypes) : []
      if (vm.newAssetConfig) { // If new config
        assetTypes.push(assetDetail)
      } else { // update the existing config
        // The configIndex is used to identify the config item order in the overall config list
        const idx = assetDetail.configIndex
        // We don't need to store it's value in the db. So remove it from the object before update it to db
        delete assetDetail.configIndex
        // Replace the config item from the config list using the index(configIndex)
        assetTypes.splice(idx, 1, assetDetail)
      }
      prefs.assetTypes = assetTypes
      const customPrefs = JSON.stringify(prefs)
      const updateObj = {
        property: {
          customPrefs
        }
      }
      updateCompanyProvider(vm.$store.state, updateObj, vm.$store.state.company.id).then(() => {
        vm.$store.commit('company/UPDATE_CONFIG', {customPrefs})
        resolve({message: 'Config updated Successfully'})
      }).catch(e => {
          // skipcq: JS-0002.  Allow console.error
          console.error('ERROR: saveAssetConfigCallback error ', e)
          // const retVal = {'status': 417, 'message': `${args.error}:<br> ${e}`}
          reject(e)
        })
      })
    },
    manageBeacon (beacon) {
      this.selectedBeacon = {...beacon}
      const locationId = this.selectedBeacon && this.selectedBeacon.location ? this.selectedBeacon.location : false
      // If there is no location linked with the beacon, open the modal
      if (!locationId) {
        this.modalIsActive.manageBeacon = true
        return
      }
      // If location found and the location linked with an area, the get it's coordinates
      const location = this.$store.state.locations.all[locationId]
      const notes = isJSON(location.notes) ? JSON.parse(location.notes) : {}
      const fenceId = notes && notes.locId ? notes.locId : ''
      const fence = fenceId && this.$store.state.geofences.all[fenceId] ? this.$store.state.geofences.all[fenceId] : {}
      if (!fence && fence.mapId) {
        this.modalIsActive.manageBeacon = true
        return
      }
      this.getRouteDetails({mapId: fence.mapId}).then(() => {
        this.modalIsActive.manageBeacon = true
      })
    },
    manageReports (group) {
      this.selectedGroup = {...group}
      this.isViewMode = false
      this.modalIsActive.manageReports = true
    },
    manageLocation (loc) {
      const vm = this
      const location = {...loc}
      vm.selectedLocation = location
      // If location found and the location linked with an area, the get it's coordinates
      const notes = isJSON(vm.selectedLocation.notes) ? JSON.parse(vm.selectedLocation.notes) : false
      // If there is no area linked, open the modal
      if (!notes) {
        vm.modalIsActive.manageLocation = true
        return
      }
      // If the area/fence details found, then get the coordinates before open the modal
      const fenceId = notes && notes.locId ? notes.locId : ''
      const fence = fenceId && vm.$store.state.geofences.all[fenceId] ? vm.$store.state.geofences.all[fenceId] : {}
      if (!fence && fence.mapId) {
        vm.modalIsActive.manageLocation = true
        return
      }
      vm.getRouteDetails({mapId: fence.mapId}).then(() => {
        vm.modalIsActive.manageLocation = true
      })
    },
    manageUser (user) {
      this.selectedUser = {...user}
      this.modalIsActive.manageUser = true
    },
    setUserObj () {
      this.selectedUser = {
        loginName: '',
        fullName: '',
        emailAddress: '',
        enabled: false
      }
    },
    viewReports () {
      this.isViewMode = true
      this.modalIsActive.manageReports = true
    },
    ...mapActions({
      getRouteDetails: 'geofences/getRouteDetails'
    })
  },
  mixins: [modalWindowManager, tableFilterManager]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
