<template>
  <nav class="level app-levelbar">
    <div class="level-left">
      <div class="level-item">
        <h3 class="subtitle is-5">
          <strong><slot></slot></strong>
        </h3>
      </div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <h4 class="subtitle is-6" style="padding-right: 50px;">
          <strong><slot name="right"></slot></strong>
        </h4>
      </div>
    </div>
  </nav>
</template>

<script>
export default {

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
