import moment from 'moment-timezone'

// import {buildMarker, getUrl, isBetween} from './helpers.js'
import {buildMarker, isBetween} from './helpers.js'
// import router from '../router'
let state = localStorage.getItem('intelyt')
state = JSON.parse(state)

let sortByCreateDate = (start, end) => {
  return moment(end.createDate).diff(start.createDate, 'seconds')
}

/*
Main/Exported Functions
*/

export const getAuth = () => {
  if (typeof state === 'undefined' || state === null) {
    state = localStorage.getItem('intelyt')
    state = JSON.parse(state)
  }
  return state.auth
}

export const getAlertsByGuid = (state) => (guid, level = ['0', '1', '2']) => {
  let fltr = (alrt) => {
    return alrt.guid === guid && level.includes(alrt.level)
  }
  // let alerts = Object.values(state.alerts).filter(fltr)
  let alerts = Object.values(state.alerts).filter(fltr)
  alerts.sort(sortByCreateDate)
  return alerts
}

export const getTimeSeries = () => (obj, id, range, field) => {
  if (!obj[id].tsData) return []
  let timeSeriesData = obj[id].tsData.map(d => [d.timestamp, d[field]])
  return range ? timeSeriesData.filter(isBetween(range)) : timeSeriesData
}

/**
 * A general function to return marker objects for use in map.<br>
 * Uses {@link buildMarker} function to populate marker object
 * @param {object} obj - Object that you want to build markers for
 * @param {object} [flt=(x) => { return true })] - Filter function to apply to object
 * before building markers
 * @returns {Array} - Array containing marker objects for whatever
 * type of object that was requested.
 */
export const getObjectMarkers = (state) => (obj, flt = () => { return true }) => {
  const usertmzn = state.user.timezone ? state.user.timezone : moment.tz.guess()
  let markerData = Object.values(obj).filter(flt).map((d, i) => buildMarker(d, i, usertmzn))
  return markerData
}

export const getTagMarkers = (state) => (deviceType = 'tags') => {
//   let deviceList = []
//   for (let key of Object.keys(state.tags)) {
//     deviceList.push(state.tags[key])
//   }
  const usertmzn = state.user.timezone ? state.user.timezone : moment.tz.guess()
  const devices = state.devices[deviceType].all
  let markerData = Object.values(devices).map((d, i) => buildMarker(d, i, usertmzn))
  return markerData
}

export const isLoggedIn = () => {
  let state = localStorage.getItem('intelyt')
  state = JSON.parse(state)
  return state.auth.status
}
