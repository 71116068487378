<template>
  <div>
    <div class="field">
      <label class="label" style="float: left;">Location</label>
      <base-icon v-if="projectId.length > 0 && showSearch" name="search" style="cursor: pointer" @click="getProjectLocation()"/>
      <div class="control">
        <input disabled  v-model="address" type="text" name="id" class="input input">
        <span v-if="errorRsp.length > 0" class="text-danger">{{errorRsp}}</span>
      </div>
    </div>
    <!--  This is a custom sectin for client crates (iChimes) which will also associate one to iTag -->
    <div class="field">
      <intelyt-card>
        <template slot="header">{{label}}:</template>
        <base-table
          :data="Object.values(inventoryData)"
          :columns="listColumns"
          :options="tableOptions"
          :searching="true">
        </base-table>
      </intelyt-card>
      <span v-if="!validSelection" class="help is-danger">Must select at least one record and Primary value</span>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import {batteryIcon, lastCommsFmt} from '@/store/formatters'
import {lookupProjectLocationHelper} from '@/store/helpers'
import {getTableEntriesProvider} from '@/store/providers'
export default {
  name: 'ChimeList',
  computed: {
    /**
     * Returns Crates list
     */
    inventoryData () {
      const totalShp = this.$store.state.shipments.all
      let guidList = this.default || []
      // console.log('this.default', this.default)
      // const guidList = ['7ADF531B-A6EE-4146-B64D-26DE5245A517']
      // Allow only the shipments which have the crate ID
      guidList = guidList.filter(shp => totalShp[shp].customer.length > 0)
      const reducer = function (acc, guid) {
        // console.log('guids', acc, guid, totalShp[guid])
        if (totalShp[guid]) {
          acc[guid] = totalShp[guid]
          acc[guid].checked = true
        }
        return acc
      }
      // let projects = guidList.reduce(reducer, {})
      // console.log('projects', projects)
      return guidList.reduce(reducer, {})
    },
    projectId () {
      // Shipments form now changed to global form object
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      return this.$store.state.form.new.shipmentId || ''
    }
  },
  data () {
    // Battery tooltip formatter
    const batteryIconFrmt = function (voltage) {
      return batteryIcon(voltage, 'chimes', true)
    }
    const vm = this
    // skipcq: JS-D1001.  Allow documentation comment
    const lastComms = function (guid) {
      const shpmnt = vm.$store.state.shipments.all[guid]
      const output = lastCommsFmt(shpmnt, 72)
      return output ? output : '<span class="text-danger">NONE</span>'
    }
    return {
      address: '',
      country: '',
      errorRsp: '',
      listColumns: [
        {label: '', id: 'guid', colFormatter: this.checkAllHTML, colClick: this.handleCheckAll, formatter: this.controlIcons, eventclick: this.handleCheck, ignoreSorting: true},
        {label: 'Primary', id: 'guid', formatter: this.optionIcons, eventclick: this.optionCheck, ignoreSorting: true},
        {label: 'Crate ID', id: 'customer', ignoreSorting: true},
        {label: 'Crate #', id: 'customInt'},
        {label: 'MAC ID', id: 'macId', ignoreSorting: true},
        {label: 'Battery', id: 'battery', formatter: batteryIconFrmt},
        {label: 'Last Comms', id: 'guid', formatter: lastComms, ignoreSorting: true}
      ],
      selectedGuid: '',
      selectedPrj: {},
      showSearch: true,
      tableOptions: {
        paginate: false,
        sortable: true,
        sortIndex: 3,
        sortType: 'asc'
      },
      isCheckedAll: false,
      validSelection: false
    }
  },
  methods: {
    getLocDetails () {
      const geocoder = new window.google.maps.Geocoder()
      // const address = 'Intel Corporation 3040 NE SHUTE RD. 97124, Hillsboro, OR US'
      const vm = this
      const address = vm.address
      const country = vm.country
      // Get the destination position details using Google's API
      geocoder.geocode( {address}, (results, status) => {
        if (status === 'OK') {
          // If the API returns the details, then set the destion and config script
          // details in the shipment new object
          const lat = results[0].geometry.location.lat()
          const lng = results[0].geometry.location.lng()
          let addressComp = results[0].address_components
          // skipcq: JS-W1044.  Allow Logical operator can be refactored to optional chain
          addressComp = addressComp.filter(cmp => cmp.types && cmp.types.includes('locality'))
          // skipcq: JS-W1044.  Allow Logical operator can be refactored to optional chain
          const dest = addressComp[0] && addressComp[0].short_name ? addressComp[0].short_name : country
          const location = {
            name: dest,
            latitude: lat,
            longitude: lng,
            address
          }
          const cfg = `AMAT/cfg/${country}.cfg`
          vm.$store.commit('SET_FORM_FIELD', {key: 'configScript', value: cfg, mode: vm.mode})
          vm.$store.commit('SET_FORM_FIELD', {key: 'destination', value: location, mode: vm.mode})
        } else {
          // skipcq: JS-0002.  Allow console.error
          console.error('Geocode was not successful for the following reason: ', status)
        }
      })
    },
    getProjectLocation () {
      const vm = this
      vm.showSearch = false
      // Get the destination details using the project ID
      lookupProjectLocationHelper(this.$store, vm.projectId).then((res) => {
        // Set the country and destination details from response
        const country = res.country
        const destination = res.destination.replace(/\n/g, ' ')
        vm.address = destination
        vm.country = country
        vm.getLocDetails()
      })
      .catch(e => {
        vm.errorRsp = e.message
        // skipcq: JS-0002.  Allow console.error
        console.error('fetch project location ERROR in chime list: ', e)
      })
    },
    checkAllHTML () {
      const isChecked = (this.isCheckedAll === true) ? 'checked="checked"' : ''
      return `<input type="checkbox" ${isChecked}> All`
    },
    controlIcons (guid) {
      const selectShp = this.inventoryData[guid]
      const isChecked = (selectShp.checked === true) ? 'checked = "checked"' : ''
      return `<input type="checkbox" ${isChecked} value="${guid}" />`
    },
    /**
     * @param {Object} row - Object value of selected row
     * Assigned selected guid to selectedGuid
     */
    handleCheck (row) {
      this.inventoryData[row.guid].checked = (this.inventoryData[row.guid].checked !== true)
      const totalData = Object.values(this.inventoryData)
      const selectedData = Object.values(this.inventoryData).filter(shp => shp.checked === true)
      this.validSelection = selectedData.length > 0 && this.selectedGuid !== ''
      this.isCheckedAll = (totalData.length === selectedData.length)
      this.updateShipmentFields()
    },
    handleCheckAll () {
      const vm = this
      vm.isCheckedAll = !vm.isCheckedAll
      Object.values(this.inventoryData).forEach(x => { x.checked = vm.isCheckedAll })
      const selectedData = Object.values(this.inventoryData).filter(shp => shp.checked === true)
      this.validSelection = selectedData.length > 0 && this.selectedGuid !== ''
      this.updateShipmentFields()
    },
    optionIcons (guid) {
      const crateID = this.inventoryData[guid].customer
      const isSelected = (guid === this.selectedGuid) ? 'checked = "checked"' : ''
      return (crateID === '') ? '' : `<input type="radio" name="selectedCrate" ${isSelected} value="${guid}" />`
    },
    optionCheck (row) {
      this.selectedGuid = row.guid
      const selectedData = Object.values(this.inventoryData).filter(shp => shp.checked === true)
      this.validSelection = selectedData.length > 0 && this.selectedGuid !== ''
      this.updateShipmentFields()
    },
    updateShipmentFields () {
      // console.log('newVal', this.inventoryData)
      // determine selected object from list if chimes/crates included
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const selectedDevice = Object.values(this.inventoryData).filter(shp => shp.guid === this.selectedGuid)
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const deviceLabel = selectedDevice.map(shpSub => shpSub.customer)[0] || ''
      // chimes list is generated from chimes/crates section - put into sublist & sublist package info
      const chimes = Object.values(this.inventoryData).filter(shp => shp.checked).map(shpSub => shpSub.macId)
      const chimeLabels = Object.values(this.inventoryData).filter(shp => shp.checked).map(shpSub => shpSub.customer)
      // console.log('newVal', deviceLabel, chimes, chimeLabels)
      this.$store.commit('SET_FORM_FIELD', {key: 'packageInfo', value: deviceLabel, mode: this.mode})
      this.$store.commit('SET_FORM_FIELD', {key: 'sublist', value: chimes, mode: this.mode})
      this.$store.commit('SET_FORM_FIELD', {key: 'sublistPackageInfo', value: chimeLabels, mode: this.mode})
    }
  },
  props: {
    label: String,
    mode: String,
    reset: Boolean,
    default: {
      type: [Array, String],
      default: () => []
    }
  },
  watch: {
    reset (val) {
      if (val) {
        this.isCheckedAll = true
        this.showSearch = true
        this.address = ''
        this.country = ''
        this.errorRsp = ''
        this.selectedGuid = ''
        this.validSelection = false
      }
    },
    selectedGuid (guid) {
      // Get the origin details based on the selected shipment guid
      // The shipment origin uses the destination field of the selected chime in the chime list
      const vm = this
      const rootState = vm.$store.state
      if (guid) {
        const whereParams = `guid = '${guid}'`
        const payload = {
          tableName: 'shippingPickList',
          where: whereParams,
          sort: 'entryId DESC'
        }
        getTableEntriesProvider(rootState, payload)
          .then(response => {
            // skipcq:JS-W1043 - Skip redundant literal in a logical expression
            const orgData = response.data[0] || false
            // skipcq: JS-W1044.  Allow Logical operator can be refactored to optional chain
            if (orgData && orgData.origin && orgData.originLatitude && orgData.originLongitude) {
              const origin = {
                name: orgData.dest,
                address: orgData.dest,
                latitude: orgData.destLatitude,
                longitude: orgData.destLongitude
              }
              vm.$store.commit('SET_FORM_FIELD', {key: 'origin', value: origin, mode: vm.mode})
            }
          })
          .catch(e => {
            // skipcq: JS-0002.  Allow console.error
            console.error('fetch origin ERROR in chime list: ', e)
          })
      }
    }
  }
}
</script>
