<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      :width="getModalWidth"
      v-on:close="close()"
    >
      <form id="editGeoFrm">
        <div class="route_section" v-if="isGroup">
          <div class="columns">
            <div class="column">
              <div class="control">
                <div class="field-group">
                  <div class="field">
                    <label class="label">Name</label>
                      <p class="control">
                        <ValidationProvider name="Name" :rules="{required: true, max: 25, regex: /^(\w|\s|-)+$/}"  v-slot="{ errors }">
                          <input class="input" :class="{'input': true, 'is-danger': errors[0] }" type="text" v-model="fence.name" name="name" :disabled="!isNew">
                          <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Description</label>
                      <p class="control">
                        <ValidationProvider name="Description" :rules="{required: true, max: 75, regex: /^(\w|\s|-)+$/}"  v-slot="{ errors }">
                          <input class="input" :disabled="mode === 'view'" :class="{'input': true, 'is-danger': errors[0] }" type="text" v-model="fence.description" name="description">
                          <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </p>
                  </div>
                </div><br>
                <div class="field">
                  <intelyt-map
                    :polygons="routePolygons"
                    :pathList="routePolylines"
                    :markers="routeMarkers"
                    :mapWidth="600"
                    :zoom="zoom"
                  >
                  </intelyt-map>
                  <intelyt-map-legend
                    v-if="routeMapMarkers.length > 0"
                    :markers="routeMapMarkers"
                    :activeMarkers="activeMarkers"
                    v-on:updateActiveMarkers="setActiveMarkers($event)"
                  ></intelyt-map-legend>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field" v-if="zoneType != 4">
                <div class="label">Location</div>
                <div class="columns">
                  <div class="column is-5">
                    <div class="select select-location is-multiple">
                      <select :size="Object.values(locationOptions).length+1" class="select2 select-multiple" v-model="selectedLocations">
                        <option v-for="(loc, index) in getSortData('locationOptions')" :key="index" :value="loc.shippingLocId">{{loc.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="column is-2">
                    <div class="origin-manage" align="center">
                      <base-icon name="right" v-tooltip="'Add Origin Location'" @click="addOriginLocation()"></base-icon>
                    </div>
                    <hr/>
                    <div class="destination-manage" align="center">
                      <base-icon name="right" v-tooltip="'Add Destionation Location'" @click="addDestionationLocation()"></base-icon>
                    </div>
                  </div>
                  <div class="column is-5">
                    <div class="location-group">
                      <label class="label">Origins</label>
                      <span v-for="origin in getSortData('origins')" :key="origin.shippingLocId">
                        <span class="tag is-primary boldtext loc-options">
                          {{origin.name}}
                          <button class="delete is-small" @click="removeLocation(origin)"></button>
                        </span>
                      </span>
                    </div>
                    <div class="location-group">
                      <label class="label">Destinations</label>
                      <span v-for="origin in getSortData('destination')" :key="origin.shippingLocId">
                        <span class="tag is-primary boldtext loc-options">
                          {{origin.name}}
                          <button class="delete is-small" @click="removeLocation(origin)"></button>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <input type="checkbox" v-model="routeTripOption" /> Include reverse route
                </div>
              </div>
              <div class="field" v-if="isGroup">
                <div class="label">Geozones</div>
                <div class="tabs is-boxed">
                  <ul class="noMarginleft">
                    <template v-for="(tab, idx) in geozoneTypes">
                      <li v-if="tab.typeId !== zoneType || type === 'Route'" :key="idx" :class="{'is-active': (activeTab === tab.typeId)}"><a @click="activateTab(tab.typeId)">{{tab.name}} ({{dynamicSelectedZones[tab.typeId].length}})</a></li>
                    </template>
                  </ul>
                </div>
                <div class="content">
                  <template v-for="(tab, idx) in geozoneTypes">
                    <div v-if="tab.typeId !== zoneType || type === 'Route'" :key="idx" :id="idx" class="content-tab" v-show="activeTab === tab.typeId">
                    <div class="field-group">
                      <div class="field">
                        <div class="columns">
                          <div class="column is-5">
                            <div class="select select-location is-multiple">
                              <select :disabled="mode === 'view'" :size="getGeofences('Geozone', tab.typeId).length+1" class="select2 select-multiple" v-model="dynamicZones[tab.typeId]">
                                <option v-for="(zone, index) in getGeofences('Geozone', tab.typeId)" :key="index" :value="zone.mapId">{{zone.name | zoneLabel(zone, tab.showDesc)}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="column is-2">
                            <div class="origin-manage" align="center">
                              <base-icon name="right" v-if="dynamicZones[tab.typeId]!==''" v-tooltip="'Add Origin Location'" @click="addDynamicGeoZone(tab.typeId)"></base-icon>
                            </div>
                          </div>
                          <div class="column is-5">
                            <span v-for="zone in dynamicZoneSort('dynamicSelectedZones', tab.typeId)" :key="zone">
                              <span class="tag is-primary boldtext loc-options">
                                {{geofences[zone].name}}
                                <button type="button" class="delete is-small" @click="removeDynamicGeoZone(geofences[zone].mapId, tab.typeId)"></button>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </template>
                </div>
              </div>
              <div class="field">
                <input type="checkbox" v-model="geoRouteStatus" /> Enable
              </div>
            </div>
          </div>
        </div>
        <div class="geozone_section" v-else>
          <div class="field-group">
            <div class="field">
              <div class="columns">
                <div class="column" v-show="showMap()">
                  <div class="field">
                    <intelyt-map
                      :markers="markers"
                      :polygons="polygons"
                      :pathList="getPolylineList"
                      :mapWidth="600"
                      :zoom="-1"
                      @polygon_changed="updatePolygon($event)"
                      @polyline_changed="updatePolyline($event)"
                    >
                    </intelyt-map>
                  </div>
                </div>
                <div class="column">
                  <div class="columns">
                    <div class="column">
                      <div>
                        <label class="label">Name</label>
                        <p class="control">
                          <ValidationProvider name="Name" :rules="{required: true, max: 25, regex: /^(\w|\s|-)+$/}"  v-slot="{ errors }">
                            <input class="input" :class="{'input': true, 'is-danger': errors[0] }" type="text" v-model="fence.name" name="name" :disabled="!isNew || mode === 'view'">
                            <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </p>
                      </div>
                      <div v-show="isNew && !isPathType() && mode !== 'view'" id="fenceAddress">
                        <label class="label">Geofence Address</label>
                        <p class="control" >
                          <gmap-autocomplete
                            class="input"
                            :value="autocomplete"
                            @place_changed="setPlace"
                            placeholder="Enter Geozone Center Address">
                          </gmap-autocomplete>
                          <span v-show="fence.points.length === 0" class="help is-danger">The Geofence Address field is required</span>
                        </p>
                      </div>
                      <div class="field" v-if="isNew && isPathType() && mode !== 'view'">
                        <div class="columns">
                          <div class="column">
                            <div class="label">Origin</div>
                            <div>
                              <label class="radio">
                                <input type="radio" name="originOption" value="enter" v-model="originOption" checked="checked">
                                Enter Destination
                              </label>
                            </div>
                            <div>
                              <label class="radio">
                                <input type="radio" name="originOption" value="select" v-model="originOption">
                                Select Destination
                              </label>
                            </div>
                            <gmap-autocomplete
                              v-if="originOption === 'enter'"
                              class="input"
                              :value="pathOriginStr"
                              :disabled="decodedPoints.length > 0"
                              @place_changed="setPathOrigin"
                              placeholder="Enter Origin">
                            </gmap-autocomplete>
                            <div class="select" v-else>
                              <select @change="setPathOrigin" v-model="selectPathOrigin" :disabled="decodedPoints.length > 0">
                                <option value="">Select Origin</option>
                                <template v-for="(location, index) in locations">
                                  <option v-if="index !== selectPathDestination" :key="index" :value="index">{{location.name}}</option>
                                </template>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label class="label">&nbsp;</label>
                        <p class="control" >
                          <input type="checkbox" v-model="geoRouteStatus" :disabled="isDisabled" /> Enable
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div>
                        <label class="label">Description</label>
                        <p class="control">
                          <ValidationProvider name="Description" :rules="{max: 75, regex: /^(\w|\s|-)+$/}"  v-slot="{ errors }">
                            <input class="input" :class="{'input': true, 'is-danger': errors[0] }" type="text" v-model="fence.description" name="description" :disabled="isDisabled">
                            <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </p>
                      </div>
                      <div class="field" v-if="isNew && isPathType()">
                        <div class="columns">
                          <div class="column">
                            <div class="label">Destination</div>
                            <div>
                              <label class="radio">
                                <input type="radio" name="destinationOption" value="enter" v-model="destinationOption" checked="checked">
                                Enter Destination
                              </label>
                            </div>
                            <div>
                              <label class="radio">
                                <input type="radio" name="destinationOption" value="select" v-model="destinationOption">
                                Select Destination
                              </label>
                            </div>
                            <gmap-autocomplete
                              v-if="destinationOption === 'enter'"
                              class="input"
                              :value="pathDestinationStr"
                              @place_changed="setPathDestination"
                              :disabled="decodedPoints.length > 0"
                              placeholder="Enter Destination">
                            </gmap-autocomplete>
                            <div class="select" v-else>
                              <select @change="setPathDestination" v-model="selectPathDestination" :disabled="decodedPoints.length > 0">
                                <option value="">Select Destination</option>
                                <template v-for="(location, index) in locations">
                                  <option v-if="index !== selectPathOrigin" :key="index" :value="index">{{location.name}}</option>
                                </template>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="map-controls">
                    <a class="button is-primary" @click="getDecodedPoints()" v-if="decodedPoints.length === 0 && (Object.values(pathOriginObj).length > 0 && Object.values(pathDestinationObj).length > 0) && polylinePoints.length > 0 && mode !== 'edit'">Get Route</a>
                    <div class="select" v-if="decodedPoints.length > 0">
                      <select v-model="toleranceInput">
                        <option v-for="(label, value) in toleranceInputs" :key="value" :value="value">{{label}}</option>
                      </select>
                    </div>
                    <a class="button is-primary" @click="simplifyPoints()" v-if="decodedPoints.length > 0">Calculate Path</a>
                    <span v-if="decodedPoints.length > 0">Decoded Points {{decodedPoints.length}} | </span>
                    <span v-if="decodedPoints.length > 0">Simplified Points {{simplifiedPoints.length}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <template slot="footer">
        <div>
          <div class="content" v-if="errorMessage.length > 1">
            <p class="has-text-danger">Error: {{errorMessage}}</p>
          </div>
          <div>
            <template v-if="mode !== 'view'">
              <a class="button is-primary" @click="submit()" v-if="!invalid && isValid">Submit</a>
              <a class="button is-primary" @click="submit()" v-if="fence.isUpdated && !isDisabled && !isNew && !isGroup && !saveInProgress">Update</a>
              <a class="button is-primary" @click="resetGeofence()" v-if="polylinePoints.length > 0 && zoneType === 0 && mode !== 'edit'">Reset</a>
            </template>
            <a class="button" @click="close()">Cancel</a>
          </div>
        </div>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'
import IntelytMapLegend from '@/components/IntelytMapLegend'
import {mapActions, mapState} from 'vuex'
import {round, centroid, buildMarker, isJSON} from '@/store/helpers.js'
import moment from 'moment-timezone'
import {simplifyDouglasPeucker} from '../../static/js/douglasPeucker.js'

export default {
  components: {
    IntelytModal,
    IntelytMapLegend
  },
  computed: {
    allButSelf: function () {
      // Returns a sorted list of all geofences except the one which is selected
      // Sort is case insensisitve
      const srtFun = function (a, b) {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) { return -1 }
        if (x > y) { return 1 }
        return 0
      }
      const objs = Object.values(this.$store.state.geofences.all).sort(srtFun)
      return objs.filter(pt => pt.mapId !== this.mapId)
    },
    getModalWidth: function () {
      return (this.type === 'Route' || this.mode === 'edit' || this.mode === 'view' || this.zoneType === 4 || (this.fence.points.length > 0 || this.polylinePoints.length > 0)) ? 1280 : 640
    },
    isGroup: function () {
      return this.type === 'Route' || this.zoneType === 4
    },
    isNew: function () {
      return this.mode.includes('add')
    },
    isValid: function () {
      const points = this.simplifiedPoints.length > 0 ? this.simplifiedPoints.length : this.fence.points.length
      const validZone = (this.fence.name.length * points) > 0
      const validGroup = this.isGroup && this.fence.name.length > 0
      return ((validZone || validGroup) && !this.saveInProgress)
    },
    isDisabled: function () {
      if (this.mode === 'view') return true
      // Get configuration from state and use mapConfigTypeId to lookup whether element is editable.
      const geozoneConfig = this.$store.state.configuration.geozoneTypes
      if (typeof this.geofence.mapConfigTypeId === 'undefined') {
        return false
      }
      return !geozoneConfig[this.geofence.mapConfigTypeId].editable
    },
    markers: function () {
      return []
    },
    // Returs the polylines for the selected route
    getPolylineList: function () {
      if (this.polylinePoints.length === 0) return []
      const pathList = []
      const lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeColor: '#FF0000',
        strokeOpacity: 1,
        scale: 2
      }
      const optionDashed = {
        geodesic: true,
        strokeOpacity: 0,
        icons: [{
          icon: lineSymbol,
          offset: '0',
          repeat: '15px'
        }]
      }
      const points = this.simplifiedPoints.length > 0 ? this.simplifiedPoints : this.polylinePoints
      let options = optionDashed
      if (this.decodedPoints.length > 0) {
        const DPOptions = { // DPOptions => Decoded Point options
          geodesic: true,
          strokeColor: '#FF0000',
          strokeOpacity: 1,
          strokeWeight: 2,
          icons: []
        }
        pathList.push({path: this.decodedPoints, options: DPOptions, editable: false})
        if (this.simplifiedPoints.length === 0) {
          return pathList
        }
        options = {...DPOptions}
        options.strokeColor = '#009900'
      }
      if (!this.isNew) {
        options = { // DPOptions => Decoded Point options
          geodesic: true,
          strokeColor: '#009900',
          strokeOpacity: 1,
          strokeWeight: 2,
          icons: []
        }
      }
      pathList.push({path: points, options: options, editable: this.mode !== 'view'})

      return pathList
    },
    /***
     * polygons - Used in the create or edit the geo zone type
     * returns object with points and editable access
     */
    polygons: function () {
      if (this.zoneType === 0) {
        return {editable: false, paths: []}
      }
      return this.selected ? {editable: !this.isDisabled, paths: [this.geofence.coordinates]} : {editable: !this.isDisabled, paths: [this.fence.points]}
    },
    /***
     * routePolygons - Used in the create or edit the Route type
     * returns object with points and editable access
     */
    routePolygons: function () {
      // If geofence not available in activeMarkers or the geofence icon is not active
      // then return empty list
      if (!this.activeMarkers.geofence || !this.activeMarkers.geofence.active) {
        return {editable: false, paths: []}
      }
      const geofences = this.$store.state.geofences.all
      let polygonList = []
      /**
       * getAssociations - This method is used to get the nested associations for the given route
       * It's a recursive method to iterate the each associations and it's nested associations until
       * it founds no associations
       */
      const getAssociations = (id) => {
        const associations = geofences[id] ? [...geofences[id].associationList] : false
        if (associations) {
          for (const assoc of associations) {
            associations.push(...getAssociations(assoc))
          }
          return associations
        }
        return false
      }
      Object.values(this.dynamicSelectedZones).forEach((val) => {
        const zones = Object.values(val)
        let nestedAssoc = []
        zones.forEach((zone) => {
          nestedAssoc.push(zone)
          const getNestedAssoc = getAssociations(zone)
          if (getNestedAssoc.length > 0) {
            nestedAssoc.push(...getNestedAssoc)
          }
        })
        for (let zone of nestedAssoc) {
          if (!geofences[zone].initialized || geofences[zone].coordinates.length === 0 || parseInt(geofences[zone].mapConfigTypeId) === 0) {
            continue
          }
          let polygon = []
          for (let node of geofences[zone].coordinates) {
            const position = {
              lat: Number.parseFloat(node.lat),
              lng: Number.parseFloat(node.lng)
            }
            polygon.push(position)
          }
          polygonList.push(polygon)
        }
      })
      return {editable: false, paths: polygonList}
    },
    /***
     * routePolylines - Get the polylines for the selected route
     * returns object with points and editable access
     */
    routePolylines: function () {
      // If geofence not available in activeMarkers or the geofence icon is not active
      // then return empty list
      if (!this.activeMarkers.geofence || !this.activeMarkers.geofence.active) {
        return []
      }
      const geofences = this.$store.state.geofences.all
      const pointOption = {
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 0.5,
        strokeWeight: 5,
        icons: []
      }
      let pathList = []
      /**
       * getAssociations - This method is used to get the nested associations for the given route
       * It's a recursive method to iterate the each associations and it's nested associations until
       * it founds no associations
       */
      const getAssociations = (id) => {
        const associations = geofences[id] ? [...geofences[id].associationList] : false
        if (associations) {
          for (const assoc of associations) {
            associations.push(...getAssociations(assoc))
          }
          return associations
        }
        return false
      }
      Object.values(this.dynamicSelectedZones).forEach((val) => {
        const zones = Object.values(val)
        let nestedAssoc = []
        zones.forEach((zone) => {
          nestedAssoc.push(zone)
          const getNestedAssoc = getAssociations(zone)
          if (getNestedAssoc.length > 0) {
            nestedAssoc.push(...getNestedAssoc)
          }
        })
        for (let zone of nestedAssoc) {
          if (!geofences[zone].initialized || geofences[zone].coordinates.length === 0 || parseInt(geofences[zone].mapConfigTypeId) !== 0) {
            continue
          }
          let points = []
          for (let node of geofences[zone].coordinates) {
            const position = {
              lat: Number.parseFloat(node.lat),
              lng: Number.parseFloat(node.lng)
            }
            points.push(position)
          }
          pathList.push({path: points, options: pointOption, editable: false})
        }
      })
      return pathList
    },
    /***
     * routeMarkers - Used in the create or edit the Route type
     * returns the marker object with the location details like origin, destination
     * and geozone details
     */
    routeMarkers: function () {
      const geofences = this.$store.state.geofences.all
      let markerList = []
      const activeMarkers = {...this.activeMarkers}
      let indx = 1
      // If geofence available in activeMarkers and the geofence icon is active
      // then loop through the item and create markers
      if (this.activeMarkers.geofence && this.activeMarkers.geofence.active) {
        Object.values(this.dynamicSelectedZones).forEach((val) => {
          const zones = Object.values(val)
          for (let ids of zones) {
            const zone = geofences[ids]
            const zoneConfig = this.$store.state.configuration.geozoneTypes[zone.mapConfigTypeId]
            // If the mapConfigTypeId has the hideMarker setting, the don't add it to marker list
            if (zoneConfig.hideMarker) continue
            const status = zone.enabled ? 'Active' : 'Deactivated'
            let center = centroid(zone.coordinates)
            let marker = {
              id: indx,
              icon: 'geofence',
              name: zone.name,
              position: {
                lat: Number.parseFloat(center.lat),
                lng: Number.parseFloat(center.lng)
              },
              infoText: `Name: ${zone.name}</br>Status: ${status}`,
              timestamp: 0
            }
            indx += 1
            markerList.push(marker)
          }
        })
        // If atleast one geofence found, then show the geofence legend in list
        if (markerList.length > 0) activeMarkers.geofence.hide = false
      }
      const usertmzn = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const addMark = (val, type) => {
        const originObj = {
          class: type,
          datetime: moment(val.createDate, 'ddd MMM D HH:mm:ss YYYY'),
          latitude: val.latitude,
          longitude: val.longitude,
          origin: val.name,
          destination: val.name,
          timestamp: moment(val.createDate, 'YYYY-MM-DD HH:mm:ssZ').format('x')
        }
        indx += 1
        markerList.push(buildMarker(originObj, indx, usertmzn))
      }
      // If origin available in activeMarkers and the origin icon is active
      // add the origin marker to the map
      if (this.activeMarkers.origin && this.activeMarkers.origin.active) {
        // If atleast one origin found, then show the origin legend in list
        if (Object.values(this.origins).length > 0) activeMarkers.origin.hide = false
        Object.values(this.origins).forEach((val) => {
          addMark(val, 'origin')
        })
      }
      // If destination available in activeMarkers and the destination icon is active
      // add the destination marker to the map
      if (this.activeMarkers.destination && this.activeMarkers.destination.active) {
        // If atleast one destination found, then show the destination legend in list
        if (Object.values(this.destination).length > 0) activeMarkers.destination.hide = false
        Object.values(this.destination).forEach((val) => {
          addMark(val, 'destination')
        })
      }
      // Update the activeMarker with updated values
      this.setActiveMarkers(activeMarkers)
      return markerList
    },
    /***
     * Returns the modal title based on the type
     * returns Route detail | Geozone detail
     */
    title: function () {
      const titleStart = this.isGroup ? 'Route' : 'Geozone'
      const titleEnd = ' Detail'
      return titleStart + titleEnd
    },
    ...mapState({
      // company: 'company',
      // geofences: 'geofences'
    })
  },
  data () {
    const geozoneTypes = Object.values(this.$store.state.configuration.geozoneTypes).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
    const geofences = this.$store.state.geofences.all
    const dynamicZones = {}
    const dynamicSelectedZones = {}
    geozoneTypes.forEach((index, value) => {
      dynamicZones[value] = ''
      dynamicSelectedZones[value] = []
    })
    const locations = this.$store.state.locations.all
    const controlIcons = [{
      icon: 'cancel',
      callback: this.removeLocation,
      tooltip: 'Remove Location'
    }]
    const toleranceInputs = {
      5: 5,
      10: 10,
      15: 15,
      20: 20,
      25: 25
    }
    let pageOptions = this.$store.state.configuration.pageOptions.editFenceModal
    const activeMarkers = {}
    // Loop through the marker item and set the active and hide properties for activeMarkers
    const pageMarkers = pageOptions.map && pageOptions.map.markers ? pageOptions.map.markers : []
    pageMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    return {
      autocomplete: '',
      activeMarkers,
      activeTab: geozoneTypes[0].typeId || 0,
      decodedPoints: [],
      destinationOption: 'enter',
      originOption: 'enter',
      simplifiedPoints: [],
      errorMessage: '',
      fence: {
        name: '',
        center: {},
        points: []
      },
      geozoneTypes: geozoneTypes,
      geoRouteStatus: true,
      dynamicZones: dynamicZones,
      dynamicSelectedZones: dynamicSelectedZones,
      geofences: geofences,
      inside: true,
      isUpdated: false,
      pathDestinationStr: '',
      pathOriginStr: '',
      locations: locations,
      locationOptions: locations,
      locationColumns: [
        {name: '', id: 'name'},
        {name: '', id: 'shippingLocId', controls: controlIcons}
      ],
      mapConfigType: this.zoneType > -1 ? this.zoneType : 0,
      options: {
        paginate: false,
        sortable: false,
        sortIndex: 0,
        sortType: 'desc'
      },
      origins: {},
      destination: {},
      selectedLocations: '',
      routeFields: {},
      routeTripOption: true,
      routeMapMarkers: pageMarkers,
      saveInProgress: false,
      selectPathOrigin: '',
      selectPathDestination: '',
      toleranceInputs: toleranceInputs,
      toleranceInput: 5,
      zoom: -1,
      zoomFixed: false,
      pathDestinationObj: {},
      pathOriginObj: {},
      polylinePoints: [],
      zonePathList: []
    }
  },
  filters: {
    zoneLabel: function (name, zone, showDesc) {
      if (!showDesc || !zone.description) {
        return name
      }
      let zoneLabel = `${name} [${zone.description}]`
      zoneLabel = zoneLabel.length > 17 ? `${zoneLabel.substring(0, 17)}...` : zoneLabel
      return zoneLabel
    }
  },
  methods: {
    /**
     * Activate the clicked tab and show it's content
     * @param {tabId} String - Clicked tab id of the tab
     * Note: Check current tab is activated or not. If not then active the tab
     * Reset the tab fields while change the tab
     */
    activateTab (tabId) {
      if (this.activeTab !== tabId) {
        this.activeTab = tabId
      }
    },
    /**
     * addOriginLocation - This method adds the selected origin value
     * in the this.origins object
     */
    addOriginLocation: function () {
      const selectKey = this.selectedLocations
      let selectOrigin = {}
      this.locationOptions = this.removeData(this.locationOptions, selectKey)
      selectOrigin[selectKey] = this.locations[selectKey]
      this.origins = {...this.origins, ...selectOrigin}
    },
    /**
     * addDestionationLocation - This method adds the selected destination value
     * in the this.destination object
     */
    addDestionationLocation: function () {
      let selectDestination = {}
      const selectKey = this.selectedLocations
      this.locationOptions = this.removeData(this.locationOptions, selectKey)
      selectDestination[selectKey] = this.locations[selectKey]
      this.destination = {...this.destination, ...selectDestination}
    },
    /**
     * addDynamicGeoZone - This method adds the selected zone value
     * in the this.dynamicZones object
     * @param {zoneType} - Is a numeric value define the geo zone type like
     * path, area, airport...
     */
    addDynamicGeoZone: function (zoneType) {
      if (typeof this.dynamicZones[zoneType] === 'undefined' || this.dynamicZones[zoneType] === '') {
        return
      }
      const selectedZones = this.dynamicZones[zoneType]
      this.dynamicSelectedZones[zoneType].push(selectedZones)
      this.dynamicZones[zoneType] = ''
      this.getRouteDetails({mapId: selectedZones})
    },
    /**
     * Get and return the sorting data
     * @param {String} data - computed or data property
     * @returns {Array} - return the sorting data
     */
    getSortData: function (data) {
      return Object.values(this[data]).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
    },
    isPathType: function () {
      return this.zoneType === 0
    },
    /**
     * Sorting the selected geozones data with type
     * @param {String} selectZone - computed or data property
     * @param {String} typeId - Id of geofence type
     * @returns {Array} - return the sorting data
     */
    dynamicZoneSort: function (selectZone, typeId) {
      // mapId
      const allZones = {...this.$store.state.geofences.all}
      // const filteredZones = Object.values(this.$store.state.geofences.all).filter(geo => this[selectZone][typeId].includes(geo.mapId))
      const filteredZones = this[selectZone][typeId].map(mapId => { return {...allZones[mapId]} })
      const sortedValues = filteredZones.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
      const mapSort = sortedValues.map(geo => geo.mapId)
      return mapSort
    },
    // Get the decoded points using the direction API for the given origin and destination.
    getDecodedPoints: function () {
      const vObj = this
      const origin = new window.google.maps.LatLng(this.pathOriginObj.lat, this.pathOriginObj.lng)
      const destination = new window.google.maps.LatLng(this.pathDestinationObj.lat, this.pathDestinationObj.lng)
      const directionService = new window.google.maps.DirectionsService()
      const waypts = this.polylinePoints.map(pts => {
        return {location: new window.google.maps.LatLng(pts.lat, pts.lng), stopover: false}
      })
      const payload = {
        origin: origin,
        destination: destination,
        waypoints: waypts,
        travelMode: window.google.maps.TravelMode.DRIVING
      }
      directionService.route(payload, function (response, status) {
        if (status === 'OK') {
          // We can user overview_path data onstrad of decode converting
          const decodedPoints = window.google.maps.geometry.encoding.decodePath(response.routes[0].overview_polyline)
          const getRoutepoints = decodedPoints.map(pts => {
            return {lat: pts.lat(), lng: pts.lng()}
          })
          vObj.decodedPoints = getRoutepoints
          vObj.zoom = 8
          vObj.zoomFixed = true
        } else {
          window.alert('Directions request failed due to ' + status)
        }
      })
    },
    simplifyPoints () {
      const simplifiedPoints = simplifyDouglasPeucker(this.decodedPoints, this.toleranceInput)
      this.simplifiedPoints = simplifiedPoints
    },
    /**
     * removeLocation - This method remove the selected origin | destination value
     * from the respective location object(this.origins|this.destination)
     */
    removeLocation: function (loc) {
      const selectKey = loc.shippingLocId
      this.locationOptions[selectKey] = this.locations[selectKey]
      if (this.destination[selectKey]) {
        this.destination = this.removeData(this.destination, selectKey)
      } else {
        this.origins = this.removeData(this.origins, selectKey)
      }
    },
    /**
     * removeData - This method is commonly used in the
     * following methods removeLocation, addDestionationLocation
     * and addOriginLocation to remove a location value form the
     * given location type like origin or destination
     */
    removeData: function (data, selectKey) {
      const allLocation = this.locations
      return Object.keys(data).reduce((object, key) => {
        if (key !== selectKey) {
          object[key] = allLocation[key]
        }
        return object
      }, {})
    },
    /**
     * removeDynamicGeoZone - This method removes the selected zone
     * from it's association type like path, area, airport
     * @param {mapId} - (Int) Identifier for the zone
     * @param {type} - (Int) Identifier for the zone type
     */
    removeDynamicGeoZone: function (mapId, type) {
      const index = this.dynamicSelectedZones[type].indexOf(String(mapId))
      if (index > -1) {
        this.dynamicSelectedZones[type].splice(index, 1)
      }
    },
    /**
     * getGeofences - get the list of given class and type
     * @param {cls} - String value like Route | Geozone
     * @param {type} - Int value like 0 | 1 | 2
     */
    getGeofences (cls, type) {
      let zones = []
      const allZones = {...this.$store.state.geofences.all}
      const geofences = Object.values(allZones).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
      const selectedZones = this.dynamicSelectedZones[type]
      const geofencesData = (type !== '') ? geofences.filter(x => parseInt(x.mapConfigTypeId) === type && !selectedZones.includes(String(x.mapId))) : geofences
      for (let zone of geofencesData) {
        if (zone.mapConfigNameType === cls) {
          zone.pointCount = zone.coordinates.length
          zones.push(zone)
        }
      }
      return zones
    },
    close () {
      const mapId = this.fence.mapId
      this.resetGeofence()
      this.$emit('close', mapId)
    },
    resetGeofence () {
      this.locationOptions = this.locations
      this.origins = {}
      this.destination = {}
      this.pathOriginStr = ''
      this.destinationOption = 'enter'
      this.originOption = 'enter'
      this.pathDestinationStr = ''
      this.selectPathOrigin = ''
      this.selectPathDestination = ''
      this.pathOriginObj = {}
      this.pathDestinationObj = {}
      this.polylinePoints = []
      this.decodedPoints = []
      this.simplifiedPoints = []
      this.activeTab = this.geozoneTypes[0].typeId || 0
      this.routeTripOption = true
      this.mapConfigType = this.zoneType > -1 ? this.zoneType : 0
      this.geoRouteStatus = true
      this.saveInProgress = false
      // Fence object holds all information for geofence definition and updates
      let fence = {
        name: '',
        mapId: '',
        alternateNames: [],
        associations: [],
        class: '',
        center: {address: '', latitude: 0, longitude: 0},
        description: '',
        inside: true,
        isUpdated: (this.mode === 'edit'),
        points: [],
        selectedAssociations: [],
        updatedPoints: []
      }
      const geozoneTypes = Object.values(this.$store.state.configuration.geozoneTypes)
      geozoneTypes.forEach((index, value) => {
        this.dynamicZones[value] = ''
        this.dynamicSelectedZones[value] = []
      })
      // Error message used if return code from API call has error
      this.errorMessage = ''
      this.autocomplete = ''
      this.$set(this, 'fence', fence)
      this.$refs.observer.reset()
    },
    // Replace the activeMarkers data property with updated marker values
    setActiveMarkers: function (activeMarkers) {
      this.activeMarkers = activeMarkers
    },
    setPlace (place) {
      // Called when new address is entered into form - sets up default diamond shape to
      // give user a starting place to begin editing
      const offsets = [[0, 0.01], [0.01, 0], [0, -0.01], [-0.01, 0]]
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      this.autocomplete = place.formatted_address
      this.fence.points = offsets.map(ofst => { return {lat: lat + ofst[0], lng: lng + ofst[1]} })
      this.fence.center.latitude = lat
      this.fence.center.longitude = lng
      this.updatePolygon(this.fence.points)
    },
    // Set the path destination based on the destination option(enter/select)
    setPathDestination (place) {
      if (this.destinationOption === 'enter') {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        this.pathDestinationStr = place.formatted_address
        this.pathDestinationObj = {lat: lat, lng: lng}
      } else {
        if (place.target.value === '') { // If the value is empty, remove the destination from the polyline points
          this.pathDestinationObj = {}
          const index = this.polylinePoints.length === 1 ? 0 : 1
          this.polylinePoints.splice(index, 1)
          if (typeof this.pathOriginObj.lat !== 'undefined') {
            this.polylinePoints.splice(0, 1, this.pathOriginObj)
          } else {
            this.polylinePoints.pop()
          }
          return
        }
        const location = this.locations[place.target.value]
        if (typeof this.pathOriginObj.lat === 'undefined') {
          this.polylinePoints.pop()
        }
        this.pathDestinationObj = {lat: parseFloat(location.latitude), lng: parseFloat(location.longitude)}
      }
      this.polylinePoints.splice(1, 1, this.pathDestinationObj)
      this.zoom = 5
      this.zoomFixed = true
    },
    // Set the path origin based on the origin option(enter/select)
    setPathOrigin (place) {
      if (this.originOption === 'enter') {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        this.pathOriginStr = place.formatted_address
        this.pathOriginObj = {lat: lat, lng: lng}
      } else {
        if (place.target.value === '') { // If the value is empty, remove the origin from the polyline points
          this.pathOriginObj = {}
          this.polylinePoints.splice(0, 1)
          if (typeof this.pathDestinationObj.lat !== 'undefined') {
            this.polylinePoints.splice(1, 1, this.pathDestinationObj)
          } else {
            this.polylinePoints.pop()
          }
          return
        }
        const location = this.locations[place.target.value]
        this.pathOriginObj = {lat: parseFloat(location.latitude), lng: parseFloat(location.longitude)}
      }
      this.zoom = 5
      this.zoomFixed = true
      this.polylinePoints.splice(0, 1, this.pathOriginObj)
      // If destination value found, add it to the polylinePoints object
      if (typeof this.pathDestinationObj.lat !== 'undefined') {
        this.polylinePoints.splice(1, 1, this.pathDestinationObj)
      }
    },
    showMap () {
      // Show map for new geofence once a valid address is found and center lat/lng is set
      return !this.isNew || this.fence.center.latitude !== 0 || this.polylinePoints.length > 0
    },
    submit () {
      // Transform current data (updatedPoints and alternateNames) into format required for upload
      //  - updatedPoints: list of objects with structure {latitude:#, longitude:#}
      //  - alternateNames: list of strings
      // =============== Code comment =============
      // const mappedPoints = this.fence.updatedPoints.map(pt => {
      //   return {latitude: round(pt.lat, 7), longitude: round(pt.lng, 7)}
      // })
      // const altNames = typeof this.fence.alternateNames === 'string' ? this.fence.alternateNames.split(',') : this.fence.alternateNames
      // // Build payload required for submission
      // const updatePayload = {
      //   mapName: this.fence.name,
      //   companyName: this.$store.state.company.name,
      //   mapId: this.fence.mapId,
      //   map: {
      //     description: this.fence.description,
      //     subMapID: this.fence.selectedAssociations,
      //     altNames: altNames,
      //     points: mappedPoints
      //   }
      // }
      // =============================================
      let alternativeNames = ''
      let associations = []
      let stateAssocs = []
      let notes = ''
      let pointIdList = []
      this.saveInProgress = true
      // const fences = this.$store.state.geofences.all
      // this.fence.mapId = (this.mode === 'edit') ? this.fence.mapId : Math.max(...Object.keys(fences)) + 1
      this.fence.mapId = (this.mode === 'edit') ? this.fence.mapId : ''
      if (this.type === 'Route' || this.zoneType === 4) {
        let alternativeTrip = []
        for (let loc1 of Object.values(this.origins)) {
          for (let loc2 of Object.values(this.destination)) {
            alternativeTrip.push(`${loc1.name}_${loc2.name}`)
          }
        }
        if (this.routeTripOption) {
          for (let loc1 of Object.values(this.destination)) {
            for (let loc2 of Object.values(this.origins)) {
              alternativeTrip.push(`${loc1.name}_${loc2.name}`)
            }
          }
        }
        let routeNotes = {
          origins: Object.keys(this.origins),
          destinations: Object.keys(this.destination),
          roundTrip: this.routeTripOption
        }
        alternativeNames = alternativeTrip.join(', ')
        notes = JSON.stringify(routeNotes)
        Object.values(this.dynamicSelectedZones).forEach((zones) => {
          zones.forEach((zone) => {
            stateAssocs.push({name: this.geofences[zone].name, id: zone, mapConfigTypeId: this.geofences[zone].mapConfigTypeId})
            associations.push(zone)
          })
        })
      } else {
        const simplifiedPoints = !this.isNew ? this.polylinePoints : this.simplifiedPoints
        const getPoints = simplifiedPoints.length > 0 ? simplifiedPoints : this.fence.updatedPoints
        pointIdList = Object.values(getPoints).map(pt => {
          return {lat: round(pt.lat, 7), lng: round(pt.lng, 7)}
        })
      }
      const createdDate = moment.utc().format('ddd MMM D HH:mm:ss YYYY')
      const updatedDate = moment.utc().format('ddd MMM D HH:mm:ss YYYY')
      let center = centroid(pointIdList)
      let statePayload = {
        center: center,
        coordinates: pointIdList,
        createDate: createdDate,
        description: this.fence.description,
        enabled: this.geoRouteStatus === true ? 1 : 0,
        associations: stateAssocs,
        associationList: associations,
        alternateNames: alternativeNames,
        lastUpdate: updatedDate,
        mapId: this.fence.mapId,
        mapConfigNameType: this.type,
        mapConfigTypeId: this.mapConfigType,
        notes: notes,
        pointIdList: Object.keys(pointIdList).join(';'),
        name: this.fence.name,
        class: (this.type === 'Route') ? 'group' : 'fence',
        status: (this.geoRouteStatus === true) ? 'Enabled' : 'Disabled',
        pointCount: pointIdList.length,
        initialized: true
      }
      // this.$store.commit('geofences/SET_STATIC_GEOFENCES', updatePayload)
      const zoneTypeConfig = Object.values(this.$store.state.configuration.geozoneTypes).filter(type => parseInt(this.mapConfigType) === type.typeId)[0]
      let mappedPoints = []
      const simplifiedMapPoints = !this.isNew ? this.polylinePoints : this.simplifiedPoints
      let payloadPoints = simplifiedMapPoints.length > 0 ? simplifiedMapPoints : this.fence.updatedPoints
      if (payloadPoints.length > 0) {
        mappedPoints = Object.values(payloadPoints).map(pt => {
          return {latitude: round(pt.lat, 7), longitude: round(pt.lng, 7), pointType: zoneTypeConfig.pointType}
        })
      }
      const altNames = typeof alternativeNames === 'string' ? alternativeNames.split(',') : this.fence.alternateNames
      // Build payload required for submission
      const updatePayload = {
        mapName: this.fence.name,
        companyName: this.$store.state.company.name,
        mapId: this.fence.mapId,
        map: {
          description: this.fence.description,
          subMapID: associations,
          altNames: altNames,
          mapConfigNameType: this.type,
          mapConfigTypeId: this.mapConfigType,
          notes: notes,
          points: mappedPoints,
          enabled: this.geoRouteStatus
        }
      }
      const isNew = (this.mode === 'add')
      this.errorMessage = ''
      this.editRouteMap([isNew, updatePayload, statePayload]).then(response => {
        if (isNew && this.type === 'Route') {
          this.$emit('setMapId', response.mapId)
        }
        this.saveInProgress = false
        this.$emit('close')
      }).catch(e => {
        // If error, set error message and leave modal open for correction
        this.errorMessage = e.message
        this.saveInProgress = false
      })
    },
    updatePolygon (points) {
      // Called if user manipulates map points & when points are first created.
      // Sets isUpdated to allow for submit button to enable.
      this.fence.isUpdated = true
      this.fence.updatedPoints = points
    },
    updatePolyline (points) {
      if (this.decodedPoints.length > 0) {
        this.simplifiedPoints = points
        return
      }
      this.polylinePoints = points
    },
    ...mapActions({
      editRouteMap: 'geofences/editRouteMap',
      getRouteDetails: 'geofences/getRouteDetails',
      getAssociations: 'geofences/initializeAssocisationsList'
    })
  },
  mounted () {
    this.resetGeofence()
  },
  // props: ['isActive', 'mode', 'type', 'selected', 'geofence', 'zoneType'],
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'add'
    },
    type: {
      type: String,
      default: 'Route'
    },
    selected: {
      type: [Number, String],
      default: 0
    },
    geofence: {
      type: [Object, Boolean],
      default: () => ({})
    },
    zoneType: {
      type: Number,
      default: -1
    }
  },
  watch: {
    isActive: function () {
      this.getAssociations()
      this.resetGeofence()
      const val = this.geofence
      if (typeof val.name === 'undefined') {
        return
      }
      const fences = this.$store.state.geofences.all
      for (let key in val.associationList) {
        let zone = val.associationList[key]
        const mapConfigTypeId = fences[zone].mapConfigTypeId || 0
        if (typeof this.dynamicSelectedZones[mapConfigTypeId] === 'undefined') {
          this.dynamicSelectedZones[mapConfigTypeId] = []
        }
        this.dynamicSelectedZones[mapConfigTypeId].push(zone)
      }
      let locations = isJSON(val.notes) ? JSON.parse(val.notes) : ''
      // let locations = val.notes !== '' ? JSON.parse('{"origins":[35,37],"destinations":[2],"roundTrip":true}') : ''
      this.routeTripOption = false
      if (typeof locations === 'object') {
        this.routeTripOption = locations.roundTrip
        const origins = locations.origins
        const destinations = locations.destinations
        for (let key in origins) {
          this.selectedLocations = origins[key]
          this.addOriginLocation()
          this.selectedLocations = ''
        }
        for (let key in destinations) {
          this.selectedLocations = destinations[key]
          this.addDestionationLocation()
          this.selectedLocations = ''
        }
      }
      // When geofence property is changed (in parent page) update the key parameters
      this.fence.mapId = val.mapId
      this.fence.name = val.name
      this.fence.associations = val.associations
      this.fence.selectedAssociations = val.associations.map(pt => pt.id)
      this.fence.alternateNames = val.alternateNames
      this.fence.description = val.description
      if (this.zoneType === 0) {
        this.polylinePoints = val.coordinates
        this.fence.updatedPoints = []
      } else {
        this.fence.updatedPoints = val.coordinates
      }
      this.fence.class = val.class
      this.mapConfigType = val.mapConfigTypeId
      this.geoRouteStatus = (parseInt(val.enabled) === 1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.select-multiple, .select-location {
  width: 100%;
}
.select-location select {
  height: 160px;
  width: 100%;
}
.select-multiple select {
  height: 80px;
  width: 100%;
  margin-bottom: 10px;
}
.origin-manage,
.destination-manage {
  margin-top: 25px;
  cursor: pointer;
}
.loc-options {
  margin-bottom: 2px;
  margin-right: 2px;
}
.location-group {
  min-height: 80px;
}
.tabs ul.noMarginleft .is-active a {
    font-weight: bold;
    color: #000;
}
</style>
