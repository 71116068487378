<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      v-on:close="close()"
    >
      <div class="content">
        <div class="control" v-if="displayMessage">
          {{message}}
        </div>
        <div>
          <div class="content" v-if="!displayMessage">
            To recover your username, enter the email address configured for your account in the iConnect system.  Your username will be sent to this address.
          </div>
          <div class="control is-horizontal" v-if="!displayMessage">
            <div class="control-label">
              <label class="label">Email Address</label>
            </div>
            <div class="control is-vertical">
              <ValidationProvider name="Email" rules="required|email"  v-slot="{ errors }">
                <input v-model="email" type="text" name="email" class="input"/>
                <span class="help is-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
          <button class="button is-primary" @click="retrieve()" :disabled="invalid" v-if="!displayMessage">Retrieve</button>
          <a class="button" @click="close()" v-if="!displayMessage">Cancel</a>
          <a class="button" @click="close()" v-if="displayMessage" >Close</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex'
import IntelytModal from '@/components/IntelytModal'

export default {
  components: {
    IntelytModal
  },
  computed: {
  },
  data () {
    return {
      title: 'Retrieve Forgotten Username',
      displayMessage: false,
      email: '',
      message: ''
    }
  },
  methods: {
    close () {
      this.email = ''
      this.displayMessage = false
      this.message = ''
      this.$emit('close')
    },
    retrieve () {
      this.$refs.observer.validate().then(result => {
        if (result) {
          this.$store.dispatch('sendUsername', this.email).then(response => {
            this.message = response.status[0][0]
            this.displayMessage = true
          }).catch(e => {
            console.log(e)
          })
        }
      })
    },
    ...mapActions([
      'sendUsername'
    ])
  },
  // props: ['isActive'],
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isActive: {
      handler: function () {
        this.$refs.observer.reset()
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
