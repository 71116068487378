<template>
  <div class="modal" :class="{'is-active': isActive}" @click="close">
    <div class="modal-background"></div>
    <div class="modal-card" v-bind:style="modalStyle" @click.stop >
      <section class="modal-card-head">
         <div style="width:100%;">
           <div class="modal-card-title">
              <span>{{title}}</span>
              <div class="is-pulled-right">
                <span class="copy-link-section" v-if="copyLinkTxt.length > 0 && !copyLinkClicked">
                  <input type="text" class="input copy-link-field" readonly :value="copyLinkTxt" :id="`copyLinkTxt-${uniqueId}`" />
                  <base-icon :style="'cursor:pointer'" :name="'link_add'" :size="30" :color="'blue'" v-tooltip="'Copy link'" @click="copyLink()"></base-icon>&nbsp;
                </span>
                <template v-if="modalControls && modalControls.length > 0" >
                  <span v-for="(ctrl, idx) in modalControls" :key="idx">
                    <base-icon v-show="ctrl.icon.show" :style="'cursor:pointer'" :name="ctrl.icon.name" :size="ctrl.icon.size || 25" :color="ctrl.icon.color || 'blue'" v-tooltip="ctrl.icon.label" @click="ctrl['callback']()"></base-icon>&nbsp;
                  </span>
                </template>
              </div>
           </div>
         </div>
      </section>
      <section class="modal-card-body">
        <slot></slot>
      </section>
      <footer class="modal-card-foot">
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    modalStyle: function () {
      return { width: this.width + 'px' }
    }
  },
  data () {
    return {
      copyLinkClicked: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    copyLink () {
      const vm = this
      vm.copyLinkClicked = true
      const copyText = document.getElementById(`copyLinkTxt-${this.uniqueId}`)
      // Select the text field
      copyText.select()
      copyText.setSelectionRange(0, 99999) // For mobile devices
      //  Copy the text inside the text field
      document.execCommand('copy')
      setTimeout(() => { vm.copyLinkClicked = false }, 1500)
    }
  },
  mounted: function () {
    document.addEventListener('keydown', (e) => {
      if (this.isActive && e.keyCode === 27) {
        this.close()
      }
    })
  },
  props: {
    isActive: Boolean,
    title: String,
    width: {
      type: Number,
      default: 640
    },
    copyLinkTxt: {
      type: String,
      default: ''
    },
    modalControls: {
      type: [Array, Boolean],
      default: false
    },
    uniqueId: {
      type: String,
      default: ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.modal.is-active {
  position: fixed;
}
.copy-link-section {
  float: right;
}
.copy-link-field {
  position: absolute;
  left: -9999999px;
}
</style>
