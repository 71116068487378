<template>
    <div>
        <!-- {{ label }} - Radio {{idx}} -->
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">{{label}}</label>
          </div>
          <div class="control">
            <label class="radio">
              <ValidationProvider :name="label" :rules="formRules"  v-slot="{ errors }">
                <template  v-for="(opt, index) in options">
                  <input :key="index" type="radio" :value="opt.value" v-model="fieldValue">
                  <label :key="`label-${index}`" :for="opt.value">{{opt.label}} &nbsp;</label>
                </template>
                <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </label>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'RadioElement',
  data () {
    return {
      fieldValue: ''
    }
  },
  props: {
    label: String,
    mode: String,
    default: String,
    field: String,
    options: Array,
    formRules: Object,
    reset: Boolean
  },
  watch: {
    reset (val) {
      if (val) {
        // If reset is true, then set the value as blank
        // If the default value is passed, then set the value
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        this.fieldValue = this.default || ''
      } else {
        this.fieldValue = ''
      }
    },
    fieldValue (val) {
      let fieldValue = val
      if (fieldValue === 'true') fieldValue = true
      if (fieldValue === 'false') fieldValue = false
      if (this.field) this.$store.commit('SET_FORM_FIELD', {key: this.field, value: fieldValue, mode: this.mode})
    }
  }
}
</script>
