<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      v-on:close="close()"
    >
      <form id="preferences">
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Full Name</label>
          </div>
          <div class="control">
            <ValidationProvider name="Full Name" :rules="{ required: true, max: 25, regex: /^(\w|\s|-)+$/ }"  v-slot="{ errors }">
              <input class="input" type="text"  :class="{'input': true, 'is-danger': errors[0] }" v-model="newPrefs.fullName" name="fullname" />
              <span class="help is-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Email Address</label>
          </div>
          <div class="control">
            <ValidationProvider name="Email Address" :rules="{ required: validatePreferences('Email'), email: true }"  v-slot="{ errors }">
              <input class="input" type="text" :class="{'input': true, 'is-danger': errors[0] }" v-model="newPrefs.emailAddress" name="emailAddress"/>
              <span class="help is-danger">{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Mobile Phone</label>
          </div>
          <div class="control">
            <ValidationProvider name="Mobile Phone" :rules="{ required: validatePreferences('Text (SMS)'), numeric: true }"  v-slot="{ errors }">
              <input class="input" type="text" :class="{'input': true, 'is-danger': errors[0] }" v-model="newPrefs.phoneNumber" name="phoneNumber"/>
              <span class="help is-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">SMS Carrier</label>
          </div>
          <div class="select">
            <select>
              <option v-for="carrier in configuration.carriers" :value="carrier.name" :key="carrier.name">{{carrier.display}}</option>
            </select>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Notification Preferences</label>
          </div>
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" id="email" value="Email" v-model="newPrefs.notifyList">
              <label for="email">Email &nbsp;</label>
              <input type="checkbox" id="sms" value="Text (SMS)" v-model="newPrefs.notifyList">
              <label for="sms">SMS</label>
            </label>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Temperature</label>
          </div>
          <div class="control">
            <label class="radio">
              <input type="radio" id="Celsius" value="Celsius" v-model="newPrefs.temperatureUnit">
              <label for="Celsius">Celsius &nbsp;</label>
              <input type="radio" id="Fahrenheit" value="Fahrenheit" v-model="newPrefs.temperatureUnit">
              <label for="Fahrenheit">Fahrenheit</label>
            </label>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Time Zone</label>
          </div>
          <div class="select">
            <select v-model="newPrefs.timezone">
              <option v-for="timezone in configuration.timezones" :value="timezone.name" :key="timezone.name">{{timezone.display}}</option>
            </select>
          </div>
        </div>
        <div class="control is-horizontal">
          <div class="control-label">
            <label class="label">Date Format</label>
          </div>
          <div class="select">
            <select v-model="newPrefs.dateFormat">
              <option value=''>Select Date Format</option>
              <option v-for="dateCfg in dateFormats" :value="dateCfg.key" :key="dateCfg.key">{{dateCfg.label}}</option>
            </select>
          </div>
        </div>
      </form>
      <template slot="footer">
        <a class="button is-primary" @click="update()" :disabled="invalid">Update</a>
        <a class="button" @click="close()">Cancel</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex'
import IntelytModal from '@/components/IntelytModal'
import moment from 'moment-timezone'

export default {
  name: 'PreferencesModal',
  components: {
    IntelytModal
  },
  computed: {
    dateFormats () {
      const formats = this.configuration.dateFormats || {}
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const tz = this.$store.state.user.timezone || 'Etc/GMT'
      // skipcq: JS-D1001.  Allow documentation comment
      const mapFn = function (key) {
        const dtCfg = formats[key]
        const dispFormats = dtCfg.display.split('|')
        return {key, label: `${dtCfg.name} | ${moment().tz(tz).format(dispFormats[0].trim())} | ${moment().tz(tz).format(dispFormats[1].trim())}`}
      }
      return Object.keys(formats).map(mapFn)
    },
    ...mapState({
      configuration: 'configuration',
      user: 'user'
    })
  },
  created () {
    this.$parent.$on('populate-user-prefs', this.populateUserPrefs)
  },
  data () {
    return {
      title: 'User Preferences',
      newPrefs: {}
    }
  },
  emits: ['close'],
  methods: {
    close () {
      this.newPrefs = {}
      this.$refs.observer.reset()
      this.$emit('close')
    },
    validatePreferences (preference) {
      return (this.newPrefs.notifyList) ? Object.values(this.newPrefs.notifyList).includes(preference) : false
    },
    populateUserPrefs () {
      // current custom prefs:
      //   - timezone - users select timezone
      //   - customPrefEx - example/sample pref - meaningless for now
      this.newPrefs = {
        userId: this.$store.state.user.userId,
        fullName: this.$store.state.user.fullName,
        emailAddress: this.$store.state.user.emailAddress,
        notifyList: this.$store.state.user.notifyList,
        phoneNumber: this.$store.state.user.phoneNumber,
        smsCarrier: this.$store.state.user.smsCarrier,
        temperatureUnit: this.$store.state.user.temperatureUnit ? this.$store.state.user.temperatureUnit : 'Fahrenheit',
        timezone: this.$store.state.user.timezone,
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        dateFormat: this.$store.state.user.dateFormat || ''
      }
      // console.log('update prefs')
    },
    update () {
      // console.log('Updating User')
      const vm = this
      this.$refs.observer.validate().then(result => {
        if (result) {
          vm.newPrefs.loginName = vm.$store.state.user.loginName
          vm.$store.dispatch('updateUser', vm.newPrefs).then(() => {
            // console.log('Update User Response: ', response)
            vm.$emit('close')
          }).catch(e => {
            // skipcq: JS-0002.  Allow console.error
            console.error(e)
          })
        }
      })
    }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
