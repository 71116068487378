<template>
<div>
  <div class="content has-text-centered">
    <div class="columns is-vcentered">
      <div class="column is-6 is-offset-3">
        <div class="box">
          <h1 class="is-title is-bold">{{error}} ({{errorCode}})</h1>
          <br>
          <p>
            <span v-html="errorMessage"></span>
          </p>
          <p>&nbsp;</p>
          <hr>
          <p class="control" v-if="$route.query.fullPath !== '/'">
            <a class="button is-primary" :href="`#${$route.query.fullPath}`"> Go Back </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  data () {
    const errorConfig = this.$store.state.configuration.errorCodes
    let errorObj = errorConfig.default
    if (errorConfig[this.$route.query.code]) {
      errorObj = errorConfig[this.$route.query.code]
    }
    return {
      error: errorObj.text,
      errorCode: errorObj.code,
      errorMessage: errorObj.message
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.is-title {
    text-transform: capitalize;
}
</style>
