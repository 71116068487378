<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      v-on:close="close()"
    >
      <div class="content">
        <div class="control" v-if="displayMessage">
          &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />
          {{message}}
          <br />&nbsp;<br />&nbsp;<br />&nbsp;<br />
        </div>
        <div>
          <form method="POST" action="/login" @submit.prevent="verify">
            <div class="content" v-if="!displayMessage">
              To complete the reset of your password and login, enter your username, requested password and the confirmation code you were sent.  Upon validation, you will be logged in and the password will be permanently reset to the new value.
            </div>
            <div class="control is-horizontal" v-if="!displayMessage">
              <div class="control-label">
                <label class="label">Username</label>
              </div>
              <div class="control">
                <ValidationProvider name="User Name" :rules="{ required: true}"  v-slot="{ errors }">
                  <input class="input" v-model="username" type="text" name="username"/>
                  <span class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="control is-horizontal" v-if="!displayMessage">
              <div class="control-label">
                <label class="label" >New Password</label>
              </div>
              <div class="control">
                <ValidationProvider name="Password" :rules="{ required: true}"  v-slot="{ errors }">
                  <input class="input" autocomplete="off" v-model="newPassword" type="password" placeholder="New Password" name="password"/>
                  <span class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="control is-horizontal" v-if="!displayMessage">
              <div class="control-label">
                <label class="label">Confirmation Code</label>
              </div>
              <div class="control">
                <ValidationProvider name="Confirmation Code" rules="required|alpha_num"  v-slot="{ errors }">
                  <input class="input" v-model="confirmation" type="text" name="confirmation"/>
                &nbsp;
                <span class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
      <template slot="footer">
        <button type="submit" class="button is-primary" @click="verify()" :disabled="invalid" v-if="!displayMessage">Verify</button>
        <a class="button" @click="close()" v-if="!displayMessage">Cancel</a>
        <a class="button" @click="close()" v-if="displayMessage" >Close</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>
<script>
import { mapActions } from 'vuex'
import IntelytModal from '@/components/IntelytModal'

export default {
  components: {
    IntelytModal
  },
  computed: {
    password: function () {
      let pass = this.newPassword + this.confirmation
      return pass
    }
  },
  data () {
    return {
      title: 'Verify Password',
      activeTab: 'request',
      confirmation: '',
      displayMessage: false,
      message: '',
      newPassword: '',
      username: ''
    }
  },
  methods: {
    close () {
      this.confirmation = ''
      this.displayMessage = false
      this.message = ''
      this.newPassword = ''
      this.username = ''
      this.$refs.observer.reset()
      this.$emit('close')
    },
    verify () {
      let creds = {
        username: this.username,
        password: this.password
      }
      this.$refs.observer.validate().then(result => {
        if (result) {
          this.$store.dispatch('verifyPassword', creds).then(response => {
            console.log('response verify password', response)
            this.message = 'Password reset was successful.  Please login now with your new credentials.'
            this.displayMessage = true
          }).catch(e => {
            console.log('ERROR verify password', e.response)
            this.message = 'The supplied values were not valid.  Please close this window and retry.'
            this.displayMessage = true
            console.log(e)
          })
        }
      })
    },
    ...mapActions([
      'login'
    ])
  },
  // props: ['isActive']
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
