/** @module state/providers */

import axios from 'axios'

// Base object factory
function baseGet (url, newParams = {}, allowedErrors = []) {
  let defaultHeaders = {
    params: {
      'killcache': new Date().getTime()
    },
    withCredentials: true
  }
  const headers = Object.assign(defaultHeaders, {
    params: newParams
  })
  // console.log('GET HEADER: ', headers)
  return new Promise((resolve, reject) => {
    axios.get(url, headers)
      .then(response => {
        // reset server session time update
        localStorage.setItem('server session time', Math.round(Date.now() / 1000))
        resolve(response.data)
      })
      .catch(e => {
        // If allowed errors found,
        // then return empty object as response than brake the other processes
        if (allowedErrors.length > 0 && allowedErrors.includes(e.response.status)) {
          // console.log('e', e, e.response)
          resolve({})
        } else {
          // skipcq JS-0002 - Avoid console.errors
          console.error('Rejecting Get: ', e)
          reject(e)
        }
      })
  })
}

// Base Put Promise object factory
function basePut (url, payload, newParams = {}) {
  let defaultHeaders = {
    params: {
      'killcache': new Date().getTime()
    },
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  }
  const headers = Object.assign(defaultHeaders, {
    params: newParams
  })
  // console.log('PUT HEADER: ', headers)
  return new Promise((resolve, reject) => {
    axios.put(url, payload, headers)
      .then(response => {
        // reset server session time update
        localStorage.setItem('server session time', Math.round(Date.now() / 1000))
        resolve(response.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}

// Base post promise object factory
function basePost (url, payload, newParams = {}) {
  let defaultHeaders = {
    params: {
      'killcache': new Date().getTime()
    },
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  }
  const headers = Object.assign(defaultHeaders, {
    params: newParams
  })
  // console.log('POST HEADER: ', headers)
  return new Promise((resolve, reject) => {
    axios.post(url, payload, headers)
      .then(response => {
        // reset server session time update
        localStorage.setItem('server session time', Math.round(Date.now() / 1000))
        resolve(response.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}

/**
 * Creates an activity from a template and returns id.  Does not execute the activity
 * @param {object} state
 * @param {object} payload - Payload for activity generation
 * @param {string} payload.template - name of template file
 * @param {string} payload.mac - MAC Id of device running activity
 * @param {string} payload.guid - Activity guid
 * @return {Promise} Promise object represents the return value from the create activity function
 */
export const createActivityProvider = (state, payload) => {
  // let config = Object.assign({}, configTemplate)
  // let url = state.configuration.baseUrl + 'activity/runActivityTemplate/'
  const url = `${state.configuration.baseUrl}activity/runActivityTemplate/`
  return basePut(url, payload)
}

/**
 * Creates an activity from a template and immediately runs it
 * @param {object} state
 * @param {object} payload - Payload for activity generation
 * @param {string} payload.template - name of template file
 * @param {string} payload.mac - full MAC Id of device running activity
 * @param {string} payload.guid - GUID of shipment
 * @param {boolean} payload.runByGUID - boolean to indicate whether search uses guid
 * @return {Promise} Promise object represents the return value from the create activity function
 */
export const createAndRunActivityTemplateProvider = (state, payload) => {
  // let url = state.configuration.baseUrl + 'activity/createAndRunActivityTemplate/'
  const url = `${state.configuration.baseUrl}activity/createAndRunActivityTemplate/`
  return basePut(url, payload)
}

/**
 * Creates an Route Map from and returns api response.
 * @param {object} state
 * @param {object} payload - Payload with updated route map information
 * @return {Promise} Promise object represents the return value from the create activity function
 */
export const createRouteMapProvider = (state, payload) => {
  // let url = state.configuration.baseUrl + 'geozone/createSet'
  const url = `${state.configuration.baseUrl}geozone/createSet`
  return basePost(url, payload)
}

export const createTableEntryProvider = (state, tableName, payload) => {
  /* createTableEntryProvider calls tables/create/ api call and will return:
      TBD
  */
  // let url = state.configuration.baseUrl + 'tables/create/' + tableName
  const url = `${state.configuration.baseUrl}tables/create/${tableName}`
  return basePost(url, payload)
}

/**
 * Fetches for list of device counts for devices associated with the supplied
 * list of devices.
 * @param {list} - list of MAC ids of the devices to get counts for
 * @returns {Promise} Promise object populated with object that contains
 * device mac ids and associated counts
 */
export function deviceCountsProvider (state, payload) {
  // const url = state.configuration.baseUrl + '/inventory/postDeviceCounts'
  const url = `${state.configuration.baseUrl}/inventory/postDeviceCounts`
  return basePost(url, payload)
}

export function getCompaniesProvider (state) {
  /* getCompanies API will return the company data including:
    - name
    - id
    - groups
    Actions:
    - success: add to global state
    - fail: log to console
  */
  // const url = state.configuration.baseUrl + 'companies'
  const url = `${state.configuration.baseUrl}companies`
  const params = {
    customPrefs: true
  }
  return baseGet(url, params)
}

/**
 * Queries for list of devices of a single type for which the user has view permissions.
 * @param {object} state - Vuex state
 * @param {string} [deviceType=DEVICE_IGATE] - type of device
 * @returns {Promise} Promise object populated with list of Device MAC Ids
 */

export function getDeviceIdsProvider (state, deviceType = 'DEVICE_IGATE') {
  // const url = state.configuration.baseUrl + '/devices/deviceIdList/' + deviceType
  const url = `${state.configuration.baseUrl}/devices/deviceIdList/${deviceType}`
  return baseGet(url)
}

export function getDeviceStatusListProvider (state, deviceList) {
  /* getDeviceStatusListProvider API will return the device status for
     a list of devices.  This data includes:
    - TBD
  */
  // let url = state.configuration.baseUrl + 'devices/statusList'
  const url = `${state.configuration.baseUrl}devices/statusList`
  return basePost(url, deviceList)
}

export function getDeviceStatusProvider (state, deviceIdList) {
  /* getDeviceStatusProvider API will return the device data including:
    - TBD
  */
  // const url = state.configuration.baseUrl + '/devices/' + deviceIdList
  const url = `${state.configuration.baseUrl}/devices/${deviceIdList}`
  return baseGet(url)
}

/**
 * Retrieves device messages of specified packet an message type.
 * @param {Number} payload.pktType - Packet type of requested message
 * @param {Number} payload.msgType - Message type of requested message
 * @param {Number} payload.start - Starting index to begin results
 * @param {Number} payload.max - Maximum number of records to return
 * @param {string} payload.where - Where clause of query
 * @param {Array} payload.fields - List of fields to return
 * @param {String} payload.deviceId - MAC Address of device for which messages are retrieved
 * @returns {Promise} Promise object that contains contents (formatted device data) of retrieved message.
 */
export const getMessagesProvider = (state, payload) => {
  const params = {
    pktType: payload.pktType,
    msgType: payload.msgType,
    start: payload.start || 0,
    max: payload.max || 100,
    where: payload.where
  }
  // Default sort is sourceTime DESC...anything else will override.
  if (payload.sort) params.orderBy = payload.sort
  // Only add fields if they are defined - empty list with return error.
  if (payload.fields) params.fields = payload.fields
  // const url = state.configuration.baseUrl + 'devices/' + payload.deviceId + '/messages'
  const url = `${state.configuration.baseUrl}devices/${payload.deviceId}/messages`
  return baseGet(url, params)
}

/**
 * A promise based API call which calls API to execute pre-defined query.
 * @param {object} state - Vuex state
 * @param {object} payload
 * @param {string} payload.query - Name of query to be executed
 * @param {Array} payload.params - List of query parameters
 * @returns {Promise} Promise object that contains the return value from the query if successfully executed.
 */
export const getQueryProvider = (state, payload) => {
  /* A promise based API call which returns
     the data from a pre-configured SQL query
     Actions:
    - success: return promised data
    - fail: log to console & reject promise
  */
  // console.log('------ getQueryProvider: ', payload)
  const paramsList = encodeURIComponent(JSON.stringify(payload.params))
  // let url = state.configuration.baseUrl + 'tables/query/' + payload.query
  let url = `${state.configuration.baseUrl}tables/query/${payload.query}`
  url += `?killcache=${new Date().getTime()}&params=${paramsList}`
  // url += '?killcache=' + new Date().getTime() + '&params=' + paramsList
  return baseGet(url)
}

export const getStaticDataProvider = (state, payload) => {
  /* A promise based API call which returns
     the data from the static data file config.json
     Actions:
    - success: return promised data
    - fail: log to console & reject promise
  */
  // const url = state.configuration.baseUrl + 'config/' + payload.path
  const url = `${state.configuration.baseUrl}config/${payload.path}`
  const params = payload.params || {}
  return baseGet(url, params, [404])
}

// export function getServerProvider (state) {
//   // console.log('call getServerProvider API')
//   const url = state.configuration.baseUrl + 'server'
//   return baseGet(url)
// }

export const getTableEntriesGenericProvider = (state, payload) => {
  /* A promise based API call which returns
     TBD
     Actions:
    - success: add to global state
    - fail: log to console

    Parameters:
      - username: the username to access the database
      - guid: the guid to access the database
      - tableName: name of database table to access
      - maxNumEntries: mySQL limit value (max is 1000)
      - start index: return rows after skipping this many rows from the beginning of what would have otherwise been returned from the query
      - columns: a javascript array of string column names to be returned
      - where: where conditions, backend automatically inserts “(guid = ‘<guid>’)”, so only use this for additional conditions
      - order: any order by statement, example “sourceTime DESC”
  */
  // const url = state.configuration.baseUrl + 'tables/getEntriesGeneric'
  const url = `${state.configuration.baseUrl}tables/getEntriesGeneric`
  const data = {
    username: payload.username,
    guid: payload.guid,
    tableName: payload.tableName,
    field: [],
    max: payload.max || 1000,
    start: payload.start || 0,
    where: payload.where || '',
    sort: payload.sort || ''
  }
  return baseGet(url, {
    data: data
  })
}

export const getTableEntriesProvider = (state, payload) => {
  /* A promise based API call which returns
     TBD
     Actions:
    - success: add to global state
    - fail: log to console
  */
  // let url = state.configuration.baseUrl + 'tables/' + payload.tableName + '/entries'
  const url = `${state.configuration.baseUrl}tables/${payload.tableName}/entries`
  const params = {
    max: payload.max || 1000,
    start: payload.start || 0,
    where: payload.where,
    field: payload.fields || [],
    sort: payload.sort
  }
  return baseGet(url, params)
}

export function getUsernameProvider (state, email) {
  /* getUsernameProvider API returns:
    - response message string
     Actions:
    - success: return message string (includes instructions for reset)
    - fail: reject with error
  */
  let data = {
    'email': email
  }
  // const url = state.configuration.baseUrl + 'server/userget'
  const url = `${state.configuration.baseUrl}server/userget`
  return basePost(url, data)
}

export const getUsersProvider = (state) => {
  /* getUsers API returns LIST of user with the following:
    - User details: fullName, loginName, emailAddress etc.
    - propertyMap w/ customPrefs -
  */
  // const url = state.configuration.baseUrl + 'users'
  const url = `${state.configuration.baseUrl}users`
  return baseGet(url)
}

export function getWarVersionProvider (state) {
  // console.log('call getServerProvider API')
  // const url = state.configuration.baseUrl + 'server/warVersionGet'
  const url = `${state.configuration.baseUrl}server/warVersionGet`
  return baseGet(url)
}

/**
 * Runs a set of commands defined in a text command file saved on server.<br>
 * Will either run for a single device or a list of devices, depending on what
 * type of data is sent.
 * Note: Must be provisioner to execute.
 * @param {object} state - Vuex state
 * @param {string | List} device- macId of device or list of macIds
 * @param {string} file - name of command file
 * @returns {Promise} Promise object populated TBD
 * @todo Consider also using queuefile when arguments are more similar - this
 * method supports only a single device.
 */
export function loadCommandFileProvider (state, {file, devices = []} = {}) {
  // let url = state.configuration.baseUrl + 'commands/qFile'
  const url = `${state.configuration.baseUrl}commands/qFile`
  let payload = {filename: file}
  if (typeof devices === 'string' || devices instanceof String) {
    payload.devices = [devices]
  } else {
    payload.devices = devices
  }
  // qFile devices, filename
  // queuefile (device, file)
  return basePut(url, payload)
}

export function loginProvider (state, creds) {
  /* loginProvider calls /users/ api call and will return:
      success: {userObject + authorized: true}
      failure: error payload
  */
  // console.log('call loginProvider API', creds)
  // configuration.post_data.password = creds.password
  /* const data = {
    password: creds.password,
    username: creds.username
  } */
  const headers = {
    params: {
      'killcache': new Date().getTime(),
      'customPrefs': true
    },
    withCredentials: true
  }
  // let url = state.configuration.baseUrl + 'users/login'
  const url = `${state.configuration.baseUrl}users/login`
  return new Promise((resolve, reject) => {
    axios.post(url, creds, headers)
      .then(response => {
        // console.log('Login Repsonse: ', response)
        let resp = response.data.data
        resp.authorized = true
        resolve(resp)
      })
      .catch(e => {
        // skipcq JS-0002 - Avoid console.errors
        console.error(e)
        let error = {code: 520, message: 'Unknown Error'}
        if (e.response) {
          error.code = e.response.status
          error.message = e.response.statusText
        } else if (!error.status) {
          // Axios generic network error
          error.code = 503
          error.message = 'Network Unavaialble'
        }
        reject(error)
      })
  })
}

export function resetPasswordProvider (state, payload) {
  /* resetPasswordProvider API returns:
     Actions:
    - success: return response object
    - fail: reject with error
  */
  let data = {
    username: payload.username,
    email: payload.email,
    password: payload.password
  }
  // let url = state.configuration.baseUrl + 'server/pwget'
  const url = `${state.configuration.baseUrl}server/pwget`
  return basePost(url, data)
}

export const runActivityProvider = (state, entryId) => {
  /*
    Runs an activity that is identified by the supplied entryId
  */

  // let url = state.configuration.baseUrl + 'activity/' + entryId
  const url = `${state.configuration.baseUrl}activity/${entryId}`
  let payload = {}
  return basePut(url, payload)
}

export const runScriptProvider = (state, scriptName, receiver, crossRefInfo, paramObjList) => {
  /* runScriptProvider calls /scripts/ api call and will return:
      success: success running script
      failure: error payload
  */
  let data = {
    scriptName: scriptName,
    receiver: receiver,
    crossRefInfo: crossRefInfo,
    paramObjList: paramObjList
  }
  // console.log('DATA: ', data)
  // let url = state.configuration.baseUrl + 'scripts'
  const url = `${state.configuration.baseUrl}scripts`
  return basePut(url, data)
}

/**
 * Sends a 'custom' list of commands to a set of devices.<br>
 * Devices to receive commands provided as a list of MAC ids.<br>
 * Commands to be applied provided as a list of strings.<br>
 * Note: Must be provisioner to execute.
 * @param {Object} state - Vuex state
 * @param {string[]} deviceIdList- macId of device
 * @param {string[]} commandList - name of command file
 * @returns {Promise} Promise object populated
 */
export const sendCommandsProvider = (state, deviceIdList, commandList) => {
  // const url = state.configuration.baseUrl + 'commands/queue'
  const url = `${state.configuration.baseUrl}commands/queue`
  const payload = {
    deviceIdList: deviceIdList,
    commandList: commandList
  }
  return basePut(url, payload)
}

export const setDeviceInfoProvider = (state, payload) => {
  /* setDeviceInfoProvider calls devices/setDeviceInfo api call and will return:
      success: payload update
      failure: error payload
  */
  //   console.log('Call setDeviceInfoProvider: ', payload)

  //   let config = Object.assign({}, configTemplate)
  // const url = state.configuration.baseUrl + 'devices/setInfo'
  const url = `${state.configuration.baseUrl}devices/setInfo`
  return basePut(url, payload)
}

export const stateObjectValuesProvider = (rootState, payload) => {
  /* stateObjectValuesProvider looks up portion of state and returns content:
      success: payload update
      failure: error payload
  */
  return new Promise((resolve) => {
    /* const labels = payload.labels
    const fields = payload.fields */
    // const hasMultiRows = payload.config.hasMultipleRows || false
    let data = {...rootState}
    const targetData = payload.sourceObject.split('.')
    targetData.forEach((child) => {
      data = {...data[child]}
    })
    /* const mapFN = function (rowData) {
      const values = {}
      fields.forEach((value, index) => {
        const key = labels[index]
        values[value] = rowData[value]
      })
      return values
    } */
    // const outputData = typeof data === 'object' ? Object.values(data).map(mapFN) : [data].map(mapFN)
    // If the data is an end object like shipment object then add it in an array and return
    const outputData = (typeof data === 'object' && typeof Object.values(data)[0] !== 'string') ? Object.values(data) : [data]
    resolve({data: outputData})
  })
}

/**
 * Updates company details and returns api response.
 * @param {object} payload - Payload with updated company information
 * example {"property":{"customPrefs":<any object here>}
 * @return {Promise} Promise object represents the return value from the API response
 */
export const updateCompanyProvider = (state, payload, id) => {
  // const url = state.configuration.baseUrl + `companies/${id}`
  const url = `${state.configuration.baseUrl}companies/${id}`
  return basePut(url, payload)
}

/**
 * Updates a group and returns api response.
 * @param {object} payload - Payload with updated group information
 * example {"enabled":true, "property":{"customPrefs":<any object here>, "groupType":<string>}}
 * @return {Promise} Promise object represents the return value from the create activity function
 */
export const updateGroupProvider = (state, payload, id) => {
  // const url = state.configuration.baseUrl + `groups/${id}`
  const url = `${state.configuration.baseUrl}groups/${id}`
  return basePut(url, payload)
}

/**
 * Updates an Route Map from and returns api response.
 * @param {object} state
 * @param {object} payload - Payload with updated route map information
 * @return {Promise} Promise object represents the return value from the create activity function
 */
export const updateRouteMapProvider = (state, payload) => {
  // const url = state.configuration.baseUrl + 'geozone/updateSet'
  const url = `${state.configuration.baseUrl}geozone/updateSet`
  return basePut(url, payload)
}

export const updateTableEntryProvider = (state, tableName, data) => {
  /* updateTableEntryProvider calls /tables/update api call and will return:
      success: payload update
      failure: error payload
  */
  // let url = state.configuration.baseUrl + 'tables/update/' + tableName
  const url = `${state.configuration.baseUrl}tables/update/${tableName}`
  return basePut(url, data)
}

export const updateUserProvider = (state, payload) => {
  // const url = state.configuration.baseUrl + 'users/' + payload.username
  const url = `${state.configuration.baseUrl}users/${payload.username}`
  return basePut(url, payload)
}

export const getStaticProvider = (url) => {
  /**
   * A promise based API call which returns whatever is retrieved by the URL
   */
  return baseGet(url)
}

/**
 * Send email to customer email address
 * @param {Object} state - Vuex state
 * @param {Object} email - Send email values like message, subject, from and to address
 */
export function sendEmailProvider (state, email) {
  /*  Approriate comments here  */
  // const url = state.configuration.baseUrl + 'server/sendmessage'
  const url = `${state.configuration.baseUrl}server/sendmessage`
  return basePost(url, email)
}
/* responseToString - Convert the response to string*/
function responseToString (response) {
  const array = new Uint8Array(response)
  let res = ''
  for (let i = 0; i < array.length; i++) {
    res += String.fromCharCode(array[i])
  }
  return res
}

export function intelytApiProvider (state, query) {
  // make hostname dynamic & make devops.icontrolinc come from config.....
  // const proto = state.configuration.baseUrl.includes('https:') ? 'https' : 'http'
  const regEx = /\/([\w]+)/
  const hostname = state.configuration.baseUrl.match(regEx)[1] || 'dev'
  // const url = 'https' + '://' + state.configuration.externalUrls.intelytApi + '/' + query
  const url = `https://${state.configuration.externalUrls.intelytApi}/${query}`
  const headers = {
    params: {
      'killcache': new Date().getTime(),
      'hostname': hostname
    },
    headers: {
      'Content-Type': 'application/json'
    }
  }
  return new Promise((resolve, reject) => {
    axios.get(url, headers).then(response => {
      // const contentType = response.headers['content-type']
      // console.log('API Response: ', response)
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * jsReportProvider - JS Report API provider
 * @param {Object} state - Vuex state object
 * @param {Object} payload - Payload for he report
 * @returns 
 */
export function jsReportProvider (state, payload) {
  // const url = 'https://intelyt.jsreportonline.net/api/report'
  const url = `${state.configuration.baseUrl}external/jsreport`
  const params = {
    responseType: 'arraybuffer'
  }
  return new Promise((resolve, reject) => {
    axios.post(url, payload, params).then(response => {
      const contentType = response.headers['content-type']
      response.toDataURI = function () {
        const base64 = window.btoa(responseToString(response.data))
        // return 'data:' + contentType + ';base64, ' + base64
        return `data:${contentType};base64, ${base64}`
      }
      response.toBlob = function () {
        const dataView = new DataView(response.data)
        let blob
        try {
          blob = new Blob([dataView], { type: contentType })
        } catch (e) {
          if (e.name === 'InvalidStateError') {
            const byteArray = new Uint8Array(response.data)
            blob = new Blob([byteArray.buffer], { type: contentType })
          } else {
            throw e
          }
        }
        return blob
      }
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}
