<template>
  <div id="app">
    <nav-bar></nav-bar>
    <app-main></app-main>
    <footer-bar></footer-bar>
    <reset-session-modal
      :isActive="sessionModalActive"
      title='Continue Application Session?'
      :remaining="modalTimeRemaining"
      v-on:close="closeModal()"
      v-on:logoutAndClose="logoutAndCloseModal()"
    >
    </reset-session-modal>
  </div>
</template>

<script>
import AppMain from '@/layout/AppMain'
import FooterBar from '@/layout/FooterBar'
import NavBar from '@/layout/NavBar'
import ResetSessionModal from '@/modals/ResetSessionModal'
import {localStorageMixin} from '@/store/mixins'
import {mapActions} from 'vuex'
import config from '../static/config.json'
import fontConfig from '../static/font-config.json'

export default {
  name: 'app',
  beforeDestroy () {
    clearInterval(this.timer)
  },
  computed: {
    authStatus () {
      return this.$store.state.auth.status
    },
    appVersion () {
      // When the user opens the application first time, the state not loaded properly
      // If the version obj not found, return 0
      return this.$store.state.configuration.version ? this.$store.state.configuration.version.number : '0'
    }
  },
  created () {
    // If the route have the query param called state,
    // Then this is the return value of the logged out response fromone login
    // The state value is the client id of the current user
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'state')) {
      // Redirect to sso login page
      this.$router.push(`/login`)
    }
    // On the initial appplication load, check the browser has the valid session
    // If the session is expired and the local storage have the old auth value then logut the session
    if (this.sessionExpired() && this.$store.state.auth.status) this.$store.dispatch('logout')
    // Set basic configuration for instance using window props
    this.setConfig()
    // Initialize starting duration for modal timer from configuration
    this.modalTimeRemaining = parseInt(this.$store.state.configuration.timers.warningDuration)
    // Provide warning if user refreshes page in case they don't want to log out
    /* window.onbeforeunload = function () {
      return 'Refreshing page will end session and require user to log in again. Are you sure?'
    } */
    // If the user is logged in, the reset the timer on each page loads
    // to get the logout popup modal
    if (this.authStatus) {
      clearInterval(this.timer)
      this.setIntervalTimer()
    }
  },
  components: {
    AppMain,
    FooterBar,
    NavBar,
    ResetSessionModal
    // NavSidebar
  },
  data () {
    return {
      modalTimer: '',
      modalTimerSet: false,
      modalTimeRemaining: 0,
      sessionModalActive: false
    }
  },
  methods: {
    /**
     * Check to determine timing of session in relation to current time.  If too
     * much time has expired since the last user update, a warning modal is opened.<br>
     * From this modal, the user can logout or continue.<br>
     * If the user does nothing, they will be automatically logged out.
     * Variables are:
     *  - currentTime - Time now i.e. when timer is checked
     *  - sessionTimer - Run interval of session check
     *  - sessionTime - Last session time stored in local storage
     *  - serverSessionTime - Last session time of server/API connection stored in local storage
     *  - sessionDuration - Configurable time in seconds session is allowed before warning modal
     *    opened
     *  - serverSessionDruation - Configurable time in seconds server session can be maintained
     *    without requesting refresh
     *  - warningDuration - Configurable time warning modal is allowed to be open before user is
     *    logged out
     */
    checkSessionTimer () {
      // check periodically if user is logged in.  If so, call defined
      // method to reset Tomcat session, otherwise do nothing
      if (this.$store.state.auth.status) {
        // Run logout if session not found or user not logged in....auto logout process
        let state = localStorage.getItem('intelyt')
        state = JSON.parse(state)
        if (!state || !state.auth.status) {
          this.$store.dispatch('logout')
        }
        // Get current time for timer checks
        const currentTime = Math.round(Date.now() / 1000)
        // Get required session timers...
        const sessionTime = parseInt(this.getLocalStorage('session time'))
        const sessionDuration = parseInt(this.$store.state.configuration.timers.sessionDuration)
        const serverSessionTime = parseInt(this.getLocalStorage('server session time'))
        const serverSessionDuration = parseInt(this.$store.state.configuration.timers.serverSessionDuration)
        const sessionInterval = parseInt(this.$store.state.configuration.timers.sessionTimer)
        // const timers = {currentTime, sessionTime, sessionDuration, serverSessionTime, serverSessionDuration, sessionInterval}
        // console.table(timers)
        // Check user session timer to see if warning modal should be displayed
        // If so, start interval time to count down the remaining warning time.
        if (currentTime > sessionTime + sessionDuration) {
          this.sessionModalActive = true
          if (!this.modalTimerSet) {
            this.modalTimer = setInterval(this.decrementTime, 1000)
            this.modalTimerSet = true
          }
        }
        // Check API/Server session timer.  If it will expire before the next interval,
        // make API to to reset
        if (currentTime > serverSessionTime + serverSessionDuration - sessionInterval) {
          this.$store.dispatch('resetServerSession')
        }
      }
    },
    closeModal () {
      this.$store.dispatch('resetSession')

      this.resetModal()
    },
    decrementTime () {
      this.modalTimeRemaining -= 1
      if (this.modalTimeRemaining < 0) { this.logoutAndCloseModal() }
    },
    logoutAndCloseModal () {
      this.resetModal()
      this.logout()
    },
    resetModal () {
      // reset modal parameters to initial state
      this.sessionModalActive = false
      this.modalTimerSet = false
      this.modalTimeRemaining = parseInt(this.$store.state.configuration.timers.warningDuration)
      clearInterval(this.modalTimer)
    },
    sessionExpired () {
      // If timer config not available, then return session expired true
      if (!this.$store.state.configuration.timers) return true
      // Get current time for timer checks
      const currentTime = Math.round(Date.now() / 1000)
      // Get required session timers...
      const sessionTime = this.getLocalStorage('session time') ? parseInt(this.getLocalStorage('session time')) : 0
      const sessionDuration = parseInt(this.$store.state.configuration.timers.sessionDuration)
      // const sessionDuration = 0
      return currentTime > (sessionTime + sessionDuration)
    },
    setConfig () {
      let useOldState = false
      // Run whenever app.vue loads (page refresh, initial load etc.)
      // Get state from local storage
      const oldState = JSON.parse(localStorage.getItem('intelyt'))
      if (oldState) useOldState = !this.sessionExpired() && (oldState.auth && oldState.auth.status)
      // console.log('useOldState', useOldState)
      // If user is logged in, replace config object w/ local storage value
      // If user is not logged in, use values from config.json until they log in and have those
      // values overridden.
      if (useOldState) {
        this.$store.commit('setConfig', oldState.configuration)
        // console.log('CONFIG: ', 'Old config found')
      } else {
        this.$store.commit('setConfig', config)
        this.$store.commit('setFont', fontConfig)
      }
    },
    setIntervalTimer () {
      // Timer to periodically check if logged in and if so
      // call check session timing function to keep session alive
      const sessionInterval = parseInt(this.$store.state.configuration.timers.sessionTimer)
      this.timer = setInterval(this.checkSessionTimer, sessionInterval * 1000)
      // this.timer = setInterval(this.resetSessionTimer, 240000)
      // Set initial session time in seconds
      this.setLocalStorage('session time', Math.round(Date.now() / 1000))
    },
    ...mapActions([
      'logout'
    ])
  },
  mixins: [localStorageMixin],
  watch: {
    // whenever user auth status changes, update the timer value
    'authStatus': {
      handler (newInput) {
        // If user logged in, initiate the timer. Else(logout) clear the timer
        newInput ? this.setIntervalTimer() : clearInterval(this.timer)
      },
      deep: true
    },
    // whenever the App version changes, refresh the page
    'appVersion': {
      handler (newInput) {
        const version = localStorage.getItem('version')
        // If version not found in the local storage or new version found, refresh the page
        if (!version || version !== newInput) {
          localStorage.setItem('version', newInput)
          alert(this.$store.state.configuration.messageStrings.newVersion)
          localStorage.removeItem('intelyt')
          window.location.reload(true)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
$primary: #03518F;
$success: #026D0E;
$tablet: 981px;
$box-padding: 0.75rem;
@import '~bulma';
@import '~bulma/sass/utilities/mixins';

html {
  background-color: whitesmoke;
}

.level:not(:last-child) {
    margin-bottom: 0.5rem;
}

.app-main {
  padding-top: 0px;
}

.icon-red {
  color: #C83232;
  opacity: .85;
}

.icon-green {
  color: #026D0E;
  opacity: .75;
}

.icon-grey {
  opacity: .25;
}

.icon-dark-grey {
  color: black;
  opacity: .75;
}

.icon-yellow {
  color: #f4c542;
  opacity: .9;
}

.icon-primary {
  color: #03518F;
}

.icon-search {
  position:relative;
  right:30px;
  color: #404040;
  opacity: .85;
}

.icon-padding {
  padding-left: 2px;
  padding-right: 2px;
}

.select:after {
  border-color: #DDD !important;
}

.tbl-cat {
  font-weight: bold;
  padding-top: 3px;
  padding-right: 3px;
  padding-left: 3px;
}

.tbl-val {
  padding-left: 3px;
}
/* Edge browser drop down menu fix */
.navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

</style>
