<template>
<div>
  <nav class="navbar is-transparent is-fixed-top" role="navigation" aria-label="dropdown navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="http://www.intelyt.com" target="_blank" rel="noopener noreferrer">
        <img :src="logo" alt="Intelyt logo" >
      </a>
      <button class="button navbar-burger" :class="{'is-active': menuIsActive}" @click="toggleMenu()">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
    <div class="navbar-menu" :class="{'is-active': menuIsActive}">
      <div class="navbar-start" v-if="auth.status" v-html="pageMenu">
      </div>
      <div class="navbar-end" v-if="auth.status">
      <span class="navbar-item">
        <a  v-if="auth.status">
          <p class="nav-item">Welcome: {{user.fullName}}</p>
        </a>
      </span>
      <span class="navbar-item">
        <base-icon name="clock" v-tooltip="userTimes" color="blue"/>
      </span>
      <span class="navbar-item">
        <intelyt-dropdown :items= alerts>
          <span v-if="alerts.length==0"><base-icon name="message" color="blue"></base-icon></span>
          <span v-if="alerts.length>0"><base-icon name="message_notification" color="blue"></base-icon></span>
        </intelyt-dropdown>
      </span>
      <span class="navbar-item">
          <base-icon name="settings" @click="openPreferencesModal" v-tooltip="'Preferences'" color="blue"/>
      </span>
      <!-- Removing Help Until Complete -->
        <!--
      <span class="navbar-item"> -->
        <!-- Removing Help Until Complete -->
        <!--
        <a class="icon" target="_blank" href="/static/help/index.html" v-tooltip="{ html: 'versionTooltip' }">
          <base-icon name="help" color="blue"/>
        </a>
        <div id="versionTooltip" v-html="version"></div>
      </span>
      -->
      <span class="navbar-item">
        <!-- This span is temporary - remove when help is complete -->
        <a class="icon">
          <base-icon name="help" @click="openHelpModal()" color="blue" v-tooltip.left="version"/>
        </a>
        <!-- <div id="versionTooltip" v-html="version"></div> -->
      </span>
      <span class="navbar-item">
          <base-icon name="logout" @click="logout" v-tooltip.left="'Logout'" color="blue"/>
      </span>
      </div>
    </div>
  </nav>
  <preferences-modal
    :isActive="preferencesModalIsActive"
    v-on:close="closePreferencesModal"
  >
  </preferences-modal>
  <help-modal
    :isActive="helpModalIsActive"
    v-on:close="closeHelpModal"
  >
  </help-modal>
</div>
</template>

<script>

import IntelytDropdown from '@/components/IntelytDropdown'
import PreferencesModal from '@/modals/PreferencesModal'
import HelpModal from '@/modals/HelpModal'
import { mapState, mapActions, mapGetters } from 'vuex'
import {hasRole, getDateFormat} from '@/store/helpers.js'
import moment from 'moment-timezone'
// import {version} from '../../package.json'

export default {
  name: 'NavBar',
  components: {
    HelpModal,
    IntelytDropdown,
    PreferencesModal
  },
  computed: {
    alerts () {
      return this.$store.getters['alerts/getUnacknowledgedAlerts']()
    },
    companyId () {
      return this.$store.state.company.id
    },
    logo () {
      return this.company.logo ? `/static/logos/${this.company.logo}` : '/static/logos/intelyt_logo.png'
    },
    pageMenu () {
      // return this.$store.getters.getMenu()
      let menuHtml = ''
      if (typeof this.menu === 'undefined') {
        return ''
      }
      for (const menuItem of this.menu) {
        if (hasRole(this.$store.state.user.roles, menuItem.roles)) {
          if (!menuItem.children) {
            menuHtml += `<a class="navbar-item" href="${menuItem.href}">`
            menuHtml += `${menuItem.name}</a>`
          } else {
            menuHtml += '<div class="navbar-item has-dropdown is-hoverable">'
            menuHtml += '<a class="navbar-link">'
            menuHtml += `${menuItem.name}</a>`
            menuHtml += '<div class="navbar-dropdown is-boxed">'
            for (const child of menuItem.children) {
              if (hasRole(this.$store.state.user.roles, child.roles)) {
                menuHtml += `<a class="navbar-item" href="${child.href}">`
                menuHtml += `${child.name}</a>`
              }
            }
          }
        }
        menuHtml += '</div></div>'
      }
      return menuHtml
    },
    ...mapState({
      auth: 'auth',
      menu: 'menu',
      company: 'company',
      user: 'user'
    }),
    // mix the getters into computed with object spread operator
    ...mapGetters({
      isLoggedIn: 'isLoggedIn'
    }),
    userTimes () {
      const fmt = getDateFormat()
      const utcTime = moment().utc().format(`${fmt} HH:mm:ss`)
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const usrTime = moment().tz(tmz).format(`${fmt} HH:mm:ss`)
      return `${utcTime} (UTC)</br>${usrTime} (${tmz})`
    }
  },
  data () {
    const version = this.$store.state.configuration.version
    let versionHtml = `<p>Version: ${version.number}`
    versionHtml += `-${version.api}`
    versionHtml += `</p><p>Date: ${version.date}</p>`
    return {
      // auth: this.$store.getters.getAuth,
      menuIsActive: false,
      helpModalIsActive: false,
      preferencesModalIsActive: false,
      version: versionHtml
    }
  },
  emits: ['populate-user-prefs'],
  methods: {
    closePreferencesModal () {
      this.preferencesModalIsActive = false
    },
    openHelpModal () {
      this.helpModalIsActive = true
    },
    closeHelpModal () {
      this.helpModalIsActive = false
    },
    openPreferencesModal () {
      this.$emit('populate-user-prefs')
      if (!this.$store.state.configuration.carriers || !this.$store.state.configuration.timezones) {
        this.$store.dispatch('createConfigObj', 'carriers')
        this.$store.dispatch('createConfigObj', 'timezones')
      }
      this.preferencesModalIsActive = true
    },
    getCount (count) {
      return count > 9 ? '9+' : count
    },
    toggleMenu () {
      this.menuIsActive = !this.menuIsActive
    },
    ...mapActions([
      'logout'
    ])
  },
  watch: {
    'companyId': {
      handler (val) {
        // if value for comapny id is defined, lookup all unack alerts
        if (val) {
          this.preferencesConfigLoaded = false
          this.$store.dispatch('alerts/initialize')
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.nav {
  position: fixed;
  min-width: 100%;
  z-index: 1024;
  box-shadow: 0 2px 3px rgba(17, 17, 17, 0.1), 0 0 0 1px rgba(17, 17, 17, 0.1);
  .container {
    margin: auto 10px;
  }

  .nav-right {
    align-items: stretch;
    align-items: stretch;
    flex: 1;
    justify-content: flex-end;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.nav-item img {
    max-height: 2.75rem;
}

</style>
