<template>
  <div>
    <article class="media alert-section" @click="openModal()">
      <figure class="media-left">
        <p class="image is-24x24">
          <base-icon :name="icon.icon"></base-icon>
        </p>
      </figure>
      <div class="media-content">
        <div class="content">
          <div :class="{'has-text-weight-bold': isUnacknowledged}">
            <div>
              <strong>{{ subject }}</strong>
              <small> ({{ relativeTime(alert.alertTime1) }})</small>
            </div>
            <!-- <div class="messagetext">
              {{ alert.message }}
            </div> -->
          </div>
        </div>
      </div>
      <div class="media-right">
        <base-icon name="view" color="black" size="20" @click="openModal()"/>
        <base-icon name="check" color="red" size="20" v-show="isUnacknowledged" @click="acknowledgeAlert(alert)"/>
      </div>
    </article>
    <alert-modal :obj="alert"
                  :title="alert.subject"
                  :body="alert.message"
                  :isActive="modalIsActive"
                  v-on:close="closeModal"
                  v-on:acknowledge="acknowledge"
                  >
  </alert-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getDateFormat } from '@/store/helpers'
import AlertModal from '@/modals/AlertModal'
import moment from 'moment-timezone'

export default {
  components: {
    AlertModal
  },
  computed: {
    isUnacknowledged () {
      return !this.alert.ackBy.length > 0
    },
    icon () {
      // return this.alertEvent[this.alert.event].icon
      const icons = (typeof this.alertEvent[this.alert.event] !== 'undefined') ? this.alertEvent[this.alert.event] : {name: '', icon: ''}
      return icons
    },
    modalIsActive: function () {
      console.log('modalIsActive: ', this.isActive)
      return this.isActive
    },
    subject: function () {
      // str = str.replace(/[^0-9\.]+/g, "");
      const subj = this.hideId ? this.alert.subject.replace(/\[.+\]/g, '') : this.alert.subject
      return subj
    },
    ...mapState({
      alertEvent: state => state.configuration.alertEvent,
      user: 'user'
    })
  },
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    ...mapActions([
      'acknowledgeAlert'
    ]),
    acknowledge () {
      this.acknowledgeAlert(this.alert).then(response => {
        console.log('Ack Alert Response', response)
      }).catch(e => {
        console.log('Ack Alert Error: ', e)
      })
    },
    openModal () {
      this.isActive = true
    },
    closeModal () {
      this.isActive = false
    },
    relativeTime: function (ts) {
      return moment.utc(ts).tz(this.$store.state.user.timezone).format(`${getDateFormat()} HH:mm z`)
    }
  },
  props: {
    alert: Object,
    hideId: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .media-left, .media-right {
    margin-left: 0.25em;
    margin-right: 0.25em;
  }
  .messagetext {
    max-width: 350px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 0px;
  }
  .alert-section, .alert-section .media-left {
    margin-bottom: 0px !important;
  }
</style>
