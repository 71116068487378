<template>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">{{metricItem.label}}</p>
        <p class="title" v-if="!metricItem.link">{{metricItem.value}}</p>
        <p class="title" v-else>
          <span class="tag is-size-7 is-danger pointer" @click="goToPage(metricItem)">{{metricItem.value}}</span>
          <peity :type="metricItem.chartType" :data="metricItem.series" :options="metricItem.options" v-if="metricItem.series"></peity>
        </p>
      </div>
    </div>
</template>

<script>

import Peity from 'vue-peity'
import router from '@/router'

export default {
  props: ['metricData'],
  components: {
    Peity
  },
  computed: {
    // Get the metrci item value form state
    metricItem: function () {
      const allMetrics = this.$store.state.metrics.all
      return allMetrics[this.metricData] || {label: this.metricData.toUpperCase(), value: '-'}
    }
  },
  methods: {
    goToPage () {
      const path = this.metricItem.link.path
      const newPath = !this.$route.path.includes(path) || this.$route.query.fltval !== this.metricItem.link.fltval
      if (path && newPath) {
        const query = {
          fltcol: this.metricItem.link.fltcol,
          fltval: this.metricItem.link.fltval,
          fltnme: this.metricItem.link.fltnme
        }
        router.push({path, query})
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
